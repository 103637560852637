// react
import { Dispatch, SetStateAction } from 'react';
//react form
import { Control, Controller, UseFormSetValue, UseFormGetValues } from 'react-hook-form';
// hooks
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
// interfaces
import {
  CreatePriceRuleRequest,
  CourtSportType
} from '@src/domain/interfaces/priceRules/priceRules';

import { WorkingHoursType } from '@interfaces/SportCenters/SportCenter';
//helpers
import {
  getAvailableTimesForTimePicker,
  getValidationForPriceRuleTimePickers,
  handleBenefitsChecked,
  handleChecked
} from '@src/helpers/utility';
// mock
import { workingDaysArr, weekendArr } from '@src/__mock__/createSportsCenter/steps';
// components
import {
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  Accordion,
  AccordionDetails,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Alert,
  AlertTitle,
  Button
} from '@mui/material';
import CustomTimePicker from '@components/CustomTimePicker/CustomTimePicker';
//icons
import { MoneyIcon } from '@components/icons/icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//theme
import theme from '@src/theme';

type NewRuleProps = {
  timePriceArr: number[];
  control: Control<CreatePriceRuleRequest>;
  courtSport: CourtSportType[];
  reqMessage: string;
  timePriceValue: number[];
  setTimePriceValue: Dispatch<SetStateAction<number[]>>;
  workingDays: string[];
  setWorkingDays: Dispatch<SetStateAction<string[]>>;
  setValue: UseFormSetValue<CreatePriceRuleRequest>;
  isSubmitted: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  workingHours: WorkingHoursType;
  getValues: UseFormGetValues<CreatePriceRuleRequest>;
  onCancel: () => void;
  saveAndCreatePriceRule: boolean;
  handleSaveAndCreatePriceRule: () => void;
  handleCreatePriceRule: () => void;
};
const NewRule = ({
  timePriceArr,
  control,
  courtSport,
  reqMessage,
  timePriceValue,
  setTimePriceValue,
  workingDays,
  setWorkingDays,
  setValue,
  isSubmitted,
  open,
  setOpen,
  workingHours,
  getValues,
  onCancel,
  saveAndCreatePriceRule,
  handleSaveAndCreatePriceRule,
  handleCreatePriceRule
}: NewRuleProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const timeStart: number = getValidationForPriceRuleTimePickers(getValues, workingHours).timeStart;
  const timeEnd: number = getValidationForPriceRuleTimePickers(getValues, workingHours).timeEnd;
  const minTime: number = getValidationForPriceRuleTimePickers(getValues, workingHours).minTime;
  const maxTime: number = getValidationForPriceRuleTimePickers(getValues, workingHours).maxTime;
  const lng = localStorage.getItem('lng');
  const isItError: boolean | undefined = getValidationForPriceRuleTimePickers(
    getValues,
    workingHours
  ).isItError;

  const arrayOfAvailableTimes: string[] = getAvailableTimesForTimePicker(minTime, maxTime);

  return (
    <Box width={'100%'}>
      {!open && !saveAndCreatePriceRule && (
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            onClick={() => setOpen(!open)}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t('stepFour.newPriceRule.create_new_price_rule_button')}
          </Button>
        </Grid>
      )}
      {(open || saveAndCreatePriceRule) && (
        <Accordion expanded={true}>
          <AccordionDetails>
            <Box>
              <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
                {t('stepFour.newPriceRule.title')}
              </Typography>
              <Typography variant="subtitle2" color="GrayText">
                {t('stepFour.newPriceRule.subtitle')}
              </Typography>
            </Box>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={12}>
                <Controller
                  name="price_rule.name_translates.sr"
                  control={control}
                  rules={{ required: !lng || lng === 'sr' ? reqMessage : false }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      sx={{ width: '100%' }}
                      label={
                        !lng || lng === 'sr'
                          ? t('stepFour.newPriceRule.price_rule.serbian_name') + ' *'
                          : t('stepFour.newPriceRule.price_rule.serbian_name_optional')
                      }
                      variant="outlined"
                      {...field}
                      error={invalid}
                      helperText={error?.message}
                      id="price_rule.price_rule.name_translates.sr"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="price_rule.name_translates.en"
                  control={control}
                  rules={{ required: lng === 'en' ? reqMessage : false }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      sx={{ width: '100%' }}
                      label={
                        lng === 'en'
                          ? t('stepFour.newPriceRule.price_rule.english_name') + ' *'
                          : t('stepFour.newPriceRule.price_rule.english_name_optional')
                      }
                      variant="outlined"
                      {...field}
                      error={invalid}
                      helperText={error?.message}
                      id="price_rule.price_rule.name_translates.en"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="court_sport_ids"
                  control={control}
                  rules={{ required: reqMessage }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel error={invalid}>
                        {t('stepFour.newPriceRule.court_sport_ids')}
                      </InputLabel>
                      <Select
                        {...field}
                        label={t('stepFour.newPriceRule.court_sport_ids')}
                        variant="outlined"
                        multiple
                        sx={{ width: '100%', '#court_sport_ids': { whiteSpace: 'pre-wrap' } }}
                        error={invalid}
                        id="court_sport_ids"
                      >
                        {courtSport.map(courtSportEl =>
                          courtSportEl.court_sports.map(coSpo => (
                            <MenuItem key={coSpo.id} value={coSpo.id}>
                              {coSpo.court.name} - {coSpo.sport.name}
                            </MenuItem>
                          ))
                        )}
                      </Select>
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="h6"
                py={2}
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                }}
              >
                {t('stepFour.newPriceRule.time_price_form_title')}
                {isSubmitted && !timePriceValue.length && (
                  <FormHelperText error>{reqMessage}</FormHelperText>
                )}
              </Typography>
            </Grid>
            {/* Time and Price */}
            {timePriceArr.map((time, i) => (
              <Grid
                key={time}
                container
                justifyContent={'space-between'}
                alignItems={'center'}
                py={2}
                sx={{
                  borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
                }}
              >
                <Grid item xs={6}>
                  <Controller
                    name={`time_prices.${i}.minutes`}
                    control={control}
                    render={({ field, fieldState: { invalid } }) => (
                      <FormGroup>
                        <FormControlLabel
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              color: `${invalid ? 'red' : 'GrayText'}`
                            }
                          }}
                          control={
                            <Checkbox
                              {...field}
                              onChange={e => {
                                handleBenefitsChecked(e, timePriceValue, setTimePriceValue);
                                field.onChange(
                                  !e.target.checked ? undefined : parseInt(e.target.value)
                                );
                              }}
                              value={time}
                              checked={timePriceValue.includes(time)}
                              sx={{ paddingLeft: 2 }}
                              id={`time_prices_minutes_${time}`}
                            />
                          }
                          label={`${time} ${t('minutes')}`}
                        />
                      </FormGroup>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={`time_prices.${i}.price`}
                    control={control}
                    defaultValue={''}
                    render={({ field, fieldState: { error, invalid } }) => (
                      <TextField
                        {...field}
                        type="number"
                        label={t('price')}
                        variant="outlined"
                        error={invalid}
                        helperText={error?.message}
                        onChange={e =>
                          field.onChange(!e.target.value ? undefined : parseInt(e.target.value))
                        }
                        sx={{ width: '100%' }}
                        id={`time_prices_price_${time}`}
                        disabled={!timePriceValue.includes(time)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <InputAdornment position="start">RSD</InputAdornment>
                              <MoneyIcon />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ))}
            <Alert
              severity="warning"
              sx={{ color: theme.palette.coachEventColor.dark, mt: 2 }}
              icon={<InfoOutlinedIcon fontSize="large" />}
            >
              <AlertTitle color={theme.palette.coachEventColor.dark}>
                <Typography variant="body1" component="span">
                  {t('stepFour.newPriceRule.price_rule_info_message_subtitle1')}{' '}
                  {t('stepFour.newPriceRule.price_rule_info_message_subtitle2')}
                </Typography>{' '}
              </AlertTitle>
              <Grid container>
                <Grid container px={5} pt={2}>
                  <Typography variant="body1" width="100%">
                    {t('stepFour.newPriceRule.price_rule_info_message_subtitle3')}
                  </Typography>
                  <Typography variant="body1" width="100%" fontFamily="Inter-Bold">
                    {t('mon')} - {t('fri')}: {workingHours.workweek_from} -{' '}
                    {workingHours.workweek_to === '23:59' ? '24:00' : workingHours.workweek_to}
                  </Typography>
                  <Typography variant="body1" width="100%" fontFamily="Inter-Bold">
                    {t('sat')}: {workingHours.saturday_from} -{' '}
                    {workingHours.saturday_to === '23:59' ? '24:00' : workingHours.saturday_to}
                  </Typography>
                  <Typography variant="body1" fontFamily="Inter-Bold">
                    {t('sun')}: {workingHours.sunday_from} -{' '}
                    {workingHours.sunday_to === '23:59' ? '24:00' : workingHours.sunday_to}
                  </Typography>
                </Grid>
              </Grid>
            </Alert>

            {/* Days and time */}
            <Grid container justifyContent="space-between">
              <Grid item sm={12}>
                <Typography variant="h6" py={2} pt={5}>
                  {t('days_and_time')}
                </Typography>
              </Grid>
              {/* time from */}
              <Grid container justifyContent="flex-start" wrap="nowrap" gap={2}>
                <Grid item xs={6}>
                  <Controller
                    name="price_rule.time_start"
                    control={control}
                    render={({ field, fieldState: { error, invalid } }) => (
                      <CustomTimePicker
                        field={field}
                        id="price_rule.time_start"
                        labelId="price_rule.time_start-label"
                        label={t('time_start')}
                        invalid={invalid}
                        availableTimes={arrayOfAvailableTimes}
                        className="fullWidth"
                        helperText={
                          timeStart > timeEnd && timeEnd !== 0
                            ? t(
                                'stepFour.newPriceRule.price_rule.time_start_error_message'
                              ).toString()
                            : isItError
                            ? t('stepFour.newPriceRule.price_rule.time_error_message').toString()
                            : error?.message
                        }
                      />
                    )}
                  />
                </Grid>
                {/* time end */}
                <Grid item xs={6}>
                  <Controller
                    name="price_rule.time_end"
                    control={control}
                    render={({ field, fieldState: { error, invalid } }) => (
                      <CustomTimePicker
                        field={field}
                        id="price_rule.time_end"
                        labelId="price_rule.time_end-label"
                        label={t('time_end')}
                        invalid={invalid}
                        availableTimes={arrayOfAvailableTimes}
                        className="fullWidth"
                        helperText={
                          timeStart > timeEnd && timeEnd !== 0
                            ? t(
                                'stepFour.newPriceRule.price_rule.time_end_error_message'
                              ).toString()
                            : isItError
                            ? t('stepFour.newPriceRule.price_rule.time_error_message').toString()
                            : error?.message
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container pt={5}>
                {isSubmitted && !workingDays.length && (
                  <Grid item sm={12} pl={2}>
                    <FormHelperText error>{reqMessage}</FormHelperText>
                  </Grid>
                )}
                {/* Working days */}
                <Grid item xs={6} md={3} pl={2}>
                  {workingDaysArr?.map(day => {
                    return (
                      <Controller
                        key={day}
                        name={`price_rule.${day}`}
                        control={control}
                        defaultValue={false}
                        render={({ field, fieldState: { error, invalid } }) => (
                          <FormGroup>
                            <FormControlLabel
                              key={day}
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  color: `${invalid ? 'red' : 'GrayText'}`
                                }
                              }}
                              control={
                                <Checkbox
                                  {...field}
                                  onChange={e => {
                                    handleChecked(e, workingDays, setWorkingDays);

                                    if (e.target.value === t('workweek')) {
                                      if (e.target.checked) {
                                        setValue('price_rule.mon', true);
                                        setValue('price_rule.tue', true);
                                        setValue('price_rule.wed', true);
                                        setValue('price_rule.thu', true);
                                        setValue('price_rule.fri', true);
                                        setValue('price_rule.workweek', true);
                                      } else {
                                        setWorkingDays([]);

                                        setValue('price_rule.mon', false);
                                        setValue('price_rule.tue', false);
                                        setValue('price_rule.wed', false);
                                        setValue('price_rule.thu', false);
                                        setValue('price_rule.fri', false);
                                        setValue('price_rule.workweek', false);
                                      }
                                    }
                                    field.onChange(e.target.checked);
                                  }}
                                  checked={
                                    workingDays.includes(t(day)) ||
                                    workingDays.includes(`${t('workweek')}`) ||
                                    workingDays.includes(`${t('wholeweek')}`)
                                  }
                                  name={t(day).toString()}
                                  value={t(day).toString()}
                                />
                              }
                              label={t(day).toString()}
                            />
                          </FormGroup>
                        )}
                      />
                    );
                  })}
                </Grid>
                {/* weekend */}
                <Grid item xs={6} md={3}>
                  {weekendArr?.map(day => {
                    return (
                      <Controller
                        key={day}
                        name={`price_rule.${day}`}
                        control={control}
                        defaultValue={false}
                        render={({ field, fieldState: { invalid } }) => (
                          <FormGroup>
                            <FormControlLabel
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  color: `${invalid ? 'red' : 'GrayText'}`
                                }
                              }}
                              control={
                                <Checkbox
                                  {...field}
                                  onChange={e => {
                                    handleChecked(e, workingDays, setWorkingDays);

                                    if (e.target.value === t('weekend')) {
                                      if (e.target.checked) {
                                        setValue('price_rule.sat', true);
                                        setValue('price_rule.sun', true);
                                        setValue('price_rule.weekend', true);
                                      } else {
                                        setWorkingDays([]);
                                        setValue('price_rule.sat', false);
                                        setValue('price_rule.sun', false);
                                        setValue('price_rule.weekend', false);
                                      }
                                    }
                                    field.onChange(e.target.checked);
                                  }}
                                  checked={
                                    workingDays.includes(t(day).toString()) ||
                                    workingDays.includes(`${t('weekend')}`) ||
                                    workingDays.includes(`${t('wholeweek')}`)
                                  }
                                  name={t(day).toString()}
                                  value={t(day).toString()}
                                />
                              }
                              label={t(day).toString()}
                            />
                          </FormGroup>
                        )}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>

            {!params.priceRuleId && (
              <Box display={'flex'} justifyContent="flex-end" my={2}>
                <Button sx={{ color: 'gray', mr: 1 }} size="small" onClick={() => onCancel()}>
                  {t('cancel')}
                </Button>
                <Button variant="contained" onClick={handleSaveAndCreatePriceRule} sx={{ mr: 1 }}>
                  {t('add_and_create_new_price_rule')}
                </Button>
                <Button variant="contained" onClick={handleCreatePriceRule}>
                  {t('save')}
                </Button>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default NewRule;
