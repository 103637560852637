//interfaces
import { WholeOwnersResponse } from '@interfaces/user/owner/owner';
//services
import { owners } from '@services/user/owner/owner';
//react-query
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useOwners = (
  pageIndex: number,
  pageSize: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string,
  onError: (err: Error) => void
): UseQueryResult<WholeOwnersResponse, Error> =>
  useQuery(
    ['owners', pageIndex, pageSize, search, gender, order_by, order_direction],
    () => owners(pageIndex, pageSize, search, gender, order_by, order_direction),
    {
      onError: err => onError(err)
    }
  );
