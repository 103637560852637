//components
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  SxProps,
  Typography
} from '@mui/material';
// icons
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
// hooks
import { useTranslation } from 'react-i18next';

// helpers
import { randomColorsForGraphs as COLORS } from '@helpers/utility';

type EarningsDataType = {
  earnings: number;
  outOf: number;
};
type TransactionDataType = {
  name: string;
  value: number;
};

type FinanceCardProps = {
  data: EarningsDataType | TransactionDataType[];
  title?: string;
  content?: string;
  sx?: SxProps;
};

const FinanceCard = ({ data, title = '', content = '', sx }: FinanceCardProps) => {
  const { t } = useTranslation();

  const renderEarningsTypeProgress = () => {
    if (!('earnings' in data)) return null;
    return (
      <Grid container spacing={2}>
        <Box pl={2} display="flex" alignItems="baseline">
          <Typography variant={'h4'}>{data.earnings.toLocaleString()} RSD</Typography>
          <Typography variant={'h6'} ml={2} sx={{ alignSelf: 'baseline' }}>
            {t('from').toLowerCase() + ' ' + data.outOf.toLocaleString()} RSD
          </Typography>
        </Box>
        <Grid item xs={12}>
          <LinearProgress
            sx={{
              height: '8px',
              borderRadius: '12px',
              '& .MuiLinearProgress-bar': { borderRadius: '12px' }
            }}
            variant="determinate"
            value={(data.earnings / data.outOf) * 100}
            valueBuffer={100}
          />
        </Grid>
      </Grid>
    );
  };
  const renderTransactionTypeProgress = () => {
    if (!Array.isArray(data)) return null;
    const determenWidthInPercent = (value: number) => {
      let sum = 0;
      data.forEach((transaction, index) => {
        sum += transaction.value;
      });
      return (value / sum) * 100;
    };
    return (
      <Grid container justifyContent="space-around">
        {data.map((transaction, index) => (
          <Box
            key={index}
            width={determenWidthInPercent(transaction.value) + '%'}
            display="flex"
            flexDirection="column"
          >
            <Box
              height={'8px'}
              borderRadius={'12px'}
              width="100%"
              mb={3}
              sx={{ backgroundColor: COLORS[index] }}
            />
            <Box display="flex" alignItems="center">
              <Box
                width="14px"
                height="14px"
                mr={1}
                sx={{ display: 'inline', backgroundColor: COLORS[index], borderRadius: '50%' }}
              />{' '}
              <Typography variant="subtitle1">{transaction.name}</Typography>
            </Box>
            <Typography variant="subtitle2" fontFamily="Inter-bold">
              {transaction.value.toLocaleString('en-US')} RSD
            </Typography>
          </Box>
        ))}
      </Grid>
    );
  };
  return (
    <Card sx={sx}>
      <CardHeader
        title={title}
        action={<Button endIcon={<NavigateNextIcon />}>{t('look').toUpperCase()}</Button>}
      />
      <CardContent>
        {content && <Typography variant={'body2'}>{content}</Typography>}
        {renderEarningsTypeProgress()}
        {renderTransactionTypeProgress()}
      </CardContent>
    </Card>
  );
};

export default FinanceCard;
