import {
  AnalyticsCountsResponseType,
  AppointmentsCountByHourResponseType,
  CourtCountBySportResponseType,
  LatestAppointmentsResponseType,
  LatestUsersResponseType,
  SportCenterAnalyticCountResponseType,
  SportCenterMostActiveUsersResponseType
} from '@interfaces/analytics/analytics';
import axiosInstance from '@services/config';

export const getAnalyticsCounts = (): Promise<{ data: AnalyticsCountsResponseType }> =>
  axiosInstance.get('/analytics/counts');

export const getLatestUsers = (limit?: number): Promise<{ data: LatestUsersResponseType }> =>
  axiosInstance.get('/analytics/latest-users', {
    params: {
      limit: limit
    }
  });

export const getAppointmentsCountByHour = (
  datetime_from?: string,
  datetime_to?: string
): Promise<{ data: AppointmentsCountByHourResponseType }> =>
  axiosInstance.get('/analytics/appointments-count-by-hour', {
    params: {
      datetime_from: datetime_from,
      datetime_to: datetime_to
    }
  });

export const getLatestappointments = (
  limit?: number
): Promise<{ data: LatestAppointmentsResponseType }> =>
  axiosInstance.get('/analytics/latest-appointments', {
    params: {
      limit: limit
    }
  });

export const getCourtsCountBySport = (): Promise<{ data: CourtCountBySportResponseType }> =>
  axiosInstance.get('/analytics/courts-count-by-sport');

export const getSportCenterAnalyticsCount = (
  sportCenter: number
): Promise<{ data: SportCenterAnalyticCountResponseType }> =>
  axiosInstance.get(`/analytics/by-sport-center/${sportCenter}/appointments`);

export const getSportCenterAppointmentsCountByHour = (
  sportCenter: number,
  datetime_from?: string,
  datetime_to?: string
): Promise<{ data: SportCenterAnalyticCountResponseType }> =>
  axiosInstance.get(`/analytics/by-sport-center/${sportCenter}/appointments-count-by-hour`, {
    params: {
      sportCenter: sportCenter,
      datetime_from: datetime_from,
      datetime_to: datetime_to
    }
  });

export const getSportCenterMostActiveUsers = (
  sportCenter: number,
  limit?: number
): Promise<{ data: SportCenterMostActiveUsersResponseType }> =>
  axiosInstance.get(`/analytics/by-sport-center/${sportCenter}/most-active-users`, {
    params: {
      sportCenter: sportCenter,
      limit: limit
    }
  });
