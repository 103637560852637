//react
import { FC } from 'react';
//router
import { Outlet } from 'react-router-dom';
//icons
import { DarkLogo, LanguageGlobe, WhiteLanguageGlobe, WhiteLogo } from '@components/icons/icons';
//mui
import { Typography, Grid, Box } from '@mui/material';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
//image
import LoginImage from '@src/assets/images/darkLoginImage.png';
import MobileLoginImage from '@src/assets/images/darkMobileLogin.png';
//translation
import i18n from '@src/Translation/i18n';
//hooks
import { useTranslation } from 'react-i18next';
import useWindowSize from '@hooks/windowSize/useWindowSize';

const LoginLayout: FC = (): JSX.Element => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const onLanguageChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem('lng', event.target.value);
  };
  return (
    <Box sx={{ p: { xs: 0, sm: 1.5 } }} m={{ xs: -1, sm: 0 }}>
      <Grid
        container
        sx={{
          backgroundImage: { xs: `url(${MobileLoginImage})`, sm: 'none' },
          backgroundSize: 'cover'
        }}
        height={63}
        justifyContent={'space-between'}
      >
        <Grid item xs={12} sm={5.7} mt={{ xs: 0.8, sm: 0 }}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            ml={{ xs: 1.5, sm: 0 }}
            mr={{ xs: 1.5, sm: 0 }}
          >
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
              {width > 599 ? (
                <DarkLogo sx={{ width: 'auto', height: '30px' }} />
              ) : (
                <WhiteLogo sx={{ width: 'auto', height: '30px' }} />
              )}
              <Typography
                mb={0.5}
                sx={{
                  color: { xs: 'white', sm: 'black' },
                  fontWeight: 600,
                  fontSize: '14px'
                }}
                ml={1}
                mt={0.5}
              >
                SportyMate
              </Typography>
            </Box>
            <Select
              onChange={onLanguageChange}
              name="language"
              sx={{
                width: '83px',
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 }
              }}
              defaultValue={`${i18n.language ? i18n.language : 'sr'}`}
              size="small"
              startAdornment={
                width > 600 ? (
                  <LanguageGlobe sx={{ width: 'auto', height: '24px', marginRight: 1 }} />
                ) : (
                  <WhiteLanguageGlobe sx={{ width: 'auto', height: '24px', marginRight: 1 }} />
                )
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundImage: width < 600 ? `url(${MobileLoginImage})` : 'none'
                  }
                }
              }}
              IconComponent={() => null}
            >
              <MenuItem value="sr">
                <Typography
                  color={{ xs: 'white', sm: 'black' }}
                  fontWeight={600}
                  fontSize={'16px'}
                  letterSpacing={'-0.5px'}
                >
                  SRB
                </Typography>
              </MenuItem>
              <MenuItem value="en">
                <Typography
                  color={{ xs: 'white', sm: 'black' }}
                  fontWeight={600}
                  fontSize={'16px'}
                  letterSpacing={'-0.5px'}
                >
                  EN
                </Typography>
              </MenuItem>
            </Select>
          </Box>
          <Box
            width={'100%'}
            display={{ xs: 'none', sm: 'flex' }}
            justifyContent={'center'}
            ml={3}
            mt={screen.height === 600 && width < 1200 && width > 900 ? 1.5 : 17}
          >
            <Outlet />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={5.7}
          display={{ xs: 'none', sm: 'flex' }}
          justifyContent={'end'}
          sx={{
            backgroundImage: `url(${LoginImage})`,
            backgroundSize: 'cover',
            height: '95vh',
            borderRadius: '36px',
            flexDirection: 'column',
            alignItems: 'start',
            justifyContent: 'end',
            color: 'white'
          }}
        >
          <Typography fontWeight={500} fontSize={'64px'} letterSpacing={'-3.5px'} ml={4}>
            {t('loginPage.all_sports')}
          </Typography>
          <Typography
            fontWeight={500}
            fontSize={'64px'}
            letterSpacing={'-3.5px'}
            mb={6}
            ml={4}
            mt={-2}
          >
            {t('loginPage.in_app')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        display={{ xs: 'flex', sm: 'none' }}
        borderRadius={'12px'}
        sx={{ background: 'white' }}
        mt={-1.2}
      >
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginLayout;
