//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

// services
import { getAllCoaches, getCoaches, getSuperAdminCoach } from '@services/coaches/coaches';
// interfaces
import {
  AllCoachesResponseType,
  CoachErrorResponseType,
  CoachErrorType,
  SuperAdminCoachResponseType
} from '@interfaces/user/coaches/coaches';
import { ErrorResponse } from '@interfaces/apiResponse';

export const useAllAdminCoachesQuery = (
  sportCenterId: number,
  page: number,
  per_page: number,
  search: string,
  onError?: (err: CoachErrorType) => void
): UseQueryResult<AllCoachesResponseType> =>
  useQuery(
    ['coaches', sportCenterId, page, per_page, search],
    () => getCoaches(sportCenterId, page, per_page, search),
    {
      onError: (err: CoachErrorResponseType) => onError && onError(err.response.data)
    }
  );

export const useAllCoachesQuery = (
  page: number,
  per_page: number,
  search: string,
  gender: string[],
  order_by: string,
  order_direction: string,
  onError?: (err: CoachErrorType) => void
): UseQueryResult<AllCoachesResponseType> =>
  useQuery(
    ['coaches', page, per_page, search, gender, order_by, order_direction],
    () => getAllCoaches(page, per_page, search, gender, order_by, order_direction),
    {
      onError: (err: CoachErrorResponseType) => onError && onError(err.response.data)
    }
  );
export const useGetSuperAdminCoachQuery = (
  superAdminCoachId: number,
  onError?: (err: CoachErrorType) => void
): UseQueryResult<SuperAdminCoachResponseType> =>
  useQuery(['superAdminCoach', superAdminCoachId], () => getSuperAdminCoach(superAdminCoachId), {
    onSuccess: data => data.data.data,
    onError: (err: CoachErrorResponseType) => onError && onError(err.response.data)
  });
