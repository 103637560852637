//interface
import { AllRolesResponseType, RoleType, SingleRoleResponseType } from '@interfaces/roles/roles';
//service
import { getAllSportCenterRoles, getSingleSportCenterRole } from '@services/roles/roles';
import { getAllRoles, getSingleRole } from '@services/roles/roles';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useAllRoles = (
  onError: (err: Error) => void,
  sportCenterId?: number
): UseQueryResult<AllRolesResponseType, Error> =>
  useQuery(
    ['all-roles', sportCenterId],
    () => {
      if (sportCenterId) return getAllSportCenterRoles(sportCenterId);
      return getAllRoles();
    },
    {
      onError: err => onError(err)
    }
  );

export const useSingleRole = (
  roleId: number | undefined,
  onSuccess: (data: RoleType | undefined) => void,
  onError: (err: Error) => void,
  sportCenterId?: number
): UseQueryResult<SingleRoleResponseType | null, Error> =>
  useQuery(
    ['single-role', roleId, sportCenterId],
    () => {
      if (roleId && sportCenterId) return getSingleSportCenterRole({ sportCenterId, roleId });
      if (roleId) return getSingleRole(roleId);
      return null;
    },
    {
      select: res => res,
      onSuccess: data => onSuccess(data?.data.data),
      onError: err => onError(err)
    }
  );

// export const useAllSportCenterRoles = (
//   sportCenterId: number | null,
//   onError: (err: Error) => void,

// ): UseQueryResult<AllRolesResponseType, Error> =>
//   useQuery(
//     ['all-sc-roles', sportCenterId],
//     () => {
//       if (sportCenterId) return getAllSportCenterRoles(sportCenterId);
//       return null;
//     },
//     {
//       onError: err => onError(err)
//     }
//   );

// export const useSingleSportCenterRole = (
//   ids: SingleSportCenterRoleRequestType | null,
//   onSuccess: (data: RoleType | undefined) => void,
//   onError: (err: Error) => void
// ): UseQueryResult<SingleRoleResponseType | null, Error> =>
//   useQuery(
//     ['single-sc-role', ids?.roleId, ids?.sportCenterId],
//     () => {
//       if (ids) return getSingleSportCenterRole(ids);
//       return null;
//     },
//     {
//       select: res => res,
//       onSuccess: data => onSuccess(data?.data.data),
//       onError: err => onError(err)
//     }
//   );
