// hooks
import { useTranslation } from 'react-i18next';
// types
import { AutocompleteType } from '@interfaces/locationsGeoCode/locationsGeoCode';
import { ControllerRenderProps } from 'react-hook-form';
// components
import {
  Autocomplete,
  TextField,
  debounce,
  CircularProgress,
  AutocompleteRenderInputParams
} from '@mui/material';
import { ReactNode } from 'react';

type SearchGeoCodeProps = {
  handleAutocompleteChange: (
    event: React.SyntheticEvent,
    option: string | AutocompleteType | null,
    reason: string
  ) => void;
  handleSearchChange: (event: React.SyntheticEvent, value: string, reason: string) => void;
  locations: AutocompleteType[];
  isLoadingLocationsList: boolean;
  label: string;
  optionLabel: string;
  field?: ControllerRenderProps<any, any>;
  id?: string;
  error?: boolean;
  helperText?: ReactNode;
};
const SearchGeoCode = ({
  handleSearchChange,
  handleAutocompleteChange,
  locations,
  isLoadingLocationsList,
  label,
  optionLabel,
  field,
  id,
  error,
  helperText
}: SearchGeoCodeProps) => {
  const { t } = useTranslation();
  const handleGetOptionLabel = (option: AutocompleteType | string): string => {
    if (
      typeof option !== 'string' &&
      (option.street || option.houseNumber || option.city || option.country)
    ) {
      return `${option.street !== 'Unknown' || '' ? option.street : ''}, ${
        option.houseNumber ? option.houseNumber : ''
      },  ${option.country !== 'Unknown' || '' ? option.country : ''}`;
    }
    if (typeof option === 'string') {
      return option;
    }
    return optionLabel;
  };

  const handleRenderOptions = (props: {}, option: AutocompleteType) => (
    <li {...props} key={option.id} id={option.id}>
      {option.street !== 'Unknown' || '' ? option.street + ', ' : ''}
      {option.houseNumber && option.houseNumber + ', '}
      <br />
      {option.city !== 'Unknown' || '' ? option.city + ', ' : ''}
      {option.country !== 'Unknown' || '' ? option.country : ''}
    </li>
  );

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label}
      error={error}
      helperText={helperText}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {isLoadingLocationsList ? <CircularProgress color="primary" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        )
      }}
    />
  );

  return (
    <Autocomplete
      {...field}
      id={id}
      freeSolo
      options={locations}
      loading={isLoadingLocationsList}
      openOnFocus
      noOptionsText={t('noLocationsText')}
      filterOptions={options => options}
      getOptionLabel={handleGetOptionLabel}
      renderOption={handleRenderOptions}
      onChange={handleAutocompleteChange}
      onInputChange={debounce(handleSearchChange, 500)}
      renderInput={renderInput}
    />
  );
};

export default SearchGeoCode;
