import { sportCenter } from '@services/sportCenter/sportCenter';
import { useEffect, useState } from 'react';
//interface
import { AxiosRequestConfig, AxiosProgressEvent } from 'axios';
import {
  UploadImageResponse,
  UploadImageError,
  ImageType
} from '@src/domain/interfaces/Images/images';
import { SuccessResponse, ErrorResponse } from '@src/domain/interfaces/apiResponse';
// hooks
import { useSetRecoilState } from 'recoil';
import {
  useUploadSCImage,
  useDeleteSCImage
} from '@src/infrastructure/api/mutations/images/images';

//helpers
import { onUploadProgress } from '@src/helpers/utility';
//atoms
import { stepOneDataAtom } from '@src/domain/atoms/stepOneData';
import { useNavigate, useParams } from 'react-router-dom';
import { useSingleSportCenter } from '@src/infrastructure/api/mutations/sportCenter/sportCenter';
import {
  SportCenterError,
  SportCenterResponse
} from '@src/domain/interfaces/SportCenters/SportCenter';
import { popupAtom } from '@atoms/popupAtom';

export function useCreateSportCenterStep2() {
  const [images, setImages] = useState<ImageType[]>([]);
  const setStepOneData = useSetRecoilState(stepOneDataAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const navigate = useNavigate();
  const params = useParams();
  const uploadPercentOptions: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      onUploadProgress(progressEvent, setUploadPercent)
  };
  const onUploadImageSuccess = (data: UploadImageResponse) => {
    setImages(images => [...images, data.data]);
  };
  const onUploadImageError = (err: UploadImageError) => {
    // alert(err.message);
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const onDeleteImageSuccess = (data: SuccessResponse) => {};
  const onDeleteImageError = (err: ErrorResponse) => {
    // alert(err.message);
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const onSingleSportCenterSuccess = (data: SportCenterResponse) => {
    if (data.data.gallery_images?.length) {
      setImages(data.data.gallery_images);
    }
  };
  const onSingleSportCenterError = (err: SportCenterError) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const { mutate: uploadImage, isLoading } = useUploadSCImage(
    onUploadImageSuccess,
    onUploadImageError
  );
  const { mutate: deleteImage } = useDeleteSCImage(onDeleteImageSuccess, onDeleteImageError);
  const handleDelete = (imageID: number | string) => {
    if (params.sportCenterId) {
      deleteImage({ objectId: params.sportCenterId, imageID: imageID });
      setImages(images =>
        images.filter((image, i) => images.findIndex(el => el.id === imageID) !== i)
      );
    }
  };
  const { mutate: getSingleSportCenter } = useSingleSportCenter(
    onSingleSportCenterSuccess,
    onSingleSportCenterError
  );
  const onDropCallback = (image: File) => {
    let imageFormData = new FormData();
    imageFormData.append('image', image);
    imageFormData.append('is_featured', '0');
    if (params.sportCenterId) {
      uploadImage({
        id: params.sportCenterId,
        data: imageFormData,
        uploadPercentOptions: uploadPercentOptions
      });
    }
  };

  const onHandleNext = () => {
    setStepOneData(stepOneData => ({
      ...stepOneData,
      basic_info: {
        ...stepOneData.basic_info,
        gallery_images: images
      }
    }));
    navigate(`/objects/create-object/${params.sportCenterId}/court`);
  };
  const onHandleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    if (params.sportCenterId) {
      getSingleSportCenter(parseInt(params.sportCenterId));
    }
  }, []);
  return {
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    isLoading,
    onHandleBack,
    onHandleNext
  };
}
