import {
  LegalDataInterface,
  LegalDataKeyType,
  OpenHoursKeys,
  OpenHours
} from '@interfaces/SportCenters/SportCenter';

export const legalDataNames: LegalDataInterface = {
  name: 'Pun naziv privrednog subjekta',
  tax_identification_number: '',
  identification_number: 'PIB',
  bank_account_number: 'Broj racuna',
  bank_name: 'Naziv banke',
  address: 'Adresa privrednog subjekta',
  city: '',
  municipality: '',
  legal_representative_1: 'Zakonski zastupnik',
  in_vat_system: 'Da li je u sistemu PDV-a?',
  e_invoicing_email: 'Email za SEF',
  contract_expiry_date: '',
  contract_start_date: ''
};

export const legalDataKeys: LegalDataKeyType[] = [
  { name: 'name' },
  { name: 'address' },
  { name: 'identification_number' },
  { name: 'bank_account_number' },
  { name: 'bank_name' },
  { name: 'legal_representative_1' },
  { name: 'in_vat_system' },
  { name: 'e_invoicing_email' },
  { name: 'contract_start_date' },
  { name: 'contract_expiry_date' }
];

export const openHours: OpenHours = {
  workweek: 'Radno vreme radnim danima',
  saturday: 'Radno vreme subotom',
  sunday: 'Radno vreme nedeljom'
};

export const openHoursKeys: OpenHoursKeys[] = [
  {
    day_type: 'workweek',
    from: 'workweek_from',
    to: 'workweek_to'
  },
  {
    day_type: 'saturday',
    from: 'saturday_from',
    to: 'saturday_to'
  },
  {
    day_type: 'sunday',
    from: 'sunday_from',
    to: 'sunday_to'
  }
];
