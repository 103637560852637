// hooks
import { useCreateSportCenterStep1 } from '@src/ui/hooks/createSportCenterStep1/useCreateSportCenterStep1';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
// components
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import BasicData from '@src/ui/components/Forms/SportCenters/BasicData/BasicData';
import Logo from '@src/ui/components/Forms/SportCenters/Logo/Logo';
import LegalData from '@src/ui/components/Forms/SportCenters/LegalData/LegalData';
import OtherData from '@src/ui/components/Forms/SportCenters/OtherData/OtherData';
import Payment from '@src/ui/components/Forms/SportCenters/Payment/Payment';
import MapsView from '@components/MapsView/MapsView';
//types
import { Control } from 'react-hook-form';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
import { BusinessUserTypeRequest } from '@interfaces/user/user';
//atom
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermission } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const StepOne = () => {
  const {
    handleSubmit,
    onSubmit,
    reqMessage,
    control,
    isItPDV,
    setIsItPDV,
    isPaymentChecked,
    setIsPaymentChecked,
    benefitsRes,
    isBenefitsChecked,
    setIsBenefitsChecked,
    handleCancel,
    handleOnLogoChange,
    handleLogoClick,
    isCreateSportCenterLoading,
    isUpdateSingleSportCenterLoading,
    isSingleSportCenterLoading,
    isUploadLogoLoading,
    handleAutocompleteChange,
    handleSearchChange,
    isLoadingLocation,
    isLoadingLocationsList,
    handleAutocompleteChangeLegalData,
    handleSearchChangeLegalData,
    locations,
    locationsLegalData,
    isLoadingLocationsListLegalData,
    isLoadingLocationLegalData,
    centerMapBasicData,
    centerMapLegalData,
    getValues,
    validateSaturday,
    validateSunday,
    validateWokweek,
    handleOnMarkerChange,
    wholeWorkingDay,
    handleChangeWholeWorkingDay,
    wholeSaturdayDay,
    handleChangeWholeSaturdayDay,
    handleChangeOffWholeSaturdayDay,
    wholeSundayDay,
    handleChangeWholeSundayDay,
    handleChangeOffWholeSundayDay
  } = useCreateSportCenterStep1();

  const { t } = useTranslation();
  const currentCMS = useRecoilValue(currentCMSAtom);

  if (
    isCreateSportCenterLoading ||
    isUpdateSingleSportCenterLoading ||
    isSingleSportCenterLoading ||
    isUploadLogoLoading
  ) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Box component={'form'} onSubmit={handleSubmit(onSubmit)} sx={{ padding: '1%' }}>
      <CardWrapper isForm={true} title="">
        <BasicData
          control={control}
          reqMessage={reqMessage}
          handleAutocompleteChange={handleAutocompleteChange}
          handleSearchChange={handleSearchChange}
          isLoadingLocation={isLoadingLocation}
          isLoadingLocationsList={isLoadingLocationsList}
          locations={locations}
        />
        {!isLoadingLocation && centerMapBasicData.lat > 0 && (
          <MapsView
            id="cms-map-basic-data"
            center={centerMapBasicData}
            handleOnMarkerChange={handleOnMarkerChange}
            draggable
          />
        )}
        <Logo
          control={control}
          handleLogoClick={handleLogoClick}
          handleOnLogoChange={handleOnLogoChange}
        />
      </CardWrapper>

      <LegalData
        control={control as Control<CreateSportCenterType | BusinessUserTypeRequest>}
        isItPDV={isItPDV}
        setIsItPDV={setIsItPDV}
        reqMessage={reqMessage}
        handleAutocompleteChange={handleAutocompleteChangeLegalData}
        handleSearchChange={handleSearchChangeLegalData}
        isLoadingLocation={isLoadingLocationLegalData}
        isLoadingLocationsList={isLoadingLocationsListLegalData}
        locations={locationsLegalData}
        centerMapLegalData={centerMapLegalData}
      />

      <Payment
        control={control}
        isPaymentChecked={isPaymentChecked}
        setIsPaymentChecked={setIsPaymentChecked}
        reqMessage={reqMessage}
      />

      <OtherData
        control={control}
        benefitsRes={benefitsRes}
        isBenefitsChecked={isBenefitsChecked}
        setIsBenefitsChecked={setIsBenefitsChecked}
        reqMessage={reqMessage}
        getValues={getValues}
        wholeWorkingDay={wholeWorkingDay}
        handleChangeWholeWorkingDay={handleChangeWholeWorkingDay}
        wholeSaturdayDay={wholeSaturdayDay}
        handleChangeWholeSaturdayDay={handleChangeWholeSaturdayDay}
        handleChangeOffWholeSaturdayDay={handleChangeOffWholeSaturdayDay}
        wholeSundayDay={wholeSundayDay}
        handleChangeWholeSundayDay={handleChangeWholeSundayDay}
        handleChangeOffWholeSundayDay={handleChangeOffWholeSundayDay}
      />

      <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
        {checkPermission(currentCMS, PermissionsEnum.update_sport_centers) && (
          <Button variant="text" color="primary" onClick={handleCancel}>
            {t('quit')}
          </Button>
        )}

        <Button
          type="submit"
          disabled={validateWokweek || validateSaturday || validateSunday}
          variant="contained"
        >
          {t('next')}
        </Button>
      </Box>
    </Box>
  );
};

export default StepOne;
