//components
import DataCard from '@components/DataCard/DataCard';
import { Alert, Box, Button, IconButton } from '@mui/material';
//icons
import AddIcon from '@mui/icons-material/Add';
import { DeleteIcon, EditIcon } from '@components/icons/icons';
//types
import { MRT_ColumnDef } from 'material-react-table';
import { NonWorkingDaysType } from '@interfaces/nonWorkingDays/nonWorkingDays';
//hooks
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useNonWorkingDays } from '@hooks/useNonWorkingDays/useNonWorkingDays';
import { useTranslation } from 'react-i18next';
//helpers
import dayjs from 'dayjs';
//recoil
import { useSetRecoilState, useResetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';

const NonWorkingDays = () => {
  const navigate = useNavigate();
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const { sportCenterId } = useParams();

  const { deleteNonWorkingDay, nonWorkingDays, isLoadingDelete, success, lng } =
    useNonWorkingDays();

  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<NonWorkingDaysType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('non_working_days.name'),
        Cell: ({ row }) =>
          lng == 'en' && row.original.name_translates.en
            ? row.original.name_translates.en
            : row.original.name_translates.sr
      },
      {
        accessorKey: 'date_start',
        header: t('from'),
        Cell: ({ row }) => dayjs(row.original.date_start).format('DD.MM.YYYY.')
      },
      {
        accessorKey: 'date_end',
        header: t('to'),
        Cell: ({ row }) => dayjs(row.original.date_end).format('DD.MM.YYYY.')
      },
      {
        accessorKey: 'id',
        header: '',
        Cell: ({ row }) => (
          <Box width={'100%'} display="flex" justifyContent={'space-evenly'}>
            <IconButton
              onClick={() => {
                navigate('edit-non-working-days/' + row.original.id);
              }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setPopup({
                  open: true,
                  variant: 'delete',
                  title:
                    lng === 'en'
                      ? t('non_working_days.deleteMessage').toString() +
                        row.original.name_translates.en +
                        '?'
                      : t('non_working_days.deleteMessage').toString() +
                        row.original.name_translates.sr +
                        '?',
                  content: '',
                  onClick: () => {
                    sportCenterId &&
                      deleteNonWorkingDay({
                        sportCenterId: +sportCenterId,
                        nonWorkingDaysId: row.original.id
                      });
                    resetPopup();
                  }
                });
              }}
              disabled={isLoadingDelete}
            >
              <DeleteIcon stroke="#D50000" />
            </IconButton>
          </Box>
        )
      }
    ],
    [isLoadingDelete]
  );

  return (
    <Box>
      <Box pr={2} sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <Button
          variant="contained"
          size="medium"
          sx={{ width: 100 }}
          onClick={() => {
            navigate('create-non-working-days');
          }}
        >
          <AddIcon /> {t('add')}
        </Button>
      </Box>
      <Box p={2}>
        {success && <Alert sx={{ position: 'fixed', width: '75%', zIndex: 1 }}>{success}</Alert>}
        <DataCard
          title={t('nonWorkingDays')}
          columns={columns}
          data={nonWorkingDays}
          showHeader={true}
        />
      </Box>
    </Box>
  );
};

export default NonWorkingDays;
