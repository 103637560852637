//react
import { ChangeEvent, Dispatch, FC, MouseEvent, SetStateAction } from 'react';
//hooks
import { useTranslation } from 'react-i18next';
//mui
import { Box, Grid, IconButton, Menu, MenuItem, TextField } from '@mui/material';
//icons
import { FilterIcon } from '@components/icons/icons';
import MenuIcon from '@mui/icons-material/Menu';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
//interfaces
import { FilterProps, TableVariantType } from '@interfaces/table/table';
//mock
import { tableHeaderSortData } from '@src/__mock__/tableHeaderSort/tableHeaderSortData';
interface MobileTableHeaderProps {
  tableVariant: TableVariantType;
  setOpenMobileMenu: Dispatch<SetStateAction<boolean>>;
  handleSearch: ((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void) | undefined;
  filterValues: FilterProps;
  handleClick?: (event: MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLDivElement>) => void;
  handleClose?: () => void;
  anchorSortEl?: null | HTMLElement;
  handleSortTables?: (index: number, tableVariant: TableVariantType) => void;
  checkSort?: number | null;
  handleClearSort?: () => void;
}

const MobileTableHeader: FC<MobileTableHeaderProps> = ({
  tableVariant,
  setOpenMobileMenu,
  handleSearch,
  filterValues,
  handleClick,
  handleClose,
  anchorSortEl,
  handleSortTables,
  checkSort,
  handleClearSort
}): JSX.Element => {
  const { t } = useTranslation();
  const menuItemsUsersSort =
    handleSortTables &&
    tableHeaderSortData.users
      .filter(key => {
        if (tableVariant !== 'users') {
          return !key.includes('birth');
        } else return key;
      })
      .map((key, index) => (
        <Grid
          key={index}
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ '&:hover': { background: 'lightgray' } }}
        >
          <MenuItem
            onClick={() =>
              checkSort === index && handleClearSort
                ? handleClearSort()
                : handleSortTables(index, tableVariant !== 'objects' ? tableVariant : 'users')
            }
            sx={{ '&:hover': { background: 'lightgray' } }}
          >
            {t(`sort.${key}`)}
          </MenuItem>
          {checkSort === index && (
            <MenuItem onClick={handleClearSort} sx={{ '&:hover': { background: 'lightgray' } }}>
              <HighlightOffIcon sx={{ color: 'gray' }} />
            </MenuItem>
          )}
        </Grid>
      ));
  const menuItemsObjectsSort =
    handleSortTables &&
    tableHeaderSortData.objects.map((key, index) => (
      <Grid
        key={index}
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ '&:hover': { background: 'lightgray' } }}
      >
        <MenuItem
          onClick={() => {
            handleClearSort && checkSort === index
              ? handleClearSort()
              : handleSortTables(index, 'objects');
          }}
          sx={{ '&:hover': { background: 'lightgray' } }}
        >
          {t(`sort.${key}`)}
        </MenuItem>
        {checkSort === index ? (
          <MenuItem onClick={handleClearSort} sx={{ '&:hover': { background: 'lightgray' } }}>
            <HighlightOffIcon sx={{ color: 'gray' }} />
          </MenuItem>
        ) : null}
      </Grid>
    ));
  return (
    <Box
      width={'92%'}
      display={'flex'}
      flexDirection={'row'}
      justifyContent={'space-between'}
      height={'40px'}
    >
      <>
        <IconButton
          id="sort-button"
          data-testid="SortButtonMobile"
          sx={{
            borderRadius: '6px',
            border: '1px solid #CACCCF',
            color: 'black'
          }}
          onClick={handleClick}
          aria-controls={anchorSortEl ? 'sort-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorSortEl ? 'true' : undefined}
        >
          <FilterIcon />
        </IconButton>
        <Menu
          id="sort-menu"
          anchorEl={anchorSortEl}
          open={anchorSortEl !== null}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button'
          }}
        >
          {tableVariant === 'objects' ? menuItemsObjectsSort : menuItemsUsersSort}
        </Menu>
      </>
      <TextField
        size="small"
        sx={{
          width: '70%'
        }}
        id="outlined-search"
        label={t('search')}
        type="text"
        value={filterValues.search}
        onChange={handleSearch}
        placeholder={t('tableHeader.searchPlaceholder').toString()}
      />
      <IconButton
        id="MenuIconCalendarMobile"
        data-testid="MenuIconCalendarMobile"
        sx={{
          borderRadius: '6px',
          border: '1px solid #CACCCF',
          color: 'black'
        }}
        onClick={() => setOpenMobileMenu(true)}
      >
        <MenuIcon />
      </IconButton>
    </Box>
  );
};

export default MobileTableHeader;
