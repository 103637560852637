// hooks
import { Controller } from 'react-hook-form';
// components
import { Typography, TextField, Grid, FormHelperText } from '@mui/material';
import SearchGeoCode from '@components/SearchGeoCode/SearchGeoCode';

// types
import { Control } from 'react-hook-form';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
import { useTranslation } from 'react-i18next';
import { AutocompleteType } from '@interfaces/locationsGeoCode/locationsGeoCode';

type BasicDataProps = {
  control: Control<CreateSportCenterType>;
  reqMessage: string;
  handleAutocompleteChange: (
    event: React.SyntheticEvent,
    option: string | AutocompleteType | null,
    reason: string
  ) => void;
  handleSearchChange: (event: React.SyntheticEvent, value: string, reason: string) => void;
  locations: AutocompleteType[];
  isLoadingLocationsList: boolean;
  isLoadingLocation: boolean;
};

const BasicData = ({
  control,
  reqMessage,
  handleAutocompleteChange,
  handleSearchChange,
  locations: locations,
  isLoadingLocationsList
}: BasicDataProps) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ width: '100%' }}>
          {t('sportCenters.stepOne.basic_data.title')}
        </Typography>
        <Typography variant="subtitle2" color="GrayText" sx={{ pb: 1 }}>
          {t('sportCenters.stepOne.basic_data.subtitle')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="basic_info.name"
          control={control}
          defaultValue=""
          rules={{ required: reqMessage }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              error={invalid}
              {...field}
              label={t('sportCenters.stepOne.basic_data.name')}
              variant="outlined"
              sx={{ width: '100%' }}
              helperText={error?.message}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          name="basic_info.email"
          control={control}
          defaultValue=""
          rules={{ required: reqMessage }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              error={invalid}
              label={t('email')}
              type="email"
              placeholder="email@mail.com"
              variant="outlined"
              sx={{ width: '100%' }}
              helperText={error?.message}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Controller
          name="basic_info.phone"
          control={control}
          defaultValue=""
          rules={{ required: reqMessage }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              type="tel"
              label={t('phone_number')}
              placeholder="+3816xxxxxxx"
              variant="outlined"
              sx={{ width: '100%' }}
              error={invalid}
              helperText={error?.message}
              required
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="basic_info.address"
          control={control}
          defaultValue=""
          rules={{ required: reqMessage }}
          render={({ field, fieldState: { invalid, error } }) => (
            <SearchGeoCode
              field={field}
              isLoadingLocationsList={isLoadingLocationsList}
              handleAutocompleteChange={handleAutocompleteChange}
              handleSearchChange={handleSearchChange}
              locations={locations}
              label={t('sportCenters.stepOne.basic_data.address').toString() + ' *'}
              optionLabel={field.value}
              id="autocomplte_basic_data"
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Controller
          name="basic_info.city"
          control={control}
          defaultValue=""
          rules={{ required: reqMessage }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              label={t('city')}
              variant="outlined"
              sx={{ width: '100%' }}
              error={invalid}
              helperText={error && error.message}
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Controller
          name="basic_info.municipality"
          control={control}
          defaultValue=""
          rules={{ required: reqMessage }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              label={t('municipality')}
              variant="outlined"
              sx={{ width: '100%' }}
              error={invalid}
              helperText={error && error.message}
              required
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default BasicData;
