// hooks
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
// containers
import StepFour from '@containers/StepFour/StepFour';
// components
import { Button, Grid } from '@mui/material';

const PriceListSettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  return (
    <Grid container>
      <Grid item xs={12} px={2}>
        <StepFour buttons={false} />
        <Grid container justifyContent="flex-end" py={2}>
          <Button
            variant="text"
            color="primary"
            onClick={() => navigate(`/settings/${params.sportCenterId}`)}
          >
            {t('cancel')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => navigate(`/settings/${params.sportCenterId}`)}
          >
            {t('save')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PriceListSettingsPage;
