import {
  DeleteImageRequest,
  UploadImageRequest,
  UploadImageResponse
} from '@interfaces/Images/images';
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  EmployeePositionResponseType,
  EmployeeResponseType,
  EmployeeSectorResponseType,
  PermissionType,
  EmployeeCreateRequest,
  Employee
} from '@interfaces/employees/employees';
import axiosInstance from '@services/config';

export const getSectors = (): Promise<EmployeeSectorResponseType> =>
  axiosInstance.get('/employee-sectors');

export const getPositions = (sectorId: string): Promise<EmployeePositionResponseType> =>
  axiosInstance.get('/employee-positions?employee_sector_id=' + sectorId);

export const createEmployee = (data: EmployeeCreateRequest): Promise<EmployeeResponseType> =>
  axiosInstance.post('/cooperation/employees', data);

export const updateEmployee = (
  data: EmployeeCreateRequest & { employeeId: number }
): Promise<EmployeeResponseType> =>
  axiosInstance.put(`/cooperation/employees/${data.employeeId}`, data);

export const getSingleEmployee = (employeeId: string): Promise<EmployeeResponseType> =>
  axiosInstance.get('/cooperation/employees/' + employeeId);

export const getPermissions = (): Promise<PermissionType> => axiosInstance.get('/permissions');
export const getSportCenterPermissions = (): Promise<PermissionType> =>
  axiosInstance.get('/sport-center-permissions');

export const uploadEmployeeDocuments = (data: UploadImageRequest): Promise<UploadImageResponse> =>
  axiosInstance.post(
    '/cooperation/employees/' + data.id + '/document',
    data.data,
    data.uploadPercentOptions
  );

export const deleteEmployeeDocuments = (data: DeleteImageRequest): Promise<SuccessResponse> =>
  axiosInstance.delete(`/cooperation/employees/${data.objectId}/document/${data.imageID}`);
