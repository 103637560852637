//hooks
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//mutations
import {
  useCreateSport,
  useDeleteSportImage,
  useUpdateSport,
  useUploadSportImage
} from '@api/mutations/sport/sport';
//queries
import { useSingleSport } from '@api/queries/sport/sport';
import {
  useDeleteCustomField,
  useUpdateSportCustomField
} from '@api/mutations/sportCustomFields/sportCustomFields';
//types
import { ImageType, UploadImageResponse } from '@interfaces/Images/images';
import { CreateSportRequest, Sport, SportCustomFields, SportError } from '@interfaces/sport/sport';
import { SportCustomFieldsTypeEnum } from '@enum/sportEnum';
import { ErrorResponse } from '@interfaces/apiResponse';
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
//helpers
import { getTranslateArray, getTranslateName, onUploadProgress } from '@helpers/utility';
import { formHelper } from '@helpers/formDataHelper';
//recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';
import { sportFormAtom } from '@atoms/sportAtom';

const languages: ('sr' | 'en')[] = ['sr', 'en'];

const inputTypes: SportCustomFieldsTypeEnum[] = [
  SportCustomFieldsTypeEnum.number,
  SportCustomFieldsTypeEnum.text,
  SportCustomFieldsTypeEnum.select
];

export function useCreateSportHook() {
  const setPopup = useSetRecoilState(popupAtom);
  const [sportForm, setSportForm] = useRecoilState(sportFormAtom);

  const [values, setValues] = useState<CreateSportRequest>();
  const [images, setImages] = useState<ImageType[]>([]);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [deleteCustomFieldsIds, setDeleteCustomFieldsIds] = useState<number[]>([]);

  const { t } = useTranslation();
  const { sportId } = useParams();
  const navigate = useNavigate();

  const lng = localStorage.getItem('lng') ?? 'sr';

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    watch,
    formState: { errors },
    reset,
    clearErrors
  } = useForm<CreateSportRequest>({
    defaultValues: {
      name_translates: {
        en: '',
        sr: ''
      },
      benefits: {
        type: SportCustomFieldsTypeEnum.checklist,
        label_translates: { en: 'benefits', sr: 'benefiti' },
        options_translates: { en: [''], sr: [''] },
        options: ['']
      },
      custom_fields: [
        {
          type: '',
          label_translates: {
            en: '',
            sr: ''
          },
          options_translates: {
            en: [],
            sr: []
          }
        }
      ]
    },
    values
  });

  const onSingleSportSuccess = (sport: Sport | undefined) => {
    if (sport) {
      let custom_fields_tmp: SportCustomFields[] = [];

      let benefits: SportCustomFields = {
        type: SportCustomFieldsTypeEnum.checklist,
        label: 'benefits',
        label_translates: { en: 'benefits', sr: 'benefiti' },
        options_translates: { en: [], sr: [] },
        options: []
      };

      sport.custom_fields.map(f => {
        if (f.type === 'checklist') {
          benefits = f;

          if (benefits.options_translates?.en) {
            benefits.options_translates.en = benefits.options_translates?.en.map(t => (t ? t : ''));

            if (benefits.options_translates.en.length === 0) {
              benefits.options_translates.en.push('');
            }
          }
          if (benefits.options_translates?.sr) {
            benefits.options_translates.sr = benefits.options_translates?.sr.map(t => (t ? t : ''));
            if (benefits.options_translates.sr.length === 0) {
              benefits.options_translates.sr.push('');
            }
          }
        } else {
          if (typeof f.options === 'string') f.options = [];
          custom_fields_tmp.push(f);
        }
      });

      sport.image && setImages([sport.image]);

      setValues({
        name: sport.name,
        name_translates: sport.name_translates,
        benefits: benefits,
        custom_fields: custom_fields_tmp,
        image: undefined
      });
    }
  };

  const onError = (err: Error | ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const onCreateSportSuccess = (data: Sport) => {
    reset();
    setImages([]);
    if (sportForm?.fromSportForm && sportForm?.sportCenterID) {
      navigate(`/objects/create-object/${sportForm.sportCenterID}/court`);
    } else {
      navigate('/settings/sports');
    }
    setSportForm({ sportID: data.id.toString() });
  };

  const onCreateSportError = (err: SportError) => {
    if (err.errors) {
      for (const key of Object.keys(err.errors)) {
        setError(key as keyof CreateSportRequest, {
          type: 'custom',
          message: err.errors[key as keyof typeof err.errors]
        });
      }
    }
  };

  const { refetch } = useSingleSport(sportId, onSingleSportSuccess, onError);

  const { mutate: createSport } = useCreateSport(onCreateSportSuccess, onCreateSportError);

  const { mutate: editSport } = useUpdateSport(
    // data => {
    //   navigate(-1);
    //   refetch();
    // },
    onCreateSportSuccess,
    onCreateSportError
  );

  const { mutate: deleteCustomField } = useDeleteCustomField(mess => {
    refetch();
  }, onError);

  const { mutate: updateCustomField } = useUpdateSportCustomField(mess => {
    refetch();
    reset();
  }, onError);

  const { mutate: uploadImage } = useUploadSportImage((data: UploadImageResponse) => {
    setImages([data.data]);
  }, onError);

  const { mutate: deleteImage } = useDeleteSportImage(() => {
    refetch();
    setImages([]);
  }, onError);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'custom_fields',
    keyName: 'arrayId'
  });

  const onSubmit = (data: CreateSportRequest) => {
    const createData: CreateSportRequest = {
      name_translates: data.name_translates,
      name: getTranslateName(lng, data.name_translates, data.name),

      image: data.image,

      custom_fields: data.custom_fields.map(field => {
        return {
          id: field.id,
          type: field.type,
          label: getTranslateName(lng, field.label_translates, field.label),
          label_translates: field.label_translates,
          options_translates:
            field.type === SportCustomFieldsTypeEnum.select ? field.options_translates : undefined,
          options:
            field.type === SportCustomFieldsTypeEnum.select
              ? getTranslateArray(lng, field.options_translates, field.options)
              : []
        };
      })
    };

    if (data.benefits && data.benefits.options?.length) {
      createData.custom_fields.push({
        id: data.benefits.id,
        type: data.benefits.type,
        label: getTranslateName(lng, data.benefits.label_translates, data.benefits.label),
        label_translates: data.benefits.label_translates,
        options_translates: data.benefits.options_translates,
        options: getTranslateArray(lng, data.benefits.options_translates, data.benefits.options)
      });
    }

    if (sportId) {
      if (deleteCustomFieldsIds) {
        for (const customFieldId of deleteCustomFieldsIds) {
          deleteCustomField({ sportId: +sportId, customFieldId: customFieldId });
        }
      }

      if (data.benefits && data.benefits.id && !data.benefits.options?.length) {
        deleteCustomField({ sportId: +sportId, customFieldId: data.benefits.id });
      }

      editSport({
        id: +sportId,
        name: createData.name,
        name_translates: createData.name_translates,
        custom_fields: createData.custom_fields
      });
    } else {
      createSport(formHelper.getBody(createData));
    }
  };

  const uploadPercentOptions: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      onUploadProgress(progressEvent, setUploadPercent)
  };

  const onDropCallback = (image: File) => {
    if (images.length > 0) {
      return;
    }

    if (sportId) {
      const data = new FormData();
      data.append('image', image);
      uploadImage({
        id: sportId,
        data: data,
        uploadPercentOptions: uploadPercentOptions
      });
    } else {
      setValue('image', image);
      setImages([
        {
          id: Math.floor(Math.random() * 100),
          src: URL.createObjectURL(image)
        }
      ]);
    }
  };

  const handleDelete = (imageID: number) => {
    if (sportId) {
      deleteImage({ objectId: +sportId, imageID: imageID });
    } else {
      setImages(images.filter(i => i.id !== imageID));
    }
  };

  const handleDeleteCustomField = (index: number, id?: number) => {
    if (sportId && id) {
      setDeleteCustomFieldsIds(prev => [...prev, id]);
    }
    remove(index);
  };

  const handelDeleteBenefit = (index: number, benefits: string) => {
    clearErrors();

    for (const lang of languages) {
      const val = getValues(`benefits.options_translates.${lang}` as const);

      val.splice(index, 1);

      setValue(`benefits.options_translates.${lang}` as const, val ? val : []);

      if (lng === lang) {
        setValue(`benefits.options` as const, val ? val : []);
      }
    }
  };

  const handleAddBenefit = () => {
    for (const lang of languages) {
      let new_value = getValues(`benefits.options_translates.${lang}`);

      if (new_value) new_value.push('');
      else new_value = [''];

      setValue(`benefits.options_translates.${lang}`, new_value);

      if (lang === lng) {
        setValue(`benefits.options`, new_value);
      }
    }
  };

  const handleAddOption = (index: number) => {
    for (const lang of languages) {
      let new_value = getValues(`custom_fields.${index}.options_translates.${lang}`);

      if (new_value) new_value.push('');
      else new_value = [''];

      setValue(`custom_fields.${index}.options_translates.${lang}`, new_value);

      if (lang === lng) {
        setValue(`custom_fields.${index}.options`, new_value);
      }
    }
  };

  const handleDeleteOption = (index: number, deleteIndex: number) => {
    clearErrors();
    for (const lang of languages) {
      const val = getValues(`custom_fields.${index}.options_translates.${lang}` as const);
      if (val.length === 1) {
        return;
      }
      val.splice(deleteIndex, 1);
      setValue(`custom_fields.${index}.options_translates.${lang}` as const, val);
      if (lang === lng) {
        setValue(`custom_fields.${index}.options`, val);
      }
    }
  };

  return {
    handleSubmit,
    onSubmit,
    fields,
    append,
    remove,
    setValue,
    getValues,
    setError,
    watch,
    control,
    errors,
    inputTypes,
    reset,
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    title: sportId ? t('sportPage.editTitle') : t('sportPage.createTitle'),
    subtitle: sportId ? t('sportPage.editSubtitle') : t('sportPage.createSubtitle'),
    handleDeleteCustomField,
    handelDeleteBenefit,
    handleAddBenefit,
    clearErrors,
    lng,
    values,
    handleDeleteOption,
    handleAddOption
  };
}
