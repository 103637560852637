//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//interfaces
import {
  AdminEmployeeErrorRessponseType,
  AdminEmployeeType,
  CreateAdminEmployeeErrorResponseType,
  CreateAdminEmployeeRequestType
} from '@interfaces/employees/employees';
//service
import {
  createSportCenterEmployee,
  deleteSingleSportCenterEmployee,
  updateSportCenterEmployee
} from '@services/cooperation/employee/employee';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';

export const useCreateAdminEmployee = (
  onSuccess: (data: AdminEmployeeType) => void,
  onError: (err: CreateAdminEmployeeErrorResponseType) => void
): UseMutationResult<
  { data: AdminEmployeeType }, // response
  AdminEmployeeErrorRessponseType,
  CreateAdminEmployeeRequestType // request
> =>
  useMutation({
    mutationFn: createSportCenterEmployee,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateAdminEmployee = (
  onSuccess: (data: AdminEmployeeType) => void,
  onError: (err: CreateAdminEmployeeErrorResponseType) => void
): UseMutationResult<
  { data: AdminEmployeeType }, // response
  AdminEmployeeErrorRessponseType,
  CreateAdminEmployeeRequestType & {
    employeeMembershipId: number;
  } // request
> =>
  useMutation({
    mutationFn: updateSportCenterEmployee,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteAdminEmployee = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: CreateAdminEmployeeErrorResponseType) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  AdminEmployeeErrorRessponseType,
  Omit<CreateAdminEmployeeRequestType, 'data'> & {
    employeeMembershipId: number;
  } // request
> =>
  useMutation({
    mutationFn: deleteSingleSportCenterEmployee,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
