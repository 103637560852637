import { currentCMSAtom } from '@atoms/currentCMS';
import { userAtom } from '@atoms/userAtom';
import { RoleEnum } from '@enum/roleEnum';
import { User } from '@interfaces/user/user';
import { useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

export function useSetLoginUser() {
  const setUser = useSetRecoilState(userAtom);
  const setCurrentCMS = useSetRecoilState(currentCMSAtom);

  let { sportCenterId } = useParams();

  const setUserCMS = (u: User) => {
    setUser(u);

    if (!sportCenterId) {
      const savedSportCenter = localStorage.getItem('sportCenter');
      if (savedSportCenter) sportCenterId = savedSportCenter;
    }

    if (!u.impersonating || !sportCenterId || sportCenterId === '-1') {
      if (u.is_admin) {
        setCurrentCMS({
          role: RoleEnum.SUPERADMIN,
          currentSportCenter: '-1',
          permissions: u.permissions
        });
        return;
      }

      if (u.role) {
        setCurrentCMS({
          role: RoleEnum.SUPERADMIN_EMPLOYEE,
          currentSportCenter: '-1',
          permissions: u.role.permissions
        });
        return;
      }
    }

    if (sportCenterId) {
      if (u.owned_sport_centers?.find(s => s.id.toString() === sportCenterId?.toString())) {
        setCurrentCMS({
          role: RoleEnum.ADMIN,
          currentSportCenter: sportCenterId,
          permissions: u.permissions
        });
        return;
      }
      if (u.coach_in_sport_centers?.find(s => s.id.toString() === sportCenterId?.toString())) {
        setCurrentCMS({
          role: RoleEnum.COACH,
          currentSportCenter: sportCenterId,
          permissions: u.permissions
        });
        return;
      }
      const sportCenterEmployee = u.sport_centers_with_role?.find(
        s => s.id.toString() === sportCenterId?.toString()
      );
      if (sportCenterEmployee) {
        setCurrentCMS({
          role: RoleEnum.ADMIN_EMPLOYEE,
          currentSportCenter: sportCenterId,
          permissions: sportCenterEmployee.role.permissions
        });
        return;
      }
    }

    if (u.owned_sport_centers) {
      if (u.owned_sport_centers[0]) {
        setCurrentCMS({
          role: RoleEnum.ADMIN,
          currentSportCenter: u.owned_sport_centers[0].id,
          permissions: u.permissions
        });
        return;
      }
    }

    if (u.coach_in_sport_centers.length > 0) {
      setCurrentCMS({
        role: RoleEnum.COACH,
        currentSportCenter: u.coach_in_sport_centers[0].id.toString(),
        permissions: u.permissions
      });
      return;
    }

    if (u.sport_centers_with_role.length > 0) {
      setCurrentCMS({
        role: RoleEnum.ADMIN_EMPLOYEE,
        currentSportCenter: u.sport_centers_with_role[0].id.toString(),
        permissions: u.sport_centers_with_role[0].role.permissions
      });
      return;
    }
  };

  const resetUser = () => {
    setUser(null);
  };

  return {
    setUserCMS,
    resetUser
  };
}
