//components
import { Box } from '@mui/material';
import StepThree from '@containers/StepThree/StepThree';

const CourtSettingsPage = () => {
  return (
    <Box p={2}>
      <StepThree hideButtons={true} />
    </Box>
  );
};

export default CourtSettingsPage;
