// hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
//react-router
import { useNavigate, useParams } from 'react-router-dom';
import useTablesLogic from '@hooks/tables/useTablesLogic';
import useAdminEmployees from '@hooks/adminEmployees/useAdminEmployeesHook';
// interfaces
import { AdminEmployeeType } from '@interfaces/employees/employees';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
// componentss
import { Card, Grid, Button, Alert } from '@mui/material';
import Loading from '@components/Loading/Loading';
// layouts
import { TableLayout } from '@layout/TableLayout/TableLayout';
// containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
// icons
import AddIcon from '@mui/icons-material/Add';
// mocks
import { useSportCenterEmployees } from '@api/queries/cooperation/employee/employee';
//atoms
import { popupAtom } from '@atoms/popupAtom';

const AdminEmployeesPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId = params.sportCenterId ? +params.sportCenterId : 0;
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);
  const { pagination, setPagination, tableInstanceRef, handleSearch, filterValues } =
    useTablesLogic();

  const { onEditEmployee, popup } = useAdminEmployees();

  const { data, isLoading: isLoadingSportCenterEmployees } = useSportCenterEmployees(
    sportCenterId,
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    onEmployeesError
  );

  function onEmployeesError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const columnsEmployee = useMemo<MRT_ColumnDef<AdminEmployeeType | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name') + ' ' + t('and') + ' ' + t('surname'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as AdminEmployeeType;
          return (
            <Button
              variant="text"
              sx={{
                textTransform: 'none',
                textDecoration: 'underline',
                color: 'black',
                '&:hover': {
                  background: 'none',
                  textDecoration: 'underline'
                }
              }}
              onClick={() => onEditEmployee(rowType)}
            >
              {cell.getValue<string>()}
            </Button>
          );
        }
      },
      {
        accessorKey: 'phone',
        header: t('phone_number'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'category',
        header: t('category'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );
  return (
    <>
      <Grid container justifyContent="flex-end" alignItems="center" m={2} mt={0}>
        <Grid item mr={5}>
          <Button
            variant="contained"
            sx={{ textTransform: 'none' }}
            startIcon={<AddIcon />}
            onClick={() => navigate('create-employee')}
          >
            {t('adminEmployeePage.buttonText')}
          </Button>
        </Grid>
      </Grid>
      {popup.content && (
        <Grid container>
          <Alert severity="success" sx={{ position: 'sticky', zIndex: 200, width: '100%', m: 2 }}>
            {popup.content}
          </Alert>
        </Grid>
      )}
      <Card sx={{ mx: 3, px: 3 }}>
        <Grid item xs={12}>
          <TableHeaderActions
            tableVariant="adminEmployees"
            handleSearch={handleSearch}
            disableFilter
            disableSort
            hideActionBtn
          />
        </Grid>
        <Grid item xs={12}>
          {data && !isLoadingSportCenterEmployees ? (
            <TableLayout
              tableVariant="adminEmployees"
              rows={data?.data.data ? data.data.data : []}
              columns={columnsEmployee}
              pagination={pagination}
              setPagination={setPagination}
              tableInstanceRef={tableInstanceRef}
              rowCount={data?.data.meta?.total}
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </>
  );
};

export default AdminEmployeesPage;
