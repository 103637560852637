//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';
//interfaces
import { BenefitsResponse, BenefitsError } from '@interfaces/SportCenters/Benefits';
//services
import { getBenefits } from '@services/sportCenter/benefits';

export const useQueryBenefits = (
  onSuccess: (data: BenefitsResponse) => void,
  onError: (err: BenefitsError) => void
): UseQueryResult<BenefitsResponse, Error> =>
  useQuery(['benefits'], getBenefits, {
    select: res => res.data,
    onSuccess: data => onSuccess(data),
    onError: err => onError(err)
  });
