import { User } from '@interfaces/user/user';

export const userData: User = {
  id: 0,
  name: '',
  first_name: '',
  last_name: '',
  email: '',
  date_of_birth: '',
  permissions: [],
  phone: '',
  role: {
    id: 0,
    name: '',
    permissions: []
  },
  sport_centers_with_role: [],
  coach_in_sport_centers: [],
  is_admin: false,
  profile_photo: {
    id: 0,
    src: ''
  },
  gender: 'male',
  impersonating: false
};
