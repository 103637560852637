//interface
import {
  AllCourtsResponse,
  CourtResponse,
  CourtType,
  RoofStatusType,
  SingleCourtRequestType,
  SurfaceTypeType
} from '@src/domain/interfaces/court/court';
//service
import {
  courtRoofStatuses,
  courtsByObject,
  courtSurfaces,
  singleCourt
} from '@src/infrastructure/services/court/court';

//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useSingleCourt = (
  data: SingleCourtRequestType,
  onSuccess: (data: CourtType) => void,
  onError: (err: Error) => void
): UseQueryResult<{ data: CourtResponse }, Error> =>
  useQuery(['single-court', data], () => singleCourt(data), {
    // select: res => res.data,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err)
  });

export const useCourtsByObjectId = (
  onError: (err: Error) => void,
  objectId: number | undefined,
  onSuccess?: (data: CourtType[]) => void
): UseQueryResult<CourtType[], Error> =>
  useQuery(
    ['get-courts-by-id', objectId],
    () => {
      if (objectId) return courtsByObject(objectId);
      return undefined;
    },
    {
      onError: err => onError(err),
      onSuccess: data => onSuccess && onSuccess(data),
      enabled: !!objectId,
      select: res => (res ? res.data.data : [])
    }
  );

export const useRoofStatuses = (
  onError: (err: Error) => void
): UseQueryResult<RoofStatusType[], Error> =>
  useQuery(['roof-status'], () => courtRoofStatuses(), {
    onError: err => onError(err),
    select: res => (res ? res.data : [])
  });

export const useSurfaceType = (
  onError: (err: Error) => void
): UseQueryResult<SurfaceTypeType[], Error> =>
  useQuery(['surface-type'], () => courtSurfaces(), {
    onError: err => onError(err),
    select: res => (res ? res.data : [])
  });
