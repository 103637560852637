export enum SportEnum {
  Fudbal = 'Fudbal',
  Tenis = 'Tenis',
  Kosarka = 'Kosarka',
  Odbojka = 'Odbojka',
  Rukomet = 'Rukomet',
  MaliFudbal = 'Mali fudbal',
  Karting = 'Karting',
  StoniTenis = 'Stoni tenis',
  Padel = 'Padel'
}

export enum SportCustomFieldsTypeEnum {
  text = 'text',
  select = 'select',
  checklist = 'checklist',
  number = 'number',
  none = 'null'
}
