// hooks
import { useForm, useWatch } from 'react-hook-form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useState, MouseEvent, ChangeEvent } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// interfaces
import { CreateUserError, User, UserErrorResponse } from '@interfaces/user/user';
import { SubmitHandler } from 'react-hook-form';
import { SuccessResponse, ErrorResponse } from '@interfaces/apiResponse';
import { UploadImageResponse } from '@interfaces/Images/images';

//axios
import { AxiosRequestConfig, AxiosProgressEvent } from 'axios';

// mutations
import { useDeleteUserProfilePhoto, useUploadUserProfilePhoto } from '@api/mutations/images/images';
import { useUser, useUpdateUser } from '@api/mutations/user/user';

// atoms
import { userAtom } from '@atoms/userAtom';
import { popupAtom } from '@atoms/popupAtom';

// mock
import { userData } from '@src/__mock__/user/userData';

// helpers
import { onUploadProgress } from '@src/helpers/utility';

const useCoachesSettingsHook = () => {
  // variables
  const [user, setUser] = useRecoilState(userAtom);
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId: number = Number(params.sportCenterId);
  const setPopup = useSetRecoilState(popupAtom);
  const [uploadPercent, setUploadPercent] = useState<number>(0);

  // form
  const {
    handleSubmit,
    control: controlCoachProfile,
    formState: { isValid: isValidCoachProfile },
    setValue,
    setError,
    reset,
    watch
  } = useForm<User>({
    defaultValues: user ? user : userData,
    mode: 'onBlur'
  });

  // Profile photo
  const profilePhotoWatch = useWatch({
    control: controlCoachProfile,
    name: 'profile_photo'
  });
  const uploadPercentOptions: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      onUploadProgress(progressEvent, setUploadPercent)
  };

  const handleOnProfilePhotoChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const profilePhotoFormData = new FormData();
    if (event.target?.files) {
      if (params.sportCenterId && user && user.id) {
        profilePhotoFormData.append('image', event.target?.files[0]);
        setValue('profile_photo', {
          id: 0,
          src: URL.createObjectURL(event.target?.files[0])
        });
        uploadProfilePhoto({
          id: user.id,
          data: profilePhotoFormData,
          uploadPercentOptions: uploadPercentOptions
        });
      } else {
        profilePhotoFormData.append('image', event.target?.files[0]);
        setValue('profile_photo', { id: 1, src: URL.createObjectURL(event.target?.files[0]) });
      }
    }
  };

  const handleProfilePhotoClick = (event: MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();

    if (params.sportCenterId && user && user.profile_photo && user.profile_photo.id > 0) {
      deleteProfilePhoto({ objectId: user.id, imageID: user.profile_photo.id });
      setValue('profile_photo', null);
    }
  };

  const onUploadProfilePhotoSuccess = (data: UploadImageResponse) => {
    me('');
  };

  const onUploadProfilePhotoError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: '',
      content: '',
      variant: 'error'
    });
  };

  const { mutate: uploadProfilePhoto, isLoading: isLoadingProfilePhoto } =
    useUploadUserProfilePhoto(onUploadProfilePhotoSuccess, onUploadProfilePhotoError);

  const onDeleteProfilePhotoSuccess = (data: SuccessResponse) => {
    me('');
  };

  const onDeleteProfilePhotoError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteProfilePhoto, isLoading: isLoadingDeleteProfilePhoto } =
    useDeleteUserProfilePhoto(onDeleteProfilePhotoSuccess, onDeleteProfilePhotoError);
  const handleCancel = () => {
    navigate(`/settings/${sportCenterId}`);
  };

  //  me
  const onUserSuccess = (data: User) => {
    setUser(data);
    reset(data);
  };

  const onUserError = (err: UserErrorResponse): void => {};

  const onUpdateUserSuccess = (data: User) => {
    me('');
  };

  const onUpdateUserError = (err: CreateUserError): void => {
    if (err.errors) {
      for (let key in err.errors) {
        setError(key as keyof User, {
          type: 'custom',
          message: err.errors[key].join(', ')
        });
      }
    }
  };

  const { mutate: me, isLoading: isLoadingMe } = useUser(onUserSuccess, onUserError);
  const { mutate: updateUser, isLoading: isLoadingUpdateUser } = useUpdateUser(
    onUpdateUserSuccess,
    onUpdateUserError
  );

  //   submit
  const onSubmitCoach: SubmitHandler<User> = async (data, e) => {
    updateUser(data);
  };

  return {
    handleSubmit: handleSubmit(onSubmitCoach),
    handleOnProfilePhotoChange,
    handleProfilePhotoClick,
    uploadPercent,
    handleCancel,
    controlCoachProfile,
    isValidCoachProfile,
    isLoadingMe,
    isLoadingUpdateUser,
    isLoadingDeleteProfilePhoto,
    isLoadingProfilePhoto
  };
};

export default useCoachesSettingsHook;
