//interface
import { DeleteImageRequest, UploadImage, UploadImageResponse } from '@interfaces/Images/images';
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  AllSportsResponse,
  CreateSportRequest,
  Sport,
  SportResponse
} from '@interfaces/sport/sport';
//axios instance
import axiosInstance from '@services/config';

export const singleSport = (id: string): Promise<{ data: SportResponse }> =>
  axiosInstance.get(`/sports/${id}`);

export const createSport = (data: FormData): Promise<{ data: SportResponse }> =>
  axiosInstance.post('/sports', data);

export const updateSport = (sport: Sport): Promise<{ data: SportResponse }> =>
  axiosInstance.put(`/sports/${sport.id}`, sport);

export const deleteSport = (id: number): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sports/${id}`);

export const allSports = (): Promise<{ data: AllSportsResponse }> => axiosInstance.get('/sports');

export const addSportImage = (): Promise<{ data: UploadImageResponse }> =>
  axiosInstance.get('/sports');

export const uploadSportImage = ({
  id,
  data,
  uploadPercentOptions
}: UploadImage): Promise<{ data: UploadImageResponse }> =>
  axiosInstance.post(`/sports/${id}/image`, data, uploadPercentOptions);

export const deleteSportImage = ({
  objectId,
  imageID
}: DeleteImageRequest): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sports/${objectId}/image/${imageID}`);
