// store
import { RoleEnum } from '@enum/roleEnum';
import { atom } from 'recoil';

export type CurrentCMSType = {
  role: RoleEnum;
  currentSportCenter: string;
  permissions: string[];
};

const currentCMSAtom = atom<CurrentCMSType | null>({
  key: 'currentCMSState',
  default: null
});

export { currentCMSAtom };
