//axios instance
import axiosInstance from '@services/config';
// interfaces
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  AllCoachesResponseType,
  CoachResponseType,
  CreateCoachRequestParamsType,
  CreateCoachRequestType,
  SingleCoachRequestType,
  SuperAdminCoachResponseType
} from '@interfaces/user/coaches/coaches';

export const getCoaches = (
  sportCenterId: number,
  page?: number,
  per_page?: number,
  search?: string
): Promise<{ data: AllCoachesResponseType }> =>
  axiosInstance.get(`/sport-centers/${sportCenterId}/coaches`, {
    params: {
      page: page,
      per_page: per_page,
      search: search
    }
  });

export const getAllCoaches = (
  page?: number,
  per_page?: number,
  search?: string,
  gender?: string[],
  order_by?: string,
  order_direction?: string
): Promise<{ data: AllCoachesResponseType }> =>
  axiosInstance.get(`/coaches`, {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });

export const getSuperAdminCoach = (superUserId: Number): Promise<SuperAdminCoachResponseType> =>
  axiosInstance.get(`/coaches/${superUserId}`);

export const createCoach = (
  data: CreateCoachRequestParamsType
): Promise<{ data: CoachResponseType }> =>
  axiosInstance.post(`/sport-centers/${data.sportCenterId}/coaches`, data.data);

export const getSingleCoach = (
  data: SingleCoachRequestType
): Promise<{ data: CoachResponseType }> =>
  axiosInstance.get(`/sport-centers/${data.sportCenterId}/coaches/${data.coachId}`);

export const updateCoach = (
  data: SingleCoachRequestType & { data: CreateCoachRequestType }
): Promise<{ data: CoachResponseType }> =>
  axiosInstance.put(`/sport-centers/${data.sportCenterId}/coaches/${data.coachId}`, data.data);

export const deleteCoach = (data: SingleCoachRequestType): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${data.sportCenterId}/coaches/${data.coachId}`);
