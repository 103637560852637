//components
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
//style
import { primaryColor } from '@src/theme';
//images
import FlagSerbia from '@src/assets/images/FlagSerbia.png';
import FlagUK from '@src/assets/images/FlagUK.png';
//translation
import i18n from '@src/Translation/i18n';
import { useTranslation } from 'react-i18next';

const LanguageSettingsPage = () => {
  const {} = useTranslation();

  const onClick = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lng', lng);
  };
  
  return (
    <Grid container spacing={2} p={2}>
      <Grid item xs={12}>
        <Card
          sx={{
            border: i18n.language === 'sr' ? '1px solid' + primaryColor : '',
            cursor: 'pointer'
          }}
          onClick={() => onClick('sr')}
        >
          <CardContent>
            <Box display={'flex'} alignItems={'center'}>
              <img src={FlagSerbia} />
              <Typography variant="h6" ml={1}>
                Srpski
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          sx={{
            border: i18n.language === 'en' ? '1px solid' + primaryColor : '',
            cursor: 'pointer'
          }}
          onClick={() => onClick('en')}
        >
          <CardContent>
            <Box display={'flex'} alignItems={'center'}>
              <img src={FlagUK} />
              <Typography variant="h6" ml={1}>
                English
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LanguageSettingsPage;
