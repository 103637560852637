// // dayjs
// import dayjs from 'dayjs';
// recharts
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  TooltipProps
} from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
// components
import { Card, Typography } from '@mui/material';
// react
import { ReactElement } from 'react';
// hooks
import { useTranslation } from 'react-i18next';
import theme from '@src/theme';

type AreaChartSMPropsType = {
  data?: { value: number; time: string }[];
  dateFormat?: string | 'HH' | 'HH:mm';
  tooltipValueText?: string;
};

const AreaChartSM = ({ data = [], dateFormat = '', tooltipValueText }: AreaChartSMPropsType) => {
  const { t } = useTranslation();
  // const thickFormatter = (value: string) =>
  //   `${dayjs(value).format(dateFormat ? dateFormat : 'HH')}h`;
  const CustomTooltip = (props: TooltipProps<ValueType, NameType>): ReactElement => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <Card sx={{ p: 2 }}>
          <Typography>{`${t('time')}: ${label}`}</Typography>
          <Typography>{`${tooltipValueText}: ${payload[0].value?.toLocaleString()}`}</Typography>
        </Card>
      );
    }

    return <></>;
  };
  const chartStyles = {
    fontSize: 9,
    lineHeight: 11,
    stroke: '',
    fontFamily: 'Inter',
    fontWeight: 500
  };

  return (
    <ResponsiveContainer height={296}>
      <AreaChart data={data} margin={{ top: 50, bottom: 50, left: 0, right: 0 }}>
        <CartesianGrid stroke={'gray'} strokeWidth={1} strokeDasharray="3 3" />
        <XAxis tickMargin={10} dataKey={'time'} {...chartStyles} />
        <YAxis {...chartStyles} />
        <Area
          type="monotone"
          dataKey="value"
          stroke={theme.palette.primary.main}
          strokeWidth={3}
          fill={theme.palette.primary.main + '33'}
        />
        <Tooltip content={<CustomTooltip />} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AreaChartSM;
