//react
import { useMemo, FC, useEffect } from 'react';
//react-router
import { useNavigate, useParams, NavLink } from 'react-router-dom';
//hooks
import useTablesLogic from '@hooks/tables/useTablesLogic';
import {
  useSportCenterInfinityMembershipsUsers,
  useSportCenterUsers
} from '@api/queries/user/user';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useLastElementScroll from '@hooks/lastElementScroll/useLastElementScroll';
import { useDeleteUserMembership, useDeleteUserMemberships } from '@api/mutations/user/user';
//containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
import MobileMenu from '@containers/MobileMenu/MobileMenu';
import MobileTableHeader from '@containers/MobileTableHeader/MobileTableHeader';
//components
import Loading from '@components/Loading/Loading';
import MobileUserRow from '@components/MobileUserRow/MobileUserRow';
//interfaces
import { AdminUserType, User } from '@interfaces/user/user';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import {
  Button,
  Grid,
  Card,
  useMediaQuery,
  CircularProgress,
  Box,
  Checkbox,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Alert
} from '@mui/material';
// icons
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { DeleteRowIcon } from '@components/icons/icons';
//atoms
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
//helpers
import { checkPermission, checkPermissionFunc } from '@helpers/utility';
//enums
import { PermissionsEnum } from '@enum/permissionsEnum';
//theme
import theme from '@src/theme';
import { RoleEnum } from '@enum/roleEnum';

const AdminUsersPage: FC = (): JSX.Element | null => {
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId = params.sportCenterId ? +params.sportCenterId : 0;
  const { t } = useTranslation();
  const currentCMS = useRecoilValue(currentCMSAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onDeleteSuccesss = (data: SuccessResponse) => {
    !isMobile && refetchSportCenterUsers();
    isMobile && refetchSportCenterInfinityUsers();
    setSuccessfullyDeletedPopup(true);
  };

  const onDeleteError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteUserMembership } = useDeleteUserMembership(onDeleteSuccesss, onDeleteError);
  const { mutate: deleteUserMemberships } = useDeleteUserMemberships(
    onDeleteSuccesss,
    onDeleteError
  );

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    handleClick,
    handleClose,
    openMobileUserDropdown,
    handleToggleMobileDetails,
    successfullyDeletedPopup,
    deleteIds,
    setDeleteIds,
    deletedNames,
    deleteEventHandler,
    anchorElActionButton,
    openMobileMenu,
    setOpenMobileMenu
  } = useTablesLogic(
    () => {},
    () => {},
    'superAdminUsers',
    deleteUserMembership,
    deleteUserMemberships
  );

  const {
    data,
    isLoading: isLoadingSportCenterUsers,
    refetch: refetchSportCenterUsers
  } = useSportCenterUsers(
    sportCenterId,
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.gender.toString(),
    sortBy,
    sortDirection,
    onUsersErrorError
  );

  const {
    data: sportCenterUsers,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    refetch: refetchSportCenterInfinityUsers
  } = useSportCenterInfinityMembershipsUsers(
    sportCenterId,
    15,
    filterValues.search,
    sortBy,
    sortDirection,
    currentCMS?.role === RoleEnum.ADMIN,
    onUsersErrorError
  );

  function onUsersErrorError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const { lastElementRef } = useLastElementScroll(
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage
  );

  const flattenedData = useMemo(
    () => (sportCenterUsers ? sportCenterUsers?.pages.flatMap(item => item.data.data) : []),
    [sportCenterUsers]
  );

  const checkedMobileUsers = useMemo(
    () =>
      isMobile && flattenedData && deleteIds
        ? flattenedData.filter(row => deleteIds.includes(+row.id))
        : [],
    [deleteIds]
  );

  const checkedMobileUsersNames = useMemo(
    () =>
      isMobile && checkedMobileUsers ? checkedMobileUsers.map(obj => obj.name).join(', ') : [],
    [checkedMobileUsers]
  );

  const renderMobileUsers = () => {
    return flattenedData.map((option, index) => (
      <Box
        ref={flattenedData.length === index + 1 ? lastElementRef : null}
        key={index}
        width={'100%'}
      >
        <MobileUserRow
          key={index}
          index={index}
          data={option}
          openMobileUserDropdown={openMobileUserDropdown}
          handleToggleMobileDetails={handleToggleMobileDetails}
          handleClick={handleClick}
          isUserChecked={deleteIds}
          setIsUserChecked={setDeleteIds}
        />
      </Box>
    ));
  };

  const columnsAllUsers = useMemo<MRT_ColumnDef<AdminUserType | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name_and_surname'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as AdminUserType;
          return (
            <NavLink to={`edit-user/${rowType.id}`} end style={{ color: 'black' }}>
              {rowType.first_name + ' ' + rowType.last_name}
            </NavLink>
          );
        }
      },
      {
        accessorKey: 'phone',
        header: t('phone'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'category',
        header: t('category'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          return <>{t('soon')}</>;
        }
      },
      {
        accessorKey: 'appointments_count',
        header: t('appointments_count'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );

  return (
    <>
      {!isMobile ? (
        <Grid container className="fullWidth">
          {checkPermission(currentCMS, PermissionsEnum.create_users) && (
            <Grid container justifyContent={'flex-end'} alignItems="end" p={3} pt={0}>
              <Grid item sm={5} className="row justify-end">
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => navigate('create-user')}
                >
                  {t('newUser')}
                </Button>
              </Grid>
            </Grid>
          )}
          {successfullyDeletedPopup && deleteIds.length > 1 ? (
            <Alert
              severity="success"
              sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
            >
              {t('users')}{' '}
              <span>
                {deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}
              </span>{' '}
              {t('superAdminUsers.successMultiple')}
            </Alert>
          ) : successfullyDeletedPopup && deleteIds.length === 1 ? (
            <Alert
              severity="success"
              sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
            >
              {t('users')}{' '}
              <span>
                {deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}
              </span>{' '}
              {t('superAdminUsers.successSingle')}
            </Alert>
          ) : null}
          <Card sx={{ mx: 3 }} className="fullWidth">
            <TableHeaderActions
              tableVariant="adminUsers"
              handleSearch={handleSearch}
              disableActions={!deletedNames}
              deleteEventHandler={checkPermissionFunc(
                currentCMS,
                PermissionsEnum.delete_sport_centers,
                deleteEventHandler
              )}
              //sort
              handleSortTables={handleSortTables}
              checkSort={checkSort}
              handleClearSort={handleClearSort}
              //filter
              handleClearFilter={handleClearFilter}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorFilterEl={anchorEl}
              anchorElActionButton={anchorElActionButton}
              anchorMenuItem={anchorMenuItem}
              anchorSortEl={anchorSortEl}
              controlFilter={controlFilter}
              handleSubmitFilter={handleSubmitFilter}
              filterValue={filterValue}
            />
            <Grid item lg={12} ml={'20px'} mr={'20px'}>
              {data && !isLoadingSportCenterUsers ? (
                <TableLayout
                  tableVariant="adminUsers"
                  rows={data?.data?.data}
                  columns={columnsAllUsers}
                  pagination={pagination}
                  setPagination={setPagination}
                  rowCount={data?.data?.meta?.total}
                  tableInstanceRef={tableInstanceRef}
                  withCheckbox
                />
              ) : (
                <Loading />
              )}
            </Grid>
          </Card>
        </Grid>
      ) : (
        <Grid container className="fullWidth" mt={-1}>
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            height={'80px'}
            borderBottom={'1px solid #D1D5DB'}
            position={'sticky'}
            top={0}
            sx={{ background: 'white' }}
            zIndex={2}
          >
            <MobileTableHeader
              tableVariant={'users'}
              setOpenMobileMenu={setOpenMobileMenu}
              handleSearch={handleSearch}
              filterValues={filterValues}
              handleClick={handleClick}
              handleClose={handleClose}
              anchorSortEl={anchorSortEl}
              handleSortTables={handleSortTables}
              checkSort={checkSort}
              handleClearSort={handleClearSort}
            />
          </Grid>
          {deleteIds.length >= 1 && (
            <Grid
              item
              xs={12}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              borderBottom={'1px solid #D1D5DB'}
              height={'56px'}
              sx={{ background: '#F5F7FA' }}
            >
              <Grid
                item
                xs={10}
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                ml={6}
              >
                <Typography fontSize={'16px'} fontWeight={500} ml={7.5}>
                  {checkedMobileUsersNames.toString().length > 30
                    ? checkedMobileUsersNames.toString().slice(0, 30) + '...'
                    : checkedMobileUsersNames.toString()}
                </Typography>
              </Grid>
              <Grid item xs={5} display={'flex'} justifyContent={'end'} flexDirection={'row'}>
                {/* <IconButton>
                  <MoreHorizIcon id="threeDots" data-testid="threeDots" sx={{ color: 'black' }} />
                </IconButton> */}
                <IconButton
                  sx={{
                    border: '1px solid #CACCCF',
                    borderRadius: '6px',
                    background: 'white',
                    mr: 2
                  }}
                  onClick={deleteEventHandler}
                >
                  <DeleteRowIcon id="deleteBulk" data-testid="deleteBulk" sx={{ color: 'black' }} />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {isLoading ? <CircularProgress /> : renderMobileUsers()}
          <Menu
            id="singleActionBtn-menu"
            anchorEl={anchorElActionButton}
            open={anchorElActionButton !== null}
            onClose={handleClose}
          >
            {deleteEventHandler && (
              <MenuItem onClick={deleteEventHandler}>
                <DeleteRowIcon />
                <Typography ml={3.5}>{t('delete')}</Typography>
              </MenuItem>
            )}
          </Menu>
          <MobileMenu
            anchor="right"
            open={openMobileMenu}
            onClose={() => setOpenMobileMenu(false)}
          />
        </Grid>
      )}
    </>
  );
};

export default AdminUsersPage;
