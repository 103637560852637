//error
import {
  DeleteImageRequest,
  ImageType,
  UploadImageError,
  UploadImageErrorResponse,
  UploadImageRequest,
  UploadImageResponse
} from '@interfaces/Images/images';
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//interface
import {
  CreateSportRequest,
  Sport,
  SportError,
  SportErrorResponse,
  SportResponse
} from '@interfaces/sport/sport';
//service
import {
  createSport,
  deleteSport,
  deleteSportImage,
  updateSport,
  uploadSportImage
} from '@services/sport/sport';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useUpdateSport = (
  onSuccess: (data: Sport) => void,
  onError: (err: SportError) => void
): UseMutationResult<
  { data: SportResponse }, // response
  SportErrorResponse,
  Sport, // request
  (data: Sport) => void // on success
> =>
  useMutation({
    mutationFn: updateSport,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useCreateSport = (
  onSuccess: (data: Sport) => void,
  onError: (err: SportError) => void
): UseMutationResult<
  { data: SportResponse }, // response
  SportErrorResponse,
  FormData, // request
  (data: Sport) => void // on success
> =>
  useMutation({
    mutationFn: createSport,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteSport = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  number, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteSport,
    onSuccess: data => onSuccess({ message: 'Successfully deleted sport' }),
    onError: err => onError(err.response.data)
  });

export const useUploadSportImage = (
  onSuccess: (data: UploadImageResponse) => void,
  onError: (err: UploadImageError) => void
): UseMutationResult<
  { data: UploadImageResponse }, // response
  UploadImageErrorResponse,
  UploadImageRequest, // request
  (data: ImageType) => void // on success
> =>
  useMutation({
    mutationFn: uploadSportImage,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteSportImage = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  DeleteImageRequest, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteSportImage,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
