//components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
//react form
import { Control, Controller } from 'react-hook-form';
//types
import { EmployeeCreateRequest } from '@interfaces/employees/employees';
//hooks
import { useTranslation } from 'react-i18next';
//helpers
import dayjs from 'dayjs';

type PersonalDataPropsType = {
  control: Control<EmployeeCreateRequest>;
};

const PersonalData = ({ control }: PersonalDataPropsType) => {
  const { t } = useTranslation();

  return (
    <Accordion expanded={true} sx={{ px: 6 }}>
      <AccordionSummary>
        <Box>
          <Typography variant="h6">{t('employee.personalData')}</Typography>
          <Typography variant="subtitle2" color="GrayText">
            {t('employee.basicDataTitle')}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} pb={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_info.first_name"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t('name')}
                  required
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error && error.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_info.last_name"
              defaultValue={''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t('surname')}
                  required
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error && error.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_info.date_of_birth"
              defaultValue={dayjs()}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <>
                  <DatePicker
                    label={t('date_of_birth') + ' *'}
                    format="DD.MM.YYYY"
                    sx={{ width: '100%' }}
                    {...field}
                  />
                  {error && <FormHelperText color="error">{error.message}</FormHelperText>}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_info.gender"
              defaultValue={''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl fullWidth>
                  <InputLabel>{t('gender') + ' *'}</InputLabel>
                  <Select {...field} label={t('gender') + ' *'} error={invalid}>
                    <MenuItem value="male">{t('male')}</MenuItem>
                    <MenuItem value="female">{t('female')}</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="cooperation_info.address"
              defaultValue={''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t('address')}
                  required
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error && error.message}
                  placeholder={t('addressPlaaceholder').toString()}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="cooperation_info.professional_qualification"
              defaultValue={''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t('employee.professional_qualification')}
                  required
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error && error.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_info.email"
              defaultValue={''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t('email')}
                  required
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error && error.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_info.phone"
              defaultValue={''}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t('phone_number')}
                  required
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error && error.message}
                  InputProps={{
                    startAdornment: <Typography>+3816</Typography>
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default PersonalData;
