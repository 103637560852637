// hooks
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// components
import { Typography, Button, Stack } from '@mui/material';
import emalVerifiedIcon from '@src/assets/images/emailVerifiedIcon.png';

const EmailVerifiedPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Stack gap={2} textAlign="center" alignItems="center">
      <img src={emalVerifiedIcon} width={100} alt="email-verified-icon" />
      <Typography variant="h2">{t('verified_email.success_message_title')}</Typography>
      <Typography variant="h5" color="text.secondary">
        {t('verified_email.success_message_content')}
      </Typography>

      <Button variant="contained" onClick={() => navigate('/login')}>
        {' '}
        {t('verified_email.button_text')}
      </Button>
    </Stack>
  );
};

export default EmailVerifiedPage;
