//react
import { useState } from 'react';
//form hooks
import { useForm } from 'react-hook-form';
//type
import { ChangePasswordErrorType, ChangePasswordRequestType } from '@interfaces/user/login';
import { User } from '@interfaces/user/user';
//mutation
import { useChangePassword } from '@api/mutations/user/login';

export function useChangePasswordHook() {
  const [showPassword, setShowPassword] = useState(-1);
  const [success, setSuccess] = useState('');
  const {
    handleSubmit,
    control,
    setError,
    reset: resetForm,
    formState: { errors, isValid }
  } = useForm<ChangePasswordRequestType>({
    defaultValues: {
      old_password: '',
      password: '',
      password_confirmation: ''
    }
  });

  const onError = (err: ChangePasswordErrorType) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as keyof ChangePasswordRequestType,
          {
            type: 'custom',
            message: err.errors[key as keyof ChangePasswordRequestType].join(' ')
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setError('root', {
        message: 'doslo je do greske'
      });
    }
  };

  const onSuccess = (u: User) => {
    setSuccess('Uspesno ste promenili lozinku');
    resetForm();

    setTimeout(() => {
      setSuccess('');
    }, 2000);
  };

  const { mutate: changePassword } = useChangePassword(onSuccess, onError);

  const onSubmit = (data: ChangePasswordRequestType) => {
    changePassword(data);
  };

  return {
    onSubmit,
    handleSubmit,
    errors,
    success,
    control,
    isValid,
    showPassword,
    setShowPassword
  };
}
