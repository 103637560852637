import { createTheme } from '@mui/material/styles';
import { grey, red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    errorPageText: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    errorPageText?: React.CSSProperties;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body3: true;
    body4: true;
    errorPageText: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    tab: true;
    sportyMateGray: true;
    appointmentTextColor: true;
    cmsEventColor: true;
    cmsRecurringEventColor: true;
    appInProgressEventColor: true;
    appCompletedEventColor: true;
    appScheduledEventColor: true;
    playedEventBtnColor: true;
    unPlayedEventBtnColor: true;
    sportyMateError: true;
    coachEventColor: true;
    coachOtherEventColor: true;
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    sportyMateGray: Palette['primary'];
    appointmentTextColor: Palette['primary'];
    cmsEventColor: Palette['primary'];
    cmsRecurringEventColor: Palette['primary'];
    appInProgressEventColor: Palette['primary'];
    appCompletedEventColor: Palette['primary'];
    appScheduledEventColor: Palette['primary'];
    playedEventBtnColor: Palette['primary'];
    unPlayedEventBtnColor: Palette['primary'];
    sportyMateError: Palette['primary'];
    coachEventColor: Palette['primary'];
    coachOtherEventColor: Palette['primary'];
    sportyMateGreen: Palette['primary'];
  }

  interface PaletteOptions {
    sportyMateGray?: PaletteOptions['primary'];
    appointmentTextColor?: PaletteOptions['primary'];
    cmsEventColor?: PaletteOptions['primary'];
    cmsRecurringEventColor?: PaletteOptions['primary'];
    appInProgressEventColor?: PaletteOptions['primary'];
    appCompletedEventColor?: PaletteOptions['primary'];
    appScheduledEventColor?: PaletteOptions['primary'];
    playedEventBtnColor?: PaletteOptions['primary'];
    unPlayedEventBtnColor?: PaletteOptions['primary'];
    sportyMateError?: Palette['primary'];
    coachEventColor?: PaletteOptions['primary'];
    coachOtherEventColor?: PaletteOptions['primary'];
    sportyMateGreen?: PaletteOptions['primary'];
  }
}
const theme = createTheme({
  palette: {
    primary: {
      main: '#6366F1'
    },
    secondary: {
      main: '#19857b',
      light: '#6B7280'
    },
    error: {
      main: red.A700
    },
    info: {
      main: '#FABD4E'
    },
    sportyMateGray: {
      main: '#6B7280',
      light: 'rgba(107, 114, 128, 0.5)'
    },
    appointmentTextColor: {
      main: '#4D5E80'
    },
    cmsEventColor: {
      main: '#f3eefe',
      dark: '#6366F1'
    },
    cmsRecurringEventColor: {
      main: '#e5dfda',
      dark: '#654321'
    },
    appInProgressEventColor: {
      main: '#fceaf1',
      dark: '#E62E7B'
    },
    appCompletedEventColor: {
      main: '#E9F9EB',
      dark: '#29CC39'
    },
    appScheduledEventColor: {
      main: '#fff9ea',
      dark: '#FFCB33'
    },
    playedEventBtnColor: {
      main: '#D1FAE5',
      dark: '#059669'
    },
    unPlayedEventBtnColor: {
      main: '#FEE2E2',
      dark: '#D50000'
    },
    coachEventColor: {
      main: '#ffcc99',
      dark: '#ff8c00'
    },
    coachOtherEventColor: {
      main: '#E8E9F0',
      dark: '#21246E'
    },
    sportyMateGreen: {
      main: '#23E5A8',
      dark: '#399097'
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'Inter'
    },
    body3: {
      fontSize: '10px'
    },
    body4: {
      fontSize: '8px'
    },
    errorPageText: {
      fontFamily: 'Inter',
      fontWeight: '500',
      fontSize: '32px',
      color: '#399097'
    }
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none'
          },
          '& input[type=number]': {
            MozAppearance: 'textfield'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
            display: 'none'
          },
          '& input[type=number]': {
            MozAppearance: 'textfield'
          }
        }
      }
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      },
      variants: [
        {
          props: { variant: 'tab' },
          style: {
            color: '#4338CA',
            background: '#E0E7FF'
          }
        },
        {
          props: { variant: 'tab', color: 'secondary' },
          style: {
            color: grey[500],
            background: 'none'
          }
        },
        {
          props: { variant: 'sportyMateError', color: 'primary' },
          style: {
            color: '#399097',
            background: '#E3F4F5'
          }
        }
      ]
    }
  }
});

export default theme;
export const primaryColor = '#6366F1';
export const greenSelectBgColor = '#3990971a';
export const greenSelectColor = '#399097';
