import React from 'react';
// components
import { Box, Stepper, Step, StepLabel } from '@mui/material';
// containers
import StepOne from '@containers/StepOne/StepOne';
import StepTwo from '@containers/StepTwo/StepTwo';
import StepThree from '@containers/StepThree/StepThree';
import StepFour from '@containers/StepFour/StepFour';
//hooks
import { useCreateSportCenterStep1 } from '@hooks/createSportCenterStep1/useCreateSportCenterStep1';

type CreateSportCenterProps = {
  activeStep: number;
};
const CreateSportCenter = ({ activeStep }: CreateSportCenterProps): JSX.Element => {
  const { steps } = useCreateSportCenterStep1();

  return (
    <Box sx={{ width: '100%', backgroundColor: '#FCFCFC', minHeight: '91vh' }}>
      <Stepper activeStep={activeStep}>
        {steps.map(label => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <React.Fragment>
          {activeStep === 0 && <StepOne />}
          {activeStep === 1 && <StepTwo />}
          {activeStep === 2 && <StepThree />}
          {activeStep === 3 && <StepFour buttons />}
        </React.Fragment>
      }
    </Box>
  );
};
export default CreateSportCenter;
