import { Dispatch, SetStateAction } from 'react';
// components
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  Tooltip,
  Accordion,
  AccordionDetails,
  FormHelperText,
  Button,
  TextField
} from '@mui/material';
import MoreInfo from '@src/ui/components/Forms/SportCenters/MoreInfo/MoreInfo';
//icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { PlusCircle } from '@components/icons/icons';
//react form
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
//interfaces
import { Sport } from '@src/domain/interfaces/sport/sport';
import { CourtType } from '@src/domain/interfaces/court/court';
import { AttachSportRequest } from '@src/domain/interfaces/sport/sport';
//hooks
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
//atoms
import { sportFormAtom } from '@atoms/sportAtom';
//theme
import { primaryColor } from '@src/theme';

type NewSportPropsType = {
  title?: string;
  subtitle?: string;
  control: Control<AttachSportRequest>;
  handleClick: Function;
  fields: CourtType[];
  disabled: boolean;
  sports: Sport[];
  setValueSport: UseFormSetValue<AttachSportRequest>;
  customFields: any;
  open: boolean;
  onClick: Dispatch<SetStateAction<boolean>>;
  editSport: boolean;
  hideTitle?: boolean;
  onCancel: () => void;
  sportCenterId: number | undefined;
};

const NewSport = ({
  title,
  subtitle,
  control,
  handleClick,
  fields,
  disabled,
  sports,
  setValueSport,
  customFields,
  open,
  onClick,
  editSport,
  onCancel,
  sportCenterId
}: NewSportPropsType) => {
  const { t } = useTranslation();
  const lng = localStorage.getItem('lng');
  const setSportForm = useSetRecoilState(sportFormAtom);
  const navigate = useNavigate();

  return (
    <Box>
      {!open && (
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            onClick={() => onClick(!open)}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t('sportCenters.stepThree.newSport.add_new_sport_button')}
          </Button>
        </Grid>
      )}
      {(open || editSport) && (
        <Accordion expanded={true}>
          <AccordionDetails sx={{ px: { md: 15, xs: 2 }, pt: 2 }}>
            <Box>
              <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
                {title}
              </Typography>
              <Typography variant="subtitle2" color="GrayText">
                {subtitle}
              </Typography>
            </Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="sportId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel error={invalid}>{t('sport')}</InputLabel>
                      <Select
                        {...field}
                        label={t('sport')}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        error={invalid}
                        id="sportName"
                        disabled={disabled || editSport}
                      >
                        <MenuItem
                          onClick={() => {
                            setSportForm({
                              fromSportForm: true,
                              sportCenterID: sportCenterId,
                              sportID: ''
                            });
                            navigate('/settings/sports/create-sport');
                          }}
                        >
                          <PlusCircle />
                          <Typography
                            ml={'2%'}
                            color={primaryColor}
                            fontSize={'1rem'}
                            fontWeight={600}
                            borderBottom={`1px solid ${primaryColor}`}
                          >
                            {t('sportCenters.stepThree.newSport.add_new_sport_button')}
                          </Typography>
                        </MenuItem>
                        {sports.map(p => (
                          <MenuItem key={p.id} value={p.id}>
                            {lng === 'en' ? p.name_translates?.en : p.name_translates?.sr}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="courtId"
                  control={control}
                  rules={{ required: true }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel error={invalid}>{t('field')}</InputLabel>
                      <Select
                        {...field}
                        label={t('field')}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        error={invalid}
                        id="sportFieldName"
                        disabled={disabled || editSport}
                      >
                        {fields?.map((teren, index) => (
                          <MenuItem key={index} value={teren.id}>
                            {teren.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="max_simultaneous_appointments"
                  control={control}
                  rules={{ required: true, min: 1 }}
                  defaultValue={1}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      sx={{ width: '100%' }}
                      label={t('sportCenters.stepThree.max_simultaneous_appointments')}
                      variant="outlined"
                      {...field}
                      error={invalid}
                      helperText={error?.message}
                      id="max_simultaneous_appointments"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {customFields && (
                  <MoreInfo
                    control={control}
                    customFields={customFields.data.data}
                    setValue={setValueSport}
                    lng={lng}
                  />
                )}
              </Grid>
            </Grid>
            {editSport ? (
              <Box display={'flex'} justifyContent="flex-end" my={2}>
                <Button color="error" size="small" onClick={() => onCancel()}>
                  {t('cancel')}
                </Button>
                <Button
                  color="success"
                  size="small"
                  variant="contained"
                  onClick={() => handleClick()}
                  disabled={disabled}
                >
                  {t('confirm')}
                </Button>
              </Box>
            ) : (
              <Box display={'flex'} justifyContent="flex-end" my={2}>
                <Button sx={{ color: 'gray', mr: 1 }} size="small" onClick={() => onCancel()}>
                  {t('cancel')}
                </Button>
                <Tooltip
                  title={t('sportCenters.stepThree.newSport.tooltip')}
                  arrow
                  placement="right"
                >
                  <Button variant="contained" onClick={() => handleClick()} disabled={disabled}>
                    {t('add')}
                  </Button>
                </Tooltip>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default NewSport;
