//interfaces
import {
  SingleSportCenterRoleRequestType,
  SportCenterRoleRequestType
} from '@interfaces/roles/roles';

import { SuccessResponse } from '@interfaces/apiResponse';
import {
  AllRolesResponseType,
  RoleType,
  RoleRequestType,
  SingleRoleResponseType
} from '@interfaces/roles/roles';
//axios instance
import axiosInstance from '@services/config';

export const getAllRoles = (): Promise<AllRolesResponseType> => axiosInstance.get('/roles');
export const getSingleRole = (id: number): Promise<SingleRoleResponseType> =>
  axiosInstance.get('/roles/' + id);

export const createRole = (data: RoleRequestType): Promise<SingleRoleResponseType> =>
  axiosInstance.post('/roles', data);

export const updateRole = (data: RoleType): Promise<SingleRoleResponseType> =>
  axiosInstance.put('/roles/' + data.id, data);

export const deleteRole = (id: number): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete('/roles/' + id);

export const getAllSportCenterRoles = (sportCenterId: number): Promise<AllRolesResponseType> =>
  axiosInstance.get(`/sport-centers/${sportCenterId}/roles`);

export const getSingleSportCenterRole = ({
  sportCenterId,
  roleId
}: SingleSportCenterRoleRequestType): Promise<SingleRoleResponseType> =>
  axiosInstance.get(`/sport-centers/${sportCenterId}/roles/${roleId}`);

export const createSportCenterRole = (
  data: SportCenterRoleRequestType
): Promise<SingleRoleResponseType> =>
  axiosInstance.post(`/sport-centers/${data.sportCenterId}/roles`, data);

export const updateSportCenterRole = (
  data: RoleType & { sportCenterId: number }
): Promise<SingleRoleResponseType> =>
  axiosInstance.put(`/sport-centers/${data.sportCenterId}/roles/` + data.id, data);

export const deleteSportCenterRole = ({
  sportCenterId,
  roleId
}: SingleSportCenterRoleRequestType): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${sportCenterId}/roles/${roleId}`);
