// types
import { Control } from 'react-hook-form';
import { TFunction } from 'i18next';
import { CourtSportType } from '@interfaces/priceRules/priceRules';
import { CreateCoachRequestType } from '@interfaces/user/coaches/coaches';
// components
import {
  Grid,
  TextField,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  InputAdornment
} from '@mui/material';
import { Controller } from 'react-hook-form';
// icons
import { MoneyIcon } from '@components/icons/icons';

type AddCoachesFieldsAndPriceProps = {
  control: Control<CreateCoachRequestType>;
  t: TFunction;
  courtSport: CourtSportType[];
};

const AddCoachesFieldsAndPrice = ({ control, t, courtSport }: AddCoachesFieldsAndPriceProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="court_sport_ids"
          control={control}
          defaultValue={[]}
          rules={{ required: t('errorMessage').toString() }}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl className="fullWidth">
              <InputLabel error={invalid}>{t('field') + ' / ' + t('sport') + ' *'}</InputLabel>
              <Select
                {...field}
                label={t('field') + ' / ' + t('sport') + ' *'}
                variant="outlined"
                multiple
                sx={{ width: '100%', '#court_sport_ids': { whiteSpace: 'pre-wrap' } }}
                error={invalid}
                id="court_sport_ids"
              >
                {courtSport.map(courtSportEl =>
                  courtSportEl.court_sports.map(coSpo => (
                    <MenuItem key={coSpo.id} value={coSpo.id}>
                      {coSpo.court.name} - {coSpo.sport.name}
                    </MenuItem>
                  ))
                )}
              </Select>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="coach_info.price_per_hour"
          control={control}
          defaultValue={''}
          rules={{ required: t('errorMessage').toString() }}
          render={({ field, fieldState: { error, invalid } }) => (
            <TextField
              {...field}
              type="number"
              label={t('price_per_hour') + ' *'}
              variant="outlined"
              error={invalid}
              helperText={error?.message}
              onChange={e => field.onChange(!e.target.value ? undefined : parseInt(e.target.value))}
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <InputAdornment position="start">RSD</InputAdornment>
                    <MoneyIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AddCoachesFieldsAndPrice;
