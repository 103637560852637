//react-query
import { UseMutationResult, useMutation } from '@tanstack/react-query';
//services
import {
  cancelAppointment,
  confirmAppointment,
  createRecurringAppointment,
  createRegularAppointment,
  deleteRecurringAppointment,
  declineAppointment,
  updateAppointment,
  updateRecurringAppointment
} from '@services/appointments/appointments';
//interfaces
import {
  RegularAppointmentType,
  CreateRegularAppointmentErrorType,
  RegularAppointmentResponseType,
  CreateRegularAppointmentErrorTypeResponse,
  RegularAppointmentCreateRequest,
  RecurringAppointmentResponseType,
  CreateRecurringAppointmentErrorTypeResponse,
  RecurringAppointmentCreateRequest,
  RecurringAppointment,
  CreateRecurringAppointmentErrorType
} from '@interfaces/appointments/appointments';
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';

export const useCreateRegularAppointment = (
  onSuccess: (data: RegularAppointmentType) => void,
  onError: (err: CreateRegularAppointmentErrorType) => void
): UseMutationResult<
  RegularAppointmentResponseType, // response
  CreateRegularAppointmentErrorTypeResponse,
  RegularAppointmentCreateRequest, // request
  (data: RegularAppointmentType) => void // success
> =>
  useMutation({
    mutationFn: createRegularAppointment,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useCreateRecurringAppointment = (
  onSuccess: (data: RecurringAppointment) => void,
  onError: (err: CreateRecurringAppointmentErrorType) => void
): UseMutationResult<
  RecurringAppointmentResponseType, // response
  CreateRecurringAppointmentErrorTypeResponse,
  RecurringAppointmentCreateRequest, // request
  (data: RecurringAppointment) => void // success
> =>
  useMutation({
    mutationFn: createRecurringAppointment,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateAppointment = (
  onSuccess: (data: RegularAppointmentType) => void,
  onError: (err: CreateRegularAppointmentErrorType) => void
): UseMutationResult<
  RegularAppointmentResponseType, // response
  CreateRegularAppointmentErrorTypeResponse,
  RegularAppointmentCreateRequest & { appointment_id: number }, // request
  (data: RegularAppointmentType) => void // on success
> =>
  useMutation({
    mutationFn: updateAppointment,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useConfirmAppointment = (
  onSuccess: (data: string) => void,
  onError: (err: Error) => void
): UseMutationResult<
  SuccessResponse, // response
  Error,
  number, // request
  (data: string) => void // on success
> =>
  useMutation({
    mutationFn: confirmAppointment,
    onSuccess: data => onSuccess(data.message),
    onError: err => onError(err)
  });

export const useDeclineAppointment = (
  onSuccess: (data: string) => void,
  onError: (err: Error) => void
): UseMutationResult<
  SuccessResponse, // response
  Error,
  number, // request
  (data: string) => void // on success
> =>
  useMutation({
    mutationFn: declineAppointment,
    onSuccess: data => onSuccess(data.message),
    onError: err => onError(err)
  });

export const useCancelAppointment = (
  onSuccess: (data: string) => void,
  onError: (err: Error) => void
): UseMutationResult<
  SuccessResponse, // response
  Error,
  number, // request
  (data: string) => void // on success
> =>
  useMutation({
    mutationFn: cancelAppointment,
    onSuccess: data => onSuccess(data.message),
    onError: err => onError(err)
  });

export const useUpdateRecurringAppointment = (
  onSuccess: (data: RecurringAppointmentResponseType) => void,
  onError: (err: CreateRecurringAppointmentErrorType) => void
): UseMutationResult<
  RecurringAppointmentResponseType, // response
  CreateRecurringAppointmentErrorTypeResponse,
  RecurringAppointmentCreateRequest & { id: number }, // request
  (data: RecurringAppointmentResponseType) => void // success
> =>
  useMutation({
    mutationFn: updateRecurringAppointment,
    onSuccess: data => onSuccess(data),
    onError: err => onError(err.response.data)
  });

export const useDeleteRecurringAppointment = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  number, //request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteRecurringAppointment,
    onSuccess: data => onSuccess({ message: 'Sucessfully deleted recurring appointment' }),
    onError: err => onError(err.response.data)
  });
