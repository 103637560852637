// axios instance
import axiosInstance from '@services/config';
// interfaces
import {
  AutocompleteResponse,
  LocationResponse,
  AutocompleteRequest,
  LocationPosition
} from '@interfaces/locationsGeoCode/locationsGeoCode';

export const getLocationsList = ({
  query
}: AutocompleteRequest): Promise<{ data: AutocompleteResponse }> =>
  axiosInstance.get('/geocode/autocomplete', {
    params: {
      query: query
    }
  });
export const getLocation = (locationId: string): Promise<{ data: LocationResponse }> =>
  axiosInstance.get(`/geocode/lookup/${locationId}`);

export const getReverseGeocode = (req: LocationPosition): Promise<{ data: AutocompleteResponse }> =>
  axiosInstance.get(`/geocode/reverse-geocode`, {
    params: { latitude: req.latitude, longitude: req.longitude }
  });
