import { SuccessResponse } from '@interfaces/apiResponse';
import {
  AllNonWorkingDaysResponseType,
  NonWorkingDaysRequestType,
  NonWorkingDaysResponseType,
  NonWorkingDaysType
} from '@interfaces/nonWorkingDays/nonWorkingDays';
import axiosInstance from '@services/config';

export const getAllNonWorkingDays = (
  sportCenterId: number
): Promise<{ data: AllNonWorkingDaysResponseType }> =>
  axiosInstance.get(`/sport-centers/${sportCenterId}/non-working-days`);

export const getSingleNonWorkingDays = (
  sportCenterId: number,
  nonWorkingDaysId: number
): Promise<{ data: NonWorkingDaysResponseType }> =>
  axiosInstance.get(`/sport-centers/${sportCenterId}/non-working-days/${nonWorkingDaysId}`);

export const createNonWorkingDays = (
  sportCenterId: number,
  data: NonWorkingDaysRequestType
): Promise<{ data: NonWorkingDaysResponseType }> =>
  axiosInstance.post(`/sport-centers/${sportCenterId}/non-working-days`, data);

export const update = (
  id: number,
  sportCenterId: number,
  data: NonWorkingDaysRequestType //& { sportCenterId: number; id: number }
): Promise<{ data: NonWorkingDaysResponseType }> =>
  axiosInstance.put(`/sport-centers/${sportCenterId}/non-working-days/${id}`, data);

export const deleteNonWorkingDay = (
  sportCenterId: number,
  nonWorkingDaysId: number
): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${sportCenterId}/non-working-days/${nonWorkingDaysId}`);
