//interfaces
import {
  AnalyticsCountsResponseType,
  AppointmentsCountByHourResponseType,
  CourtCountBySportResponseType,
  LatestAppointmentsResponseType,
  LatestUsersResponseType,
  SportCenterAppointmentsCountByHourResponseType,
  SportCenterMostActiveUsersResponseType,
  SportCenterAnalyticCountType,
  SportCenterAnalyticCountResponseType
} from '@interfaces/analytics/analytics';
//services
import {
  getAnalyticsCounts,
  getAppointmentsCountByHour,
  getCourtsCountBySport,
  getLatestUsers,
  getLatestappointments,
  getSportCenterAnalyticsCount,
  getSportCenterAppointmentsCountByHour,
  getSportCenterMostActiveUsers
} from '@services/analytics/analytics';
//react-query
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useAnalyticsCounts = (
  onError: (err: Error) => void
): UseQueryResult<AnalyticsCountsResponseType, Error> =>
  useQuery(['superadminAnalyticsCounts'], () => getAnalyticsCounts(), {
    onError: err => onError(err)
  });

export const useLatestUsers = (
  onError: (err: Error) => void,
  limit?: number
): UseQueryResult<LatestUsersResponseType, Error> =>
  useQuery(['superadminLatestUsers', limit], () => getLatestUsers(limit), {
    onError: err => onError(err)
  });

export const useAppointmentsCountByHour = (
  onError: (err: Error) => void,
  datetime_from?: string,
  datetime_to?: string
): UseQueryResult<AppointmentsCountByHourResponseType, Error> =>
  useQuery(
    ['superadminAppointmentsCountByHour', datetime_from, datetime_to],
    () => getAppointmentsCountByHour(datetime_from, datetime_to),
    {
      onError: err => onError(err)
    }
  );

export const useLatestAppointments = (
  onError: (err: Error) => void,
  limit?: number
): UseQueryResult<LatestAppointmentsResponseType, Error> =>
  useQuery(['superadminLatestAppointments', limit], () => getLatestappointments(limit), {
    onError: err => onError(err)
  });

export const useCourtsCountBySports = (
  onError: (err: Error) => void
): UseQueryResult<CourtCountBySportResponseType, Error> =>
  useQuery(['superadminCourtsCountBySports'], () => getCourtsCountBySport(), {
    onError: err => onError(err)
  });

export const useSportCenterAnalitycsCount = (
  sportCenter: number,
  onError: (err: Error) => void
): UseQueryResult<SportCenterAnalyticCountResponseType, Error> =>
  useQuery(
    ['adminSportCenterAnalyticsCount', sportCenter],
    () => getSportCenterAnalyticsCount(sportCenter),
    {
      onError: err => onError(err)
    }
  );

export const useSportCenterAppointmentsCountByHour = (
  onError: (err: Error) => void,
  sportCenter: number,
  datetime_from?: string,
  datetime_to?: string
): UseQueryResult<SportCenterAppointmentsCountByHourResponseType, Error> =>
  useQuery(
    ['adminSportCenterAppointmentsCountByHour', sportCenter, datetime_from, datetime_to],
    () => getSportCenterAppointmentsCountByHour(sportCenter, datetime_from, datetime_to),
    {
      onError: err => onError(err)
    }
  );

export const useSportCenterMostActiveUsers = (
  onError: (err: Error) => void,
  sportCenter: number,
  limit?: number
): UseQueryResult<SportCenterMostActiveUsersResponseType, Error> =>
  useQuery(
    ['adminSportCenterMostActiveUsers', sportCenter, limit],
    () => getSportCenterMostActiveUsers(sportCenter, limit),
    {
      onError: err => onError(err)
    }
  );
