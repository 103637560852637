// hooks
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

// atoms
import { popupAtom } from '@atoms/popupAtom';
// queries
import { useCourtSport } from '@api/queries/priceRules/priceRules';
// interfaces
import { SportCenterWithCourtSportResponse } from '@interfaces/SportCenters/SportCenter';
import {
  AdminCoachesKeyType,
  CoachErrorKeys,
  CoachErrorType,
  CoachResponseType,
  CoachType,
  CreateCoachRequestType
} from '@interfaces/user/coaches/coaches';
import { SubmitHandler } from 'react-hook-form';
import { CourtSportType, CourtSportError } from '@interfaces/priceRules/priceRules';
// mocks
import { adminCoachesKeys, createAdminCoach } from '@src/__mock__/adminCoaches/adminCoaches';
import {
  useCreateCoach,
  useDeleteCoach,
  useSingleCoach,
  useUpdateSingleCoach
} from '@api/mutations/coaches/coaches';
import { getDataFromCoahesResponse } from '@helpers/utility';
import dayjs from 'dayjs';
import { SuccessResponse } from '@interfaces/apiResponse';

const useCoachesHook = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [courtSport, setCourtSport] = useState<CourtSportType[]>([]);
  const sportCenterId: number = Number(params.sportCenterId);
  const coachId: number = Number(params.coachId);
  const [adminCoachesEditData, setAdminCoachesEditData] =
    useState<AdminCoachesKeyType[]>(adminCoachesKeys);

  const [coachData] = useState<CreateCoachRequestType>(createAdminCoach);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [isAddMoreHours, setIsAddMoreHours] = useState<boolean>(false);
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setError
  } = useForm<CreateCoachRequestType>({
    defaultValues: coachData,
    mode: 'onBlur'
  });

  //get created courts sports
  const onGetCourtSportSuccess = (data: SportCenterWithCourtSportResponse) => {
    setCourtSport(data.data.courts);
  };

  function onGetCourtSportError(err: CourtSportError) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const { refetch: getCourtSport, isLoading: isLoadingGetCourtSport } = useCourtSport(
    sportCenterId,
    onGetCourtSportSuccess,
    onGetCourtSportError
  );

  // Create coach

  const onCreateCoachSuccess = (data: CoachResponseType) => {
    navigate(`/users/${sportCenterId}/coaches`);
  };
  const onCreateCoachError = (err: CoachErrorType) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as CoachErrorKeys,
          {
            type: 'custom',
            message: err.errors[key].join()
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };
  const { mutate: createCoach, isLoading: isLoadingCreateCoach } = useCreateCoach(
    onCreateCoachSuccess,
    onCreateCoachError
  );
  // Get single coach
  const onSingleCoachSuccess = (data: CoachResponseType) => {
    setAdminCoachesEditData(getDataFromCoahesResponse(data).tableData);
    reset(getDataFromCoahesResponse(data).editFormData);
  };
  const onSingleCoachError = (err: CoachErrorType) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: getSingleCoach, isLoading: isLoadingSingleCoach } = useSingleCoach(
    onSingleCoachSuccess,
    onSingleCoachError
  );
  // Update coach
  const onUpdateCoachSuccess = (data: CoachResponseType) => {
    if (isAddMoreHours || openEdit) {
      setIsAddMoreHours(false);
      setOpenEdit(false);
      setAdminCoachesEditData(getDataFromCoahesResponse(data).tableData);
      reset(getDataFromCoahesResponse(data).editFormData);
    }
  };
  const onUpdateCoachError = (err: CoachErrorType) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as CoachErrorKeys,
          {
            type: 'custom',
            message: err.errors[key].join()
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };
  const { mutate: updateCoach, isLoading: isLoadingUpdateCoach } = useUpdateSingleCoach(
    onUpdateCoachSuccess,
    onUpdateCoachError
  );
  // Delete coach
  const onDeleteCoachSuccess = (data: SuccessResponse) => {
    navigate(`/users/${sportCenterId}/coaches`);
    resetPopup();
  };
  const onDeleteCoachError = (err: CoachErrorType) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteCoach, isLoading: isLoadingDeleteCoach } = useDeleteCoach(
    onDeleteCoachSuccess,
    onDeleteCoachError
  );
  const handleCancel = () => {
    if (coachId) {
      setOpenEdit(false);
    } else {
      navigate(`/users/${sportCenterId}/coaches`);
    }
  };
  const onAddCoachSubmit: SubmitHandler<CreateCoachRequestType> = (data, e) => {
    e?.preventDefault();
    let coachInfo = data.coach_info;
    if (coachId && sportCenterId) {
      if (isAddMoreHours || openEdit) {
        updateCoach({
          sportCenterId: sportCenterId,
          coachId: coachId,
          data: {
            coach_info: {
              date_start: dayjs(coachInfo.date_start).format('DD.MM.YYYY'),
              date_end: dayjs(coachInfo.date_end).format('DD.MM.YYYY'),
              cancellation_policy: coachInfo.cancellation_policy,
              assigned_hours: coachInfo.assigned_hours,
              price_per_hour: coachInfo.price_per_hour
            },
            court_sport_ids: data.court_sport_ids,
            user_info: data.user_info
          }
        });
      }
    } else {
      createCoach({
        sportCenterId: sportCenterId,
        data: {
          coach_info: {
            date_start: dayjs(coachInfo.date_start).format('DD.MM.YYYY'),
            date_end: dayjs(coachInfo.date_end).format('DD.MM.YYYY'),
            cancellation_policy: coachInfo.cancellation_policy,
            assigned_hours: coachInfo.assigned_hours,
            price_per_hour: coachInfo.price_per_hour
          },
          court_sport_ids: data.court_sport_ids,
          user_info: data.user_info
        }
      });
    }
  };
  const onEditCoach = (row?: CoachType): void => {
    if (coachId) {
      setOpenEdit(true);
    } else if (row) {
      navigate(`edit-coach/${row.id}`);
    }
  };
  const onAddMoreHours = () => {
    setOpenEdit(true);
    setIsAddMoreHours(true);
  };
  const onDeactivateCoach = () => {
    setPopup({
      open: true,
      title: t('coming_soon'),
      // t('coachesPage.deactivate.title')
      content: t('coming_soon'),
      // t('coachesPage.deactivate.subtitle')
      variant: 'info'
      // onClick: () => {
      //   alert(`${t('coachesPage.deactivate.deactivated_info')} `);
      //   navigate(`/users/${sportCenterId}/coaches`);
      //   resetPopup();
      // }
    });
  };

  const onDeleteCoach = () => {
    setPopup({
      open: true,
      title: t('coachesPage.delete.title'),
      content: t('coachesPage.delete.subtitle'),
      variant: 'error',
      buttonText: `${t('delete')}`,
      onClick: () => deleteCoach({ sportCenterId, coachId })
    });
  };
  useEffect(() => {
    if (sportCenterId) {
      getCourtSport();
      if (coachId) {
        // Get coach
        getSingleCoach({ sportCenterId, coachId });
      }
    }
  }, []);

  useEffect(() => {
    if (!openEdit) {
      setIsAddMoreHours(false);
    }
  }, [openEdit]);

  return {
    handleSubmit: handleSubmit(onAddCoachSubmit),
    control,
    courtSport,
    isLoadingGetCourtSport,
    isValid,
    adminCoachesEditData,
    openEdit,
    handleCancel,
    isDirty,
    onEditCoach,
    isAddMoreHours,
    onAddMoreHours,
    onDeactivateCoach,
    onDeleteCoach,
    isLoadingCreateCoach,
    isLoadingSingleCoach,
    isLoadingUpdateCoach,
    setOpenEdit
  };
};

export default useCoachesHook;
