import React, { useMemo } from 'react';
//components
import { Avatar, Box, Grid, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AnaliticsCard from '@components/AnaliticsCard/AnaliticsCard';
import ChartCard from '@components/ChartCard/ChartCard';
import DataCard from '@components/DataCard/DataCard';
import { StyledBadge } from '@components/StyledBadge/StyledBadge';
import AreaChartSM from '@components/Charts/AreaChart/AreaChart';
import PieChartSM from '@components/Charts/PieChart/PieChart';

//interfaces
import { type MRT_ColumnDef } from 'material-react-table';
import { LatestAppointmentType, LatestUserType } from '@interfaces/analytics/analytics';

// hooks
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';

// queries
import {
  useAnalyticsCounts,
  useAppointmentsCountByHour,
  useCourtsCountBySports,
  useLatestAppointments,
  useLatestUsers
} from '@api/queries/analytics/analytics';

// atoms
import { popupAtom } from '@atoms/popupAtom';

const AnaliticsPage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);

  const onAnalyticsError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const { data: analyticsCounts } = useAnalyticsCounts(onAnalyticsError);
  const { data: latestUsers } = useLatestUsers(onAnalyticsError);
  const { data: appointmentCountByHour } = useAppointmentsCountByHour(onAnalyticsError);
  const { data: latestAppintments } = useLatestAppointments(onAnalyticsError);
  const { data: courtsCountBySports } = useCourtsCountBySports(onAnalyticsError); //da se poveze sa komponentom dole

  const appointmentColumns = useMemo<MRT_ColumnDef<LatestAppointmentType>[]>(
    () => [
      {
        accessorKey: 'user',
        header: t('user'),
        Cell: ({ row }) => (
          <ListItemText
            primary={row.original.user.name}
            secondary={row.original.created_at_diff_for_humans}
          />
        )
      },
      {
        accessorKey: 'sport_center.name',
        header: t('sport_center')
      },
      {
        accessorKey: 'time',
        header: t('time')
      },
      {
        accessorKey: 'date',
        header: t('date')
      },
      {
        accessorKey: 'price',
        header: t('superadmin_analytics.fee_price'),
        Cell: ({ row }) => `${row.original.price} RSD`
      }
    ],
    []
    //end
  );

  const userColumns = useMemo<MRT_ColumnDef<LatestUserType>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('users'),
        Cell: ({ row }) => (
          <ListItem>
            <ListItemAvatar>
              <StyledBadge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                variant="standard"
              >
                <Avatar src={row.original.profile_photo?.src} />
              </StyledBadge>
            </ListItemAvatar>
            <ListItemText
              primary={row.original.name}
              secondary={row.original.created_at_diff_for_humans}
            />
          </ListItem>
        )
      }
    ],
    []
    //end
  );

  return (
    <Box px={3} py={2}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4}>
          <AnaliticsCard
            header={t('superadmin_analytics.total_users')}
            content={`${analyticsCounts?.data.data.users.total_count}`}
            percent={`${analyticsCounts?.data.data.users.last_month_diff_in_percentage}`}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnaliticsCard
            header={t('superadmin_analytics.total_objects')}
            content={`${analyticsCounts?.data.data.sport_centers.total_count}`}
            percent={`${analyticsCounts?.data.data.sport_centers.last_month_diff_in_percentage}`}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AnaliticsCard
            header={t('superadmin_analytics.total_reservations')}
            content={`${analyticsCounts?.data.data.appointments.total_count}`}
            percent={`${analyticsCounts?.data.data.appointments.last_month_diff_in_percentage}`}
            footerText={t('superadmin_analytics.compared_to_last_month')}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={12} md={4}>
          <DataCard
            title={t('superadmin_analytics.new_users')}
            columns={userColumns}
            data={latestUsers?.data.data || []}
            removePadding={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <ChartCard
            title={t('superadmin_analytics.objects_visitation')}
            count={`${appointmentCountByHour?.data.data.appointments_total_count}`}
            subtitle={t('today')}
            next="link to page"
          >
            <Box sx={{ height: '70%', px: 5 }} display="flex" justifyContent={'center'}>
              <AreaChartSM
                data={appointmentCountByHour?.data.data.appointments_count_by_hour || []}
                tooltipValueText={t('users').toString()}
              />
            </Box>
          </ChartCard>
        </Grid>
      </Grid>

      <Grid container spacing={3} mt={1}>
        <Grid item xs={12} sm={12} md={7}>
          <DataCard
            title={t('superadmin_analytics.last_scheduled_appointments')}
            data={latestAppintments?.data.data || []}
            columns={appointmentColumns}
            removePadding={true}
            showHeader={true}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <ChartCard
            title={t('superadmin_analytics.courts_representation')}
            count={`${courtsCountBySports?.data.data.courts_total_count}`}
            subtitle={t('superadmin_analytics.total_courts')}
          >
            <Box sx={{ height: '70%' }} display="flex" justifyContent={'center'}>
              <PieChartSM data={courtsCountBySports?.data.data.courts_count_by_sport || []} />
            </Box>
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnaliticsPage;
