// hooks
import { useEffect, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// queries
import { useCourtSport } from '@api/queries/priceRules/priceRules';
// mutations
import {
  useCreatePriceRule,
  useSinglePriceRule,
  useUpdatePriceRule,
  useAllPriceRules,
  useDeletePriceRule
} from '@api/mutations/priceRules/priceRules';
// interfaces
import {
  CreatePriceRuleRequest,
  CourtSportType,
  CourtSportErrorResponse,
  CourtSportError,
  CreatePriceRuleResponse,
  AllPriceRulesResponse,
  PriceRuleTableFields,
  PriceRuleErrorKeys,
  PriceRuleGraphRowData,
  CourtSportData
} from '@interfaces/priceRules/priceRules';
import {
  SportCenterWithCourtSportResponse,
  WorkingHoursType
} from '@interfaces/SportCenters/SportCenter';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';

// atoms
import { stepOneDataAtom } from '@atoms/stepOneData';
import { popupAtom } from '@atoms/popupAtom';
// mocks
import { timePriceArr, priceRule } from '@src/__mock__/createSportsCenter/steps';

//hellpers
import {
  getArrayOfdaysFromPriceRule,
  prepackPriceRuleIntoFields,
  prepackPriceRuleIntoGraph
} from '@src/helpers/utility';

export const useCreateSportCenterStep4 = () => {
  const params = useParams();
  const navigate = useNavigate();
  const id: number | undefined = params.sportCenterId ? parseInt(params.sportCenterId) : undefined;
  const resetStepData = useResetRecoilState(stepOneDataAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const [courtSport, setCourtSport] = useState<CourtSportType[]>([]);
  const [timePriceValue, setTimePriceValue] = useState<number[]>([]);
  const [workingDays, setWorkingDays] = useState<string[]>([]);
  const [successAlert, setSuccessAlert] = useState('');
  const [priceRulesFields, setPriceRulesFields] = useState<PriceRuleTableFields[]>([]);
  const [openPriceRuleForm, setOpenPriceRuleForm] = useState<boolean>(true);
  const [workingHours, setWorkingHours] = useState<WorkingHoursType>({
    saturday_from: '',
    saturday_to: '',
    sunday_from: '',
    sunday_to: '',
    workweek_from: '',
    workweek_to: ''
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [courtSportArr, setCourtSportArr] = useState<CourtSportData[]>([]);
  let currentItem: CourtSportData = courtSportArr[currentPage];
  const [initialGraphState, setInitialGraphState] = useState<PriceRuleGraphRowData[]>([]);
  const [saveAndCreatePriceRule, setSaveAndCreatePriceRule] = useState<boolean>(false);
  const { t } = useTranslation();
  const reqMessage = t('errorMessage');
  const lng = localStorage.getItem('lng');

  const {
    handleSubmit: handleSubmitRule,
    control: controlRule,
    reset: resetRuleForm,
    setValue,
    setError,
    getValues,
    formState: { isSubmitted }
  } = useForm<CreatePriceRuleRequest>({
    defaultValues: priceRule,
    mode: 'onBlur'
  });

  const handleCancelPriceRule = () => {
    resetRuleForm();
    setWorkingDays([]);
    setTimePriceValue([]);
    setSaveAndCreatePriceRule(false);
    setOpenPriceRuleForm(false);
  };

  const handleFinish = () => {
    resetStepData();
    navigate('/objects');
  };

  const handleBack = (): void => {
    navigate(-1);
  };
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  //get created courts
  const onGetCourtSportSuccess = (data: SportCenterWithCourtSportResponse) => {
    setInitialGraphState([]);
    setCourtSport(data.data.courts);
    setWorkingHours(data.data.working_hours);
  };

  function onGetCourtSportError(err: CourtSportError) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }
  const onCreatePriceRuleSuccess = (data: CreatePriceRuleResponse) => {
    setSuccessAlert(
      `${t('stepFour.price_rule_rule')} “${data.data.price_rule.name}” ${t(
        'stepFour.price_rule_create_success_message'
      )} `
    );
    resetRuleForm();
    setTimePriceValue([]);
    setWorkingDays([]);
    setPriceRulesFields([]);
    !saveAndCreatePriceRule && setOpenPriceRuleForm(false);
    setOpenPriceRuleForm(true);
    if (params.sportCenterId) {
      getPriceRules({
        sportCenterID: id
      });
    }

    setTimeout(() => setSuccessAlert(''), 2000);
  };
  const onCreatePriceRuleError = (err: CourtSportError) => {
    if (err.errors) {
      for (const key in err.errors) {
        if (key === 'price_rule') {
          setPopup({
            open: true,
            title: err.message,
            content: '',
            variant: 'error'
          });
        } else {
          setError(
            key as PriceRuleErrorKeys,
            {
              type: 'custom',
              message: err.errors[key][0]
            },
            {
              shouldFocus: true
            }
          );
        }
      }
    } else {
      setError('price_rule.time_start', {
        type: 'custom',
        message: err.message
      });
    }
  };
  const onGetPriceRulesSucces = (data: AllPriceRulesResponse) => {
    setPriceRulesFields([]);
    prepackPriceRuleIntoFields(data, setPriceRulesFields, t);
    if (data.data.length > 0) {
      let tempArr: CourtSportData[] = [];
      data.data.map(priceRule => {
        priceRule.court_sports.map(coSpo => {
          return tempArr.push({
            id: coSpo.id,
            name: `${coSpo.court.name} - ${coSpo.sport.name}`
          });
        });
      });
      const filteredTempArr = tempArr.filter((coSpo, i, arr) => {
        return i === arr.findIndex(coSp => coSp.id === coSpo.id);
      });
      setCourtSportArr(filteredTempArr);
    }
  };
  const onGetPriceRulesError = (err: CourtSportErrorResponse) => {};
  const onGetPriceRulesByCourtSportIDSucces = (data: AllPriceRulesResponse) => {
    prepackPriceRuleIntoGraph(data, setInitialGraphState, workingHours, t);
  };
  const onGetPriceRulesByCourtSportIDError = (err: CourtSportErrorResponse) => {};
  const onGetSinglePriceRuleSuccess = (data: CreatePriceRuleResponse) => {
    setOpenPriceRuleForm(true);

    let tempArr: number[] = [];
    if (data.data.court_sports.length > 0) {
      data.data.court_sports.map(coSpor => {
        tempArr.push(coSpor.id);
      });
    }
    if (data.data.time_prices.length > 0) {
      data.data.time_prices.map(timePrice => {
        setTimePriceValue(prev => [...prev, +timePrice.minutes]);
        switch (timePrice.minutes) {
          case 180:
            setValue('time_prices.0', timePrice);
            break;
          case 150:
            setValue('time_prices.1', timePrice);
            break;
          case 120:
            setValue('time_prices.2', timePrice);
            break;
          case 90:
            setValue('time_prices.3', timePrice);
            break;
          case 60:
            setValue('time_prices.4', timePrice);
            break;
          case 30:
            setValue('time_prices.5', timePrice);
            break;
          default:
            break;
        }
      });
    }
    setWorkingDays(getArrayOfdaysFromPriceRule(data.data.price_rule, t).arrayOfDays);
    setValue('price_rule', data.data.price_rule);
    setValue(
      'price_rule.time_end',
      data.data.price_rule.time_end === '23:59' ? '24:00' : data.data.price_rule.time_end
    );
    setValue(
      'price_rule.time_start',
      data.data.price_rule.time_start === '23:59' ? '24:00' : data.data.price_rule.time_start
    );
    setValue('court_sport_ids', tempArr);
  };
  const onGetSinglePriceRuleError = (err: CourtSportError) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as PriceRuleErrorKeys,
          {
            type: 'custom',
            message: err.errors[key][0]
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setError('court_sport_ids', {
        type: 'custom',
        message: err.message
      });
    }
  };

  const onUpdatePriceRuleSuccess = (data: CreatePriceRuleResponse) => {
    setSuccessAlert(
      `${t('stepFour.price_rule_rule')} “${data.data.price_rule.name}” ${t(
        'stepFour.price_rule_edit_success_message'
      )} `
    );
    resetRuleForm();
    setTimePriceValue([]);
    setWorkingDays([]);
    setPriceRulesFields([]);
    !saveAndCreatePriceRule && setOpenPriceRuleForm(false);
    if (params.sportCenterId) {
      getPriceRules({
        sportCenterID: id
      });
    }
    setTimeout(() => setSuccessAlert(''), 2000);
  };
  const onUpdatePriceRuleError = (err: CourtSportError) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as PriceRuleErrorKeys,
          {
            type: 'custom',
            message: err.errors[key][0]
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setError('court_sport_ids', {
        type: 'custom',
        message: err.message
      });
    }
  };

  const onDeletePriceRuleSuccess = (data: SuccessResponse) => {
    setSuccessAlert(`${t('stepFour.price_rule_delete_success_message')} `);
    getPriceRules({
      sportCenterID: id
    });
    setTimeout(() => setSuccessAlert(''), 2000);
  };
  const onDeletePriceRuleError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { refetch: getCourtSport, isLoading: isLoadingGetCourtSport } = useCourtSport(
    id,
    onGetCourtSportSuccess,
    onGetCourtSportError
  );
  const { mutate: getPriceRules, isLoading: isLoadingGetPriceRules } = useAllPriceRules(
    onGetPriceRulesSucces,
    onGetPriceRulesError
  );
  const { mutate: getPriceRulesByCourtSportID, isLoading: isLoadingGetPriceRulesbyCourtSportID } =
    useAllPriceRules(onGetPriceRulesByCourtSportIDSucces, onGetPriceRulesByCourtSportIDError);
  const { mutate: createPriceRule, isLoading: isLoadingCreatePriceRule } = useCreatePriceRule(
    onCreatePriceRuleSuccess,
    onCreatePriceRuleError,
    Number(id)
  );
  const { mutate: updatePriceRule, isLoading: isLoadingUpdatePriceRule } = useUpdatePriceRule(
    onUpdatePriceRuleSuccess,
    onUpdatePriceRuleError,
    Number(id)
  );
  const { mutate: getSinglePriceRule, isLoading: isLoadingGetSinglePriceRule } = useSinglePriceRule(
    onGetSinglePriceRuleSuccess,
    onGetSinglePriceRuleError
  );
  const { mutate: deletePriceRule, isLoading: isLoadingDelete } = useDeletePriceRule(
    onDeletePriceRuleSuccess,
    onDeletePriceRuleError
  );
  const onEditRule = (data: PriceRuleTableFields) => {
    id && getSinglePriceRule({ sportCenterId: id, priceRuleID: data.id });
  };
  const onDeletePriceRule = (data: PriceRuleTableFields) => {
    deletePriceRule({ sportCenterId: Number(id), priceRuleID: data.id });
  };
  const onEditRuleSettings = (data: PriceRuleTableFields) => {
    navigate(`edit/${data.id}`);
  };
  //create price rule
  const onSubmitRule: SubmitHandler<CreatePriceRuleRequest> = async (data, e) => {
    const filterTimePrice = data.time_prices.filter(el => el.minutes !== undefined);
    delete data.price_rule.weekend;
    delete data.price_rule.workweek;
    data.time_prices = filterTimePrice;
    data.price_rule.time_end === '24:00'
      ? (data.price_rule.time_end = '23:59')
      : data.price_rule.time_end;
    data.price_rule.name =
      lng === 'en' ? data.price_rule.name_translates.en : data.price_rule.name_translates.sr;

    if (data.price_rule.id !== 0) {
      return updatePriceRule(data);
    }
    createPriceRule(data);
  };

  useEffect(() => {
    if (params.sportCenterId) {
      getCourtSport();
      getPriceRules({
        sportCenterID: id
      });
    }
  }, []);
  useEffect(() => {
    priceRulesFields.length > 0 ? setOpenPriceRuleForm(false) : setOpenPriceRuleForm(true);
  }, [priceRulesFields.length]);
  useEffect(() => {
    if (courtSportArr.length > 0) {
      getPriceRulesByCourtSportID({
        sportCenterID: id,
        courtSportID: currentItem.id
      });
    }
  }, [currentPage, courtSportArr]);
  return {
    controlRule,
    courtSport,
    handleSubmitRule: handleSubmitRule(onSubmitRule),
    handleBack,
    handleFinish,
    timePriceValue,
    setTimePriceValue,
    reqMessage,
    timePriceArr,
    onEditRule,
    onDeletePriceRule,
    workingDays,
    setWorkingDays,
    setValue,
    isSubmitted,
    successAlert,
    priceRulesFields,
    openPriceRuleForm,
    setOpenPriceRuleForm,
    isLoadingCreatePriceRule,
    isLoadingGetCourtSport,
    isLoadingGetPriceRules,
    isLoadingUpdatePriceRule,
    initialGraphState,
    courtSportArr,
    handlePageChange,
    currentPage,
    currentItem,
    isLoadingGetPriceRulesbyCourtSportID,
    workingHours,
    getValues,
    onEditRuleSettings,
    deletePriceRule,
    isLoadingGetSinglePriceRule,
    isLoadingDelete,
    handleCancelPriceRule,
    saveAndCreatePriceRule,
    setSaveAndCreatePriceRule
  };
};
