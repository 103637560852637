//interfaces
import { CourtSportData } from '@interfaces/priceRules/priceRules';
// components
import { Button, Grid } from '@mui/material';
// icons
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type PriceRulePaginationProps = {
  courtSportArr: CourtSportData[];
  handlePageChange: (pageNumber: number) => void;
  currentPage: number;
  currentItem: CourtSportData;
};
const PriceRulePagination = ({
  courtSportArr,
  handlePageChange,
  currentPage,
  currentItem
}: PriceRulePaginationProps) => {
  if (courtSportArr.length === 0) {
    return null;
  }

  return currentItem?.name ? (
    <Grid container gap={1}>
      <Grid>
        <Button
          aria-label="settings"
          variant="outlined"
          color="primary"
          sx={{ borderColor: '#C9C9C9', borderRadius: '10px' }}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 0}
        >
          <NavigateBeforeIcon />
        </Button>
      </Grid>
      <Grid>
        <Button
          aria-label="settings"
          variant="outlined"
          sx={{
            borderColor: '#C9C9C9',
            textTransform: 'capitalize',
            color: 'black',
            borderRadius: '10px'
          }}
        >
          {currentItem.name}
        </Button>
      </Grid>
      <Grid>
        <Button
          aria-label="settings"
          variant="outlined"
          sx={{ borderColor: '#C9C9C9', borderRadius: '10px' }}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === courtSportArr.length - 1}
        >
          <NavigateNextIcon />
        </Button>
      </Grid>
    </Grid>
  ) : null;
};

export default PriceRulePagination;
