// english
import enGlobalsTranslation from './globals/globals.json';
import enSidebarTranslation from './sidebar/sidebar.json';
import enLoginTranslation from './login/login.json';
import enTableTranslation from './table/table.json';
import enSportCenterTranslation from './sportCenter/sportCenter.json';
import enUsersTranslation from './users/users.json';
import enEmployeesTranslation from './employees/employees.json';
import enSettingsTranslation from './settings/settings.json';
import enCalendarTranslation from './calendar/calendar.json';
import enCoachesTranslation from './coaches/coaches.json';
import enNonWorkingDaysTranslation from './nonWorkingDays/nonWorkingDays.json';
import enAnalyticsTranslation from './analytics/analytics.json';
import enFinancesTranslation from './finances/finances.json';

export const translationEnglish = {
  ...enGlobalsTranslation,
  ...enSidebarTranslation,
  ...enLoginTranslation,
  ...enTableTranslation,
  ...enSportCenterTranslation,
  ...enUsersTranslation,
  ...enEmployeesTranslation,
  ...enSettingsTranslation,
  ...enCalendarTranslation,
  ...enCoachesTranslation,
  ...enNonWorkingDaysTranslation,
  ...enAnalyticsTranslation,
  ...enFinancesTranslation
};
