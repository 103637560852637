//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//error
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
// services
import {
  createPriceRule,
  getSinglePriceRule,
  updatePriceRule,
  getPriceRules,
  deletePriceRule
} from '@services/priceRules/priceRules';
// interfaces
import {
  CreatePriceRuleRequest,
  CreatePriceRuleResponse,
  CourtSportError,
  CourtSportErrorResponse,
  AllPriceRulesRequest,
  AllPriceRulesResponse,
  SinglePriceRuleRequestType
} from '@interfaces/priceRules/priceRules';

export const useCreatePriceRule = (
  onSuccess: (data: CreatePriceRuleResponse) => void,
  onError: (err: CourtSportError) => void,
  sportCenterId: Number
): UseMutationResult<
  { data: CreatePriceRuleResponse }, // response
  CourtSportErrorResponse,
  CreatePriceRuleRequest, // request
  (data: CreatePriceRuleResponse) => void // on success
> =>
  useMutation({
    mutationFn: data => createPriceRule({ ...data, sportCenterId }),
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useSinglePriceRule = (
  onSuccess: (data: CreatePriceRuleResponse) => void,
  onError: (err: CourtSportError) => void
): UseMutationResult<
  { data: CreatePriceRuleResponse }, // response
  CourtSportErrorResponse,
  SinglePriceRuleRequestType, // request
  (data: CreatePriceRuleResponse) => void // on success
> =>
  useMutation({
    mutationFn: getSinglePriceRule,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
export const useUpdatePriceRule = (
  onSuccess: (data: CreatePriceRuleResponse) => void,
  onError: (err: CourtSportError) => void,
  sportCenterId: Number
): UseMutationResult<
  { data: CreatePriceRuleResponse }, // response
  CourtSportErrorResponse,
  CreatePriceRuleRequest,
  (data: CreatePriceRuleResponse) => void // on success
> =>
  useMutation({
    mutationFn: data => updatePriceRule({ ...data, sportCenterId }),
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useAllPriceRules = (
  onSuccess: (data: AllPriceRulesResponse) => void,
  onError: (err: CourtSportErrorResponse) => void
): UseMutationResult<
  { data: AllPriceRulesResponse }, // response
  CourtSportErrorResponse,
  AllPriceRulesRequest,
  (data: CreatePriceRuleResponse) => void // on success
> =>
  useMutation({
    mutationFn: getPriceRules,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useDeletePriceRule = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  SinglePriceRuleRequestType, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deletePriceRule,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
