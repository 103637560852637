import { useEffect, useState } from 'react';
// hooks
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//atom
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { stepOneDataAtom } from '@atoms/stepOneData';
import { popupAtom } from '@atoms/popupAtom';
import { sportFormAtom } from '@atoms/sportAtom';
//interface
import {
  CourtError,
  CourtSportType,
  CourtType,
  CreateCourtRequest
} from '@src/domain/interfaces/court/court';
import { AttachSportRequest } from '@src/domain/interfaces/sport/sport';
import {
  ImageType,
  UploadImageError,
  UploadImageResponse
} from '@src/domain/interfaces/Images/images';
import { ErrorResponse, SuccessResponse } from '@src/domain/interfaces/apiResponse';
import { Sport, SportCustomFields } from '@src/domain/interfaces/sport/sport';
//services
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { useAllSports } from '@src/infrastructure/api/queries/sport/sport';
import {
  useAttachSport,
  useCourtById,
  useCreateCourt,
  useDeleteCourt,
  useDetachSport,
  useUpdateCourt
} from '@src/infrastructure/api/mutations/court/court';
import {
  useCourtsByObjectId,
  useRoofStatuses,
  useSurfaceType
} from '@src/infrastructure/api/queries/court/court';
import { useSportCustomFields } from '@src/infrastructure/api/queries/sportCustomFields/sportCustomFields';
import {
  useDeleteCourtImage,
  useUploadCourtImage
} from '@src/infrastructure/api/mutations/images/images';
//helpers
import { getCourtSport, onUploadProgress } from '@src/helpers/utility';

export function useCreateCportCenterStep3() {
  const params = useParams();
  const sportCenterId: number | undefined = params.sportCenterId
    ? parseInt(params.sportCenterId)
    : undefined;
  const courtId: number | undefined = params.courtId ? parseInt(params.courtId) : undefined;
  const resetStepData = useResetRecoilState(stepOneDataAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const { t } = useTranslation();
  const [successField, setSuccessField] = useState('');
  const [editCourt, setEditCourt] = useState(false);
  const [editSport, setEditSport] = useState(false);
  const [openCourt, setOpenCourt] = useState(false);
  const [openSport, setOpenSport] = useState(false);
  const [images, setImages] = useState<ImageType[]>([]);
  const [courtImages, setCourtImages] = useState<File[]>([]);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [sportFormA, setSportFormA] = useRecoilState(sportFormAtom);

  const navigate = useNavigate();
  const { mutate: createCourt } = useCreateCourt(onCreateCourt, onCourtError);
  const { mutate: updateCourt } = useUpdateCourt(onUpdateCourtSuccess, onCourtError);
  const { mutate: deleteCourt } = useDeleteCourt(onDeleteCourtSuccess, onDeleteCourtError);
  const { mutate: createAttach } = useAttachSport(onCreateAttchCourt, onAttachError);
  const { mutate: detachSport } = useDetachSport(onCreateAttchCourt, onAttachError);
  const { mutate: uploadImage } = useUploadCourtImage(onUploadImageSuccess, onUploadImageError);
  const { mutate: deleteImage } = useDeleteCourtImage(onDeleteImageSuccess, onDeleteImageError);
  const { mutate: getSingleCourt, data: singleCourt } = useCourtById(
    onGetSingleCourtSuccess,
    onGetSingleCourtError
  );
  const { data: sports } = useAllSports(onSuccess, onError);
  const { data: courts, refetch: getCourts } = useCourtsByObjectId(onGetCourtsError, sportCenterId);
  const { data: roofStatus } = useRoofStatuses(onError);
  const { data: surfaceType } = useSurfaceType(onError);

  useEffect(() => {
    if (sportCenterId && courtId) {
      getSingleCourt({ sportCenterId: sportCenterId, courtId: courtId });
      setEditCourt(true);
    }
  }, [params]);

  const {
    handleSubmit: handleSubmitField,
    control: controlField,
    reset: resetFieldForm,
    formState: { isSubmitSuccessful: isSubmitSuccessfulField, isValidating },
    getValues,
    setValue: setValueCourt
  } = useForm<CreateCourtRequest>({
    defaultValues: {
      name: '',
      roof_status: '',
      surface_type: ''
    },
    mode: 'onBlur'
  });

  useEffect(() => {
    if (editCourt && isValidating && courtId) {
      const formValues = getValues();
      updateCourt({ ...formValues, id: courtId });
    }
  }, [isValidating]);

  const {
    handleSubmit: handleSubmitSport,
    control: controlSport,
    reset: resetSportForm,
    setValue: setValueSport,
    setError: setErrorSport
  } = useForm<AttachSportRequest>({
    defaultValues: {
      courtId: '',
      sportId: '',
      custom_fields_data: {}
    }
  });

  const uploadPercentOptions: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      onUploadProgress(progressEvent, setUploadPercent)
  };

  function onUploadImageSuccess(data: UploadImageResponse) {
    if (!editCourt) setImages([]);
    else
      sportCenterId &&
        courtId &&
        getSingleCourt({ sportCenterId: sportCenterId, courtId: courtId });
    setImages(images => [...images, data.data]);
  }

  function onUploadImageError(err: UploadImageError) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  function onDeleteImageSuccess(data: SuccessResponse) {
    if (singleCourt && sportCenterId)
      getSingleCourt({ sportCenterId: sportCenterId, courtId: singleCourt.data.data.id });
  }

  function onDeleteImageError(err: ErrorResponse) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const handleDelete = (imageID: number) => {
    if (editCourt && singleCourt && sportCenterId) {
      deleteImage({
        courtId: singleCourt.data.data.id,
        imageID: imageID,
        sportCenterId: sportCenterId
      });
      setImages(images =>
        images.filter((image, i) => images.findIndex(el => el.id === imageID) !== i)
      );
    } else {
      setImages(images =>
        images.filter((image, i) => images.findIndex(el => el.id === imageID) !== i)
      );
    }
  };

  const onDropCallback = (image: File) => {
    if (editCourt) {
      const form_data = new FormData();
      form_data.append('image', image);
      if (singleCourt && sportCenterId)
        uploadImage({
          data: form_data,
          courtId: singleCourt.data.data.id,
          uploadPercentOptions,
          sportCenterId: sportCenterId
        });
    } else {
      setCourtImages(courtImages => [...courtImages, image]);
      setImages(images => [
        ...images,
        {
          id: Math.floor(Math.random() * 100),
          src: URL.createObjectURL(image)
        }
      ]);
    }
  };

  //get sports
  function onSuccess(data: Sport[]) {}

  function onError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  //create court
  const onSubmitCourt: SubmitHandler<CreateCourtRequest> = async (data, e) => {
    if (sportCenterId) {
      if (editCourt && courtId) {
        updateCourt({ ...data, sport_center_id: sportCenterId, id: courtId });
      } else if (editCourt && data.id) {
        updateCourt({ ...data, sport_center_id: sportCenterId, id: data.id });
      } else {
        createCourt({ ...data, sport_center_id: sportCenterId });
      }
    }
  };

  function onCreateCourt(court: CourtType) {
    if (sportCenterId) {
      getCourts();
      courtImages.forEach(image => {
        let imageFormData = new FormData();
        imageFormData.append('image', image);
        uploadImage({
          courtId: court.id,
          data: imageFormData,
          uploadPercentOptions: uploadPercentOptions,
          sportCenterId: sportCenterId
        });
      });
    }
    setSuccessField(`${t('sportCenters.stepThree.newField.successMessage')} ${court.name}`);
    resetFieldForm({ name: '', roof_status: '', surface_type: '' });
    setImages([]);
    setCourtImages([]);
    setOpenCourt(false);
    setTimeout(() => setSuccessField(''), 2000);
  }

  function onUpdateCourtSuccess(court: CourtType) {
    setEditCourt(false);
    setOpenCourt(false);
    getCourts();

    if (!params.courtId) {
      resetFieldForm({ name: '', roof_status: '', surface_type: '' });
      setImages([]);
      setCourtImages([]);
      setTimeout(() => setSuccessField(''), 2000);
    }
  }

  function onCourtError(err: CourtError) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  //edit court
  const onEditCourt = (courtId: number) => {
    getSingleCourt({ sportCenterId: Number(sportCenterId), courtId: courtId });
  };

  //delete court
  function onDeleteCourtSuccess(message: SuccessResponse) {
    resetPopup();
    getCourts();
  }

  function onDeleteCourtError(err: ErrorResponse) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  //create sport
  const onSubmitSport: SubmitHandler<AttachSportRequest> = async (data, e) => {
    if (params.sportCenterId) {
      createAttach({
        sportId: data.sportId,
        courtId: data.courtId,
        sportCenterId: params.sportCenterId,
        custom_fields_data: data.custom_fields_data,
        max_simultaneous_appointments: data.max_simultaneous_appointments
      });
    }
  };

  function onGetCourtsError(err: CourtError) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  function onCreateAttchCourt(message: SuccessResponse) {
    setEditSport(false);
    setOpenSport(false);
    setSportFormA(null);
    resetSportForm({ sportId: '', courtId: '', custom_fields_data: {} });
    resetPopup();

    if (params.sportCenterId) {
      getCourts();
    }
  }

  function onAttachError(err: CourtError) {
    if (err.errors) {
      for (const key in err.errors) {
        setErrorSport(
          key as 'custom_fields_data',
          {
            type: 'custom',
            message: err.errors[key as 'custom_fields_data'][0]
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  }

  //edit sport
  const onEditSport = (data: CourtSportType) => {
    setOpenSport(true);
    setEditSport(true);
    resetSportForm(data);
    setValueSport(`custom_fields_data`, data.custom_fields_data);
    setValueSport('max_simultaneous_appointments', data.max_simultaneous_appointments);
  };

  function onGetSingleCourtSuccess(data: CourtType) {
    resetFieldForm({
      id: data.id,
      name: data.name,
      surface_type: data.surface_type.value,
      roof_status: data.roof_status.value,
      allow_available_slot_search: data.advanced.allow_available_slot_search,
      allow_in_app_reservations: data.advanced.allow_in_app_reservations,
      is_court_active: data.advanced.is_court_active
    });
    if (data.images && data.images?.length > 0) setImages(data.images);
    setValueCourt('allow_available_slot_search', data.advanced.allow_available_slot_search);
    setValueCourt('allow_in_app_reservations', data.advanced.allow_in_app_reservations);
    setValueCourt('is_court_active', data.advanced.is_court_active);
    setEditCourt(true);
    setOpenCourt(true);
  }
  function onGetSingleCourtError(err: ErrorResponse) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const handleBack = () => {
    resetStepData();
    navigate(-1);
  };
  const handleNext = () => {
    resetStepData();
    navigate(`/objects/create-object/${params.sportCenterId}/priceList`);
  };

  const selectedSport = useWatch({ control: controlSport, name: 'sportId' });

  const onSuccessCustomFields = (data: SportCustomFields[]) => {};
  const onErrorCustomFields = (error: Error) => {
    setPopup({
      open: true,
      title: error.message,
      content: '',
      variant: 'error'
    });
  };

  const { data: customFields } = useSportCustomFields(
    onSuccessCustomFields,
    onErrorCustomFields,
    selectedSport
  );

  const handleCancelEditSport = () => {
    setOpenSport(false);
    setEditSport(false);
    setSportFormA(null);
    resetSportForm({ sportId: '', courtId: '', custom_fields_data: {} });
  };

  const handleCancelEditCourt = () => {
    setOpenCourt(false);
    setEditCourt(false);
    resetFieldForm({ name: '', roof_status: '', surface_type: '' });
    setCourtImages([]);
    setImages([]);
  };

  useEffect(() => {
    if (sportFormA?.sportID) {
      setOpenSport(true);
      setValueSport('sportId', sportFormA.sportID);
    }
  }, []);

  return {
    createCourt,
    updateCourt,
    deleteCourt,
    courts: courts ? courts : [],
    getCourts,
    controlField,
    handleSubmitField: handleSubmitField(onSubmitCourt),
    successField,
    onEditCourt,
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    sports: sports?.data ? sports.data : [],
    controlSport,
    handleSubmitSport: handleSubmitSport(onSubmitSport),
    isSubmitSuccessfulField,
    onEditSport,
    deleteImage,
    detachSport,
    handleNext,
    handleBack,
    setValueSport,
    customFields,
    openCourt,
    setOpenCourt,
    openSport,
    setOpenSport,
    editSport,
    editCourt,
    handleCancelEditSport,
    handleCancelEditCourt,
    sportCenterId,
    surfaceType: surfaceType ? surfaceType : [],
    roofStatus: roofStatus ? roofStatus : [],
    courtSport: getCourtSport(courts ? courts : [])
  };
}
