// store
import { atom } from 'recoil';
// interfaces
import { EmployeeCreateRequest } from '@interfaces/employees/employees';

const createEmployeeAtom = atom<EmployeeCreateRequest | null>({
  key: 'createEmployeeAtom',
  default: null
});

export { createEmployeeAtom };
