//api
import { useAllSports } from '@api/queries/sport/sport';
import { useDeleteSport } from '@api/mutations/sport/sport';
//components
import DataCard from '@components/DataCard/DataCard';
import { Box, Button, IconButton } from '@mui/material';
//icons
import { DeleteIcon, EditIcon } from '@components/icons/icons';
import AddIcon from '@mui/icons-material/Add';
//interfaces
import { Sport } from '@interfaces/sport/sport';
import { MRT_ColumnDef } from 'material-react-table';
//hooks
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//recoil
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
//helpers
import { checkPermission, getTranslateName } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const SportSettingsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentCMS = useRecoilValue(currentCMSAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const lng = localStorage.getItem('lng');

  const { data: sports, refetch } = useAllSports(
    data => {},
    err => {}
  );
  const { mutate: deleteSport } = useDeleteSport(
    data => {
      refetch();
    },
    err => {
      setPopup({
        open: true,
        variant: 'error',
        title: err.message,
        content: '',
        onClick: () => {
          resetPopup();
        }
      });
    }
  );

  const columns = useMemo<MRT_ColumnDef<Sport>[]>(
    () => [
      {
        header: t('sportPage.sportName'),
        accessorKey: 'name',
        Cell: ({ row }) => getTranslateName(lng, row.original.name_translates, row.original.name)
      },
      {
        header: '',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <Box display={'flex'} justifyContent={'flex-end'}>
            {checkPermission(currentCMS, PermissionsEnum.update_sports) && (
              <IconButton onClick={() => navigate('edit-sport/' + row.original.id)}>
                <EditIcon />
              </IconButton>
            )}
            {checkPermission(currentCMS, PermissionsEnum.delete_sports) && (
              <IconButton
                onClick={() =>
                  setPopup({
                    open: true,
                    variant: 'delete',
                    title: t('sportPage.deleteMessage').toString() + row.original.name + '?',
                    content: '',
                    onClick: () => {
                      deleteSport(row.original.id);
                      resetPopup();
                    }
                  })
                }
              >
                <DeleteIcon stroke="red" />
              </IconButton>
            )}
          </Box>
        )
      }
    ],
    []
  );

  return (
    <Box p={{ xs: 1, sm: 3 }}>
      {checkPermission(currentCMS, PermissionsEnum.create_sports) && (
        <Box mt={{ sm: -7 }} mb={2} display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" onClick={() => navigate('create-sport')}>
            <AddIcon /> {t('sportPage.addNewSportBtn')}
          </Button>
        </Box>
      )}
      <DataCard
        title={t('sports')}
        data={sports ? sports.data : []}
        columns={columns}
        showHeader={true}
      />
    </Box>
  );
};

export default SportSettingsPage;
