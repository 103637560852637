// components
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
//types
import { Control, Controller } from 'react-hook-form';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
//translate
import { useTranslation } from 'react-i18next';

type ObjectAdvancedSettingsPropsType = {
  control: Control<CreateSportCenterType>;
};

const ObjectAdvancedSettings = ({ control }: ObjectAdvancedSettingsPropsType) => {
  const { t } = useTranslation();
  return (
    <CardWrapper
      isForm={true}
      title={
        <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
          {t('settings.advancedSettings')}
        </Typography>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            name="max_days_upfront"
            control={control}
            defaultValue={1}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                error={invalid}
                {...field}
                type="number"
                label={t('settings.advanced.max_days_upfront')}
                sx={{ width: '100%' }}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="max_upfront_reservations"
            control={control}
            defaultValue={1}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                error={invalid}
                {...field}
                type="number"
                label={t('settings.advanced.max_upfront_reservations')}
                sx={{ width: '100%' }}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="cancellation_policy_in_hours"
            control={control}
            defaultValue={1}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                error={invalid}
                {...field}
                type="number"
                label={t('settings.advanced.cancellation_policy_in_hours')}
                sx={{ width: '100%' }}
                helperText={error?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="list_sport_center_in_app"
            control={control}
            defaultValue={false}
            render={({ field, fieldState: { error } }) => (
              <Box p={2} sx={{ width: '100% ', display: 'flex', alignItems: 'center' }}>
                <Switch
                  {...field}
                  onChange={e => {
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
                <Typography ml={2}>{t('settings.advanced.list_sport_center_in_app')}</Typography>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="allow_half_hour_appointment"
            control={control}
            defaultValue={false}
            render={({ field, fieldState: { error } }) => (
              <>
                <FormGroup>
                  <FormControlLabel
                    {...field}
                    control={
                      <Checkbox
                        onChange={e => {
                          field.onChange(e.target.checked);
                        }}
                        checked={field.value}
                      />
                    }
                    label={t('settings.advanced.allow_half_hour_appointment')}
                  />
                </FormGroup>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </>
            )}
          />
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

export default ObjectAdvancedSettings;
