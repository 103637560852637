import React, { useState } from 'react';
//components
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
//icons
import { ViewMoreIcon, ShowLessIcon } from '@components/icons/icons';

type CustomAccordionPropsType = {
  summary: string | React.ReactNode;
  children: React.ReactNode;
  defaultExpanded?: boolean;
};

const CustomAccordion = ({
  summary,
  children,
  defaultExpanded = false
}: CustomAccordionPropsType) => {
  const [expand, setExpand] = useState(defaultExpanded);
  return (
    <Accordion expanded={expand}>
      <AccordionSummary
        onClick={() => setExpand(!expand)}
        expandIcon={expand ? <ShowLessIcon /> : <ViewMoreIcon />}
      >
        <Typography variant="h6">{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
