//interface
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  AllCourtsResponse,
  CourtResponse,
  CreateCourtRequest,
  RoofStatusType,
  SingleCourtRequestType,
  SurfaceTypeType
} from '@src/domain/interfaces/court/court';
import { AttachSportRequest, DettachSportRequest } from '@src/domain/interfaces/sport/sport';
//axios instance
import axiosInstance from '@services/config';

export const singleCourt = (data: SingleCourtRequestType): Promise<{ data: CourtResponse }> =>
  axiosInstance.get(`/sport-centers/${data.sportCenterId}/courts/${data.courtId}`);

export const createCourt = (data: CreateCourtRequest): Promise<{ data: CourtResponse }> =>
  axiosInstance.post(`/sport-centers/${data.sport_center_id}/courts`, data);

export const updateCourt = (
  court: CreateCourtRequest & { id: number }
): Promise<{ data: CourtResponse }> =>
  axiosInstance.put(`/sport-centers/${court.sport_center_id}/courts/${court.id}`, court);

export const deleteCourt = (data: SingleCourtRequestType): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${data.sportCenterId}/courts/${data.courtId}`);

export const attachSport = (data: AttachSportRequest): Promise<{ data: SuccessResponse }> =>
  axiosInstance.post(
    `/sport-centers/${data.sportCenterId}/courts/${data.courtId}/attach-sport/${data.sportId}`,
    {
      custom_fields_data: data.custom_fields_data,
      max_simultaneous_appointments: data.max_simultaneous_appointments
    }
  );

export const detachSport = (data: DettachSportRequest): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(
    `/sport-centers/${data.sportCenterId}/courts/${data.courtId}/detach-sport/${data.sportId}`
  );

export const courtsByObject = (objectId: number): Promise<{ data: AllCourtsResponse }> =>
  axiosInstance.get(`/courts/by-sport-center/${objectId}?with[]=courtSports.sport`);

export const courtRoofStatuses = (): Promise<{
  data: RoofStatusType[];
}> => axiosInstance.get(`/courts/roof-statuses`);

export const courtSurfaces = (): Promise<{
  data: SurfaceTypeType[];
}> => axiosInstance.get(`/courts/surfaces`);
