// interfaces
import {
  PriceRuleGraphRowData,
  CourtSportData,
  GraphKeys
} from '@interfaces/priceRules/priceRules';

//components
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  CircularProgress
} from '@mui/material';
import PriceRulePagination from '@components/PriceRulePagination/PriceRulePagination';
// hooks
import { useTranslation } from 'react-i18next';

type PriceRuleGraphProps = {
  graphRows: PriceRuleGraphRowData[];
  courtSportArr: CourtSportData[];
  handlePageChange: (pageNumber: number) => void;
  currentPage: number;
  currentItem: CourtSportData;
  isLoading: boolean;
};
const PriceRuleGraph = ({
  graphRows,
  courtSportArr,
  handlePageChange,
  currentPage,
  currentItem,
  isLoading
}: PriceRuleGraphProps) => {
  const { t } = useTranslation();
  const renderTableCellContent = (value: string | GraphKeys | null) => {
    if (typeof value === 'string') return null;

    if (value?.ruleName && value?.text) {
      return (
        <Tooltip key={Math.random()} arrow title={value.ruleName} placement="right">
          <TableCell
            sx={{
              backgroundColor: value?.color,
              textAlign: 'left'
            }}
            rowSpan={value?.rowSpan}
          >
            {value?.text?.length > 0 &&
              value.text.map((textEl, index) => (
                <Typography key={`${textEl}-${index}`} variant="subtitle1" textAlign="center">
                  {textEl}
                </Typography>
              ))}
          </TableCell>
        </Tooltip>
      );
    } else if (value?.color) {
      return (
        <TableCell
          key={Math.random()}
          sx={{
            backgroundColor: value.color
          }}
        ></TableCell>
      );
    }
  };

  return (
    <Card
      className="priceRuleGraphWrapper"
      sx={{
        px: 1,
        width: '100%',
        overflowX: 'auto'
      }}
    >
      <CardHeader
        title={t('stepFour.price_rule_graph_title')}
        subheader={t('stepFour.price_rule_graph_subtitle')}
        sx={{ flexWrap: 'wrap', gap: 2 }}
        action={
          <PriceRulePagination
            courtSportArr={courtSportArr}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            currentItem={currentItem}
          />
        }
      />
      {!isLoading ? (
        <CardContent sx={{ position: 'relative', minWidth: '500px', overflowX: 'auto' }}>
          <Table>
            <TableHead sx={{ width: '100%' }}>
              <TableRow sx={{ background: '#f5f5f5', th: { textAlign: 'center' } }}>
                <TableCell id="hours" sx={{ width: { sm: '15%', md: '8px' } }} />
                <TableCell>{t('workweek')}</TableCell>
                <TableCell>{t('sat')}</TableCell>
                <TableCell>{t('sun')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {graphRows.length > 0 &&
                graphRows.map((graphRow, i) => (
                  <TableRow key={i + graphRow.hours} sx={{ height: '40px' }}>
                    {Object.values(graphRow).length > 0 &&
                      Object.values(graphRow).map((value, i) =>
                        typeof value === 'string' ? (
                          <TableCell key={i + value}>{value}</TableCell>
                        ) : (
                          renderTableCellContent(value)
                        )
                      )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </CardContent>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress />
        </Grid>
      )}
    </Card>
  );
};

export default PriceRuleGraph;
