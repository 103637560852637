//hooks
import { useCreateSportCenterStep4 } from '@hooks/createSportCenterStep4/useCreateSportCenterStep4';
import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
// components
import { Box, Button, CircularProgress, Grid, Alert } from '@mui/material';
import NewRule from '@components/Forms/SportCenters/NewRule/NewRule';
import PriceRulesData from '@components/Forms/SportCenters/PriceRulesData/PriceRulesData';
import PriceRuleGraph from '@components/Forms/SportCenters/PriceRulesGraph/PriceRuleGraph';
//atom
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermission } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

type StepFourProps = {
  buttons: boolean;
};

const StepFour = ({ buttons = true }: StepFourProps) => {
  const { t } = useTranslation();
  const {
    controlRule,
    handleSubmitRule,
    handleBack,
    handleFinish,
    timePriceValue,
    setTimePriceValue,
    courtSport,
    reqMessage,
    timePriceArr,
    onEditRule,
    onDeletePriceRule,
    workingDays,
    setWorkingDays,
    setValue,
    isSubmitted,
    successAlert,
    priceRulesFields,
    openPriceRuleForm,
    setOpenPriceRuleForm,
    isLoadingCreatePriceRule,
    isLoadingGetCourtSport,
    isLoadingGetPriceRules,
    isLoadingUpdatePriceRule,
    initialGraphState,
    courtSportArr,
    handlePageChange,
    currentPage,
    currentItem,
    isLoadingGetPriceRulesbyCourtSportID,
    workingHours,
    getValues,
    handleCancelPriceRule,
    saveAndCreatePriceRule,
    setSaveAndCreatePriceRule
  } = useCreateSportCenterStep4();

  const currentCMS = useRecoilValue(currentCMSAtom);

  const saveAndCreatePriceRuleFunction = () => {
    setSaveAndCreatePriceRule(true);
    handleSubmitRule();
  };
  const createPriceRuleFunction = () => {
    setSaveAndCreatePriceRule(false);
    handleSubmitRule();
  };

  if (
    isLoadingCreatePriceRule ||
    isLoadingGetCourtSport ||
    isLoadingGetPriceRules ||
    isLoadingUpdatePriceRule
  ) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Box sx={{ padding: '2% 5%' }} display="flex" flexDirection={'column'} gap={3}>
      {successAlert && (
        <Alert severity="success" sx={{ position: 'sticky', zIndex: 200, width: '70%' }}>
          {successAlert}
        </Alert>
      )}
      {/* Created price rules data cards */}
      {priceRulesFields.length > 0 && (
        <PriceRulesData
          priceRulesFields={priceRulesFields}
          onEditRule={onEditRule}
          onDeletePriceRule={onDeletePriceRule}
        />
      )}
      {/* Create price rule form */}
      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <Box sx={{ flex: '1 1 auto' }}>
          <NewRule
            timePriceArr={timePriceArr}
            control={controlRule}
            courtSport={courtSport}
            reqMessage={reqMessage}
            timePriceValue={timePriceValue}
            setTimePriceValue={setTimePriceValue}
            workingDays={workingDays}
            setWorkingDays={setWorkingDays}
            setValue={setValue}
            isSubmitted={isSubmitted}
            open={openPriceRuleForm}
            setOpen={setOpenPriceRuleForm}
            workingHours={workingHours}
            getValues={getValues}
            onCancel={handleCancelPriceRule}
            saveAndCreatePriceRule={saveAndCreatePriceRule}
            handleSaveAndCreatePriceRule={saveAndCreatePriceRuleFunction}
            handleCreatePriceRule={createPriceRuleFunction}
          />
        </Box>
      </Box>
      {/* Created price rules graph */}
      {priceRulesFields.length > 0 && initialGraphState.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <PriceRuleGraph
            courtSportArr={courtSportArr}
            graphRows={initialGraphState}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            currentItem={currentItem}
            isLoading={isLoadingGetPriceRulesbyCourtSportID}
          />
        </Box>
      )}
      {buttons && (
        <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
          {checkPermission(currentCMS, PermissionsEnum.update_sport_centers) && (
            <Button variant="text" color="primary" onClick={handleBack}>
              {t('back')}
            </Button>
          )}
          <Button
            type="submit"
            variant="contained"
            onClick={handleFinish}
            disabled={!priceRulesFields.length}
          >
            {t('finish')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default StepFour;
