// dayjs
import dayjs from 'dayjs';

// hooks
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useSetRecoilState, useRecoilValue } from 'recoil';

// interfaces
import { CoachInfoType } from '@interfaces/user/coaches/coaches';
import { RegularAppointmentType } from '@interfaces/appointments/appointments';
import { type MRT_ColumnDef } from 'material-react-table';

// enums
import { AppointmentStatusEnum } from '@enum/appointmentStatusEnum';

// components
import { Grid, Chip } from '@mui/material';
import Loading from '@components/Loading/Loading';
import StatisticCard from '@components/StatisticCard/StatisticCard';
import ScheduleRounded from '@mui/icons-material/ScheduleRounded';
// import { NavLink } from 'react-router-dom';

// containers
import ProfileInfoCard from '@containers/ProfileInfoCard/ProfileInfoCard';
import CardWrapper from '@containers/CardWrapper/CardWrapper';

// layouts
import { TableLayout } from '@layout/TableLayout/TableLayout';

// queries
import { useSingleSportCenter } from '@api/queries/sportCenter/sportCenter';
import { useAppointments } from '@api/queries/appointments/appointments';

// atoms
import { popupAtom } from '@atoms/popupAtom';
import { userAtom } from '@atoms/userAtom';

// icons
import { CalendarIcon } from '@components/icons/icons';
import { getColorOfAppointmentStatus } from '@helpers/utility';

const CoachesLeasedHoursSettingsPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId: number = Number(params?.sportCenterId);
  const setPopup = useSetRecoilState(popupAtom);
  const user = useRecoilValue(userAtom);
  const [coachInfo, setCoachInfo] = useState<CoachInfoType | undefined>(undefined);
  let daysLeft: number = 0;
  const { t } = useTranslation();
  const { pagination, setPagination } = useTablesLogic();

  const columnsAppointments = useMemo<MRT_ColumnDef<RegularAppointmentType | {}>[]>(
    () => [
      {
        accessorKey: 'id',
        header: t('id'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false
        // TODO:  return thiss after mvp
        // Cell: ({ row }) => {
        //   const rowType = row.original as RegularAppointment;
        //   return (
        //     <NavLink to={`/appointment/${rowType.id}`} style={{ color: 'black' }}>
        //       {rowType.id ? rowType.id : ''}
        //     </NavLink>
        //   );
        // }
      },
      {
        accessorKey: 'user',
        header: t('user'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          return <span>{user ? user.name : ''}</span>;
        }
      },
      {
        accessorKey: 'court_sport.court.name',
        header: t('field'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'datetime_start',
        header: t('date'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as RegularAppointmentType;

          return (
            <>
              <span>{dayjs(rowType.datetime_start).format('DD.MM.YYYY')}</span>
              <br />
              <span>
                {dayjs(rowType.datetime_start).format('HH:mm')} -{' '}
                {dayjs(rowType.datetime_end).format('HH:mm')}
              </span>
            </>
          );
        }
      },
      {
        accessorKey: 'status',
        header: t('status'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as RegularAppointmentType;
          return (
            <Chip
              label={t(rowType.status).toString()}
              variant="outlined"
              color={getColorOfAppointmentStatus(rowType.status)}
            />
          );
        }
      }
    ],
    [t]
  );

  function onAppointmentError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const { data: appointmentsByUser } = useAppointments(
    {
      sport_center_id: sportCenterId,
      page: pagination.pageIndex + 1,
      per_page: pagination.pageSize
    },
    onAppointmentError
  );

  const { data: sportCenterData } = useSingleSportCenter(sportCenterId.toString());

  useEffect(() => {
    if (user)
      user.coach_in_sport_centers.filter(spoCenter =>
        spoCenter.id === sportCenterId ? setCoachInfo(spoCenter.coach_info) : []
      );
  }, []);
  if (coachInfo) {
    daysLeft = dayjs(coachInfo.date_end, 'DD.MM.YYYY').diff(dayjs(), 'days');
  }

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 5
    });
  }, []);

  if (!sportCenterData && !appointmentsByUser) return <Loading />;

  const sCImage =
    sportCenterData &&
    sportCenterData.data &&
    sportCenterData.data.logo &&
    sportCenterData.data.logo.src;

  return (
    <>
      {sportCenterData && sportCenterData.data ? (
        <Grid container p={3} spacing={2}>
          <Grid item xs={12}>
            <ProfileInfoCard
              name={sportCenterData.data.name}
              image={sCImage ? sCImage : ''}
              otherInfo={[
                {
                  title: t('address'),
                  value: sportCenterData.data.address + ', ' + sportCenterData.data.city,
                  textColor: 'primary'
                },
                {
                  title: t('owner'),
                  value: sportCenterData.data.name
                },
                {
                  title: t('email'),
                  value: sportCenterData.data.email
                },
                {
                  title: t('phone_number'),
                  value: sportCenterData.data.phone
                }
              ]}
            />
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}

      {coachInfo ? (
        <Grid container p={3} spacing={2}>
          <Grid item xs={12} sm={4}>
            <StatisticCard
              icon={<ScheduleRounded />}
              title={`${coachInfo.assigned_hours}`}
              subtitle={t('coachesPage.leased_hours')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatisticCard
              icon={<ScheduleRounded />}
              title={`${coachInfo.remaining_hours}`}
              subtitle={t('coachesPage.remaining_hours')}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <StatisticCard
              icon={<CalendarIcon stroke="white" />}
              title={`${daysLeft} ${t('coachesPage.days')}`}
              subtitle={t('coachesPage.remaining_time')}
            />
          </Grid>
        </Grid>
      ) : (
        <Loading />
      )}

      {appointmentsByUser && appointmentsByUser.data ? (
        <Grid item xs={12} px={1} pb={2}>
          <CardWrapper
            title={t('calendar.appointment_history')}
            children={
              <TableLayout
                tableVariant="users"
                columns={columnsAppointments}
                rows={appointmentsByUser.data}
                pagination={pagination}
                setPagination={setPagination}
                rowCount={appointmentsByUser.meta.total}
              />
            }
          />
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default CoachesLeasedHoursSettingsPage;
