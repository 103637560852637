//type
import {
  Employee,
  EmployeePositionResponseType,
  EmployeePositionType,
  EmployeeResponseType,
  EmployeeSectorResponseType,
  EmployeeSectorType,
  PermissionType
} from '@interfaces/employees/employees';
//service
import {
  getPermissions,
  getPositions,
  getSectors,
  getSingleEmployee,
  getSportCenterPermissions
} from '@services/employees/employees';
//hooks
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useAllSectors = (
  onError: (err: Error) => void,
  onSuccess?: (data: EmployeeSectorType[]) => void
): UseQueryResult<EmployeeSectorResponseType, Error> =>
  useQuery({
    queryKey: ['all-sectors'],
    queryFn: getSectors,
    onSuccess: data => onSuccess && onSuccess(data.data.data),
    onError: err => onError(err)
  });

export const useAllPositions = (
  setctorId: string,
  onError: (err: Error) => void,
  onSuccess?: (data: EmployeePositionType[]) => void
): UseQueryResult<EmployeePositionResponseType, Error> =>
  useQuery({
    queryKey: ['all-positions', setctorId],
    queryFn: () => getPositions(setctorId),
    onSuccess: data => onSuccess && onSuccess(data.data.data),
    onError: err => onError(err),
    enabled: !!setctorId
  });

export const useSingleEmployee = (
  employeeId: string | undefined,
  onError: (err: Error) => void,
  onSuccess?: (data: Employee) => void
): UseQueryResult<EmployeeResponseType, Error> =>
  useQuery({
    queryKey: ['single-employee', employeeId],
    queryFn: () => {
      if (employeeId) return getSingleEmployee(employeeId);
      return null;
    },
    onSuccess: data => onSuccess && onSuccess(data.data.data),
    onError: err => onError(err),
    enabled: !!employeeId
  });

export const usePermissions = (
  isAdmin: boolean,
  onError: (err: Error) => void
): UseQueryResult<{ data: PermissionType[] }, Error> =>
  useQuery({
    queryKey: ['all-permisions'],
    queryFn: () => {
      if (isAdmin) return getPermissions();
      else return getSportCenterPermissions();
    },

    onError: err => onError(err)
  });
