// hooks
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// components
import { Box, Button, Drawer, Grid, Typography } from '@mui/material';

// images
import ErrorPageImage from '@src/assets/images/errorPage.png';

const Error404Page = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{ backgroundColor: 'rgba(217, 217, 217, 0.1)' }}
    >
      <Grid item sm={9} textAlign="center" py={3}>
        <Grid my={3}>
          <img src={ErrorPageImage} alt="error" />
        </Grid>
        <Grid my={2} mx={15} px={10}>
          <Typography variant="errorPageText">
            {t('error_page_title_1')}
            <br />
            {t('error_page_title_2')}
          </Typography>
        </Grid>
        <Grid my={5}>
          <Button onClick={() => navigate(-1)} variant="sportyMateError">
            {' '}
            {t('error_page_button_text')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Error404Page;
