//interface
import { AllSportsResponse, Sport, SportResponse } from '@interfaces/sport/sport';
//service
import { allSports, singleSport } from '@services/sport/sport';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useSingleSport = (
  id: string | undefined,
  onSuccess: (data: Sport | undefined) => void,
  onError: (err: Error) => void
): UseQueryResult<SportResponse | undefined, Error> =>
  useQuery(
    ['single-sport', id],
    () => {
      if (id) return singleSport(id);
      return null;
    },
    {
      select: res => res?.data,
      onSuccess: data => onSuccess(data?.data),
      onError: err => onError(err)
    }
  );

export const useAllSports = (
  onSuccess: (data: Sport[]) => void,
  onError: (err: Error) => void
): UseQueryResult<AllSportsResponse, Error> =>
  useQuery(['all-sports'], () => allSports(), {
    select: res => res.data,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });
