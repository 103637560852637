import { useMemo } from 'react';
//components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Checkbox,
  Typography
} from '@mui/material';
import { Box } from '@mui/system';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
//interfaces
import { PermissionType } from '@interfaces/employees/employees';
//hooks
import { useTranslation } from 'react-i18next';
//icons
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type DefineAccessPropsType = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  permissions: PermissionType[];
  default_permissions?: string[];
  error?: boolean;
};

const DefineAccess = ({
  onChange,
  permissions,
  default_permissions = [],
  error = false
}: DefineAccessPropsType) => {
  const { t } = useTranslation();

  const columns = useMemo<MRT_ColumnDef<PermissionType>[]>(
    () => [
      {
        accessorKey: 'modul',
        header: t('module'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'read',
        header: t('read'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <Checkbox
            value={row.original.read?.value}
            onChange={onChange}
            disabled={!row.original.read}
            checked={default_permissions.includes(row.original.read?.value)}
            sx={{
              color: error ? 'red' : ''
            }}
          />
        )
      },
      {
        accessorKey: 'create',
        header: t('create'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <Checkbox
            value={row.original.create?.value}
            onChange={onChange}
            disabled={
              !row.original.create || !default_permissions.includes(row.original.read?.value)
            }
            checked={default_permissions.includes(row.original.create?.value)}
            sx={{
              color: error ? 'red' : ''
            }}
          />
        )
      },

      {
        accessorKey: 'update',
        header: t('update'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <Checkbox
            value={row.original.update?.value}
            onChange={onChange}
            disabled={
              !row.original.update || !default_permissions.includes(row.original.read?.value)
            }
            checked={default_permissions.includes(row.original.update?.value)}
            sx={{
              color: error ? 'red' : ''
            }}
          />
        )
      },
      {
        accessorKey: 'delete',
        header: t('delete'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <Checkbox
            value={row.original.delete?.value}
            onChange={onChange}
            disabled={
              !row.original.delete || !default_permissions.includes(row.original.read?.value)
            }
            checked={default_permissions.includes(row.original.delete?.value)}
            sx={{
              color: error ? 'red' : ''
            }}
          />
        )
      }
    ],
    [default_permissions, error]
  );

  return (
    <Accordion expanded={true} sx={{ px: 6 }}>
      <AccordionSummary>
        <Box>
          <Typography variant="h6">{t('permissions')}</Typography>
          <Typography variant="subtitle2" color="GrayText">
            {t('employee.permissionsSubtitle')}
          </Typography>
        </Box>
      </AccordionSummary>
      <Alert
        severity="warning"
        sx={{ color: '#ED6C02', mb: 1 }}
        icon={<InfoOutlinedIcon fontSize="large" />}
      >
        <AlertTitle color="#ED6C02">
          <Typography variant="body1" component="span">
            <b>NOTE:</b> {t('rolePage.warningMessage')}
          </Typography>
        </AlertTitle>
      </Alert>
      <AccordionDetails sx={{ pb: 5 }}>
        <MaterialReactTable
          columns={columns}
          data={permissions}
          enableColumnActions={false}
          enableColumnFilters={false}
          enablePagination={false}
          enableSorting={false}
          enableBottomToolbar={false}
          enableTopToolbar={false}
          muiTableBodyRowProps={{ hover: false }}
          defaultColumn={{
            minSize: 10,
            maxSize: 200,
            size: 100
          }}
          muiTablePaperProps={{
            sx: {
              border: 'none',
              boxShadow: 'none'
            }
          }}
          muiTableBodyCellProps={{
            sx: {
              padding: '7px'
            }
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default DefineAccess;
