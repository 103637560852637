import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
//components
import { ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from '@mui/material';

type TabLinkProps = {
  text: string;
  icon?: ReactNode;
  link: string;
  coming_soon?: string;
  pathname?: string;
  secondPathname?: boolean;
};

export const TabLink = ({
  text,
  icon,
  link,
  coming_soon,
  pathname,
  secondPathname
}: TabLinkProps) => (
  <NavLink
    to={link}
    onClick={e => coming_soon && e.preventDefault()}
    end
    style={({ isActive }) =>
      isActive || (pathname?.includes(link) && (!secondPathname || pathname?.includes('edit-user')))
        ? { textDecoration: 'none', color: 'inherit', backgroundColor: '#F3F4F6' }
        : { textDecoration: 'none', color: 'inherit', opacity: 0.7 }
    }
  >
    <ListItem sx={{ backgroundColor: 'inherit' }} dense>
      <ListItemButton>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} secondary={coming_soon && coming_soon} />
      </ListItemButton>
    </ListItem>
  </NavLink>
);
