// axios instance
import axiosInstance from '@services/config';
//interface
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  AllPriceRulesRequest,
  AllPriceRulesResponse,
  CreatePriceRuleRequestType,
  CreatePriceRuleResponse,
  SinglePriceRuleRequestType
} from '@interfaces/priceRules/priceRules';
import { SportCenterWithCourtSportResponse } from '@interfaces/SportCenters/SportCenter';

export const getPriceRules = ({
  sportCenterID,
  courtID,
  courtSportID
}: AllPriceRulesRequest): Promise<{ data: AllPriceRulesResponse }> =>
  axiosInstance.get(`/sport-centers/${sportCenterID}/price-rules`, {
    params: {
      sport_center_id: sportCenterID,
      court_id: courtID,
      court_sport_id: courtSportID
    }
  });
export const getSinglePriceRule = (
  data: SinglePriceRuleRequestType
): Promise<{ data: CreatePriceRuleResponse }> =>
  axiosInstance.get(`/sport-centers/${data.sportCenterId}/price-rules/${data.priceRuleID}`);

export const createPriceRule = (
  data: CreatePriceRuleRequestType
): Promise<{ data: CreatePriceRuleResponse }> =>
  axiosInstance.post(`/sport-centers/${data.sportCenterId}/price-rules`, data);

export const updatePriceRule = (
  priceRule: CreatePriceRuleRequestType
): Promise<{ data: CreatePriceRuleResponse }> =>
  axiosInstance.put(
    `/sport-centers/${priceRule.sportCenterId}/price-rules/${priceRule.price_rule.id}`,
    priceRule
  );

export const deletePriceRule = (
  data: SinglePriceRuleRequestType
): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${data.sportCenterId}/price-rules/${data.priceRuleID}`);

export const getCourtSportFromSportCenter = (
  sportCenterID: number | undefined
): Promise<{ data: SportCenterWithCourtSportResponse }> =>
  axiosInstance.get(
    `/sport-centers/${sportCenterID}?with[]=courts.courtSports.sport&with[]=courts.courtSports.court`
  );

export const getAvailableTimePrices = (
  court_sport_id: number,
  datetime_start: string
): Promise<{ data: CreatePriceRuleResponse }> =>
  axiosInstance.get('/price-rules/get/available-time-prices', {
    params: {
      court_sport_id: court_sport_id,
      datetime_start: datetime_start
    }
  });
