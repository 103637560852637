import { Wrapper, Status } from '@googlemaps/react-wrapper';
import Loading from '@components/Loading/Loading';
import Map from '@components/MapsView/Map';
type MapsViewProps = {
  center: google.maps.LatLngLiteral;
  style?: { [key: string]: string };
  id?: string;
  handleOnMarkerChange?: (newPosition: google.maps.LatLngLiteral) => void;
  draggable?: boolean;
};

const MapsView: React.FC<MapsViewProps> = ({
  center,
  style,
  id,
  handleOnMarkerChange,
  draggable
}) => {
  const render = (status: Status): JSX.Element => {
    if (status === Status.LOADING) return <Loading />;
    if (status === Status.FAILURE) return <Loading />;

    return <h1> loading ...</h1>;
  };
  return (
    <Wrapper apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY} render={render}>
      <Map
        id={id}
        center={center}
        zoom={18}
        handleOnMarkerChange={handleOnMarkerChange}
        draggable={draggable}
      />
    </Wrapper>
  );
};

export default MapsView;
