import React from 'react';
import { Avatar, Box, Button, Card, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

type SettingsCardPropsType = {
  title: string;
  subtitle: string;
  onClick: () => void;
  icon: React.ReactNode;
};

const SettingsCard = ({ title, subtitle, onClick, icon }: SettingsCardPropsType) => (
  <Card
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      p: 2,
      alignItems: 'center',
      alignContent: 'center'
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar sx={{ bgcolor: '#CACACB' }}>{icon}</Avatar>
      <Box ml={1}>
        <Typography variant="h6" fontWeight={'bold'}>
          {title}
        </Typography>
        <Typography variant="body2">{subtitle}</Typography>
      </Box>
    </Box>

    <Button
      variant="outlined"
      size="small"
      sx={{ borderRadius: '10px', height: '42px', width: '42px', minWidth: '42px' }}
      onClick={onClick}
    >
      <NavigateNextIcon />
    </Button>
  </Card>
);
export default SettingsCard;
