//react
import { useMemo, FC } from 'react';
//react-router
import { NavLink, useNavigate } from 'react-router-dom';
//recoil
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';
import { stepOneDataAtom } from '@atoms/stepOneData';
import { currentCMSAtom } from '@atoms/currentCMS';
//hooks
import useTablesLogic from '@src/ui/hooks/tables/useTablesLogic';
import { useSportCenter } from '@api/queries/sportCenter/sportCenter';
import {
  useDeleteSportCenter,
  useDeleteSportCenters
} from '@api/mutations/sportCenter/sportCenter';
import { usePostImpersonate } from '@api/mutations/impersonation/impersonation';
import { useUser } from '@api/mutations/user/user';
import { useQueryBenefits } from '@api/queries/sportCenter/benefits';
import { useSetLoginUser } from '@hooks/useLoginUser/useSetLoginUser';
import { useTranslation } from 'react-i18next';
//containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
//components
import Loading from '@components/Loading/Loading';
//interfaces
import { SportCenterResponseType } from '@interfaces/SportCenters/SportCenter';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
import { BenefitsError, BenefitsResponse } from '@interfaces/SportCenters/Benefits';
import { User, UserErrorResponse } from '@interfaces/user/user';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import { Alert, Grid, Card } from '@mui/material';
//helpers
import { checkPermission, checkPermissionFunc } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';
import { RoleEnum } from '@enum/roleEnum';

const SportCenters: FC = (): JSX.Element | null => {
  const stepOneData = useRecoilValue(stepOneDataAtom);
  const resetStepOne = useResetRecoilState(stepOneDataAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setUserCMS } = useSetLoginUser();
  const currentCMS = useRecoilValue(currentCMSAtom);

  const onDeleteSuccesss = (data: SuccessResponse) => {
    refetch();
    setSuccessfullyDeletedPopup(true);
  };

  const onDeleteError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteObject } = useDeleteSportCenter(onDeleteSuccesss, onDeleteError);
  const { mutate: deleteObjects } = useDeleteSportCenters(onDeleteSuccesss, onDeleteError);

  const onBenefitsSuccess = (data: BenefitsResponse) => {};

  const onBenefitsError = (data: BenefitsError) => {
    setPopup({
      open: true,
      title: data.message,
      content: '',
      variant: 'error'
    });
  };

  const { data: benefitsData } = useQueryBenefits(onBenefitsSuccess, onBenefitsError);

  const handleAddObject = () => {
    if (stepOneData.id) {
      setPopup({
        open: true,
        title: t('sportCenters.continuePopupTitle'),
        content: t('sportCenters.continuePopupSubtitle') + ' ' + stepOneData.basic_info.name + '?',
        variant: 'info',
        onClose: onCloseContinue,
        onClick: onContinue
      });
    } else {
      navigate('/objects/create-object');
    }
  };

  const onCloseContinue = () => {
    resetStepOne();
    navigate('/objects/create-object');
  };
  const onContinue = () => {
    navigate(`/objects/create-object/${stepOneData.id}`);
    resetPopup();
  };

  const onUserSuccess = (data: User) => {
    setUserCMS(data);
  };
  const onUserError = (err: UserErrorResponse): void => {
    if (err.status === 442) navigate('/email-not-verified');
  };

  const { mutate: me } = useUser(onUserSuccess, onUserError);

  const onImpersonateSuccess = () => {
    navigate('/');
    me('');
  };
  const onImpersonateError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const { mutate: postImpersonate } = usePostImpersonate(onImpersonateSuccess, onImpersonateError);

  const handleImpersonate = (owner_id: string) => {
    postImpersonate(owner_id);
  };

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    handleDeleteRow,
    sendEmailEventHandler,
    deleteEventHandler,
    deletedNames,
    deleteIds,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    successfullyDeletedPopup,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    anchorElActionButton,
    handleClick,
    handleClose
  } = useTablesLogic(deleteObject, deleteObjects, 'sportCenters');

  const {
    data,
    refetch,
    isLoading: isLoadingSportsCenters
  } = useSportCenter(
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.payment_methods,
    filterValues.benefits,
    filterValues.fee_percentage_min && +filterValues.fee_percentage_min,
    filterValues.fee_percentage_max && +filterValues.fee_percentage_max,
    sortBy,
    sortDirection,
    onSportCentersError
  );

  function onSportCentersError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const columnsAllObjects = useMemo<MRT_ColumnDef<SportCenterResponseType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => (
          <NavLink to={`/objects/object/${row.original.id}`} end style={{ color: 'black' }}>
            {cell.getValue<string>()}{' '}
          </NavLink>
        )
      },
      {
        accessorKey: 'address',
        header: t('address'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'fee_percentage',
        header: t('fee_percentage'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell }) => cell.getValue<number>() + '%'
      }
    ],
    []
  );

  return (
    <Grid container>
      {successfullyDeletedPopup && deleteIds.length > 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('sport_centers')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('sportCenters.successMultiple')}
        </Alert>
      ) : successfullyDeletedPopup && deleteIds.length === 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('sport_center')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('sportCenters.successSingle')}
        </Alert>
      ) : null}
      <Card sx={{ mx: 3 }} className="fullWidth">
        <TableHeaderActions
          tableVariant="objects"
          disableActions={!deletedNames}
          handleAddItemBtn={checkPermissionFunc(
            currentCMS,
            PermissionsEnum.create_sport_centers,
            handleAddObject
          )}
          deleteEventHandler={checkPermissionFunc(
            currentCMS,
            PermissionsEnum.delete_sport_centers,
            deleteEventHandler
          )}
          // sendEmailEventHandler={sendEmailEventHandler} //TODO: return after MVP
          // sendMessageEventHandler={() => {}}
          // deactivateEventHandler={() => {}}
          handleSearch={handleSearch}
          // disableActionBtn={true}
          //sort
          handleSortTables={handleSortTables}
          checkSort={checkSort}
          handleClearSort={handleClearSort}
          //filter
          handleClearFilter={handleClearFilter}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorFilterEl={anchorEl}
          anchorElActionButton={anchorElActionButton}
          anchorMenuItem={anchorMenuItem}
          anchorSortEl={anchorSortEl}
          controlFilter={controlFilter}
          handleSubmitFilter={handleSubmitFilter}
          filterValue={filterValue}
          benefits={benefitsData?.data}
        />
        <Grid item lg={12} ml={'20px'} mr={'20px'}>
          {data && !isLoadingSportsCenters ? (
            <TableLayout
              tableVariant="objects"
              rows={data?.data?.data}
              columns={columnsAllObjects as MRT_ColumnDef<{}>[]}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={data?.data?.meta?.total}
              tableInstanceRef={tableInstanceRef}
              withActions
              withCheckbox
              handleDeleteRow={
                checkPermission(currentCMS, PermissionsEnum.delete_sport_centers)
                  ? handleDeleteRow
                  : undefined
              }
              handleViewMoreRow={
                currentCMS?.role === RoleEnum.SUPERADMIN ? handleImpersonate : undefined
              }
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default SportCenters;
