//interfaces
import { ManagerResponse } from '@src/domain/interfaces/user/manager/manager';
//axios instance
import axiosInstance from '@services/config';

export const managers = (
  page: number,
  per_page: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): Promise<{ data: ManagerResponse }> => {
  return axiosInstance.get('users/sport-center-managers', {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });
};
