// hooks
import { useTranslation } from 'react-i18next';
import useAddCoach from '@hooks/adminCoaches/useCoachesHook';
// components
import { Box, Button } from '@mui/material';

import CardWrapper from '@containers/CardWrapper/CardWrapper';
import AddCoachesBasicInfo from '@components/Forms/AddCoaches/AddCoachesBasicInfo';
import AddCoacgesFieldsAndPrice from '@components/Forms/AddCoaches/AddCoachesFieldsAndPrice';
import Loading from '@components/Loading/Loading';

const AddCoachesPage = () => {
  const {
    handleSubmit,
    control,
    courtSport,
    isLoadingGetCourtSport,
    isValid,
    handleCancel,
    isLoadingCreateCoach
  } = useAddCoach();
  const { t } = useTranslation();

  if (isLoadingGetCourtSport && isLoadingCreateCoach) {
    return <Loading />;
  }

  return isLoadingCreateCoach ? (
    <Loading />
  ) : (
    <Box component="form" pb={3} onSubmit={handleSubmit}>
      <CardWrapper
        isForm
        title={t('coachesPage.create.info_title')}
        subtitle={t('coachesPage.create.info_subtitle')}
        children={<AddCoachesBasicInfo control={control} t={t} />}
      />

      <CardWrapper
        isForm
        title={t('fieldsAndPrice')}
        subtitle={t('coachesPage.create.fields_price_subtitle')}
        children={<AddCoacgesFieldsAndPrice control={control} t={t} courtSport={courtSport} />}
      />

      <Box display="flex" flexDirection="row" p={3} justifyContent="flex-end" gap={2}>
        <Button variant="text" color="primary" onClick={handleCancel}>
          {t('quit')}
        </Button>
        <Button type="submit" variant="contained" disabled={!isValid}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default AddCoachesPage;
