//interfaces
import { SportCustomFields, SportCustomFieldsResponse } from '@src/domain/interfaces/sport/sport';
//axios instance
import axiosInstance from '@services/config';
import { SuccessResponse } from '@interfaces/apiResponse';

export const getCustomFields = (id: string): Promise<{ data: SportCustomFieldsResponse }> =>
  axiosInstance.get(`/sports/${id}/sport-custom-fields`);

export const createCustomFields = (
  data: SportCustomFields & { sportId: number }
): Promise<{ data: SportCustomFieldsResponse }> =>
  axiosInstance.post(`/sports/${data.sportId}/sport-custom-fields`, data);

export const updateCustomFields = (
  data: SportCustomFields & { sportId: number }
): Promise<{ data: SportCustomFieldsResponse }> =>
  axiosInstance.put(`/sports/${data.sportId}/sport-custom-fields/${data.id}`, data);

export const deleteCustomFields = (data: {
  sportId: number;
  customFieldId: number;
}): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sports/${data.sportId}/sport-custom-fields/${data.customFieldId}`);
