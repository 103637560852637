//react
import { useMemo, FC } from 'react';
//react-router
import { useNavigate } from 'react-router-dom';
//hooks
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useDeleteUser, useDeleteUsers } from '@api/mutations/user/user';
import { useManagers } from '@api/queries/user/manager/manager';
import { useTranslation } from 'react-i18next';
//containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
//components
import Popup from '@components/Popup/Popup';
import Loading from '@components/Loading/Loading';
//interfaces
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
import { Manager } from '@interfaces/user/manager/manager';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import { Alert, Card, Grid } from '@mui/material';

//TODO: delete this page
const ManagersPage: FC = (): JSX.Element | null => {
  const { t } = useTranslation();

  const onDeleteSuccesss = (data: SuccessResponse) => {
    refetch();
    setSuccessfullyDeletedPopup(true);
  };

  const onDeleteError = (err: ErrorResponse) => {
    console.log(err.message);
  };

  const { mutate: deleteUser } = useDeleteUser(onDeleteSuccesss, onDeleteError);

  const { mutate: deleteUsers } = useDeleteUsers(onDeleteSuccesss, onDeleteError);

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    sendEmailEventHandler,
    deleteEventHandler,
    deletedNames,
    deleteIds,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    successfullyDeletedPopup,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    handleClick,
    handleClose
  } = useTablesLogic(deleteUser, deleteUsers, 'superAdminUsers');

  const {
    data,
    refetch,
    isLoading: isLoadingManagers
  } = useManagers(
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.gender.toString(),
    sortBy,
    sortDirection
  );

  const columnsManagers = useMemo<MRT_ColumnDef<Manager | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name_and_surname'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'phone',
        header: t('phone'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorFn: data => {
          let output: string[] = [];
          let shorterOutput: string[] = [];
          const managerData = data as Manager;
          managerData.manager_in_sport_centers.map(sport_center => {
            output.push(sport_center.name);
          });
          if (output.length > 2) {
            shorterOutput = output.slice(0, 2);
            shorterOutput.push('...');
          } else {
            shorterOutput = output;
          }
          return shorterOutput.join('; ');
        },
        header: t('owned_sport_centers'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );

  return (
    <Grid container>
      {successfullyDeletedPopup && deleteIds.length > 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('users')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('superAdminUsers.successMultiple')}
        </Alert>
      ) : successfullyDeletedPopup && deleteIds.length === 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('user')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('superAdminUsers.successSingle')}
        </Alert>
      ) : null}
      <Card sx={{ mx: 3 }} className="fullWidth">
        <TableHeaderActions
          tableVariant="menagers"
          sendEmailEventHandler={sendEmailEventHandler}
          deleteEventHandler={deleteEventHandler}
          handleSearch={handleSearch}
          //sort
          handleSortTables={handleSortTables}
          checkSort={checkSort}
          handleClearSort={handleClearSort}
          //filter
          handleClearFilter={handleClearFilter}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorFilterEl={anchorEl}
          anchorMenuItem={anchorMenuItem}
          anchorSortEl={anchorSortEl}
          controlFilter={controlFilter}
          handleSubmitFilter={handleSubmitFilter}
          filterValue={filterValue}
        />
        <Grid item lg={12} ml={'20px'} mr={'20px'}>
          {data && !isLoadingManagers ? (
            <TableLayout
              tableVariant="menagers"
              rows={data?.data?.data}
              columns={columnsManagers}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={data?.data?.meta?.total}
              tableInstanceRef={tableInstanceRef}
              withCheckbox
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default ManagersPage;
