//interface
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  UploadImage,
  UploadImageResponse,
  DeleteImageRequest,
  UploadCourtImageRequest,
  DeleteCourtImageRequest
} from '@src/domain/interfaces/Images/images';
//axios instance
import axiosInstance from '@services/config';

export const uploadSCImage = ({
  id,
  data,
  uploadPercentOptions
}: UploadImage): Promise<{ data: UploadImageResponse }> =>
  axiosInstance.post(`/sport-centers/${id}/image`, data, uploadPercentOptions);

export const deleteSCImage = ({
  objectId,
  imageID
}: DeleteImageRequest): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${objectId}/image/${imageID}`);

export const uploadCourtImage = ({
  courtId,
  data,
  sportCenterId,
  uploadPercentOptions
}: UploadCourtImageRequest): Promise<{ data: UploadImageResponse }> =>
  axiosInstance.post(`/sport-centers/${sportCenterId}/courts/${courtId}/image`, data, uploadPercentOptions);

export const deleteCourtImage = ({
  courtId,
  imageID,
  sportCenterId
}: DeleteCourtImageRequest): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${sportCenterId}/courts/${courtId}/image/${imageID}`);

/**  export const uploadCourtImage = (courtId: Number): Promise<{ data: SuccessResponse }> =>
    axiosInstance.post(`/courts/${courtId}/image`);
  
  export const deleteCourtImage = (
    courtId: Number,
    imageId: Number
  ): Promise<{ data: SuccessResponse }> =>
    axiosInstance.delete(`/courts/${courtId}/detach-sport/${imageId}`); */

export const uploadUserProfilePhoto = ({
  id,
  data,
  uploadPercentOptions
}: UploadImage): Promise<{ data: UploadImageResponse }> =>
  axiosInstance.post(`users/${id}/image`, data, uploadPercentOptions);

export const deleteUserProfilePhoto = ({
  objectId: userId,
  imageID: imageId
}: DeleteImageRequest): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`users/${userId}/image/${imageId}`);
