// interfaces
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { AdminCoachesKeyType } from '@interfaces/user/coaches/coaches';
import { RecurringAppointmentResponseType } from '@interfaces/appointments/appointments';
import dayjs, { Dayjs } from 'dayjs';
// hooks
import { useTranslation } from 'react-i18next';
import useCalendarsLogic from '@hooks/calendars/useCalendarsLogic';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
// atoms
import { popupAtom } from '@atoms/popupAtom';

// queries
import { useRecurringAppointmentByID } from '@api/queries/appointments/appointments';

// containers
import AddApointment from '@containers/Appointment/AddAppointment/AddAppointment';
// components
import { Box, Button, Card, CardContent, Grid, Typography } from '@mui/material';
import Loading from '@components/Loading/Loading';
// icons
import { DeactivateIcon, DeleteIcon, EditIcon } from '@components/icons/icons';
// theme
import theme from '@src/theme';
import { getDataFromRecurringAppointment } from '@helpers/utility';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
import { useDeleteRecurringAppointment } from '@api/mutations/appointments/appointments';
import DataCard from '@components/DataCard/DataCard';

const EditPermanentAppointmentPage = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const permanentAppointmentId = params.permanentAppointmentId
    ? +params.permanentAppointmentId
    : undefined;

  const [editPermanentAppointmentData, setEditPermanentAppointmentData] = useState<
    {
      name: string;
      value?: string | number | Dayjs;
    }[]
  >([]);

  const {
    openAddAppointment,
    setOpenAddAppointment,
    addPermanentAppointment,
    selectedTimeDate,
    setSelectedTimeDate,
    calendarWorkingHours
  } = useCalendarsLogic();
  const onSuccessPermanentAppointment = (data: RecurringAppointmentResponseType) => {
    let permanentAppointment = data.data.data;
    let tempData: {
      name: string;
      value?: string | number | Dayjs;
    }[] = [
      {
        name: 'calendar.permanentAppointment.name',
        value: permanentAppointment.name
      },
      {
        name: 'calendar.permanentAppointment.bearer',
        value: permanentAppointment.user.name
      },
      {
        name: 'fieldsAndSports',
        value:
          permanentAppointment.court_sport.court.name +
          ' - ' +
          permanentAppointment.court_sport.sport.name
      },
      {
        name: 'from_day',
        value: dayjs(permanentAppointment.date_from).format('DD/MM/YYYY')
      },
      {
        name: 'to_day',
        value: dayjs(permanentAppointment.date_to).format('DD/MM/YYYY')
      },
      {
        name: 'calendar.permanentAppointment.appointment_start',
        value: permanentAppointment.time_start
      },
      {
        name: 'calendar.permanentAppointment.appointment_end',
        value: getDataFromRecurringAppointment(permanentAppointment, t).timeEnd
      },
      {
        name: 'calendar.permanentAppointment.days_in_week',
        value: getDataFromRecurringAppointment(permanentAppointment, t).arrayOfDays.join()
      },
      {
        name: 'price',
        value: permanentAppointment.price + ' RSD'
      }
    ];

    setEditPermanentAppointmentData(tempData);
  };
  const onErroPermanentAppointment = (err: Error) => {};

  const {
    data: permanentAppointmentData,
    isLoading: isLoadingPermanentAppointment,
    refetch: refetchPermanentAppointment
  } = useRecurringAppointmentByID(
    permanentAppointmentId && +permanentAppointmentId,
    onSuccessPermanentAppointment,
    onErroPermanentAppointment
  );
  const editPermanentAppointmentColumns = useMemo<MRT_ColumnDef<AdminCoachesKeyType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        Cell: ({ row }) => (
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="subtitle1">{t(`${row.original.name}`).toString()}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{row.original?.value?.toString()}</Typography>
            </Grid>
          </Grid>
        )
      }
    ],
    []
  );

  const onDeletePermanentAppointmentSuccess = (data: SuccessResponse) => {
    resetPopup();
    navigate(-1);
  };
  const onDeletePermanentAppointmentError = (error: ErrorResponse) => {};

  const { mutate: deletePermanentAppointment } = useDeleteRecurringAppointment(
    onDeletePermanentAppointmentSuccess,
    onDeletePermanentAppointmentError
  );

  if (isLoadingPermanentAppointment) {
    return <Loading />;
  }
  return (
    <Box p={3}>
      <Box display="flex" flexDirection="row" p={2} pr={0} justifyContent="flex-end" gap={2}>
        {/* <Button
          variant="contained"
          color="info"
          sx={{
            color: 'white',
            textTransform: 'none'
          }}
          endIcon={
            <DeactivateIcon fill="white" stroke="white" style={{ width: '20px', height: '20px' }} />
          }
        >
          {t('deactivate')}
        </Button> */}

        <Button
          variant="contained"
          color="error"
          sx={{
            textTransform: 'none'
          }}
          onClick={() => {
            setPopup({
              open: true,
              title: t('calendar.permanentAppointment.delete_popUp_title'),
              content: '',
              variant: 'info',
              onClick: () => {
                deletePermanentAppointment(Number(permanentAppointmentId));
              }
            });
          }}
          endIcon={
            <DeleteIcon fill="white" stroke="white" style={{ width: '15px', height: '15px' }} />
          }
        >
          {t('calendar.delete_appointment')}
        </Button>
      </Box>

      <Card>
        <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
          <Typography variant="h6">
            {t('calendar.permanentAppointment.appointment_overview_title')}
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'none',
              borderColor: theme.palette.sportyMateGray.light
            }}
            endIcon={
              <EditIcon
                fill={theme.palette.primary.main}
                stroke={theme.palette.primary.main}
                viewBox="0 0 25 24"
              />
            }
            onClick={() => setOpenAddAppointment(true)}
          >
            {t('adminEmployeePage.edit.edit_button_text')}
          </Button>
        </Box>
        <DataCard
          title=""
          data={editPermanentAppointmentData}
          columns={editPermanentAppointmentColumns}
          removeShaddow
        />
      </Card>
      <AddApointment
        sportCenterID={Number(params.sportCenterId)}
        anchor="right"
        open={openAddAppointment}
        onClose={() => {
          setOpenAddAppointment(false);
          setSelectedTimeDate({
            dateAppointment: null,
            startAppointment: '',
            durationAppointment: ''
          });
          refetchPermanentAppointment();
        }}
        addAppointmentType={'permanentAppointment'}
        addRegularAppointment={() => {}}
        addPermanentAppointment={addPermanentAppointment}
        dateAppointment={selectedTimeDate.dateAppointment}
        startAppointment={selectedTimeDate.startAppointment}
        reccuringAppointmentId={permanentAppointmentId}
        noButtons
        calendarWorkingHours={calendarWorkingHours}
      />
    </Box>
  );
};

export default EditPermanentAppointmentPage;
