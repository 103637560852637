//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';
// services
import {
  getAvailableTimePrices,
  getCourtSportFromSportCenter,
  getPriceRules
} from '@services/priceRules/priceRules';
// interfaces
import { SportCenterWithCourtSportResponse } from '@interfaces/SportCenters/SportCenter';
import {
  AllPriceRulesResponse,
  CourtSportErrorResponse,
  AllPriceRulesRequest,
  CreatePriceRuleResponse,
  CourtSportError
} from '@interfaces/priceRules/priceRules';
// dayjs
import dayjs from 'dayjs';

export const usePriceRules = (
  AllPriceRulesRequest: AllPriceRulesRequest,
  onSuccess: (data: AllPriceRulesResponse) => void,
  onError: (err: CourtSportErrorResponse) => void,
  enabledProp: boolean
): UseQueryResult<AllPriceRulesResponse, CourtSportErrorResponse> =>
  useQuery(['price-rules', AllPriceRulesRequest], () => getPriceRules(AllPriceRulesRequest), {
    select: res => res.data,
    onSuccess: data => onSuccess(data),
    onError: err => onError(err),
    enabled: enabledProp
  });

export const useCourtSport = (
  sportCenterID: number | undefined,
  onSuccess: (data: SportCenterWithCourtSportResponse) => void,
  onError: (err: CourtSportError) => void
): UseQueryResult<SportCenterWithCourtSportResponse, CourtSportError> =>
  useQuery(
    ['court-sport-by-sport-center', sportCenterID],
    () => getCourtSportFromSportCenter(sportCenterID),
    {
      select: res => res.data,
      onSuccess: data => onSuccess(data),
      onError: err => onError(err),
      enabled: !!sportCenterID
    }
  );

export const useAvailableTimePrices = (
  court_sport_id: number,
  datetime_start: string,
  onSuccess: (data: CreatePriceRuleResponse) => void,
  onError: (err: CourtSportErrorResponse) => void
): UseQueryResult<CreatePriceRuleResponse, CourtSportErrorResponse> =>
  useQuery(
    ['available-time-prices', court_sport_id, datetime_start],
    () => getAvailableTimePrices(court_sport_id, datetime_start),
    {
      select: res => res.data,
      onSuccess: data => onSuccess(data),
      onError: err => onError(err),
      enabled:
        court_sport_id && datetime_start.length > 11 && dayjs().isBefore(dayjs(`${datetime_start}`))
          ? true
          : false
    }
  );
