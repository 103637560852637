//mui
import Button from '@mui/material/Button';
import { Typography, Box, TextField } from '@mui/material';
//hooks
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
//api
import { useSendVerificationEmail, useUpdateUnverifiedEmail } from '@api/mutations/user/login';
//type
import { SuccessResponse } from '@interfaces/apiResponse';
import { UnverifiedEmailErrorType } from '@interfaces/user/login';
//atom
import { popupAtom } from '@atoms/popupAtom';
//react hook form
import { Controller, useForm } from 'react-hook-form';
import { useIsImpersonating } from '@api/queries/user/user';
import { red } from '@mui/material/colors';
import { useProfileMenu } from '@hooks/useProfileMenu/useProfileMenu';

const EmailNotVerifiedPage = () => {
  const { control, handleSubmit, setError } = useForm({ defaultValues: { email: ' ' } });

  const { t: translation } = useTranslation();

  const [change, setChange] = useState(false);

  const setPopup = useSetRecoilState(popupAtom);

  const { deleteImpersonate, submitLogout } = useProfileMenu();

  const onSuccess = (data: SuccessResponse) => {
    setPopup({
      open: true,
      title: translation('unverified_email.success_message_title'),
      content: translation('unverified_email.success_message_content'),
      variant: 'success',
      stayOpen: true
    });
  };
  const onError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const onUpdateEmailError = (err: UnverifiedEmailErrorType) => {
    if (err.errors?.email) {
      setError(
        'email',
        {
          type: 'custom',
          message: err.errors.email.join(',')
        },
        { shouldFocus: true }
      );
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };

  const { mutate: sendEmail } = useSendVerificationEmail(onSuccess, onError);
  const { mutate: updateEmail } = useUpdateUnverifiedEmail(onSuccess, onUpdateEmailError);
  const { data } = useIsImpersonating(onError);

  const onSubmit = (data: { email: string }) => {
    updateEmail(data);
  };

  return (
    <Box>
      {!change && (
        <Box>
          <Typography
            variant="h4"
            sx={{
              textAlign: { xs: 'center', sm: 'center', md: 'left' },
              fontWeight: 800
            }}
          >
            {translation('unverified_email.verify_email_title')}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt={4}>
            {translation('unverified_email.success_message_content')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              marginTop: '42px',
              width: '100%',
              gap: 2
            }}
          >
            <Button variant="contained" sx={{ width: '100%' }} onClick={() => sendEmail(null)}>
              {translation('unverified_email.send_again')}
            </Button>
            <Button variant="outlined" sx={{ width: '100%' }} onClick={() => setChange(true)}>
              {translation('unverified_email.change_email')}
            </Button>
          </Box>
          <Box mt={2}>
            {data?.data.is_impersonating ? (
              <Button
                color="error"
                fullWidth
                sx={{ bgcolor: red[50] }}
                onClick={() => deleteImpersonate(undefined)}
              >
                {translation('unverified_email.back_as_superadmin')}
              </Button>
            ) : (
              <Button color="error" fullWidth sx={{ bgcolor: red[50] }} onClick={submitLogout}>
                {translation('log_out')}
              </Button>
            )}
          </Box>
        </Box>
      )}

      {change && (
        <Box>
          <Typography
            variant="h4"
            sx={{
              textAlign: { xs: 'center', sm: 'center', md: 'left' },
              fontWeight: 800
            }}
          >
            {translation('unverified_email.new_email_title')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {translation('unverified_email.new_email_subtitle')}
          </Typography>
          <Box
            component={'form'}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '42px',
              width: '100%'
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField label="Email" {...field} error={invalid} helperText={error?.message} />
              )}
            />

            <Button type="submit" variant="contained" sx={{ my: 2 }}>
              {translation('confirm')}
            </Button>
            <Button onClick={() => setChange(false)}> {translation('back')}</Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default EmailNotVerifiedPage;
