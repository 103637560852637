//router
import { createBrowserRouter } from 'react-router-dom';
//layout
import Layout from '@layout/Layout';
// pages
import Error404Page from '@pages/Error404Page/Error404Page';
import EmailNotVerifiedPage from '@pages/EmailNotVerified/EmailNotVerifiedPage';
import LoginLayout from '@layout/LoginLayout/LoginLayout';
//routes
import { globalRoutes } from './globalRoutes';
import { superadminRoutes } from './superadminRoutes';
import { adminRoutes } from './adminRoutes';
import EmailVerifiedPage from '@pages/EmailVerified/EmailVerifiedPage';

export const routerConfig = createBrowserRouter([
  {
    path: '/email-not-verified',
    element: <LoginLayout />,
    children: [
      {
        path: '',
        element: <EmailNotVerifiedPage />
      }
    ]
  },
  {
    path: '/email-verified',
    element: <LoginLayout />,
    children: [
      {
        path: '',
        element: <EmailVerifiedPage />
      }
    ]
  },
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error404Page />,
    children: [globalRoutes, superadminRoutes, adminRoutes]
  }
]);
