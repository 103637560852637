// interfaces
import React, { Dispatch, SetStateAction } from 'react';

import { sha256 } from 'js-sha256';
import { AxiosProgressEvent } from 'axios';
import { BenefitsType } from '@interfaces/SportCenters/Benefits';
import {
  AllPriceRulesResponse,
  PriceRuleTableFields,
  PriceRuleType,
  CreatePriceRuleRequest,
  CourtSportsType,
  PriceRuleGraphRowData,
  TimePricesType,
  CourtSportData,
  GraphKeys,
  PriceRuleTypeResponse
} from '@interfaces/priceRules/priceRules';
import {
  SportCenterResponse,
  SportCenterResponseType,
  SportCenterWithCourtSportResponse,
  WorkingHoursType,
  workingDaysArrType
} from '@interfaces/SportCenters/SportCenter';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { TFunction } from 'i18next';
import { LinkNames } from '@interfaces/routes';
import theme from '@src/theme';
import { TableVariantType } from '@interfaces/table/table';
import { AppointmentReservedFromEnum, AppointmentStatusEnum } from '@enum/appointmentStatusEnum';
import {
  EventFormattedType,
  RecurringAppointment,
  RecurringAppointmentCreateRequest,
  RecurringAppointmentResponseType,
  RegularAppointmentType,
  RegularAppointmentCreateRequest,
  RegularAppointmentResponseType,
  CalendarWorkingHoursType
} from '@interfaces/appointments/appointments';
import dayjs, { Dayjs } from 'dayjs';
import { CourtSportType, CourtType } from '@interfaces/court/court';
import {
  AdminCoachesKeyType,
  CoachResponseType,
  CreateCoachRequestType
} from '@interfaces/user/coaches/coaches';
import { CurrentCMSType } from '@atoms/currentCMS';
import { RoleEnum } from '@enum/roleEnum';
import { PermissionsEnum } from '@enum/permissionsEnum';
import { SportCustomFieldsRequest } from '@interfaces/sport/sport';

export const hashWithSha256 = async (message: string) => {
  const messageBuffer = new TextEncoder().encode(message);
  const hashBuffer = sha256.digest(messageBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
};

export const handleChecked = (
  event: React.ChangeEvent<HTMLInputElement>,
  getCheckedArr: string[],
  setCheckedArr: (e: string[]) => void
) => {
  let updateCheckedArr = [...getCheckedArr];
  if (event.target.checked) {
    updateCheckedArr = [...getCheckedArr, event.target.value];
  } else {
    updateCheckedArr.splice(getCheckedArr.indexOf(event.target.value), 1);
  }
  setCheckedArr(updateCheckedArr);
  return updateCheckedArr;
};

export const handleBenefitsChecked = (
  event: React.ChangeEvent<HTMLInputElement>,
  getCheckedArr: number[],
  setCheckedArr: (e: number[]) => void
) => {
  let updateCheckedArr = [...getCheckedArr];
  if (event.target.checked) {
    updateCheckedArr = [...getCheckedArr, parseInt(event.target.value)];
  } else {
    updateCheckedArr.splice(getCheckedArr.indexOf(parseInt(event.target.value)), 1);
  }
  setCheckedArr(updateCheckedArr);
  return updateCheckedArr;
};

export const onUploadProgress = (
  progressEvent: AxiosProgressEvent,
  setUploadPercent: Dispatch<SetStateAction<number>>
) => {
  const { loaded, total } = progressEvent;
  if (total) {
    let percent = Math.floor((loaded * 100) / total);
    setUploadPercent(prev => (percent >= 100 ? 0 : prev + percent));
  }
};
export const createBenefitsArray = (arr: BenefitsType) => {
  let benefitsArr: number[] = [];
  arr.map(el => {
    benefitsArr.push(el.id);
  });
  return benefitsArr;
};

export const getArrayOfTimePriceStrings = (timePriceArr: TimePricesType[]): string[] => {
  let timePriceStrings: string[] = [];
  timePriceArr.length > 0 &&
    timePriceArr.map(timePrice => {
      timePriceStrings.push(`${timePrice.minutes}min - ${timePrice.price} RSD `);
    });
  return timePriceStrings;
};
let priceRuleKeys: (keyof PriceRuleType)[] = [];
export const getArrayOfdaysFromPriceRule = (
  priceRule: PriceRuleType,
  t: TFunction
): {
  arrayOfDays: string[];
  isItWorkingWeek: boolean;
  isItWeekend: boolean;
  isItWholeWeek: boolean;
} => {
  const arrayOfDays: string[] = [];
  const isItWorkingWeek: boolean =
    priceRule.mon && priceRule.tue && priceRule.wed && priceRule.thu && priceRule.fri;
  const isItWeekend: boolean = priceRule.sat && priceRule.sun;
  const isItWholeWeek: boolean = isItWeekend && isItWorkingWeek;
  priceRuleKeys = Object.keys(priceRule) as (keyof typeof priceRule)[];
  const dayKeys: workingDaysArrType[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  if (isItWholeWeek) {
    arrayOfDays.push(t('wholeweek'));
  } else if (isItWeekend && !isItWorkingWeek) {
    arrayOfDays.push(t('weekend'));
    priceRuleKeys.map(key => {
      if (
        (key === 'mon' || key === 'tue' || key === 'wed' || key === 'thu' || key === 'fri') &&
        priceRule[key]
      ) {
        dayKeys.map(day => {
          day === key && priceRule[key] && arrayOfDays.push(t(day));
        });
      }
    });
  } else if (!isItWeekend && isItWorkingWeek) {
    arrayOfDays.push(t('workweek'));
    priceRuleKeys.map(key => {
      if ((key === 'sat' || key === 'sun') && priceRule[key]) {
        dayKeys.map(day => {
          day === key && priceRule[key] && arrayOfDays.push(t(day));
        });
      }
    });
  } else {
    priceRuleKeys.map(key => {
      dayKeys.map(day => {
        day === key && priceRule[key] && arrayOfDays.push(t(day));
      });
    });
  }
  return { arrayOfDays, isItWorkingWeek, isItWeekend, isItWholeWeek };
};

export const getArrayOfCourtSportsStrings = (
  courtSports: CourtSportsType[]
): { courtSportStrings: string[]; courtSportIds: number[] } => {
  let courtSportStrings: string[] = [];
  let courtSportIds: number[] = [];
  courtSports.map(courSport => {
    courtSportStrings.push(`${courSport.court.name} - ${courSport.sport.name}`);
    courtSportIds.push(courSport.id);
  });

  return { courtSportStrings, courtSportIds };
};
export const prepackPriceRuleIntoFields = (
  data: AllPriceRulesResponse,
  setPriceRulesFields: Dispatch<SetStateAction<PriceRuleTableFields[]>>,
  t: TFunction
) => {
  data.data.map(priceRule => {
    let arrayOfDays: string[] = getArrayOfdaysFromPriceRule(priceRule.price_rule, t).arrayOfDays;
    let tempArrayOfStrings: string[] = getArrayOfCourtSportsStrings(
      priceRule.court_sports
    ).courtSportStrings;

    setPriceRulesFields(prev => [
      ...prev,
      {
        id: priceRule.price_rule.id,
        name: priceRule.price_rule.name,
        workingHours: `${priceRule.price_rule.time_start} ${t('to').toLowerCase()} ${
          priceRule.price_rule.time_end === '23:59' ? '24:00' : priceRule.price_rule.time_end
        }`,
        days: arrayOfDays.length > 0 ? arrayOfDays.join(', ') : '',
        fieldsNames: tempArrayOfStrings.length > 0 ? tempArrayOfStrings.join('; ') : '',
        priceTime: getArrayOfTimePriceStrings(priceRule.time_prices).join(),
        name_translates: priceRule.price_rule.name_translates
      }
    ]);
  });
};

export const requiredMessage: string = 'Morate popuniti ovo polje *';

export function sortTables(
  index: number,
  tableVarant: TableVariantType,
  setSortBy: Dispatch<SetStateAction<string>>,
  setSortDirection: Dispatch<SetStateAction<string>>,
  setCheckSort: Dispatch<SetStateAction<number | null>>
) {
  if (tableVarant === 'objects') {
    switch (index) {
      case 0:
        setSortBy('name');
        setSortDirection('asc');
        setCheckSort(0);
        break;
      case 1:
        setSortBy('name');
        setSortDirection('desc');
        setCheckSort(1);
        break;
      case 2:
        setSortBy('email');
        setSortDirection('asc');
        setCheckSort(2);
        break;
      case 3:
        setSortBy('email');
        setSortDirection('desc');
        setCheckSort(3);
        break;
      case 4:
        setSortBy('address');
        setSortDirection('asc');
        setCheckSort(4);
        break;
      case 5:
        setSortBy('address');
        setSortDirection('desc');
        setCheckSort(5);
        break;
      case 6:
        setSortBy('fee_percentage');
        setSortDirection('asc');
        setCheckSort(6);
        break;
      case 7:
        setSortBy('fee_percentage');
        setSortDirection('desc');
        setCheckSort(7);
        break;
      default:
        break;
    }
  } else if (tableVarant === 'users') {
    switch (index) {
      case 0:
        setSortBy('first_name');
        setSortDirection('asc');
        setCheckSort(0);
        break;
      case 1:
        setSortBy('first_name');
        setSortDirection('desc');
        setCheckSort(1);
        break;
      case 2:
        setSortBy('last_name');
        setSortDirection('asc');
        setCheckSort(2);
        break;
      case 3:
        setSortBy('last_name');
        setSortDirection('desc');
        setCheckSort(3);
        break;
      case 4:
        setSortBy('email');
        setSortDirection('asc');
        setCheckSort(4);
        break;
      case 5:
        setSortBy('email');
        setSortDirection('desc');
        setCheckSort(5);
        break;
      case 6:
        setSortBy('date_of_birth');
        setSortDirection('asc');
        setCheckSort(6);
        break;
      case 7:
        setSortBy('date_of_birth');
        setSortDirection('desc');
        setCheckSort(7);
        break;
      case 8:
        setSortBy('phone');
        setSortDirection('asc');
        setCheckSort(8);
        break;
      case 9:
        setSortBy('phone');
        setSortDirection('desc');
        setCheckSort(9);
        break;
      default:
        break;
    }
  } else {
    switch (index) {
      case 0:
        setSortBy('first_name');
        setSortDirection('asc');
        setCheckSort(0);
        break;
      case 1:
        setSortBy('first_name');
        setSortDirection('desc');
        setCheckSort(1);
        break;
      case 2:
        setSortBy('last_name');
        setSortDirection('asc');
        setCheckSort(2);
        break;
      case 3:
        setSortBy('last_name');
        setSortDirection('desc');
        setCheckSort(3);
        break;
      case 4:
        setSortBy('email');
        setSortDirection('asc');
        setCheckSort(4);
        break;
      case 5:
        setSortBy('email');
        setSortDirection('desc');
        setCheckSort(5);
        break;
      case 6:
        setSortBy('phone');
        setSortDirection('asc');
        setCheckSort(6);
        break;
      case 7:
        setSortBy('phone');
        setSortDirection('desc');
        setCheckSort(7);
        break;
      default:
        break;
    }
  }
}

export function getOccurrence(array: number[], value: number) {
  return array.filter((v: number) => v === value).length;
}

export const createPriceRuleGraphRows = (
  hours: string,
  workingDays: GraphKeys | null,
  saturday: GraphKeys | null,
  sunday: GraphKeys | null
): PriceRuleGraphRowData => {
  return { hours, workingDays, saturday, sunday };
};

export const prepackPriceRuleIntoGraph = (
  data: AllPriceRulesResponse,
  setPriceRuleGraphData: Dispatch<SetStateAction<PriceRuleGraphRowData[]>>,
  workingHours: WorkingHoursType,
  t: TFunction
) => {
  let initialArr: PriceRuleGraphRowData[] = [];
  const workweekTo = workingHours.workweek_to === '23:59' ? '24' : workingHours.workweek_to;
  const saturdayTo = workingHours.saturday_to === '23:59' ? '24' : workingHours.saturday_to;
  const sundayTo = workingHours.sunday_to === '23:59' ? '24' : workingHours.sunday_to;

  for (let i = 0; i < 24; i++) {
    initialArr.push(
      createPriceRuleGraphRows(
        i < 10 ? `0${i}:00` : `${i}:00`,
        i >= parseInt(workingHours.workweek_from) && i < parseInt(workweekTo)
          ? {
              color: 'transparent'
            }
          : {
              color: '#D0D0D0'
            },
        i >= parseInt(workingHours.saturday_from) && i < parseInt(saturdayTo)
          ? {
              color: 'transparent'
            }
          : {
              color: '#D0D0D0'
            },
        i >= parseInt(workingHours.sunday_from) && i < parseInt(sundayTo)
          ? {
              color: 'transparent'
            }
          : {
              color: '#D0D0D0'
            }
      )
    );
  }

  data?.data?.forEach(priceRule => {
    priceRuleKeys = Object.keys(priceRule.price_rule) as (keyof typeof priceRule.price_rule)[];
    const returnTimeEndFor24h =
      priceRule.price_rule.time_end === '23:59' ? '24:00' : priceRule.price_rule.time_end;
    const returnRowSpan = (priceRule: PriceRuleTypeResponse, time: string) => {
      const result = parseInt(time) - parseInt(priceRule.price_rule.time_start);
      return result <= 0 ? 1 : result;
    };

    initialArr.forEach(el => {
      if (parseInt(el.hours) === parseInt(priceRule.price_rule.time_start)) {
        priceRuleKeys.map(key => {
          if (
            (key === 'mon' || key === 'tue' || key === 'wed' || key === 'thu' || key === 'fri') &&
            priceRule.price_rule[key]
          ) {
            return (el.workingDays = {
              text: getArrayOfTimePriceStrings(priceRule.time_prices),
              color: 'rgba(99, 102, 241, 0.3)',
              rowSpan: returnRowSpan(priceRule, returnTimeEndFor24h),
              ruleName: priceRule.price_rule.name
            });
          } else if (key === 'sat' && priceRule.price_rule[key]) {
            return (el.saturday = {
              text: getArrayOfTimePriceStrings(priceRule.time_prices),
              color: 'rgba(35, 229, 168, 0.3)',
              rowSpan: returnRowSpan(priceRule, returnTimeEndFor24h),
              ruleName: priceRule.price_rule.name
            });
          } else if (key === 'sun' && priceRule.price_rule[key]) {
            return (el.sunday = {
              text: getArrayOfTimePriceStrings(priceRule.time_prices),
              color: 'rgba(35, 229, 168, 0.3)',
              rowSpan: returnRowSpan(priceRule, returnTimeEndFor24h),
              ruleName: priceRule.price_rule.name
            });
          }
        });
      } else if (
        parseInt(el.hours) < parseInt(returnTimeEndFor24h) &&
        parseInt(el.hours) >= parseInt(priceRule.price_rule.time_start)
      ) {
        priceRuleKeys.map(key => {
          if (
            (key === 'mon' || key === 'tue' || key === 'wed' || key === 'thu' || key === 'fri') &&
            priceRule.price_rule[key] &&
            el.workingDays?.color === 'transparent'
          ) {
            return (el.workingDays = null);
          } else if (
            key === 'sat' &&
            priceRule.price_rule[key] &&
            el.saturday?.color === 'transparent'
          ) {
            return (el.saturday = null);
          } else if (
            key === 'sun' &&
            priceRule.price_rule[key] &&
            el.sunday?.color === 'transparent'
          ) {
            return (el.sunday = null);
          }
        });
      }
    });
  });
  setPriceRuleGraphData(initialArr);
};

export function padTo2Digits(num: number) {
  return String(num).padStart(2, '0');
}

export const getValidationForPriceRuleTimePickers = (
  getValues: UseFormGetValues<CreatePriceRuleRequest>,
  workingHours: WorkingHoursType
): {
  timeStart: number;
  timeEnd: number;
  minTimeString: string;
  maxTimeString: string;
  isItError: boolean | undefined;
  maxTime: number;
  minTime: number;
} => {
  const timeStart = parseInt(getValues('price_rule.time_start'));
  const timeEnd = parseInt(
    getValues('price_rule.time_end') === '23:59' ? '24' : getValues('price_rule.time_end')
  );
  const isItInWorkingWeek =
    getValues('price_rule.mon') ||
    getValues('price_rule.tue') ||
    getValues('price_rule.wed') ||
    getValues('price_rule.thu') ||
    getValues('price_rule.fri') ||
    getValues('price_rule.workweek');
  const isItInWeekend = getValues('price_rule.weekend');
  const isItSunday = getValues('price_rule.sun');
  const isItSaturday = getValues('price_rule.sat');
  const workweekTo = workingHours.workweek_to === '23:59' ? '24' : workingHours.workweek_to;
  const saturdayTo = workingHours.saturday_to === '23:59' ? '24' : workingHours.saturday_to;
  const sundayTo = workingHours.sunday_to === '23:59' ? '24' : workingHours.sunday_to;
  const isItError =
    (timeStart < parseInt(workingHours.workweek_from) && isItInWorkingWeek) ||
    (timeStart < parseInt(workingHours.saturday_from) && (isItSaturday || isItInWeekend)) ||
    (timeStart < parseInt(workingHours.sunday_from) && (isItSunday || isItInWeekend)) ||
    (timeEnd > parseInt(workweekTo) && isItInWorkingWeek) ||
    (timeEnd > parseInt(saturdayTo) && (isItSaturday || isItInWeekend)) ||
    (timeEnd > parseInt(sundayTo) && (isItSunday || isItInWeekend));
  const maxTime = Math.max(parseInt(workweekTo), parseInt(saturdayTo), parseInt(saturdayTo));
  const minTime = Math.min(
    parseInt(workingHours.workweek_from),
    parseInt(workingHours.saturday_from),
    parseInt(workingHours.sunday_from)
  );
  const minTimeString = minTime < 10 ? `0${minTime}:00` : `${minTime}:00`;
  const maxTimeString = maxTime < 10 ? `0${maxTime}:00` : `${maxTime}:00`;

  return {
    timeStart,
    timeEnd,
    isItError,
    maxTime,
    minTime,
    maxTimeString,
    minTimeString
  };
};

//HH:MM (hour:minutes)
export function findMinMaxTime(array: (string | undefined)[]) {
  array.sort((a, b) => {
    if (a && b && a > b) {
      return -1;
    }
    if (a && b && a < b) {
      return 1;
    }
    return 0;
  });
}

export const getAvailableTimesForTimePicker = (minStartTime: number, maxStartTime: number) => {
  let tempArrayOfStrings = [];
  for (let i: number = minStartTime; i <= maxStartTime; i++) {
    tempArrayOfStrings.push(i < 10 ? '0' + i + ':' + '00' : i + ':' + '00');
    i !== maxStartTime && tempArrayOfStrings.push(i < 10 ? '0' + i + ':' + '30' : i + ':' + '30');
  }
  return tempArrayOfStrings;
};

export const generateBreadcrumbs = (path: string, t: Function): LinkNames[] => {
  const asPathWithoutQuery = path.split('?')[0];
  const asPathNestedRoutes = asPathWithoutQuery.split('/').filter(v => v.length > 0);

  if (asPathNestedRoutes.includes('create-object')) {
    return [];
  }

  const crumblist = asPathNestedRoutes.map((subpath, idx) => {
    let link = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/');

    if (+asPathNestedRoutes[idx + 1]) {
      link += '/' + asPathNestedRoutes[idx + 1];
    }

    if (link === '/cooperation') link = '#';

    return { link, name: t('sidebar.' + subpath, '') };
  });

  return crumblist;
};

export function getDayName(date: Dayjs) {
  let dayNum = date.day();
  let dayName: string = '';
  switch (dayNum) {
    case 0:
      dayName = 'sun';
      break;
    case 1:
      dayName = 'mon';
      break;
    case 2:
      dayName = 'tue';
      break;
    case 3:
      dayName = 'wed';
      break;
    case 4:
      dayName = 'thu';
      break;
    case 5:
      dayName = 'fri';
      break;
    case 6:
      dayName = 'sat';
      break;
  }
  return dayName;
}

export function getAppointmentColor(
  reserved_from: string,
  status: string,
  datetime_start: Dayjs,
  recurringAppointmentID: number | null
) {
  let appointmentObject = { color: '', borderColor: '', timeColor: '' };
  if (reserved_from === AppointmentReservedFromEnum.cms && !recurringAppointmentID) {
    appointmentObject.color = theme.palette.cmsEventColor.main;
    appointmentObject.borderColor = theme.palette.cmsEventColor.dark;
    appointmentObject.timeColor = theme.palette.cmsEventColor.dark;
    return appointmentObject;
  } else if (
    reserved_from === AppointmentReservedFromEnum.client &&
    dayjs() >= datetime_start &&
    status !== AppointmentStatusEnum.completed
  ) {
    appointmentObject.color = theme.palette.appInProgressEventColor.main;
    appointmentObject.borderColor = theme.palette.appInProgressEventColor.dark;
    appointmentObject.timeColor = theme.palette.appInProgressEventColor.dark;
    return appointmentObject;
  } else if (
    reserved_from === AppointmentReservedFromEnum.client &&
    status === AppointmentStatusEnum.completed &&
    dayjs() >= datetime_start
  ) {
    appointmentObject.color = theme.palette.appCompletedEventColor.main;
    appointmentObject.borderColor = theme.palette.appCompletedEventColor.dark;
    appointmentObject.timeColor = theme.palette.appCompletedEventColor.dark;
    return appointmentObject;
  } else if (
    reserved_from === AppointmentReservedFromEnum.client &&
    dayjs().isBefore(datetime_start)
  ) {
    appointmentObject.color = theme.palette.appScheduledEventColor.main;
    appointmentObject.borderColor = theme.palette.appScheduledEventColor.dark;
    appointmentObject.timeColor = theme.palette.appScheduledEventColor.dark;
    return appointmentObject;
  } else if (reserved_from === AppointmentReservedFromEnum.cms && recurringAppointmentID) {
    appointmentObject.color = theme.palette.cmsRecurringEventColor.main;
    appointmentObject.borderColor = theme.palette.cmsRecurringEventColor.dark;
    appointmentObject.timeColor = theme.palette.cmsRecurringEventColor.dark;
    return appointmentObject;
  } else {
    appointmentObject.color = theme.palette.cmsEventColor.main;
    appointmentObject.borderColor = theme.palette.cmsEventColor.dark;
    appointmentObject.timeColor = theme.palette.cmsEventColor.dark;
    return appointmentObject;
  }
}

export function removeDuplicatesFromCourtSportArrayById(array: CourtSportData[]) {
  return array.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
}

export function findMinMaxTimeInPriceRulesByCourtSportID(
  array: PriceRuleTypeResponse[],
  dayName: string
) {
  let tempArr: { time_start: string; time_end: string }[] = [];
  array.map(item => {
    if (
      item?.price_rule.hasOwnProperty(dayName) &&
      item?.price_rule[dayName as keyof typeof item.price_rule] == true
    ) {
      tempArr.push({
        time_start: item?.price_rule?.time_start,
        time_end: item?.price_rule?.time_end
      });
    }
  });
  return tempArr;
}

export function getSingleAppointmentData(res: RegularAppointmentResponseType) {
  let durationAppointment =
    dayjs(res.data.data.datetime_end).diff(dayjs(res.data.data.datetime_start)) / 1000 / 60;
  let selectedAppointmentObject = {
    court_sport_id: res.data.data.court_sport_id,
    regularAppointment_date: dayjs(res.data.data.datetime_start),
    datetime_start: `${dayjs(res.data.data.datetime_start).format('HH')}:${dayjs(
      res.data.data.datetime_start
    ).format('mm')}`,
    minutes: durationAppointment,
    description: res.data.data.description != null ? res.data.data.description : '',
    user_id: res.data.data.user.id
  };
  return selectedAppointmentObject;
}

export function fillWorkingHoursArrayForSportCenter(data: WorkingHoursType | undefined) {
  return [
    data?.workweek_from,
    data?.workweek_to,
    data?.saturday_from,
    data?.saturday_to,
    data?.sunday_from,
    data?.sunday_to
  ];
}

export function setCourtSportAndDescForAppointmentByCourtSportSuccess(
  data: SportCenterWithCourtSportResponse,
  setCourtSport: Dispatch<SetStateAction<CourtSportData[]>>,
  setRegularAppointmentValue: UseFormSetValue<RegularAppointmentCreateRequest>,
  setRecurringAppointmentValue: UseFormSetValue<RecurringAppointmentCreateRequest>,
  startAppointment: string | undefined,
  dateAppointment: Dayjs | null | undefined,
  lng: string | null,
  durationAppointment?: string | number
) {
  let tempArr: CourtSportData[] = [];
  data.data.courts.map(item => {
    item.court_sports.map(coSpo => {
      if (lng === 'en' && coSpo.sport.name_translates) {
        return tempArr.push({
          id: coSpo.id,
          name: `${coSpo.court.name} - ${coSpo.sport.name_translates.en}`
        });
      } else {
        return tempArr.push({
          id: coSpo.id,
          name: `${coSpo.court.name} - ${coSpo.sport.name}`
        });
      }
    });
  });
  setCourtSport(tempArr);
  if (tempArr && tempArr.length === 1) {
    setRegularAppointmentValue('court_sport_id', tempArr[0].id);
    setRecurringAppointmentValue('court_sport_id', tempArr[0].id);
  }
  if (startAppointment && dateAppointment && durationAppointment) {
    setRegularAppointmentValue('datetime_start', startAppointment);
    setRegularAppointmentValue('regularAppointment_date', dateAppointment);
    setRegularAppointmentValue('minutes', durationAppointment);
    setRecurringAppointmentValue('time_start', startAppointment);
    setRecurringAppointmentValue('date_from', dateAppointment);
    setRecurringAppointmentValue('minutes', durationAppointment);
  }
}

export function formatCalendarEvents(
  eventsFormatted: EventFormattedType[],
  appointments: RegularAppointmentType[] | undefined,
  lng: string | null
) {
  if (appointments)
    for (let i = 0; i < appointments?.length; i++) {
      let startDateHourDisplay =
        lng === 'en'
          ? dayjs(appointments[i].datetime_start).format('hh')
          : dayjs(appointments[i].datetime_start).format('HH');
      let startDateMinuteDisplay = dayjs(appointments[i].datetime_start).format('mm');
      let appointmentColor = getAppointmentColor(
        appointments[i].reserved_from,
        appointments[i].status,
        dayjs(appointments[i].datetime_start),
        appointments[i].recurring_appointment_id
      );
      eventsFormatted.push({
        resourceId: appointments[i].court_sport.court_id,
        start: appointments[i].datetime_start,
        end: appointments[i].datetime_end,
        color: appointmentColor.color,
        borderColor: appointmentColor.borderColor,
        display: 'block',
        extendedProps: {
          id: appointments[i].id,
          start: `${startDateHourDisplay}:${startDateMinuteDisplay}`,
          userName: appointments[i].user?.name,
          courtName: appointments[i].court_sport.court.name,
          userEmail: appointments[i].user?.email,
          sportName: appointments[i].court_sport.sport.name,
          status: appointments[i].status,
          reservedFrom: appointments[i].reserved_from,
          profilePhoto: appointments[i].user?.profile_photo,
          recurringAppointmentID: appointments[i].recurring_appointment_id,
          userPhoneNumber: appointments[i].user?.phone
        }
      });
    }
}

export function formatCalendarEventsForCoach(
  eventsFormatted: EventFormattedType[],
  appointments: RegularAppointmentType[] | undefined,
  lng: string | null
) {
  if (appointments)
    for (let i = 0; i < appointments?.length; i++) {
      let startDateHourDisplay =
        lng === 'en'
          ? dayjs(appointments[i].datetime_start).format('hh')
          : dayjs(appointments[i].datetime_start).format('HH');
      let startDateMinuteDisplay = dayjs(appointments[i].datetime_start).format('mm');
      if (!appointments[i].status) {
        eventsFormatted.push({
          resourceId: appointments[i].court_sport.court_id,
          start: appointments[i].datetime_start,
          end: appointments[i].datetime_end,
          color: theme.palette.coachOtherEventColor.main,
          borderColor: theme.palette.coachOtherEventColor.dark,
          display: 'block',
          extendedProps: {
            id: appointments[i].id,
            start: `${startDateHourDisplay}:${startDateMinuteDisplay}`
          }
        });
      } else {
        eventsFormatted.push({
          resourceId: appointments[i].court_sport.court_id,
          start: appointments[i].datetime_start,
          end: appointments[i].datetime_end,
          color: theme.palette.coachEventColor.main,
          borderColor: theme.palette.coachEventColor.dark,
          display: 'block',
          extendedProps: {
            id: appointments[i].id,
            start: `${startDateHourDisplay}:${startDateMinuteDisplay}`,
            userName: appointments[i].user?.name,
            courtName: appointments[i].court_sport.court.name,
            userEmail: appointments[i].user?.email,
            sportName: appointments[i].court_sport.sport.name,
            status: appointments[i].status,
            reservedFrom: appointments[i].reserved_from,
            profilePhoto: appointments[i].user?.profile_photo,
            recurringAppointmentID: appointments[i].recurring_appointment_id
          }
        });
      }
    }
}

export function getRecurringAppointmentData(res: RecurringAppointmentResponseType) {
  let selectedRecurringAppointmentObject = {
    court_sport_id: res.data.data.court_sport_id,
    name: res.data.data.name,
    date_from: res.data.data.date_from,
    date_to: res.data.data.date_to,
    time_start: res.data.data.time_start,
    minutes: res.data.data.minutes,
    price: res.data.data.price,
    user_id: res.data.data.user.id,
    mon: res.data.data.mon,
    tue: res.data.data.tue,
    wed: res.data.data.wed,
    thu: res.data.data.thu,
    fri: res.data.data.fri,
    sat: res.data.data.sat,
    sun: res.data.data.sun
  };
  return selectedRecurringAppointmentObject;
}

export const getDataFromCoahesResponse = (
  data: CoachResponseType
): {
  tableData: AdminCoachesKeyType[];
  editFormData: CreateCoachRequestType;
} => {
  let tempArrayOfCourtSport: string[] = [];
  let tempArrOfCourtSportIds: number[] = [];
  const coachInfo = data.data.coach_info;

  if (data.data.coach_info.court_sports) {
    tempArrayOfCourtSport = getArrayOfCourtSportsStrings(
      data.data.coach_info?.court_sports
    ).courtSportStrings;
    tempArrOfCourtSportIds = getArrayOfCourtSportsStrings(
      data.data.coach_info?.court_sports
    ).courtSportIds;
  }
  let tableData = [
    { name: 'name_and_surname', value: data.data.name },
    { name: 'email', value: data.data.email },
    { name: 'phone_number', value: data.data.phone },
    {
      name: 'coachesPage.create.appointment_date_from',
      value: data.data.coach_info.date_start
    },
    {
      name: 'coachesPage.create.appointment_date_to',
      value: data.data.coach_info.date_end
    },
    { name: 'coachesPage.create.number_of_hours', value: data.data.coach_info.assigned_hours },
    { name: 'cancel_policy', value: data.data.coach_info.cancellation_policy },
    {
      name: 'fieldsAndSports',
      value: tempArrayOfCourtSport.join('; ')
    },
    { name: 'price', value: data.data.coach_info.price_per_hour }
  ];
  let editFormData = {
    user_info: {
      full_name: data.data.name,
      email: data.data.email,
      phone: data.data.phone
    },
    coach_info: {
      date_start: dayjs(coachInfo.date_start, 'DD.MM.YYYY'),
      date_end: dayjs(coachInfo.date_end, 'DD.MM.YYYY'),
      assigned_hours: coachInfo.assigned_hours,
      cancellation_policy: coachInfo.cancellation_policy,
      price_per_hour: coachInfo.price_per_hour
    },
    court_sport_ids: tempArrOfCourtSportIds
  };
  return {
    tableData,
    editFormData
  };
};

export const checkPermission = (currentCMS: CurrentCMSType | null, permission: PermissionsEnum) => {
  if (currentCMS?.role === RoleEnum.SUPERADMIN) return true;
  if (currentCMS?.role === RoleEnum.ADMIN) return true;
  if (currentCMS?.role === RoleEnum.COACH) return true;

  return currentCMS?.permissions?.find(p => p === permission);
};

export const checkPermissionFunc = (
  currentCMS: CurrentCMSType | null,
  permission: PermissionsEnum,
  func: () => void
) => {
  if (currentCMS?.role === RoleEnum.SUPERADMIN) return func;
  if (currentCMS?.role === RoleEnum.ADMIN) return func;
  if (currentCMS?.role === RoleEnum.COACH) return func;

  if (currentCMS?.permissions.find(p => p === permission)) return func;
  return undefined;
};

export const getInputType = (key: string) => {
  if (
    key === 'user_info.gender' ||
    key === 'sector' ||
    key === 'cooperation_info.employee_position_id' ||
    key === 'user_info.role_id'
  ) {
    return 'select';
  }

  if (key === 'cooperation_info.salary') {
    return 'number';
  }

  if (key === 'documents') {
    return 'custom';
  }

  return 'text';
};

export const getDataFromRecurringAppointment = (
  data: RecurringAppointment,
  t: TFunction
): {
  day: string;
  timeEnd: string;
  arrayOfDays: string[];
  isItWorkingWeek: boolean;
  isItWeekend: boolean;
  isItWholeWeek: boolean;
} => {
  let day: string = '';

  let timeEnd = dayjs(`2023-11-02 ${data.time_start}:00`)
    .add(Number(data.minutes), 'minute')
    .format('HH:mm');

  const arrayOfDays: string[] = [];
  const isItWorkingWeek: boolean = data.mon && data.tue && data.wed && data.thu && data.fri;
  const isItWeekend: boolean = data.sat && data.sun;
  const isItWholeWeek: boolean = isItWeekend && isItWorkingWeek;
  const dataKeys = Object.keys(data) as (keyof typeof data)[];
  const dayKeys: workingDaysArrType[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  if (isItWholeWeek) {
    arrayOfDays.push(t('wholeweek'));
  } else if (isItWeekend && !isItWorkingWeek) {
    arrayOfDays.push(t('weekend'));
    dataKeys.map(key => {
      if (
        (key === 'mon' || key === 'tue' || key === 'wed' || key === 'thu' || key === 'fri') &&
        data[key]
      ) {
        dayKeys.map(day => {
          day === key && data[key] && arrayOfDays.push(t(day));
        });
      }
    });
  } else if (!isItWeekend && isItWorkingWeek) {
    arrayOfDays.push(t('workweek'));
    dataKeys.map(key => {
      if ((key === 'sat' || key === 'sun') && data[key]) {
        dayKeys.map(day => {
          day === key && data[key] && arrayOfDays.push(t(day));
        });
      }
    });
  } else {
    dataKeys.map(key => {
      dayKeys.map(day => {
        day === key && data[key] && arrayOfDays.push(t(day));
      });
    });
  }
  [];
  return {
    timeEnd,
    day,
    arrayOfDays,
    isItWorkingWeek,
    isItWeekend,
    isItWholeWeek
  };
};

export const convertStringTimeIntoNumber = (str: string) => {
  if (str?.charAt(0) === '0') {
    str = str?.charAt(1);
  }
  if (str?.charAt(0) !== '0') {
    str = str?.substring(0, 2);
  }
  return str;
};

export const getDuartionTimeForAppointmentBasedOnStartTime = (
  timePickerTimes: {
    minTime: number;
    maxTime: number;
  },
  selectedTime: string
) => {
  let duartionResult: TimePricesType[] = [];
  if (
    timePickerTimes.maxTime - +selectedTime.substring(0, 2) > 3 ||
    (timePickerTimes.maxTime - +selectedTime.substring(0, 2) === 3 && +selectedTime.charAt(3) !== 3)
  ) {
    duartionResult = [
      { minutes: 30, price: '' },
      { minutes: 60, price: '' },
      { minutes: 90, price: '' },
      { minutes: 120, price: '' },
      { minutes: 150, price: '' },
      { minutes: 180, price: '' }
    ];
  } else if (
    timePickerTimes.maxTime - +selectedTime.substring(0, 2) === 3 &&
    +selectedTime.charAt(3) === 3
  ) {
    duartionResult = [
      { minutes: 30, price: '' },
      { minutes: 60, price: '' },
      { minutes: 90, price: '' },
      { minutes: 120, price: '' },
      { minutes: 150, price: '' }
    ];
  } else if (
    timePickerTimes.maxTime - +selectedTime.substring(0, 2) === 2 &&
    +selectedTime.charAt(3) === 3
  ) {
    duartionResult = [
      { minutes: 30, price: '' },
      { minutes: 60, price: '' },
      { minutes: 90, price: '' }
    ];
  } else if (
    timePickerTimes.maxTime - +selectedTime.substring(0, 2) === 2 &&
    +selectedTime.charAt(3) !== 3
  ) {
    duartionResult = [
      { minutes: 30, price: '' },
      { minutes: 60, price: '' },
      { minutes: 90, price: '' },
      { minutes: 120, price: '' }
    ];
  } else if (
    timePickerTimes.maxTime - +selectedTime.substring(0, 2) === 1 &&
    +selectedTime.charAt(3) === 3
  ) {
    duartionResult = [{ minutes: 30, price: '' }];
  } else if (
    timePickerTimes.maxTime - +selectedTime.substring(0, 2) === 1 &&
    +selectedTime.charAt(3) !== 3
  ) {
    duartionResult = [
      { minutes: 30, price: '' },
      { minutes: 60, price: '' }
    ];
  } else {
    duartionResult = [];
  }
  return duartionResult;
};

export const getStartTimeForAppointmentBasedOnTheGivenDate = (
  date: Dayjs,
  calendarWorkingHours: CalendarWorkingHoursType
) => {
  let availableStartTimes: { minTime: number; maxTime: number } = { minTime: 0, maxTime: 0 };
  if (date.get('d') >= 1 && date.get('d') <= 5 && calendarWorkingHours.workingHours) {
    availableStartTimes = {
      minTime: +convertStringTimeIntoNumber(calendarWorkingHours.workingHours?.workweek_from),
      maxTime: +convertStringTimeIntoNumber(calendarWorkingHours.workingHours?.workweek_to)
    };
  } else if (date.get('d') === 6 && calendarWorkingHours.workingHours) {
    availableStartTimes = {
      minTime: +convertStringTimeIntoNumber(calendarWorkingHours.workingHours?.saturday_from),
      maxTime: +convertStringTimeIntoNumber(calendarWorkingHours.workingHours?.saturday_to)
    };
  } else if (date.get('d') === 0 && calendarWorkingHours.workingHours) {
    availableStartTimes = {
      minTime: +convertStringTimeIntoNumber(calendarWorkingHours.workingHours?.sunday_from),
      maxTime: +convertStringTimeIntoNumber(calendarWorkingHours.workingHours?.sunday_to)
    };
  }
  return availableStartTimes;
};

export function getTranslateName(
  lng: string | null,
  objectTranslates: { en: string; sr: string } | undefined,
  name: string
) {
  let result = '';
  if (lng === 'en' && objectTranslates?.en) {
    result = objectTranslates.en;
  } else if ((!lng || lng === 'sr') && objectTranslates?.sr) {
    result = objectTranslates.sr;
  } else {
    result = name;
  }
  return result;
}

export function getTranslateArray(
  lng: string | null,
  objectTranslates: { en: string[]; sr: string[] } | undefined,
  backupArray?: string[] | null
) {
  let result: string[] | null = [];
  if (lng === 'en' && objectTranslates?.en && objectTranslates?.en?.length > 0) {
    result = objectTranslates.en;
  } else if ((!lng || lng === 'sr') && objectTranslates?.sr && objectTranslates?.sr?.length > 0) {
    result = objectTranslates.sr;
  } else {
    result = backupArray ? backupArray : [];
  }
  return result;
}

export const getCourtSport = (courts: CourtType[]): CourtSportType[] => {
  const lng = localStorage.getItem('lng');
  return courts
    ?.map(c => {
      return c.court_sports.map(court_sport => {
        const custom_fields_data: SportCustomFieldsRequest = {};

        if (court_sport.sport.court_custom_fields) {
          court_sport.sport.court_custom_fields.forEach(element => {
            custom_fields_data[element.id.toString()] = element.value;
          });
        }

        return {
          courtId: c.id.toString(),
          courtName: c.name,
          roof_status: c.roof_status.label,
          sportName: getTranslateName(
            lng,
            court_sport.sport.name_translates,
            court_sport.sport.name
          ),
          sportId: court_sport.sport_id.toString(),
          custom_fields_data: custom_fields_data,
          max_simultaneous_appointments: court_sport.max_simultaneous_appointments
        };
      });
    })
    .flat(1);
};
export const getColorOfAppointmentStatus = (
  appointmentStatus: string
): 'success' | 'error' | 'primary' | 'warning' | 'default' => {
  switch (appointmentStatus) {
    case 'scheduled':
      return 'warning';
    case 'in_progress':
      return 'primary';
    case 'completed':
      return 'success';
    case 'canceled':
    case 'pending':
    case 'unrealized':
      return 'error';
    default:
      return 'default';
  }
};

export const getSlotTimeBasedOnSelectedDay = (
  fromDateCalendar: string,
  calendarWorkingHours: CalendarWorkingHoursType
) => {
  if (dayjs(fromDateCalendar).day() === 0) {
    return {
      from: calendarWorkingHours.workingHours?.sunday_from,
      to: calendarWorkingHours.workingHours?.sunday_to
    };
  } else if (dayjs(fromDateCalendar).day() === 6) {
    return {
      from: calendarWorkingHours.workingHours?.saturday_from,
      to: calendarWorkingHours.workingHours?.saturday_to
    };
  } else {
    return {
      from: calendarWorkingHours.workingHours?.workweek_from,
      to: calendarWorkingHours.workingHours?.workweek_to
    };
  }
};

let workingHoursKeys: (keyof WorkingHoursType)[] = [];
export const allDayObjectValuesResponse = (res: SportCenterResponse) => {
  workingHoursKeys = Object.keys(res.data.working_hours) as (keyof typeof res.data.working_hours)[];
  workingHoursKeys.map((key: keyof typeof res.data.working_hours) => {
    if (key != 'id' && res.data.working_hours[key] === '23:59') {
      res.data.working_hours[key] = '24:00';
    }
    if (key != 'id' && res.data.working_hours[key] === null) {
      res.data.working_hours[key] = '00:00';
    }
  });
  return res.data.working_hours;
};

export const allDayObjectValuesRequest = (req: SportCenterResponseType) => {
  if (req.working_hours.workweek_to === '24:00') {
    req.working_hours.workweek_to = '23:59';
  }
  if (req.working_hours.saturday_to === '00:00') {
    req.working_hours.saturday_from = '';
    req.working_hours.saturday_to = '';
  }
  if (req.working_hours.saturday_to === '24:00') {
    req.working_hours.saturday_to = '23:59';
  }
  if (req.working_hours.sunday_to === '00:00') {
    req.working_hours.sunday_from = '';
    req.working_hours.sunday_to = '';
  }
  if (req.working_hours.sunday_to === '24:00') {
    req.working_hours.sunday_to = '23:59';
  }
  return req.working_hours;
};

export const randomColorsForGraphs = [
  '#23E5A8',
  '#299CF3',
  '#FC6075',
  '#6366F1',
  '#FABD4E',
  '#FFC107',
  '#2196F3',
  '#FF9800',
  '#00BCD4',
  '#9E9E9E',
  '#FFEB3B',
  '#8BC34A',
  '#FF5722',
  '#03A9F4',
  '#CDDC39',
  '#673AB7',
  '#795548',
  '#FF5252',
  '#00E676'
];
