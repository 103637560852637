export enum AppointmentStatusEnum {
  scheduled = 'scheduled',
  canceled = 'canceled',
  in_progress = 'in_progress',
  completed = 'completed',
  pending = 'pending',
  unrealized = 'unrealized'
}

export enum AppointmentReservedFromEnum {
  cms = 'cms',
  client = 'client'
}

export enum AppointmentType {
  regular = 'regularAppointment',
  recurring = 'recurringAppointment'
}
