import React from 'react';
//components
import { Avatar, Card, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
//icons
import {
  DeleteRowIcon,
  DotsInCircleIcon,
  LocationIcon,
  RemoveIcon,
  AboutViewIcon,
  AddImageIcon
} from '@components/icons/icons';
//translation
import { useTranslation } from 'react-i18next';

type ProfileInfoCardPropsType = {
  name: string;
  lastSeen?: string;
  image?: string;
  location?: string;
  otherInfo: {
    title: string;
    value: string;
    textColor?: string;
  }[];
  handleDeactivate?: () => void;
  handleDelete?: () => void;
  handleAccess?: () => void;
  isUser?: boolean;
};

const ProfileInfoCard = ({
  name,
  lastSeen,
  image,
  otherInfo,
  location,
  handleDeactivate,
  handleDelete,
  handleAccess,
  isUser
}: ProfileInfoCardPropsType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card
      sx={{
        display: 'flex',
        gap: 5,
        flexDirection: { xs: 'column', sm: 'row' },
        position: 'relative',
        border: 'none'
      }}
    >
      <Avatar
        src={image}
        alt={'logo'}
        sx={{ m: 2, width: '170px', height: '170px', background: '#f5f5f5' }}
      >
        {' '}
      </Avatar>

      {!image && (
        <Box position={'absolute'} top={60} left={70}>
          <AddImageIcon />
        </Box>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <CardContent>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'}>
              <Typography component="div" variant="h5">
                {name}
              </Typography>

              {location && (
                <Box display={'flex'} ml={1} mt={1}>
                  <LocationIcon />
                  <Typography variant="subtitle2" color="text.secondary" component="div">
                    {location}
                  </Typography>
                </Box>
              )}
            </Box>

            {!isUser && (
              <Box>
                <IconButton onClick={handleClick}>
                  <DotsInCircleIcon />
                </IconButton>

                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                  {handleAccess && (
                    <MenuItem onClick={handleAccess}>
                      <ListItemIcon>
                        <AboutViewIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('accessObject')}</Typography>
                    </MenuItem>
                  )}

                  {handleDelete && (
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <RemoveIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('delete')}</Typography>
                    </MenuItem>
                  )}

                  {handleDeactivate && (
                    <MenuItem onClick={handleDeactivate}>
                      <ListItemIcon>
                        <DeleteRowIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">{t('deactivate')}</Typography>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            )}
          </Box>

          <Typography variant="subtitle2" color="primary" component="div">
            {lastSeen}
          </Typography>
          {otherInfo.map((el, index) => (
            <Typography
              key={index}
              variant="subtitle2"
              color={el.textColor ? el.textColor : 'text.secondary'}
              component="div"
            >
              {el.title} : {el.value}
            </Typography>
          ))}
        </CardContent>
      </Box>
    </Card>
  );
};

export default ProfileInfoCard;
