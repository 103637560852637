//react
import { FormEvent, useState } from 'react';
//mui
import Button from '@mui/material/Button';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  InputAdornment,
  IconButton,
  FormHelperText,
  Typography
} from '@mui/material';
//form hooks
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//icons
import { VisibleOffIcon, VisibleOnIcon } from '@components/icons/icons';
//type
import { ResetPasswordFormRequestType } from '@interfaces/user/login';

type ResetPasswordFormPropsType = {
  onSubmit: (ev: FormEvent<any>) => void;
  control: Control<ResetPasswordFormRequestType>;
  isLoading: boolean;
};

const ResetPasswordForm = ({ onSubmit, control, isLoading }: ResetPasswordFormPropsType) => {
  const { t: translation } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);

  return (
    <Box p={{ xs: 0, sm: 3 }}>
      <Typography
        variant="h4"
        sx={{
          textAlign: 'left',
          letterSpacing: '-1.5px',
          fontWeight: 800,
          mb: 4
        }}
      >
        {translation('setPassword.title')}
      </Typography>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Box
          component={'form'}
          display={'flex'}
          flexDirection={'column'}
          width={'100%'}
          gap={2}
          onSubmit={onSubmit}
        >
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl>
                <InputLabel error={invalid}>{translation('setPassword.password')}</InputLabel>
                <OutlinedInput
                  label={translation('setPassword.password')}
                  type={showPassword ? 'text' : 'password'}
                  sx={{ background: '#F5F7FA' }}
                  {...field}
                  error={invalid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(show => !show)} edge="end">
                        {showPassword ? <VisibleOnIcon /> : <VisibleOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="password_confirmation"
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl>
                <InputLabel error={invalid}>
                  {translation('setPassword.password_confirmation')}
                </InputLabel>
                <OutlinedInput
                  label={translation('setPassword.password_confirmation')}
                  type={showPassword ? 'text' : 'password'}
                  sx={{ background: '#F5F7FA' }}
                  {...field}
                  error={invalid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(show => !show)} edge="end">
                        {showPassword ? <VisibleOnIcon /> : <VisibleOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            type="submit"
            disabled={isLoading}
            sx={{ height: '56px', fontWeight: 700, fontSize: '16px' }}
          >
            {translation('confirm')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordForm;
