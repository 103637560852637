//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

//interfaces
import {
  Employee,
  EmployeeResponseType,
  WholeEmployeesResponse,
  AdminEmployeeResponseType,
  CreateAdminEmployeeRequestType,
  CreateAdminEmployeeErrorResponseType,
  AdminEmployeeType,
  SingleAdminEmployeeResponseType,
  AdminEmployeeErrorRessponseType
} from '@interfaces/employees/employees';

//service
import {
  employees,
  singleEmployee,
  getSportCenterEmployees,
  getSingleSportCenterEmployee
} from '@services/cooperation/employee/employee';

export const useEmployees = (
  pageIndex: number,
  pageSize: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string,
  onError: (err: Error) => void
): UseQueryResult<WholeEmployeesResponse, Error> =>
  useQuery(
    ['employees', pageIndex, pageSize, search, gender, order_by, order_direction],
    () => employees(pageIndex, pageSize, search, gender, order_by, order_direction),
    {
      onError: err => onError(err)
    }
  );

export const useSingleEmployee = (
  id: number,
  onSuccess: (data: Employee) => void,
  onError: (err: Error) => void
): UseQueryResult<EmployeeResponseType, Error> =>
  useQuery(['singleEmployee'], () => singleEmployee(id), {
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err)
  });

export const useSportCenterEmployees = (
  sportCenterId: number,
  pageIndex?: number,
  pageSize?: number,
  search?: string,
  onError?: (err: Error) => void
): UseQueryResult<AdminEmployeeResponseType, Error> =>
  useQuery(
    ['sportCenterEmployees', sportCenterId, pageIndex, pageSize, search],
    () => getSportCenterEmployees(sportCenterId, pageIndex, pageSize, search),
    {
      onError: err => onError && onError(err)
    }
  );

export const useSingleSportCenterEmployee = (
  req: Omit<CreateAdminEmployeeRequestType, 'data'> & {
    employeeMembershipId: number;
  },
  onSuccess?: (data: AdminEmployeeType) => void,
  onError?: (err: CreateAdminEmployeeErrorResponseType) => void
): UseQueryResult<SingleAdminEmployeeResponseType> =>
  useQuery(['singleSportCenterEmployee', req], () => getSingleSportCenterEmployee(req), {
    onSuccess: data => onSuccess && onSuccess(data.data.data),
    onError: (err: AdminEmployeeErrorRessponseType) => onError && onError(err.response.data),
    enabled: req?.employeeMembershipId && req?.sportCenterId ? true : false
  });
