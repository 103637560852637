// hooks
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
// components
import {
  Typography,
  TextField,
  Box,
  FormControl,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputAdornment
} from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
// types
import { Control } from 'react-hook-form';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
//helpers
import { handleChecked } from '@src/helpers/utility';
//mock
import { payments } from '@src/__mock__/createSportsCenter/steps';

type PaymentProps = {
  control: Control<CreateSportCenterType>;
  isPaymentChecked: string[];
  setIsPaymentChecked: (payment: string[]) => void;
  reqMessage: string;
};
const Payment = ({ control, isPaymentChecked, setIsPaymentChecked, reqMessage }: PaymentProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      {/* TODO: return thi after MVP */}
      {/* <CardWrapper
        isForm={true}
        title={
          <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
            {t('payment')}
          </Typography>
        }
        subtitle={
          <Typography variant="subtitle2" color="GrayText" sx={{ pb: 2 }}>
            {t('sportCenters.stepOne.payment.subtitle')}
          </Typography>
        }
      > */}
      {/* <Box>
          <FormControl> */}

      {/* <FormLabel>{t('package')}</FormLabel>
            <Controller
              name="package"
              control={control}
              // rules={{ required: reqMessage }}
              render={({ field, fieldState: { error } }) => (
                <RadioGroup defaultValue={'gold'} {...field}>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                  <FormControlLabel value="bronzani" control={<Radio />} label={t('bronze')} />
                  <FormControlLabel value="silver" control={<Radio />} label={t('silver')} />
                  <FormControlLabel value="gold" control={<Radio />} label={t('gold')} />
                </RadioGroup>
              )}
            /> */}

      {/* <Controller
              name="fee_percentage"
              control={control}
              rules={{ required: reqMessage }}
              render={({ field, fieldState: { error, invalid } }) => (
                <TextField
                  {...field}
                  label={t('fee_percentage')}
                  type="number"
                  variant="outlined"
                  sx={{ width: '100%', mt: 1 }}
                  required
                  InputProps={{
                    startAdornment: <InputAdornment position="start">%</InputAdornment>
                  }}
                  error={invalid}
                />
              )}
            />
          </FormControl>
        </Box> */}
      {/* </CardWrapper> */}

      <CardWrapper
        isForm={true}
        title={
          <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
            {t('sportCenters.stepOne.payment.optionTitle')}
          </Typography>
        }
        subtitle={
          <Typography variant="subtitle2" color="GrayText" sx={{ pb: 2 }}>
            {t('sportCenters.stepOne.payment.optionSubtitle')}
          </Typography>
        }
      >
        <Controller
          name="payment_methods"
          control={control}
          defaultValue={[]}
          rules={{ required: reqMessage }}
          render={({ field, fieldState: { error, invalid } }) => (
            <>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
              <FormGroup>
                {payments.map((payment, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      {...field}
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          color: `${invalid ? 'red' : '#6B7280'}`
                        }
                      }}
                      control={
                        <Checkbox
                          onChange={e =>
                            field.onChange(handleChecked(e, isPaymentChecked, setIsPaymentChecked))
                          }
                          checked={isPaymentChecked.includes(payment)}
                          name={payment}
                          value={payment}
                        />
                      }
                      label={t(
                        payment === 'cash' ? payment : 'sportCenters.stepOne.payment.' + payment
                      )}
                    />
                  );
                })}
              </FormGroup>
            </>
          )}
        />
      </CardWrapper>
    </Box>
  );
};
export default Payment;
