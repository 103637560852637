//components
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import Button from '@mui/material/Button';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  InputAdornment,
  IconButton,
  FormHelperText,
  Typography,
  Alert
} from '@mui/material';
//form hooks
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
//hooks
import { useChangePasswordHook } from '@hooks/useChangePassword/useChangePasswordHook';
import { useNavigate, useParams } from 'react-router-dom';

const ChangePasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    onSubmit,
    handleSubmit,
    errors,
    success,
    control,
    isValid,
    showPassword,
    setShowPassword
  } = useChangePasswordHook();

  const params = useParams();

  return (
    <Box p={2} component={'form'} onSubmit={handleSubmit(onSubmit)}>
      {success && (
        <Alert severity="success" sx={{ position: 'fixed', zIndex: 200, width: '70%' }}>
          {t('changePassword.successMessage')}
        </Alert>
      )}
      <CardWrapper title={t('changePassword.title')}>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Typography color={'error'}>{errors.root?.message}</Typography>
          <Controller
            name="old_password"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl>
                <InputLabel error={invalid}>{t('changePassword.old_password') + ' *'}</InputLabel>
                <OutlinedInput
                  label={t('changePassword.old_password') + ' *'}
                  type={showPassword === 0 ? 'text' : 'password'}
                  {...field}
                  error={invalid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(show => (show === 0 ? -1 : 0))}
                        edge="end"
                      >
                        {showPassword === 0 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />

          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl>
                <InputLabel error={invalid}>{t('changePassword.new_password') + ' *'}</InputLabel>
                <OutlinedInput
                  label={t('changePassword.new_password') + ' *'}
                  type={showPassword === 1 ? 'text' : 'password'}
                  {...field}
                  error={invalid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(show => (show === 1 ? -1 : 1))}
                        edge="end"
                      >
                        {showPassword === 1 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
          <Controller
            name="password_confirmation"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl>
                <InputLabel error={invalid}>
                  {t('changePassword.password_confirmation') + ' *'}
                </InputLabel>
                <OutlinedInput
                  label={t('changePassword.password_confirmation') + ' *'}
                  type={showPassword === 2 ? 'text' : 'password'}
                  {...field}
                  error={invalid}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(show => (show === 2 ? -1 : 2))}
                        edge="end"
                      >
                        {showPassword === 2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                <FormHelperText color="error">{error?.message}</FormHelperText>
              </FormControl>
            )}
          />
        </Box>
      </CardWrapper>
      <Box display={'flex'} justifyContent={'flex-end'} mt={2}>
        <Button
          onClick={() =>
            navigate('/settings' + (params.sportCenterId ? `/${params.sportCenterId}` : ''))
          }
        >
          {t('cancel')}
        </Button>
        <Button type="submit" disabled={!isValid} variant="contained">
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePasswordPage;
