// hooks
import { useTranslation } from 'react-i18next';
import useCoachesSettingsHook from '@hooks/adminCoaches/useCoachesSettingsHook';

// components
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Box,
  LinearProgress
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Logo from '@components/Forms/SportCenters/Logo/Logo';
import Loading from '@components/Loading/Loading';

const CoachSettingsProfilePage = () => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    handleCancel,
    controlCoachProfile,
    handleOnProfilePhotoChange,
    handleProfilePhotoClick,
    isValidCoachProfile,
    uploadPercent,
    isLoadingMe,
    isLoadingUpdateUser,
    isLoadingDeleteProfilePhoto,
    isLoadingProfilePhoto
  } = useCoachesSettingsHook();

  return (
    <Box p={3} component="form" onSubmit={handleSubmit}>
      <Card sx={{ py: 5, px: '15%' }}>
        <CardHeader
          title={t('userProfile.coach_settings_title')}
          subheader={t('userProfile.coach_settings_subtitle')}
        />
        {isLoadingMe && isLoadingUpdateUser ? (
          <Loading />
        ) : (
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Controller
                  name="first_name"
                  control={controlCoachProfile}
                  defaultValue=""
                  rules={{ required: t('errorMessage').toString() }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      error={invalid}
                      {...field}
                      label={t('name') + ' *'}
                      variant="outlined"
                      sx={{ width: '100%' }}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="last_name"
                  control={controlCoachProfile}
                  defaultValue=""
                  rules={{ required: t('errorMessage').toString() }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      error={invalid}
                      {...field}
                      label={t('surname') + ' *'}
                      variant="outlined"
                      sx={{ width: '100%' }}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="email"
                  control={controlCoachProfile}
                  defaultValue=""
                  rules={{ required: t('errorMessage').toString() }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      error={invalid}
                      label={t('email') + ' *'}
                      type="email"
                      placeholder="email@mail.com"
                      variant="outlined"
                      sx={{ width: '100%' }}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="phone"
                  control={controlCoachProfile}
                  defaultValue=""
                  rules={{ required: t('errorMessage').toString() }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      {...field}
                      type="phone"
                      label={t('phone_number') + ' *'}
                      placeholder="+3816xxxxxxx"
                      variant="outlined"
                      sx={{ width: '100%' }}
                      error={invalid}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Logo
                  control={controlCoachProfile}
                  name="profile_photo"
                  title={t('profile_photo').toString()}
                  handleLogoClick={handleProfilePhotoClick}
                  handleOnLogoChange={handleOnProfilePhotoChange}
                />
                {uploadPercent > 0 &&
                  uploadPercent < 100 &&
                  isLoadingDeleteProfilePhoto &&
                  isLoadingProfilePhoto && (
                    <LinearProgress value={uploadPercent} valueBuffer={uploadPercent} />
                  )}
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
      <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
        <Button variant="text" color="primary" onClick={handleCancel}>
          {t('quit')}
        </Button>

        <Button type="submit" variant="contained" disabled={!isValidCoachProfile}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default CoachSettingsProfilePage;
