//router
import { RouterProvider } from 'react-router-dom';
import { routerConfig } from './router/router';
//localization
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
//components
import CustomPopup from '@components/Popup/CustomPopup';
import Loading from '@components/Loading/Loading';
//style
import { ThemeProvider } from '@mui/material';
import theme from './theme';
import './app.css';
import '@styles/components/globals.scss';
//recoil
import { useRecoilValue } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';

function App() {
  const popup = useRecoilValue(popupAtom);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        {popup.open && popup.title && <CustomPopup />}
        <RouterProvider router={routerConfig} />
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
