// types
import { Control } from 'react-hook-form';
import { CreateCoachRequestType } from '@interfaces/user/coaches/coaches';
import { TFunction } from 'i18next';
// hooks
import { useParams } from 'react-router-dom';
// components
import {
  Grid,
  TextField,
  FormHelperText,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
// dayjs
import dayjs from 'dayjs';
// mocks
import { cancel_policy } from '@src/__mock__/adminCoaches/adminCoaches';

type AddCoachesBasicInfoProps = {
  control: Control<CreateCoachRequestType>;
  t: TFunction;
  isEditHours?: boolean;
};

const AddCoachesBasicInfo = ({ control, t, isEditHours }: AddCoachesBasicInfoProps) => {
  const params = useParams();
  const coachId: boolean | undefined = params.coachId ? true : undefined;
  return (
    <Grid container spacing={2}>
      {!isEditHours && (
        <>
          <Grid item xs={12}>
            <Controller
              name="user_info.full_name"
              control={control}
              defaultValue=""
              rules={{ required: t('errorMessage').toString() }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  error={invalid}
                  {...field}
                  label={t('name') + ' ' + t('and') + ' ' + t('surname') + ' *'}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="user_info.email"
              control={control}
              defaultValue=""
              rules={{ required: t('errorMessage').toString() }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  error={invalid}
                  label={t('email') + ' *'}
                  type="email"
                  placeholder="email@mail.com"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Controller
              name="user_info.phone"
              control={control}
              defaultValue=""
              rules={{ required: t('errorMessage').toString() }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  type="phone"
                  label={t('phone_number') + ' *'}
                  placeholder="+3816xxxxxxx"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error?.message}
                />
              )}
            />
          </Grid>
        </>
      )}
      <Grid item xs={12} md={6}>
        <Controller
          name="coach_info.date_start"
          control={control}
          rules={{ required: t('errorMessage').toString() }}
          defaultValue={dayjs()}
          render={({ field, fieldState: { invalid, error } }) => (
            <>
              <DatePicker
                label={t('coachesPage.create.appointment_date_from') + ' *'}
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                disabled={coachId && !isEditHours}
                {...field}
              />
              {error && <FormHelperText color="error">{error.message}</FormHelperText>}
            </>
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="coach_info.date_end"
          control={control}
          rules={{ required: t('errorMessage').toString() }}
          defaultValue={dayjs()}
          render={({ field, fieldState: { invalid, error } }) => (
            <>
              <DatePicker
                label={t('coachesPage.create.appointment_date_to') + ' *'}
                format="DD/MM/YYYY"
                sx={{ width: '100%' }}
                disabled={coachId && !isEditHours}
                {...field}
              />
              {error && <FormHelperText color="error">{error.message}</FormHelperText>}
            </>
          )}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Controller
          name="coach_info.assigned_hours"
          control={control}
          rules={{ required: t('errorMessage').toString() }}
          defaultValue={0}
          render={({ field, fieldState: { error, invalid } }) => (
            <TextField
              {...field}
              type="number"
              label={t('coachesPage.create.number_of_hours') + ' *'}
              variant="outlined"
              error={invalid}
              helperText={error?.message}
              disabled={coachId && !isEditHours}
              onChange={e => field.onChange(!e.target.value ? undefined : parseInt(e.target.value))}
              sx={{ width: '100%' }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="coach_info.cancellation_policy"
          control={control}
          defaultValue=""
          rules={{ required: t('errorMessage').toString() }}
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl className="fullWidth">
              <InputLabel error={invalid}>{t('cancel_policy') + ' *'}</InputLabel>
              <Select
                {...field}
                label={t('cancel_policy') + ' *'}
                variant="outlined"
                error={invalid}
                disabled={coachId && !isEditHours}
              >
                {cancel_policy.map(canPo => (
                  <MenuItem key={canPo} value={canPo}>
                    {canPo + 'h'}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AddCoachesBasicInfo;
