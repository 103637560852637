// interfaces
import {
  AdminEmployeesPermisionsType,
  CreateAdminEmployeeType
} from '@interfaces/employees/employees';
import { TFunction } from 'i18next';

// components
import {
  Card,
  Grid,
  CardHeader,
  CardContent,
  TextField,
  Button,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Box,
  ListItemIcon
} from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
// icons
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
// theme
import theme from '@src/theme';
import { RoleType } from '@interfaces/roles/roles';

type CreateAdminEmployeFormProps = {
  control: Control<CreateAdminEmployeeType>;
  t: TFunction;
  sportCenterId: number | string;
  sportCenterRoles: RoleType[];
  isEditOpen?: boolean;
};

const CreateAdminEmployeeForm = ({
  control,
  t,
  sportCenterId,
  sportCenterRoles,
  isEditOpen = false
}: CreateAdminEmployeFormProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          name="user_info.full_name"
          control={control}
          defaultValue=""
          rules={{ required: t('errorMessage').toString() }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              error={invalid}
              {...field}
              label={t('name_and_surname') + ' *'}
              variant="outlined"
              sx={{ width: '100%' }}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="user_info.email"
          control={control}
          defaultValue=""
          rules={{ required: t('errorMessage').toString() }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              error={invalid}
              label={t('email') + ' *'}
              type="email"
              placeholder="email@mail.com"
              variant="outlined"
              sx={{ width: '100%' }}
              helperText={error?.message}
              disabled={isEditOpen}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Controller
          name="user_info.phone"
          control={control}
          defaultValue=""
          rules={{ required: t('errorMessage').toString() }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              {...field}
              type="tel"
              label={t('phone_number') + ' *'}
              placeholder="+3816xxxxxxx"
              variant="outlined"
              sx={{ width: '100%' }}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="role_id"
          control={control}
          rules={{ required: t('errorMessage').toString() }}
          defaultValue=""
          render={({ field, fieldState: { invalid, error } }) => (
            <FormControl sx={{ width: '100%' }}>
              <InputLabel error={invalid}>{t('permissions') + ' *'}</InputLabel>
              <Select
                {...field}
                label={t('permissions') + ' *'}
                variant="outlined"
                fullWidth
                error={invalid}
              >
                <MenuItem>
                  <ListItemIcon>
                    <AddCircleOutlineRoundedIcon />
                  </ListItemIcon>
                  <NavLink
                    to={`/settings/${sportCenterId}/access/create-role`}
                    style={{ color: theme.palette.primary.main }}
                  >
                    {t('adminEmployeePage.categories.add_category')}
                  </NavLink>
                </MenuItem>
                {sportCenterRoles.map(role => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default CreateAdminEmployeeForm;
