//axios instance
import { SuccessResponse } from '@interfaces/apiResponse';
import axiosInstance from '@services/config';

export const postImpersonate = (userId: string): Promise<{ data: SuccessResponse }> =>
  axiosInstance.post(`/stateful/impersonate/${userId}`);

export const deleteImpersonate = (): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/stateful/impersonation`);

export const isImpersonating = (): Promise<{ data: { is_impersonating: boolean } }> =>
  axiosInstance.get(`/is-impersonating`);
