//recoil
import { useRecoilValue } from 'recoil';
//atoms
import { userAtom } from '@atoms/userAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
// enums
import { RoleEnum } from '@enum/roleEnum';
//components
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useMediaQuery
} from '@mui/material';
import CustomSelect from '@components/CustomSelect/CustomSelect';
//icons
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import PersonIcon from '@mui/icons-material/Person';
// import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
// import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
//colors
import theme, { greenSelectBgColor, greenSelectColor } from '@src/theme';
//hooks
import { useProfileMenu } from '@hooks/useProfileMenu/useProfileMenu';
import { useTranslation } from 'react-i18next';
import { red } from '@mui/material/colors';

const ProfileMenu = (): JSX.Element => {
  const user = useRecoilValue(userAtom);
  const currentCMS = useRecoilValue(currentCMSAtom);

  const { t } = useTranslation();

  const {
    handleChange,
    open,
    handleClick,
    anchorEl,
    handleClose,
    submitLogout,
    navigate,
    deleteImpersonate
  } = useProfileMenu();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box>
      {!!(
        user?.owned_sport_centers?.length ||
        user?.sport_centers_with_role.length ||
        user?.coach_in_sport_centers.length
      ) && (
        <CustomSelect
          value={currentCMS ? currentCMS.currentSportCenter : ''}
          onChange={handleChange}
          color={greenSelectColor}
          bgColor={greenSelectBgColor}
        >
          {!user.impersonating && (user.is_admin || user.role) && (
            <MenuItem value={'-1'} key={''}>
              superadmin
            </MenuItem>
          )}

          {user?.owned_sport_centers?.map(sc => (
            <MenuItem value={sc.id} key={sc.id}>
              {isMobile && sc.name.length > 11 ? `${sc.name.substring(0, 11)}..` : sc.name}
            </MenuItem>
          ))}
          {user?.sport_centers_with_role?.map(sc => (
            <MenuItem value={sc.id} key={sc.id}>
              {isMobile && sc.name.length > 11 ? `${sc.name.substring(0, 11)}..` : sc.name}
            </MenuItem>
          ))}
          {user?.coach_in_sport_centers?.map(sc => (
            <MenuItem value={sc.id} key={sc.id}>
              {isMobile && sc.name.length > 11 ? `${sc.name.substring(0, 11)}..` : sc.name}
            </MenuItem>
          ))}
        </CustomSelect>
      )}

      {user?.impersonating && (
        <Tooltip title={t('backToSuperadmin')}>
          <IconButton
            color="error"
            onClick={() => deleteImpersonate(undefined)}
            size="small"
            sx={{ mx: { xs: 1, md: 2 }, bgcolor: red[50], borderRadius: '4px' }}
          >
            <LogoutRoundedIcon color="error" />
          </IconButton>
        </Tooltip>
      )}
      {/* TODO: return this after MVP */}
      {/* <IconButton id="NotificationsRoundedIcon" data-testid="NotificationsRoundedIcon" size="small">
        <NotificationsRoundedIcon />
      </IconButton> */}
      <IconButton
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        id="PersonIcon"
        data-testid="PersonIcon"
      >
        <Avatar>
          {user?.first_name && !user?.last_name
            ? `${user?.first_name?.[0]}`
            : !user?.first_name && user?.last_name
            ? `${user?.last_name?.[0]}`
            : user?.first_name && user?.last_name
            ? `${user?.first_name?.[0]}${user?.last_name?.[0]}`
            : `${''}`}
        </Avatar>
      </IconButton>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{ right: 0 }}
      >
        <ListItem>
          <ListItemText
            primary={user?.first_name + ' ' + user?.last_name}
            secondary={user?.email}
          />
        </ListItem>
        <Divider />

        <MenuItem
          onClick={() => {
            navigate(
              currentCMS?.currentSportCenter
                ? `/settings/${currentCMS.currentSportCenter}`
                : '/settings'
            );
          }}
        >
          <ListItemIcon>
            <SettingsRoundedIcon />
          </ListItemIcon>
          {t('settings.settings')}
        </MenuItem>
        {currentCMS?.currentSportCenter && !user?.is_admin && (
          <MenuItem
            onClick={() =>
              navigate(
                currentCMS?.role === RoleEnum.COACH ||
                  currentCMS.role === RoleEnum.ADMIN_EMPLOYEE ||
                  currentCMS.role === RoleEnum.SUPERADMIN_EMPLOYEE
                  ? `/settings/${currentCMS.currentSportCenter}/profile`
                  : `/settings/${currentCMS.currentSportCenter}/object-profile`
              )
            }
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            {t('profile')}
          </MenuItem>
        )}

        <Divider />

        <MenuItem onClick={submitLogout} id="SignOutIcon" data-testid="SignOutIcon">
          <ListItemIcon>
            <LogoutRoundedIcon />
          </ListItemIcon>
          {t('log_out')}
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ProfileMenu;
