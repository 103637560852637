//hooks
import { useDropzone } from 'react-dropzone';
// components
import { Typography, IconButton, Button, LinearProgress, Box, Card } from '@mui/material';
// icons
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ClearIcon from '@mui/icons-material/Clear';
//translation
import { useTranslation } from 'react-i18next';
//type
import { ImageType } from '@interfaces/Images/images';

type FileUploadProps = {
  title: string;
  onDropCallback: (file: File) => void;
  uploadPercent: number;
  isLoading?: boolean;
  files: File[] | ImageType[];
  handleFileDelete: (file: File | ImageType) => void;
};

const FileUpload = ({
  title,
  onDropCallback,
  uploadPercent,
  isLoading,
  files,
  handleFileDelete
}: FileUploadProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [], 'application/pdf': [], 'application/doc': [], 'application/docx': [] },
    onDrop: acceptFiles =>
      acceptFiles.map(file => {
        onDropCallback(file);
      })
  });

  const { t } = useTranslation();

  const renderFiles =
    files &&
    files.length > 0 &&
    files.map((file, i) => (
      <Card key={i}>
        <Box
          width={'95%'}
          minHeight={70}
          display={'flex'}
          justifyContent="space-between"
          pt={1}
          alignItems={'center'}
        >
          <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            px={3}
          >
            <Typography>{'title' in file && file.title}</Typography>
            <Typography>{'name' in file && file.name}</Typography>
            {isLoading && (
              <Box>
                <Typography variant="body2" color="text.secondary">
                  loading...
                </Typography>
                <LinearProgress
                  color="primary"
                  variant="buffer"
                  value={uploadPercent}
                  valueBuffer={uploadPercent}
                  sx={{ width: '100%', mt: 1 }}
                />
              </Box>
            )}
          </Box>
          <IconButton onClick={() => (handleFileDelete ? handleFileDelete(file) : null)}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Card>
    ));

  return (
    <Box py={2}>
      <Typography variant="subtitle2" color="GrayText" sx={{ width: '100%', pb: '7px' }}>
        {title}
      </Typography>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '152px',
          border: '1px dashed rgba(0, 0, 0, 0.2)',
          borderRadius: '4px',
          '&:hover': {
            borderColor: 'blue',
            background: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <input {...getInputProps()} />
        <IconButton size="medium" color="primary">
          <UploadFileOutlinedIcon />
        </IconButton>
        <Typography variant="subtitle2">
          <Button variant="text" component="a" sx={{ textTransform: 'none' }}>
            {t('imageUpload.click')}
            <input {...getInputProps()} />
          </Button>
          {t('imageUpload.dragAndDrop')}
        </Typography>
        <Typography variant="subtitle2" color="GrayText">
          PNG, JPG, DOC, DOCX or PDF (max. 3MB)
        </Typography>
      </Box>

      <Box width={'100%'} display={'flex'} flexDirection="column" rowGap={1} pt={2}>
        {renderFiles}
      </Box>
    </Box>
  );
};

export default FileUpload;
