//interface
import { SportCenterResponse, WholeObjectsResponse } from '@interfaces/SportCenters/SportCenter';
//service
import { sportCenter, singleSportCenter } from '@services/sportCenter/sportCenter';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useSportCenter = (
  pageIndex: number,
  pageSize: number,
  search: string,
  payment_methods: string[],
  benefits: number[],
  fee_percentage_min: number | null | string,
  fee_percentage_max: number | null | string,
  order_by: string,
  order_direction: string,
  onError: (err: Error) => void
): UseQueryResult<WholeObjectsResponse, Error> =>
  useQuery(
    [
      'objects',
      pageIndex,
      pageSize,
      search,
      payment_methods,
      benefits,
      fee_percentage_min,
      fee_percentage_max,
      order_by,
      order_direction
    ],
    () =>
      sportCenter(
        pageIndex,
        pageSize,
        search,
        payment_methods,
        benefits,
        fee_percentage_min,
        fee_percentage_max,
        order_by,
        order_direction
      ),
    {
      onError: err => onError(err)
    }
  );

export const useSingleSportCenter = (
  id: string | undefined,
  onSuccess?: (data: SportCenterResponse | undefined) => void,
  onError?: (err: Error) => void
): UseQueryResult<SportCenterResponse | undefined, Error> =>
  useQuery(['single-sport-center', id], () => (id ? singleSportCenter(parseInt(id)) : null), {
    select: res => res?.data,
    onSuccess: data => onSuccess && onSuccess(data),
    onError: err => onError && onError(err)
  });
