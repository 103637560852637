//interfaces
import { PopupVariantType } from '@interfaces/popup';

export const boxStyle: PopupVariantType = {
  delete: {
    width: '30%'
  },
  success: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  warning: { width: '30%' },
  info: { width: '30%' },
  error: { width: '30%' }
};

export const dialogStyle: PopupVariantType = {
  delete: {
    display: 'flex',
    flexDirection: 'row'
  },
  success: {
    textAlign: 'center'
  },
  warning: { display: 'flex', flexDirection: 'row' },
  info: { display: 'flex', flexDirection: 'row' },
  error: { display: 'flex', flexDirection: 'row' }
};
