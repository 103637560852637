// components
import { Box, FormHelperText, Grid, Switch, Typography } from '@mui/material';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
//interface
import { CourtType, CreateCourtRequest } from '@src/domain/interfaces/court/court';
//hooks
import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';

type AdvancedCourtSettingsPropsType = {
  control: Control<CreateCourtRequest>;
};

export const AdvancedCourtSettings = ({ control }: AdvancedCourtSettingsPropsType) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
          {t('settings.advancedSettings')}
        </Typography>
      </Grid>

      <Grid item xs={12} sm={6} display={'flex'} alignItems={'center'}>
        <Controller
          name="allow_in_app_reservations"
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { invalid, error } }) => (
            <Box>
              <Switch {...field} checked={field.value} />
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </Box>
          )}
        />
        <Typography>{t('settings.advanced.allow_in_app_reservations')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'center'}>
        <Controller
          name="is_court_active"
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { invalid, error } }) => (
            <Box>
              <Switch {...field} checked={field.value} />
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </Box>
          )}
        />
        <Typography>{t('settings.advanced.is_court_active')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} display={'flex'} alignItems={'center'}>
        <Controller
          name="allow_available_slot_search"
          control={control}
          defaultValue={false}
          render={({ field, fieldState: { invalid, error } }) => (
            <Box>
              <Switch {...field} checked={field.value} />
              {error && <FormHelperText error>{error.message}</FormHelperText>}
            </Box>
          )}
        />
        <Typography>{t('settings.advanced.allow_available_slot_search')}</Typography>
      </Grid>
    </>
  );
};
