//react
import { Dispatch, FC, MouseEvent, SetStateAction } from 'react';
//icons
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
//mui
import { Checkbox, Grid, IconButton, Typography } from '@mui/material';
//interfaces
import { User } from '@interfaces/user/user';
//hooks
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
//react-router
import { NavLink } from 'react-router-dom';
//atoms
import { currentCMSAtom } from '@atoms/currentCMS';
//enums
import { RoleEnum } from '@enum/roleEnum';
//helpers
import { handleBenefitsChecked } from '@helpers/utility';
interface MobileUserRowProps {
  index: number;
  data: User;
  openMobileUserDropdown: number | null;
  handleToggleMobileDetails: (index: number) => void;
  handleClick?: (event: MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLDivElement>) => void;
  isUserChecked?: number[];
  setIsUserChecked?: Dispatch<SetStateAction<number[]>>;
}

const MobileUserRow: FC<MobileUserRowProps> = ({
  index,
  data,
  openMobileUserDropdown,
  handleToggleMobileDetails,
  handleClick,
  isUserChecked,
  setIsUserChecked
}): JSX.Element | null => {
  const { t } = useTranslation();
  const currentCMS = useRecoilValue(currentCMSAtom);
  return (
    <>
      <Grid
        item
        xs={12}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        borderBottom={'1px solid #D1D5DB'}
        height={'56px'}
      >
        <Grid item xs={10} display={'flex'} flexDirection={'row'} alignItems={'center'} ml={1}>
          <IconButton
            id="userInfoDropdown"
            data-testid="userInfoDropdown"
            onClick={() => handleToggleMobileDetails(index)}
          >
            {openMobileUserDropdown === index ? (
              <KeyboardArrowDownIcon sx={{ color: '#242A38' }} />
            ) : (
              <KeyboardArrowRightIcon sx={{ color: '#242A38' }} />
            )}
          </IconButton>

          <Checkbox
            sx={{ color: '#D1D5DB' }}
            checked={isUserChecked?.includes(+data.id)}
            value={+data.id}
            onChange={e =>
              isUserChecked &&
              setIsUserChecked &&
              handleBenefitsChecked(e, isUserChecked, setIsUserChecked)
            }
          />
          {currentCMS?.role === RoleEnum.ADMIN ? (
            <NavLink to={`edit-user/${data?.id}`} style={{ color: 'black' }}>
              <Typography fontSize={'16px'} ml={2}>
                {data?.name}
              </Typography>
            </NavLink>
          ) : (
            <Typography fontSize={'16px'} ml={2}>
              {data?.name}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2} display={'flex'} justifyContent={'center'}>
          <IconButton
            onClick={handleClick}
            aria-label="dotsMenu"
            aria-valuetext={data?.name}
            aria-valuenow={+data?.id}
          >
            <MoreHorizIcon id="threeDots" data-testid="threeDots" sx={{ color: 'black' }} />
          </IconButton>
        </Grid>
      </Grid>
      {openMobileUserDropdown === index && (
        <Grid
          item
          xs={12}
          sx={{ background: '#F5F7FA' }}
          height={'112px'}
          borderBottom={'1px solid #D1D5DB'}
        >
          <Grid
            item
            xs={12}
            height={'56px'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Typography fontSize={'16px'} ml={2.5} width={'60px'}>
              Email:
            </Typography>
            <Typography fontSize={'16px'} ml={4.5}>
              {data?.email}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            height={'56px'}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <Typography fontSize={'16px'} ml={2.5} width={'60px'}>
              {`${t('phone')}:`}
            </Typography>
            <Typography fontSize={'16px'} ml={4.5}>
              {data?.phone}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};
export default MobileUserRow;
