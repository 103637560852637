export const tableHeaderSortData = {
  objects: [
    'name_asc',
    'name_dsc',
    'email_asc',
    'email_dsc',
    'address_asc',
    'address_dsc',
    'fee_percentage_asc',
    'fee_percentage_dsc'
  ],
  users: [
    'first_name_asc',
    'first_name_dsc',
    'surname_asc',
    'surname_dsc',
    'email_asc',
    'email_dsc',
    'birth_date_asc',
    'birth_date_dsc',
    'phone_asc',
    'phone_dsc'
  ]
};
