import { useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
//components
import { Box } from '@mui/system';
import Header from '@components/Header/Header';
import Sidebar from '@src/ui/containers/Sidebar/Sidebar';
import { Breadcrumbs, IconButton, useMediaQuery } from '@mui/material';
//hooks
import { useTranslation } from 'react-i18next';
//atoms
import { useRecoilValue } from 'recoil';
import { userAtom } from '@atoms/userAtom';
import { calendarFullScreenAtom } from '@atoms/calendarFullScreen';
//icons
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
//helpers
import { generateBreadcrumbs } from '@helpers/utility';
//hooks
import { useSetLoginUser } from '@hooks/useLoginUser/useSetLoginUser';
import { useLoggedUser } from '@api/queries/user/user';
//type
import { User, UserErrorResponse } from '@interfaces/user/user';
//components
import Loading from '@components/Loading/Loading';
//theme
import theme from '@src/theme';

function Layout(): JSX.Element {
  const [openSidemenu, setOpenSidemenu] = useState(false);
  const user = useRecoilValue(userAtom);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const calendarFullScreen = useRecoilValue(calendarFullScreenAtom);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const paths = generateBreadcrumbs(location.pathname, t);

  const { setUserCMS } = useSetLoginUser();
  const onUserSuccess = (u: User) => {
    setUserCMS(u);
  };
  const onUserError = (err: UserErrorResponse) => {
    if (err.status === 442) navigate('/email-not-verified');
  };

  const { isLoading } = useLoggedUser(onUserSuccess, onUserError);

  if (isLoading) {
    return <Loading />;
  }

  if (!user) {
    return <Outlet />;
  }
  return (
    <Box
      sx={{
        m: '-8px',
        marginLeft:
          calendarFullScreen && location.pathname.includes('calendar')
            ? 0
            : { md: '264px', sm: '-8px' },
        marginTop:
          ((calendarFullScreen || isMobile) && location.pathname.includes('calendar')) ||
          (isMobile && location.pathname.includes('users'))
            ? 0
            : '65px',
        backgroundColor: location.pathname.includes('calendar') || isMobile ? 'white' : '#FCFCFC',
        minHeight: 'calc(100vh - 65px)'
      }}
    >
      {calendarFullScreen && location.pathname.includes('calendar') ? null : (
        <Sidebar open={openSidemenu} onClose={() => setOpenSidemenu(false)} />
      )}
      {((calendarFullScreen || isMobile) && location.pathname.includes('calendar')) ||
      (isMobile && location.pathname.includes('users')) ? null : (
        <Header setOpen={setOpenSidemenu} />
      )}

      {((calendarFullScreen || isMobile) && location.pathname.includes('calendar')) ||
      (isMobile &&
        !location.pathname.includes('edit') &&
        !location.pathname.includes('objects/object')) ? null : (
        <Box display={'flex'} p={1} alignItems={'center'}>
          {paths.length > 2 && (
            <IconButton
              sx={{
                backgroundColor: '#D1D1D1',
                ':hover': {
                  bgcolor: '#D1D1D1'
                },
                height: '24px',
                width: '24px'
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackRoundedIcon sx={{ color: 'black' }} />
            </IconButton>
          )}

          <Breadcrumbs separator="-" sx={{ pl: 1 }}>
            {paths.map((el, i) =>
              el.name ? (
                <NavLink
                  to={el.link}
                  key={i}
                  end
                  style={({ isActive }) =>
                    isActive
                      ? { textDecoration: 'none', color: '#000000', fontWeight: 'bold' }
                      : { textDecoration: 'none', color: '#000000' }
                  }
                >
                  {el.name}
                </NavLink>
              ) : null
            )}
          </Breadcrumbs>
        </Box>
      )}

      <Outlet />
    </Box>
  );
}

export default Layout;
