import { SportEnum } from '@enum/sportEnum';
import { BenefitsType } from '@interfaces/SportCenters/Benefits';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
import { CreatePriceRuleRequest } from '@interfaces/priceRules/priceRules';
import { AutocompleteType } from '@interfaces/locationsGeoCode/locationsGeoCode';
import { workingDaysArrType } from '@interfaces/SportCenters/SportCenter';

import dayjs from 'dayjs';

const today = dayjs().format('YYYY-MM-DD').toString();
export const banks = [
  'Addiko Banka',
  'АIК Banka',
  'ALTA Banka',
  'API Banka',
  'Banca Intesa',
  'Banka Poštanska Štedionica',
  'Bank of China Serbia',
  'PBA Banka',
  'Expobank',
  'Erste Bank',
  'Eurobank Direktna',
  'Halkbanka',
  'NLB Komercijalna Banka',
  'Mirabank',
  'Mobi Banka',
  '3 Banka',
  'OTP Banka Srbija',
  'ProCredit Bank',
  'Naša AIK Banka',
  'Srpska Banka'
];

export const emptyObject: CreateSportCenterType = {
  basic_info: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    municipality: '',
    longitude: '',
    latitude: '',
    description: '',
    description_translates: {
      en: '',
      sr: ''
    },
    // logo: undefined,
    gallery_images: undefined
  },
  legal_data: {
    name: '',
    tax_identification_number: '',
    identification_number: '',
    bank_account_number: '',
    bank_name: '',
    address: '',
    city: '',
    municipality: '',
    legal_representative_1: '',
    legal_representative_2: '',
    in_vat_system: false,
    e_invoicing_email: '',
    contract_start_date: today,
    contract_expiry_date: today
  },
  payment_methods: [],
  benefits: [],
  working_hours: {
    workweek_from: '08:00',
    workweek_to: '20:00',
    saturday_from: '08:00',
    saturday_to: '16:00',
    sunday_from: '08:00',
    sunday_to: '16:00'
  },
  fee_percentage: 0,
  package: ''
};

export const benefits_mock = [
  {
    id: 1,
    name: 'Parking'
  },
  {
    id: 2,
    name: 'Wi-Fi'
  },
  {
    id: 3,
    name: 'Grejanje'
  },
  {
    id: 4,
    name: 'Kafić'
  },
  {
    id: 5,
    name: 'Tuševi'
  },
  {
    id: 6,
    name: 'Pet friendly'
  },
  {
    id: 7,
    name: 'Ventilacija'
  },
  {
    id: 8,
    name: 'Semafor'
  },
  {
    id: 9,
    name: 'Snimanje termina'
  },
  {
    id: 10,
    name: 'Restoran'
  },
  {
    id: 11,
    name: 'Lopte'
  },
  {
    id: 12,
    name: 'Reketi'
  },
  {
    id: 13,
    name: 'Markeri'
  },
  {
    id: 14,
    name: 'Reflektori'
  },
  {
    id: 15,
    name: 'Klimatizacija'
  },
  {
    id: 16,
    name: 'Toalet'
  },
  {
    id: 17,
    name: 'Grejanje u svlačionicama'
  },
  {
    id: 18,
    name: 'Zaključavanje svlačionica'
  }
];

export const payments = ['cash', 'card'];
export const genders = ['male', 'female'];

// export const podloga: SurfaceTypeType[] = [
//   {
//     key: SurfaceTypeEnum.Grass,
//     value: 'Trava'
//   },
//   {
//     key: SurfaceTypeEnum.ArtificialGrass,
//     value: 'Veštačka trava'
//   },
//   {
//     key: SurfaceTypeEnum.Clay,
//     value: 'Glina'
//   },
//   {
//     key: SurfaceTypeEnum.Rubber,
//     value: 'Guma'
//   },
//   {
//     key: SurfaceTypeEnum.Concrete,
//     value: 'Beton'
//   },
//   {
//     key: SurfaceTypeEnum.Ice,
//     value: 'Led'
//   },
//   {
//     key: SurfaceTypeEnum.Tartan,
//     value: 'Tartan'
//   },
//   {
//     key: SurfaceTypeEnum.Sand,
//     value: 'Pesak'
//   },
//   {
//     key: SurfaceTypeEnum.Carpet,
//     value: 'Tepih'
//   },
//   {
//     key: SurfaceTypeEnum.Hardwood,
//     value: 'Drvo'
//   }
// ];
// export const tip: RoofStatusType[] = [
//   {
//     key: RoofStatusEnum.Open,
//     value: 'Otvoren'
//   },
//   {
//     key: RoofStatusEnum.Closed,
//     value: 'Zatvoren'
//   }
// ];

export const allSportsTypes: SportEnum[] = [
  SportEnum.Fudbal,
  SportEnum.Kosarka,
  SportEnum.Odbojka,
  SportEnum.Rukomet,
  SportEnum.Tenis,
  SportEnum.Karting,
  SportEnum.Padel,
  SportEnum.MaliFudbal,
  SportEnum.StoniTenis
];

// export const fudbalBenefits: BenefitsType = [
//   {
//     id: 1,
//     name: 'Martinele'
//   },
//   {
//     id: 2,
//     name: 'Linije'
//   },
//   {
//     id: 3,
//     name: 'Semafor'
//   },
//   {
//     id: 4,
//     name: 'Grejanje'
//   },
//   {
//     id: 5,
//     name: 'Snimanje terena'
//   },
//   {
//     id: 6,
//     name: 'Lopte'
//   },
//   {
//     id: 7,
//     name: 'Markeri'
//   }
// ];

// export const tenisBenefits: BenefitsType = [
//   {
//     id: 1,
//     name: 'Reketi'
//   },
//   {
//     id: 2,
//     name: 'Dubi Linije'
//   },
//   {
//     id: 3,
//     name: 'Loptice'
//   },
//   {
//     id: 4,
//     name: 'Grejanje'
//   },
//   {
//     id: 5,
//     name: 'Snimanje terena'
//   }
// ];
export const priceRule: CreatePriceRuleRequest = {
  price_rule: {
    id: 0,
    name: '',
    time_start: '',
    time_end: '',
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
    sun: false,
    workweek: false,
    weekend: false,
    name_translates: {
      en: '',
      sr: ''
    }
  },
  time_prices: [],
  court_sport_ids: []
};
export const timePriceArr: number[] = [180, 150, 120, 90, 60, 30];
export const locations: AutocompleteType[] = [
  {
    id: 'here:af:street:Gz2sL.36CoF1ZNxZ5ultxC',
    street: 'Ulica Uciteljska',
    city: 'Beograd',
    country: 'Srbija',
    municipality: 'Zvezdara',
    houseNumber: '21'
  },
  {
    id: 'here:af:street:VE0pZ4-mvyAThbrxsIUIFB',
    street: 'Ulica Ucitelj-Milina',
    city: 'Nis',
    country: 'Srbija',
    municipality: 'Medijana',
    houseNumber: '21'
  },
  {
    id: 'here:af:street:yFrOLZ6ULapqx8rYM5PhrD',
    street: 'Ulica Ucitelja Mihailovica',
    city: 'Cukarica',
    country: 'Srbija',
    municipality: 'Zeleznik',
    houseNumber: '21'
  },
  {
    id: 'here:af:street:ccLRXCzAFdH783VSqqWWbD',
    street: 'Ulica Uciteljska',
    city: 'Palilula',
    country: 'Srbija',
    municipality: 'Borca',
    houseNumber: '21'
  },
  {
    id: 'here:af:street:ipzZwwSz1rKWH.kHeN8kND',
    street: 'Ulica Uciteljska',
    city: 'Mladenovac',
    country: 'Srbija',
    municipality: 'Mladenovac',
    houseNumber: '21'
  }
];
export const workingDaysArr: workingDaysArrType[] = ['workweek', 'mon', 'tue', 'wed', 'thu', 'fri'];
export const weekendArr: workingDaysArrType[] = ['weekend', 'sat', 'sun'];
