//interfaces
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  AdminEmployeeResponseType,
  EmployeeCreateRequest,
  EmployeeResponseType,
  CreateAdminEmployeeRequestType,
  AdminEmployeeType
} from '@interfaces/employees/employees';
//axios instance
import axiosInstance from '@services/config';

export const createEmployee = (data: EmployeeCreateRequest): Promise<EmployeeResponseType> =>
  axiosInstance.post('/cooperation/employees', data);

export const singleEmployee = (id: Number): Promise<EmployeeResponseType> =>
  axiosInstance.get(`/cooperation/employees/${id}`);

export const employees = (
  page: number,
  per_page: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): Promise<EmployeeResponseType> => {
  return axiosInstance.get('/cooperation/employees/', {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });
};

export const getSportCenterEmployees = (
  sportCenterId: number,
  page?: number,
  per_page?: number,
  search?: string
): Promise<{ data: AdminEmployeeResponseType }> =>
  axiosInstance.get(`sport-centers/${sportCenterId}/employeeMemberships`, {
    params: {
      page: page,
      per_page: per_page,
      search: search
    }
  });

export const createSportCenterEmployee = (
  req: CreateAdminEmployeeRequestType
): Promise<{ data: AdminEmployeeType }> =>
  axiosInstance.post(`sport-centers/${req.sportCenterId}/employeeMemberships`, req.data);

export const getSingleSportCenterEmployee = (
  req: Omit<CreateAdminEmployeeRequestType, 'data'> & {
    employeeMembershipId: number;
  }
): Promise<{ data: AdminEmployeeType }> =>
  axiosInstance.get(
    `sport-centers/${req.sportCenterId}/employeeMemberships/${req.employeeMembershipId}`
  );

export const deleteSingleSportCenterEmployee = (
  req: Omit<CreateAdminEmployeeRequestType, 'data'> & {
    employeeMembershipId: number;
  }
): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(
    `sport-centers/${req.sportCenterId}/employeeMemberships/${req.employeeMembershipId}`
  );

export const updateSportCenterEmployee = (
  req: CreateAdminEmployeeRequestType & {
    employeeMembershipId: number;
  }
): Promise<{ data: AdminEmployeeType }> =>
  axiosInstance.put(
    `sport-centers/${req.sportCenterId}/employeeMemberships/${req.employeeMembershipId}`,
    req.data
  );
