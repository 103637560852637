//axios instance
import axiosInstance from '@services/config';
//interface
import {
  AppointmentsByUserResponse,
  RegularAppointmentResponse,
  RegularAppointmentCreateRequest,
  RegularAppointmentResponseType,
  RecurringAppointmentCreateRequest,
  RecurringAppointmentResponseType,
  ReccuringAppointmentsResponseType,
  RecurringAppointment,
  WholeAppointmentsResponse,
  AllAppointmentsRequestType,
  AllAppointmentsResponseType
} from '@interfaces/appointments/appointments';
import { SuccessResponse } from '@interfaces/apiResponse';
import { A } from '@fullcalendar/core/internal-common';

export const getAppointments = (
  params?: AllAppointmentsRequestType
): Promise<{ data: AllAppointmentsResponseType }> =>
  axiosInstance.get('/appointments', {
    params: {
      from_date: params?.from_date,
      to_date: params?.to_date,
      status: params?.status,
      sport_center_id: params?.sport_center_id,
      court_id: params?.court_id,
      sport_id: params?.sport_id,
      page: params?.page,
      per_page: params?.per_page
    }
  });

export const getSportCenterAppointments = (
  sportCenter: number | undefined,
  from_date: string,
  to_date: string,
  court_id?: number | string,
  sport_id?: number | string,
  status?: string,
  user_id?: number
): Promise<{ data: RegularAppointmentResponse }> =>
  axiosInstance.get(`/appointments/by-sport-center/${sportCenter}`, {
    params: {
      sportCenter: sportCenter,
      from_date: from_date,
      to_date: to_date,
      court_id: court_id,
      sport_id: sport_id,
      status: status,
      user_id: user_id
    }
  });

export const createRegularAppointment = (
  data: RegularAppointmentCreateRequest
): Promise<RegularAppointmentResponseType> => axiosInstance.post('/appointments', data);

export const getAppointmentsByUser = (userId: string): Promise<AppointmentsByUserResponse> =>
  axiosInstance.get('/appointments/by-user/' + userId);

export const getAppointmentsByID = (
  id: number | undefined
): Promise<RegularAppointmentResponseType> => axiosInstance.get(`/appointments/${id}`);

export const createRecurringAppointment = (
  data: RecurringAppointmentCreateRequest
): Promise<RecurringAppointmentResponseType> => axiosInstance.post('/recurring-appointments', data);

export const getRecurringAppointmentsByID = (
  id: number | undefined
): Promise<RecurringAppointmentResponseType> => axiosInstance.get(`/recurring-appointments/${id}`);

export const updateAppointment = (
  data: RegularAppointmentCreateRequest & { appointment_id: number }
): Promise<RegularAppointmentResponseType> =>
  axiosInstance.put(`/appointments/${data.appointment_id}`, data);

export const confirmAppointment = (id: number): Promise<SuccessResponse> =>
  axiosInstance.patch(`/appointments/${id}/confirm`);

export const declineAppointment = (id: number): Promise<SuccessResponse> =>
  axiosInstance.patch(`/appointments/${id}/decline`);

export const cancelAppointment = (id: number): Promise<SuccessResponse> =>
  axiosInstance.patch(`/appointments/${id}/cancel`);

export const getRecurringAppointments = (
  sportCenterId: number | undefined,
  page: number,
  per_page: number,
  search?: string
): Promise<ReccuringAppointmentsResponseType> =>
  axiosInstance.get(`/recurring-appointments/by-sport-center/${sportCenterId}`, {
    params: {
      page: page,
      per_page: per_page,
      search: search
    }
  });

export const updateRecurringAppointment = (
  data: RecurringAppointmentCreateRequest & { id: number }
): Promise<RecurringAppointmentResponseType> =>
  axiosInstance.put(`/recurring-appointments/${data.id}`, data);

export const deleteRecurringAppointment = (
  recurringAppointmentId: number
): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/recurring-appointments/${recurringAppointmentId}`);

export const getSportCenterAppointmentsForCoach = (
  sportCenter: number | undefined,
  from_date: string,
  to_date: string,
  court_id?: number | string,
  sport_id?: number | string,
  status?: string,
  user_id?: number
): Promise<{ data: RegularAppointmentResponse }> =>
  axiosInstance.get(`/appointments/by-sport-center-for-coaches/${sportCenter}`, {
    params: {
      sportCenter: sportCenter,
      from_date: from_date,
      to_date: to_date,
      court_id: court_id,
      sport_id: sport_id,
      status: status,
      user_id: user_id
    }
  });
