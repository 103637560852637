//hooks
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//components
import SettingsCard from '@containers/SettingsCard/SettingsCard';
//icons
import {
  CalendarNoDatesIcon,
  DocumentsIcon,
  KeyIcon,
  LeasedHoursIcon,
  PriceIcon,
  SecurityIcon,
  TournamentIcon,
  TranslateIcon,
  UserProfileIcon,
  UsersIcon
} from '@src/ui/components/icons/icons';
//enum
import { RoleEnum } from '@enum/roleEnum';
import { PermissionsEnum } from '@enum/permissionsEnum';
//atom
import { useRecoilValue } from 'recoil';
import { currentCMSAtom } from '@atoms/currentCMS';
import { userAtom } from '@atoms/userAtom';

export function useSettings() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentCMS = useRecoilValue(currentCMSAtom);
  const user = useRecoilValue(userAtom);

  const settings: {
    role: RoleEnum[];
    element: React.ReactNode;
    permission: string;
  }[] = [
    {
      role: [RoleEnum.ADMIN],
      permission: '',
      element: (
        <SettingsCard
          icon={<UsersIcon />}
          title={t('settings.objectProfile')}
          subtitle={t('settings.objectProfileSubtitle')}
          onClick={() => {
            navigate(`object-profile`);
          }}
        />
      )
    },
    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: PermissionsEnum.read_courts,
      element: (
        <SettingsCard
          icon={<DocumentsIcon />}
          title={t('fieldsAndSports')}
          subtitle={t('settings.fieldsAndSportsSubtitle')}
          onClick={() => {
            navigate(`fields-and-sports`);
          }}
        />
      )
    },
    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: PermissionsEnum.read_price_rules,
      element: (
        <SettingsCard
          icon={<PriceIcon />}
          title={t('pricelist')}
          subtitle={t('settings.pricelistSubtitle')}
          onClick={() => {
            navigate(`pricelist`);
          }}
        />
      )
    },
    {
      role: [RoleEnum.ADMIN, RoleEnum.ADMIN_EMPLOYEE],
      permission: PermissionsEnum.read_non_working_days,
      element: (
        <SettingsCard
          icon={<CalendarNoDatesIcon />}
          title={t('nonWorkingDays')}
          subtitle={t('settings.nonWorkingDaysSubtitle')}
          onClick={() => {
            navigate(`non-working-days`);
          }}
        />
      )
    },
    {
      role: [
        RoleEnum.SUPERADMIN,
        RoleEnum.SUPERADMIN_EMPLOYEE,
        RoleEnum.ADMIN,
        RoleEnum.ADMIN_EMPLOYEE
      ],
      permission: PermissionsEnum.read_roles,
      element: (
        <SettingsCard
          icon={<KeyIcon />}
          title={t('permissions')}
          subtitle={t('settings.accessSubtitle')}
          onClick={() => {
            navigate(`access`);
          }}
        />
      )
    },
    {
      role: [RoleEnum.SUPERADMIN, RoleEnum.SUPERADMIN_EMPLOYEE],
      permission: PermissionsEnum.read_sports,
      element: (
        <SettingsCard
          icon={<TournamentIcon stroke="#6B7280" />}
          title={t('sports')}
          subtitle={t('settings.sportsSubtitle')}
          onClick={() => {
            navigate(`sports`);
          }}
        />
      )
    },

    {
      role: [RoleEnum.COACH],
      permission: '',
      element: (
        <SettingsCard
          icon={<UserProfileIcon />}
          title={t('profile')}
          subtitle={t('settings.profileSubtitle')}
          onClick={() => {
            navigate(`profile`);
          }}
        />
      )
    },
    {
      role: [RoleEnum.COACH],
      permission: '',
      element: (
        <SettingsCard
          icon={<LeasedHoursIcon />}
          title={t('leased_hours')}
          subtitle={t('settings.leased_hoursSubtitle')}
          onClick={() => {
            navigate(`leased-hours/${user && user.id}`);
          }}
        />
      )
    },
    {
      role: [],
      permission: '',
      element: (
        <SettingsCard
          icon={<TranslateIcon />}
          title={t('language')}
          subtitle={t('settings.languageSubtitle')}
          onClick={() => {
            navigate(`language`);
          }}
        />
      )
    },
    {
      role: [],
      permission: '',
      element: (
        <SettingsCard
          icon={<SecurityIcon />}
          title={t('security')}
          subtitle={t('settings.securitySubtitle')}
          onClick={() => {
            navigate(`change-password`);
          }}
        />
      )
    }
  ];

  function getSettings(): ReactNode[] {
    const role = currentCMS?.role;
    const permissions = currentCMS?.permissions;

    return settings
      .map(el => {
        if (el.role.length === 0) return el.element;

        if (el.role.find(r => r === role) !== undefined) {
          //if role exists
          if (role === RoleEnum.SUPERADMIN || role === RoleEnum.ADMIN || role === RoleEnum.COACH)
            return el.element;
          else {
            if (el.permission === '') {
              return el.element;
            }
            if (permissions?.find(p => p === el.permission) !== undefined) {
              //if permission exists
              return el.element;
            } else return undefined;
          }
        }
      })
      .filter(el => el !== undefined);
  }
  return { getSettings };
}
