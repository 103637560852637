//error
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
// interfaces
import {
  UploadImageResponse,
  UploadImageRequest,
  ImageType,
  DeleteImageRequest,
  UploadImageError,
  UploadImageErrorResponse,
  DeleteCourtImageRequest,
  UploadCourtImageRequest
} from '@src/domain/interfaces/Images/images';
//service
import {
  uploadSCImage,
  deleteSCImage,
  uploadCourtImage,
  deleteCourtImage,
  uploadUserProfilePhoto,
  deleteUserProfilePhoto
} from '@src/infrastructure/services/Images/images';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useUploadSCImage = (
  onSuccess: (data: UploadImageResponse) => void,
  onError: (err: UploadImageError) => void
): UseMutationResult<
  { data: UploadImageResponse }, // response
  UploadImageErrorResponse,
  UploadImageRequest, // request
  (data: ImageType) => void // on success
> =>
  useMutation({
    mutationFn: uploadSCImage,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteSCImage = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  DeleteImageRequest, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteSCImage,
    onSuccess: data => onSuccess({ message: 'Successfully deleted image' }),
    onError: err => onError(err.response.data)
  });

export const useUploadCourtImage = (
  onSuccess: (data: UploadImageResponse) => void,
  onError: (err: UploadImageError) => void
): UseMutationResult<
  { data: UploadImageResponse }, // response
  UploadImageErrorResponse,
  UploadCourtImageRequest, // request
  (data: ImageType) => void // on success
> =>
  useMutation({
    mutationFn: uploadCourtImage,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteCourtImage = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  DeleteCourtImageRequest, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteCourtImage,
    onSuccess: data => onSuccess({ message: 'Successfully deleted image' }),
    onError: err => onError(err.response.data)
  });

export const useUploadUserProfilePhoto = (
  onSuccess: (data: UploadImageResponse) => void,
  onError: (err: UploadImageError) => void
): UseMutationResult<
  { data: UploadImageResponse }, // response
  UploadImageErrorResponse,
  UploadImageRequest, // request
  (data: ImageType) => void // on success
> =>
  useMutation({
    mutationFn: uploadUserProfilePhoto,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteUserProfilePhoto = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  DeleteImageRequest, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteUserProfilePhoto,
    onSuccess: data => onSuccess({ message: 'Successfully deleted image' }),
    onError: err => onError(err.response.data)
  });
