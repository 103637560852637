//react
import { MouseEvent, FC, ChangeEvent, useState } from 'react';
//hooks
import useWindowSize from '@hooks/windowSize/useWindowSize';
import { useTranslation } from 'react-i18next';
//interfaces
import { BenefitsType } from '@interfaces/SportCenters/Benefits';
import { FilterProps } from '@interfaces/table/table';
import { TableVariantType } from '@interfaces/table/table';
//mock
import { tableHeaderSortData } from '@src/__mock__/tableHeaderSort/tableHeaderSortData';
//react form
import { Control, Controller } from 'react-hook-form';
//mui
import {
  Button,
  FormControl,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Popover,
  InputLabel,
  IconButton,
  OutlinedInput,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  debounce
} from '@mui/material';
// icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import AddCircleOutlineSharpIcon from '@mui/icons-material/AddCircleOutlineSharp';
import {
  BellRingIcon,
  DeactivateIcon,
  DeleteRowIcon,
  SendMessageIcon,
  AboutViewIcon
} from '@components/icons/icons';

// helpers
import { payments, genders } from '@src/__mock__/createSportsCenter/steps';
import { handleChecked, handleBenefitsChecked } from '@helpers/utility';
interface TableHeaderProps {
  tableVariant: TableVariantType;
  filterValue?: string;
  controlFilter?: Control<FilterProps>;
  handleSubmitFilter?: () => void;
  sendEmailEventHandler?: () => void;
  sendMessageEventHandler?: () => void;
  deactivateEventHandler?: () => void;
  deleteEventHandler?: () => void;
  handleSearch?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  disableSearch?: boolean;
  disableFilter?: boolean;
  disableSort?: boolean;
  hideActionBtn?: boolean;
  handleAddItemBtn?: () => void;
  handleClearFilter?: () => void;
  handleSortTables?: (index: number, tableVariant: TableVariantType) => void;
  handleClearSort?: () => void;
  isCheckedGender?: boolean[];
  benefits?: BenefitsType;
  disableActions?: boolean;
  checkSort?: number | null;
  anchorFilterEl?: null | HTMLElement;
  anchorMenuItem?: null | HTMLElement;
  anchorElActionButton?: null | HTMLElement;
  anchorSortEl?: null | HTMLElement;
  handleClick?: (event: MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLDivElement>) => void;
  handleClose?: () => void;
}

const TableHeaderActions: FC<TableHeaderProps> = ({
  filterValue = '',
  controlFilter,
  handleSubmitFilter,
  handleClearFilter,
  sendEmailEventHandler,
  sendMessageEventHandler,
  deactivateEventHandler,
  deleteEventHandler,
  handleSearch,
  disableSearch = false,
  disableFilter = false,
  disableSort = false,
  hideActionBtn = false,
  handleAddItemBtn,
  checkSort,
  handleSortTables,
  handleClearSort,
  tableVariant,
  disableActions,
  anchorFilterEl = null,
  anchorElActionButton = null,
  anchorMenuItem = null,
  anchorSortEl = null,
  handleClick,
  handleClose,
  benefits
}): JSX.Element => {
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const [isPaymentOrGenderChecked, setIsPaymentOrGenderChecked] = useState<string[]>([]);
  const [isBenefitsChecked, setIsBenefitsChecked] = useState<number[]>([]);
  const menuOptions = ['benefits']; //TODO: move to mock
  // 'paymentMethod', 'fee_percentage', removed from menuOptions array for first release
  const lng = localStorage.getItem('lng');

  if (isBenefitsChecked.length && benefits) {
    let tempStrings: string[] = [];
    let tempArray = benefits?.filter(benefit => isBenefitsChecked.includes(benefit.id));

    tempArray.forEach(item => {
      tempStrings.push(item.name);
    });
    filterValue = tempStrings.toString();
  }

  const renderMenuOptions = () => {
    if (tableVariant === 'objects') {
      return menuOptions.map((option, index) => (
        <MenuItem
          key={index}
          onClick={handleClick}
          aria-controls={anchorMenuItem ? 'second-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={anchorMenuItem ? 'true' : undefined}
        >
          {t(`${option}`)}
        </MenuItem>
      ));
    } else {
      return <MenuItem onClick={handleClick}>{t(`gender`)}</MenuItem>;
    }
  };

  const renderSecondMenuItems = () => {
    return (
      <Box
        component="form"
        onSubmit={e => {
          if (handleSubmitFilter && handleClose) {
            e.preventDefault();
            handleSubmitFilter();
            handleClose();
          }
        }}
      >
        {anchorMenuItem?.textContent == t('paymentMethod') && (
          <Controller
            name="payment_methods"
            control={controlFilter}
            defaultValue={[]}
            render={({ field }) => (
              <FormGroup>
                {payments.map((payment, index) => {
                  return (
                    <MenuItem key={index}>
                      <FormControlLabel
                        {...field}
                        control={
                          <Checkbox
                            onChange={e =>
                              field.onChange(
                                handleChecked(
                                  e,
                                  isPaymentOrGenderChecked,
                                  setIsPaymentOrGenderChecked
                                )
                              )
                            }
                            checked={isPaymentOrGenderChecked.includes(payment)}
                            name={payment}
                            value={payment}
                          />
                        }
                        label={t(payment)}
                      />
                    </MenuItem>
                  );
                })}
              </FormGroup>
            )}
          />
        )}
        {anchorMenuItem?.textContent === t('fee_percentage') && (
          <Box>
            <Controller
              name="fee_percentage_min"
              control={controlFilter}
              render={({ field }) => (
                <MenuItem>
                  <TextField
                    {...field}
                    type="number"
                    label={t('from')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </MenuItem>
              )}
            />
            <Controller
              name="fee_percentage_max"
              control={controlFilter}
              render={({ field }) => (
                <MenuItem>
                  <TextField
                    {...field}
                    label={t('to')}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                  />
                </MenuItem>
              )}
            />
          </Box>
        )}
        {anchorMenuItem?.textContent === t('benefits') && benefits && benefits.length && (
          <Controller
            name="benefits"
            control={controlFilter}
            defaultValue={[]}
            render={({ field, fieldState: { error, invalid } }) => (
              <FormGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  mt: 1
                }}
              >
                {benefits &&
                  isBenefitsChecked &&
                  setIsBenefitsChecked &&
                  benefits.map((benefit, index) => {
                    return (
                      <MenuItem key={index}>
                        <FormControlLabel
                          key={benefit.id}
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              color: `${invalid ? 'red' : '#6B7280'}`
                            }
                          }}
                          control={
                            <Checkbox
                              {...field}
                              checked={isBenefitsChecked.includes(benefit.id)}
                              name={benefit.name}
                              value={benefit.id}
                              onChange={e =>
                                field.onChange(
                                  handleBenefitsChecked(e, isBenefitsChecked, setIsBenefitsChecked)
                                )
                              }
                            />
                          }
                          label={
                            lng === 'en' ? benefit.name_translates.en : benefit.name_translates.sr
                          }
                        />
                      </MenuItem>
                    );
                  })}
              </FormGroup>
            )}
          />
        )}
        {anchorMenuItem?.textContent === t('gender') && (
          <Controller
            name="gender"
            control={controlFilter}
            defaultValue={[]}
            render={({ field, fieldState: { invalid } }) => (
              <FormGroup>
                {genders.map((gender, index) => {
                  return (
                    <MenuItem key={index}>
                      <FormControlLabel
                        {...field}
                        sx={{
                          '& .MuiFormControlLabel-label': {
                            color: `${invalid ? 'red' : '#6B7280'}`
                          }
                        }}
                        control={
                          <Checkbox
                            onChange={e =>
                              field.onChange(
                                handleChecked(
                                  e,
                                  isPaymentOrGenderChecked,
                                  setIsPaymentOrGenderChecked
                                )
                              )
                            }
                            checked={isPaymentOrGenderChecked.includes(gender)}
                            name={gender}
                            value={gender}
                          />
                        }
                        label={t(gender)}
                      />
                    </MenuItem>
                  );
                })}
              </FormGroup>
            )}
          />
        )}
        <MenuItem sx={{ position: 'sticky', background: 'white', zIndex: 2, bottom: 0 }}>
          <Button type="submit" variant="contained">
            {t('apply').toUpperCase()}
          </Button>
        </MenuItem>
      </Box>
    );
  };

  const menuItemsUsersSort =
    handleSortTables &&
    tableHeaderSortData.users
      .filter(key => {
        if (tableVariant !== 'users') {
          return !key.includes('birth');
        } else return key;
      })
      .map((key, index) => (
        <Grid
          key={index}
          container
          alignItems="center"
          justifyContent="space-between"
          sx={{ '&:hover': { background: 'lightgray' } }}
        >
          <MenuItem
            onClick={() =>
              checkSort === index && handleClearSort
                ? handleClearSort()
                : handleSortTables(index, tableVariant !== 'objects' ? tableVariant : 'users')
            }
            sx={{ '&:hover': { background: 'lightgray' } }}
          >
            {t(`sort.${key}`)}
          </MenuItem>
          {checkSort === index && (
            <MenuItem onClick={handleClearSort} sx={{ '&:hover': { background: 'lightgray' } }}>
              <HighlightOffIcon sx={{ color: 'gray' }} />
            </MenuItem>
          )}
        </Grid>
      ));

  const menuItemsObjectsSort =
    handleSortTables &&
    tableHeaderSortData.objects.map((key, index) => (
      <Grid
        key={index}
        container
        alignItems="center"
        justifyContent="space-between"
        sx={{ '&:hover': { background: 'lightgray' } }}
      >
        <MenuItem
          onClick={() => {
            handleClearSort && checkSort === index
              ? handleClearSort()
              : handleSortTables(index, 'objects');
          }}
          sx={{ '&:hover': { background: 'lightgray' } }}
        >
          {t(`sort.${key}`)}
        </MenuItem>
        {checkSort === index ? (
          <MenuItem onClick={handleClearSort} sx={{ '&:hover': { background: 'lightgray' } }}>
            <HighlightOffIcon sx={{ color: 'gray' }} />
          </MenuItem>
        ) : null}
      </Grid>
    ));

  return (
    <Grid
      container
      gap={2}
      justifyContent="space-between"
      wrap={width < 1200 ? 'wrap' : 'nowrap'}
      p={3}
      pl={disableFilter && disableSort ? 0 : 3}
    >
      {(!disableSearch || !disableFilter || !disableSort) && (
        <Grid
          item
          xs={12}
          md={disableFilter && disableSort ? 5 : 7}
          className={`row justify-start align-center ${width < 1300 ? 'wrap' : 'nowrap'}`}
          gap={disableFilter && disableSort ? 0 : 2}
        >
          {!disableSearch && handleSearch && (
            <TextField
              sx={{
                xs: {
                  width: '100%'
                }
              }}
              id="outlined-search"
              label={t('search')}
              type="text"
              onChange={debounce(handleSearch, 500)}
              fullWidth={(disableFilter && disableSort) || width < 900}
              placeholder={
                tableVariant !== 'superUsers' && tableVariant !== 'coaches'
                  ? t('tableHeader.searchPlaceholder').toString()
                  : t('coachesPage.searchLabel').toString()
              }
            />
          )}
          {!disableFilter && (
            <FormControl fullWidth={width < 900}>
              <InputLabel id="label-filter" htmlFor="outlined-filter">
                {t('filter')}
              </InputLabel>
              <OutlinedInput
                aria-describedby="base-name-helper-text"
                label={t('filter')}
                placeholder={t('tableHeader.addFilter').toString()}
                id="outlined-filter"
                autoComplete="off"
                value={t(filterValue).toString()}
                onClick={handleClick}
                endAdornment={
                  filterValue && (
                    <IconButton
                      aria-label="toggle filter"
                      onClick={() => {
                        if (handleClearFilter) {
                          handleClearFilter();
                          setIsBenefitsChecked([]);
                          setIsPaymentOrGenderChecked([]);
                        }
                      }}
                    >
                      <HighlightOffSharpIcon />
                    </IconButton>
                  )
                }
                startAdornment={
                  !filterValue && (
                    <IconButton
                      onClick={handleClick}
                      aria-controls={anchorFilterEl ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={anchorFilterEl ? 'true' : undefined}
                    >
                      <AddCircleOutlineSharpIcon />
                    </IconButton>
                  )
                }
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorFilterEl}
                open={anchorFilterEl !== null}
                onClose={handleClose}
              >
                {renderMenuOptions()}
              </Menu>
              <Popover
                sx={{
                  ml: 1,
                  maxHeight: '300px',
                  maxWidth: '300px'
                }}
                open={anchorMenuItem !== null}
                onClose={handleClose}
                id="second-menu"
                anchorEl={anchorMenuItem}
                anchorOrigin={{
                  vertical: width < 1200 ? 'bottom' : 'top',
                  horizontal: width < 1200 ? 'left' : 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
              >
                {renderSecondMenuItems()}
              </Popover>
            </FormControl>
          )}

          {!disableSort && (
            <>
              <Grid item alignSelf={'center'}>
                <Tooltip title={t('sort.sort')} placement="top">
                  <IconButton
                    id="sort-button"
                    onClick={handleClick}
                    aria-controls={anchorSortEl ? 'sort-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={anchorSortEl ? 'true' : undefined}
                  >
                    <FilterListIcon fontSize="medium" sx={{ fill: 'gray' }} />
                    {width < 1200 && <Typography variant="subtitle1">{t('sort.sort')}</Typography>}
                  </IconButton>
                </Tooltip>
              </Grid>
              <Menu
                id="sort-menu"
                anchorEl={anchorSortEl}
                open={anchorSortEl !== null}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                {tableVariant === 'objects' ? menuItemsObjectsSort : menuItemsUsersSort}
              </Menu>
            </>
          )}
        </Grid>
      )}

      {/* {(!hideActionBtn || !disableAddBtn) && (
        <Grid
          item
          xs={12}
          md={4}
          className={`row ${width < 1200 ? 'justify-start' : 'justify-end'} align-center`}
          gap={2}
        >
          {!hideActionBtn && (
            <Grid item>
              <Button
                variant="outlined"
                onClick={handleClick}
                endIcon={anchorElActionButton ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              >
                {t('action')}
              </Button>

              <Menu
                id="actionBtn-menu"
                anchorEl={anchorElActionButton}
                open={anchorElActionButton !== null}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                {tableVariant !== 'objects' && (
                  <Box>
                    {tableVariant !== 'superUsers' && (
                      <MenuItem onClick={sendEmailEventHandler}>
                        <BellRingIcon />
                        <Typography ml={3.5}>{t('tableHeader.sendNotification')}</Typography>
                      </MenuItem>
                    )}
                    <MenuItem onClick={sendEmailEventHandler}>
                      <SendMessageIcon />
                      <Typography ml={3.5}>{t('tableHeader.sendMessage')}</Typography>
                    </MenuItem>
                    {tableVariant !== 'superUsers' && (
                      <MenuItem>
                        <DeactivateIcon />
                        <Typography ml={3.5}>{t('deactivate')}</Typography>
                      </MenuItem>
                    )}
                  </Box>
                )}

                {tableVariant === 'objects' && (
                  <MenuItem>
                    <AboutViewIcon />
                    <Typography ml={3.5}>{t('tableHeader.accessObject')}</Typography>
                  </MenuItem>
                )}
                <MenuItem onClick={deleteEventHandler} disabled={disableActions}>
                  <DeleteRowIcon />
                  <Typography ml={3.5}>{t('delete')}</Typography>
                </MenuItem>
              </Menu>
            </Grid>
          )}
          {!disableAddBtn && (
            <Grid item>
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddItemBtn}>
                {t('add')}
              </Button>
            </Grid>
          )}
        </Grid>
      )} */}

      <Grid
        item
        xs={12}
        md={4}
        className={`row ${width < 1200 ? 'justify-start' : 'justify-end'} align-center`}
        gap={2}
      >
        {!hideActionBtn && (
          <Grid item>
            <Button
              variant="outlined"
              onClick={handleClick}
              endIcon={anchorElActionButton ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              // disabled={disableActions}
            >
              {t('action')}
            </Button>

            <Menu
              id="actionBtn-menu"
              anchorEl={anchorElActionButton}
              open={anchorElActionButton !== null}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button'
              }}
            >
              {sendEmailEventHandler && (
                <MenuItem onClick={sendEmailEventHandler} disabled={disableActions}>
                  <BellRingIcon />
                  <Typography ml={3.5}>{t('tableHeader.sendNotification')}</Typography>
                </MenuItem>
              )}
              {sendMessageEventHandler && (
                <MenuItem onClick={sendMessageEventHandler} disabled={disableActions}>
                  <SendMessageIcon />
                  <Typography ml={3.5}>{t('tableHeader.sendMessage')}</Typography>
                </MenuItem>
              )}

              {deactivateEventHandler && (
                <MenuItem onClick={deactivateEventHandler} disabled={disableActions}>
                  <DeactivateIcon />
                  <Typography ml={3.5}>{t('deactivate')}</Typography>
                </MenuItem>
              )}

              {deleteEventHandler && (
                <MenuItem onClick={deleteEventHandler} disabled={disableActions}>
                  <DeleteRowIcon />
                  <Typography ml={3.5}>{t('delete')}</Typography>
                </MenuItem>
              )}
            </Menu>
          </Grid>
        )}
        {handleAddItemBtn && (
          <Grid item>
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddItemBtn}>
              {t('add')}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default TableHeaderActions;
