// components
import {
  TextField,
  Box,
  FormGroup,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Grid,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
//types
import { SportCustomFieldsTypeEnum } from '@src/domain/enum/sportEnum';
import { SportCustomFields } from '@src/domain/interfaces/sport/sport';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
//helpers
import { getTranslateArray, getTranslateName } from '@helpers/utility';

type MoreInfoProps = {
  customFields: SportCustomFields[];
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  lng: string | null;
};

const MoreInfo = ({ customFields, control, setValue, lng }: MoreInfoProps) => (
  <Box width={'100%'}>
    <Grid container spacing={2}>
      {customFields.map((option, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Controller
            name={`custom_fields_data.${option.id?.toString()}`}
            control={control}
            defaultValue=""
            rules={{ required: option.required }}
            render={({ field, fieldState: { invalid, error } }) => {
              if (
                option.type === SportCustomFieldsTypeEnum.text ||
                option.type === SportCustomFieldsTypeEnum.number
              )
                return (
                  <TextField
                    {...field}
                    label={getTranslateName(lng, option?.label_translates, option.label)}
                    sx={{ width: '100%' }}
                    variant="outlined"
                    type={option.type}
                    InputLabelProps={{
                      shrink: true
                    }}
                    error={invalid}
                    helperText={error?.message}
                    id={option.label}
                    required={option.required}
                  />
                );

              if (option.type === SportCustomFieldsTypeEnum.select)
                return (
                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel error={invalid}>
                      {getTranslateName(lng, option.label_translates, option.label)}
                    </InputLabel>
                    <Select
                      {...field}
                      label={getTranslateName(lng, option.label_translates, option.label)}
                      variant="outlined"
                      sx={{ width: '100%' }}
                      error={invalid}
                      id={option.label}
                    >
                      {getTranslateArray(lng, option.options_translates, option.options)?.map(
                        (op, index) => {
                          return (
                            <MenuItem key={index} value={op}>
                              {op}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                );

              if (option.type === SportCustomFieldsTypeEnum.checklist)
                return (
                  <FormGroup
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mt: 1,
                      ml: 5
                    }}
                  >
                    {getTranslateArray(lng, option.options_translates, option.options)?.map(
                      (benefit, index) => {
                        return (
                          <FormControlLabel
                            key={index}
                            sx={{
                              '& .MuiFormControlLabel-label': {
                                color: `${invalid ? 'red' : '#6B7280'}`
                              }
                            }}
                            control={
                              <Checkbox
                                {...field}
                                name={benefit}
                                value={benefit}
                                checked={field.value.includes(benefit)}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                  let updateCheckedArr = [...field.value];
                                  if (event.target.checked) {
                                    updateCheckedArr = [...field.value, event.target.value];
                                  } else {
                                    updateCheckedArr.splice(
                                      field.value.indexOf(event.target.value),
                                      1
                                    );
                                  }

                                  setValue(
                                    `custom_fields_data.${option.id?.toString()}`,
                                    updateCheckedArr
                                  );
                                }}
                              />
                            }
                            label={benefit}
                          />
                        );
                      }
                    )}
                  </FormGroup>
                );

              return <div></div>;
            }}
          />
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default MoreInfo;
