//components
import DefineAccess from '@components/Forms/Employees/DefineAccess/DefineAccess';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import { Alert, Box, Button, TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
//hooks
import { useSuperadminRoleHook } from '@hooks/useCreateRoleHook/useCreateRoleHook';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const CreateRolePage = () => {
  const { t } = useTranslation();
  const { permissions, checkItem, control, onSubmit, default_permissions, success, errors, lng } =
    useSuperadminRoleHook();

  const navigate = useNavigate();
  const params = useParams();

  return (
    <Box py={2}>
      {success && <Alert sx={{ position: 'fixed', width: '75%', zIndex: 1 }}>{success}</Alert>}
      <CardWrapper title={t('access')} subtitle={t('rolePage.subtitle')} isForm={true}>
        <Controller
          name="name_translates.sr"
          control={control}
          rules={{ required: !lng || lng === 'sr' ? true : false }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              fullWidth
              label={
                !lng || lng === 'sr'
                  ? t('rolePage.roleNameSerbian') + ' *'
                  : t('rolePage.roleNameSerbianOptional')
              }
              {...field}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
        <Controller
          name="name_translates.en"
          control={control}
          rules={{ required: lng === 'en' ? true : false }}
          render={({ field, fieldState: { invalid, error } }) => (
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              label={
                lng === 'en'
                  ? t('rolePage.roleNameEnglish') + ' *'
                  : t('rolePage.roleNameEnglishOptional')
              }
              {...field}
              error={invalid}
              helperText={error?.message}
            />
          )}
        />
        {errors && errors.permissions && (
          <Typography variant="body2" color={'error'}>
            {errors.permissions.message}
          </Typography>
        )}
        <DefineAccess
          onChange={checkItem}
          permissions={permissions}
          default_permissions={default_permissions}
          error={!!errors?.permissions}
        />
      </CardWrapper>
      <Box display={'flex'} gap={2} justifyContent={'flex-end'} mr={2}>
        <Button onClick={() => navigate(-1)}>{t('cancel')}</Button>
        <Button variant="contained" onClick={onSubmit}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateRolePage;
