//components
import { Select, SelectChangeEvent, ThemeProvider, createTheme } from '@mui/material';
import { ReactNode } from 'react';

const selectTheme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 0,
          '&:focus': {
            border: 0
          }
        }
      }
    }
  }
});

type CustomSelectPropsType = {
  value: string;
  onChange: (ev: SelectChangeEvent) => void;
  color: string;
  bgColor: string;
  children: ReactNode;
  fontSize?: string;
};

const CustomSelect = ({
  value,
  onChange,
  children,
  color,
  bgColor,
  fontSize
}: CustomSelectPropsType) => {
  return (
    <ThemeProvider theme={selectTheme}>
      <Select
        sx={{
          background: bgColor,
          '.MuiSvgIcon-root ': {
            fill: color + ' !important'
          },
          color: color,
          fontSize: fontSize ? fontSize : '1rem'
        }}
        value={value}
        onChange={onChange}
        size="small"
      >
        {children}
      </Select>
    </ThemeProvider>
  );
};

export default CustomSelect;
