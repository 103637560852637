//interfaces
import { WholeManagersResponse } from '@interfaces/user/manager/manager';
//services
import { managers } from '@services/user/manager/manager';
//react-query
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useManagers = (
  pageIndex: number,
  pageSize: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): UseQueryResult<WholeManagersResponse> =>
  useQuery(
    ['managers', pageIndex, pageSize, search, gender, order_by, order_direction],
    () => managers(pageIndex, pageSize, search, gender, order_by, order_direction),
    {
      onError: err => console.log(err)
    }
  );
