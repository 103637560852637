//components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import FileUpload from '@containers/FileUpload/FileUpload';
//hooks
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
//types
import {
  EmployeeCreateRequest,
  EmployeePositionType,
  EmployeeSectorType
} from '@interfaces/employees/employees';
import { ImageType } from '@interfaces/Images/images';
//hooks
import { useTranslation } from 'react-i18next';

type EmploymentDetailsPropsType = {
  control: Control<EmployeeCreateRequest>;
  setValue: UseFormSetValue<EmployeeCreateRequest>;
  files: File[];
  onDropCallback: (image: File) => void;
  uploadPercent: number;
  isLoading?: boolean;
  sectors: EmployeeSectorType[];
  positions: EmployeePositionType[];
  handleFileDelete: (file: File | ImageType) => void;
};
const EmploymentDetails = ({
  control,
  files,
  uploadPercent,
  isLoading,
  onDropCallback,
  sectors,
  positions,
  setValue,
  handleFileDelete
}: EmploymentDetailsPropsType) => {
  const { t } = useTranslation();
  return (
    <Accordion expanded={true} sx={{ px: 6 }}>
      <AccordionSummary>
        <Box>
          <Typography variant="h6">{t('employee.employmentDetails')}</Typography>
          <Typography variant="subtitle2" color="GrayText">
            {t('employee.employmentDetailsSubtitle')}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="cooperation_info.salary"
              defaultValue={0}
              control={control}
              rules={{ required: true, max: 100000000 }}
              render={({ field, fieldState: { invalid, error } }) => (
                <TextField
                  {...field}
                  label={t('salary')}
                  type={'number'}
                  placeholder="iznos plate u RSD"
                  required
                  sx={{ width: '100%' }}
                  error={invalid}
                  helperText={error && error.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="sector"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel error={invalid}>{t('sector') + ' *'}</InputLabel>
                  <Select
                    {...field}
                    label={t('sector') + ' *'}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    error={invalid}
                    onChange={ev => {
                      field.onChange(ev);
                      setValue('cooperation_info.employee_position_id', '');
                    }}
                  >
                    {sectors.map(p => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="cooperation_info.employee_position_id"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field, fieldState: { invalid, error } }) => (
                <FormControl sx={{ width: '100%' }}>
                  <InputLabel error={invalid}>{t('position') + ' *'}</InputLabel>
                  <Select
                    {...field}
                    label={t('position') + ' *'}
                    variant="outlined"
                    sx={{ width: '100%' }}
                    error={invalid}
                  >
                    {positions.map(p => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FileUpload
              title={t('employee.attacment')}
              files={files}
              onDropCallback={onDropCallback}
              uploadPercent={uploadPercent}
              isLoading={isLoading}
              handleFileDelete={handleFileDelete}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmploymentDetails;
