//mui
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Box,
  DialogContentText,
  Tooltip,
  IconButton
} from '@mui/material';
//icons
import { DeletePopupIcon, PopupInfoIcon, PopupWarningIcon } from '@components/icons/icons';
import { PopupDeleteIcon, PopupSuccessIcon } from '@src/ui/components/icons/icons';
import CloseIcon from '@mui/icons-material/Close';
//translate
import { useTranslation } from 'react-i18next';
//interfaces
import { PopupIconsType } from '@interfaces/popup';
//mock style
import { boxStyle, dialogStyle } from '@src/__mock__/popupStyle/popupStyle';
import { useRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';

const icons: PopupIconsType = {
  delete: <PopupDeleteIcon />,
  success: <PopupSuccessIcon />,
  warning: <PopupWarningIcon />,
  info: <PopupInfoIcon />,
  error: <PopupDeleteIcon />
};

const CustomPopup = () => {
  const { t } = useTranslation();
  const [popup_atom, setPopupAtom] = useRecoilState(popupAtom);

  const {
    open,
    title,
    content,
    onClick,
    tooltipTitle,
    variant,
    onClose,
    onCancel,
    stayOpen = false,
    buttonText,
    back
  } = popup_atom;

  const handleClose = () => {
    if (!stayOpen) {
      setPopupAtom({ ...popup_atom, open: false });
      if (onClose) onClose();
    } else {
      window.open('about:blank', '_self');
      window.close();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return onCancel ? onCancel() : handleClose();
        }
        handleClose();
      }}
    >
      {onCancel && (
        <IconButton onClick={onCancel} sx={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseIcon />
        </IconButton>
      )}
      <Tooltip title={tooltipTitle} placement="top">
        <DialogContent sx={dialogStyle[variant]}>
          <Box height={'40%'} sx={boxStyle[variant]}>
            {icons[variant]}
          </Box>

          <Box>
            <DialogTitle sx={{ padding: 0, marginBottom: '10px' }}>{title}</DialogTitle>
            <DialogContentText>{content}</DialogContentText>
          </Box>
        </DialogContent>
      </Tooltip>
      <DialogActions sx={{ display: 'flex', flexDirection: 'row', p: 3, pt: 0 }}>
        {onClick && (
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              color: 'gray',
              borderColor: 'lightgray',
              ':hover': { borderColor: 'gray' }
            }}
          >
            {back ? back : t('quit')}
          </Button>
        )}
        {stayOpen ? (
          <Button onClick={handleClose}>{t('close')}</Button>
        ) : (
          <Button
            onClick={onClick ? onClick : handleClose}
            variant="contained"
            color={variant === 'delete' ? 'error' : variant === 'success' ? 'primary' : variant}
            startIcon={variant === 'delete' ? <DeletePopupIcon /> : null}
            fullWidth={!onClick}
          >
            {buttonText ? buttonText : t(`popup.${variant}`)}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CustomPopup;
