//react
import { useMemo, FC } from 'react';
//react-router
import { NavLink } from 'react-router-dom';
//hooks
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useDeleteUser, useDeleteUsers } from '@api/mutations/user/user';
import { useAppUsers } from '@api/queries/user/appUser/appUser';
import { useTranslation } from 'react-i18next';
//containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
//components
import Loading from '@components/Loading/Loading';
//interfaces
import { User } from '@interfaces/user/user';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import { Alert, Card, Grid } from '@mui/material';
//recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
//atom
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
//helpers
import { checkPermissionFunc } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const AppUsersPage: FC = (): JSX.Element | null => {
  const { t } = useTranslation();

  const currentCMS = useRecoilValue(currentCMSAtom);
  const setPopup = useSetRecoilState(popupAtom);

  const onDeleteSuccesss = (data: SuccessResponse) => {
    refetch();
    setSuccessfullyDeletedPopup(true);
  };

  const onDeleteError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteUser } = useDeleteUser(onDeleteSuccesss, onDeleteError);
  const { mutate: deleteUsers } = useDeleteUsers(onDeleteSuccesss, onDeleteError);

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    sendEmailEventHandler,
    deleteEventHandler,
    deletedNames,
    deleteIds,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    successfullyDeletedPopup,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    anchorElActionButton,
    handleClick,
    handleClose,
    handleDeleteRow
  } = useTablesLogic(deleteUser, deleteUsers, 'superAdminUsers');

  const {
    data,
    refetch,
    isLoading: isLoadingAppUsers
  } = useAppUsers(
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.gender.toString(),
    sortBy,
    sortDirection,
    onAppUsersError
  );

  function onAppUsersError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const columnsAllUsers = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name_and_surname'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => (
          <NavLink to={`/users/app-users/${row.original.id}`} end style={{ color: 'black' }}>
            {cell.getValue<string>()}{' '}
          </NavLink>
        )
      },
      {
        accessorKey: 'phone',
        header: t('phone'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'date_of_birth',
        header: t('date_of_birth'),
        minSize: 10,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );

  return (
    <Grid container>
      {successfullyDeletedPopup && deleteIds.length > 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('users')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('superAdminUsers.successMultiple')}
        </Alert>
      ) : successfullyDeletedPopup && deleteIds.length === 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('users')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('superAdminUsers.successSingle')}
        </Alert>
      ) : null}
      <Card sx={{ mx: 3 }} className="fullWidth">
        <TableHeaderActions
          disableActions={!deletedNames}
          tableVariant="appUsers"
          // sendEmailEventHandler={sendEmailEventHandler}
          deleteEventHandler={checkPermissionFunc(
            currentCMS,
            PermissionsEnum.delete_users,
            deleteEventHandler
          )}
          handleSearch={handleSearch}
          //sort
          handleSortTables={handleSortTables}
          checkSort={checkSort}
          handleClearSort={handleClearSort}
          //filter
          handleClearFilter={handleClearFilter}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorFilterEl={anchorEl}
          anchorElActionButton={anchorElActionButton}
          anchorMenuItem={anchorMenuItem}
          anchorSortEl={anchorSortEl}
          controlFilter={controlFilter}
          handleSubmitFilter={handleSubmitFilter}
          filterValue={filterValue}
        />
        <Grid item lg={12} ml={'20px'} mr={'20px'}>
          {data && !isLoadingAppUsers ? (
            <TableLayout
              tableVariant="appUsers"
              rows={data?.data?.data}
              columns={columnsAllUsers as MRT_ColumnDef<{}>[]}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={data?.data?.meta?.total}
              tableInstanceRef={tableInstanceRef}
              withCheckbox
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default AppUsersPage;
