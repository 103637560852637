// interfaces
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { AdminEmployeesKeyType } from '@interfaces/employees/employees';

// hooks
import useAdminEmployees from '@hooks/adminEmployees/useAdminEmployeesHook';
import { useMemo } from 'react';

// containers
import CardWrapper from '@containers/CardWrapper/CardWrapper';

// components
import { Box, Button, Card, CardContent, Grid, Typography, Drawer, Alert } from '@mui/material';

// atoms
import { popupAtom } from '@atoms/popupAtom';

// icons
import {
  // TODO: return after MVP
  // DeactivateIcon,
  DeleteIcon,
  EditIcon
} from '@components/icons/icons';
import CreateAdminEmployeeForm from '@components/Forms/AdminEmployees/CreateAdminEmployeeForm';

// theme
import theme from '@src/theme';
import Loading from '@components/Loading/Loading';

const EditEmployeePage = () => {
  const {
    t,
    employeeEditData,
    onEditEmployee,
    openEdit,
    onClose,
    handleCancel,
    control,
    isDirty,
    isValid,
    sportCenterId,
    handleSubmit,
    sportCenterRolesData,
    isLoadingRoles,
    isLoadingSingleEmployee,
    isLoadingDeleteSingleEmployee,
    onDeleteEmployee,
    employeeId,
    popup
  } = useAdminEmployees();

  const employesColumns = useMemo<MRT_ColumnDef<AdminEmployeesKeyType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        Cell: ({ row }) => (
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="subtitle1">{t(`${row.original.name}`).toString()}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{row.original?.value}</Typography>
            </Grid>
          </Grid>
        )
      }
    ],
    []
  );
  if (isLoadingSingleEmployee && isLoadingRoles && isLoadingDeleteSingleEmployee)
    return <Loading />;
  return (
    <Box p={3} pt={0}>
      <Box className="row justify-end wrap" p={2} pr={0} pt={0} gap={2}>
        {/* TODO: return after MVP */}
        {/* <Button
          variant="contained"
          color="info"
          sx={{
            color: 'white'
          }}
          endIcon={<DeactivateIcon fill="white" stroke="white" />}
        >
          {t('deactivate')}
        </Button> */}

        <Button
          variant="contained"
          color="error"
          endIcon={<DeleteIcon fill="white" stroke="white" viewBox="0 0 25 24" />}
          onClick={onDeleteEmployee}
        >
          {t('delete')}
        </Button>
      </Box>
      {popup.content && (
        <Grid container>
          <Alert severity="success" sx={{ position: 'sticky', zIndex: 200, width: '100%', m: 2 }}>
            {popup.content}
          </Alert>
        </Grid>
      )}
      <Card>
        <Box className="row justify-between" p={2}>
          <Typography variant="h6">{t('adminEmployeePage.edit.info_subtitle')}</Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'none',
              borderColor: theme.palette.sportyMateGray.light
            }}
            endIcon={
              <EditIcon
                fill={theme.palette.primary.main}
                stroke={theme.palette.primary.main}
                viewBox="0 0 25 24"
              />
            }
            onClick={() => onEditEmployee()}
          >
            {t('adminEmployeePage.edit.edit_button_text')}
          </Button>
        </Box>

        <CardContent>
          <MaterialReactTable
            columns={employesColumns}
            data={employeeEditData}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
            defaultColumn={{
              minSize: 20,
              maxSize: 200,
              size: 100
            }}
            muiTablePaperProps={{
              sx: {
                border: 'none',
                boxShadow: 'none'
              }
            }}
            muiTableHeadProps={{
              sx: { display: 'none' }
            }}
          />
        </CardContent>

        <Drawer
          open={openEdit}
          onClose={onClose}
          anchor="right"
          PaperProps={{
            sx: {
              width: '50%',
              padding: '30'
            }
          }}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <CardWrapper
              removeShadow
              title={t('adminEmployeePage.create.info_title')}
              subtitle={t('adminEmployeePage.create.info_subtitle')}
              children={
                <CreateAdminEmployeeForm
                  control={control}
                  t={t}
                  sportCenterId={sportCenterId}
                  sportCenterRoles={sportCenterRolesData}
                  isEditOpen={openEdit}
                />
              }
            />
            <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
              <Button variant="text" color="primary" onClick={handleCancel}>
                {t('quit')}
              </Button>

              <Button type="submit" variant="contained" disabled={!isDirty && !isValid}>
                {t('save')}
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Card>
    </Box>
  );
};

export default EditEmployeePage;
