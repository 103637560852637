// interfaces
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import { AdminCoachesKeyType } from '@interfaces/user/coaches/coaches';
// hooks
import { useTranslation } from 'react-i18next';
import useCoachesHook from '@hooks/adminCoaches/useCoachesHook';
import { useMemo } from 'react';
// containers
import CardWrapper from '@containers/CardWrapper/CardWrapper';
// forms
import AddCoachesBasicInfo from '@components/Forms/AddCoaches/AddCoachesBasicInfo';
import AddCoacgesFieldsAndPrice from '@components/Forms/AddCoaches/AddCoachesFieldsAndPrice';
// components
import { Box, Button, Card, CardContent, Grid, Typography, Drawer } from '@mui/material';
import Loading from '@components/Loading/Loading';
// icons
import { DeactivateIcon, DeleteIcon, EditIcon, PlusCircle } from '@components/icons/icons';
// theme
import theme from '@src/theme';

const EditCoachesPage = () => {
  const {
    handleSubmit,
    control,
    courtSport,
    isLoadingGetCourtSport,
    isValid,
    isDirty,
    adminCoachesEditData,
    openEdit,
    setOpenEdit,
    handleCancel,
    onEditCoach,
    onAddMoreHours,
    isAddMoreHours,
    onDeactivateCoach,
    onDeleteCoach,
    isLoadingSingleCoach,
    isLoadingUpdateCoach
  } = useCoachesHook();
  const { t } = useTranslation();
  const coachesColumns = useMemo<MRT_ColumnDef<AdminCoachesKeyType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        Cell: ({ row }) => (
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="subtitle1">{t(`${row.original.name}`).toString()}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography>{row.original?.value?.toString()}</Typography>
            </Grid>
          </Grid>
        )
      }
    ],
    []
  );
  if (isLoadingGetCourtSport && isLoadingSingleCoach && isLoadingUpdateCoach) {
    return <Loading />;
  }
  return (
    <Box p={3}>
      <Box display="flex" flexDirection="row" p={2} pr={0} justifyContent="flex-end" gap={2}>
        <Button
          variant="contained"
          color="primary"
          sx={{
            color: 'white',
            textTransform: 'none'
          }}
          onClick={onAddMoreHours}
          endIcon={
            <PlusCircle fill="white" stroke="white" style={{ width: '15px', height: '15px' }} />
          }
        >
          {t('coachesPage.create.add_more_appointments')}
        </Button>
        {/* <Button
          variant="contained"
          color="info"
          sx={{
            color: 'white',
            textTransform: 'none'
          }}
          onClick={onDeactivateCoach}
          endIcon={
            <DeactivateIcon fill="white" stroke="white" style={{ width: '20px', height: '20px' }} />
          }
        >
          {t('deactivate')}
        </Button> */}

        <Button
          variant="contained"
          color="error"
          sx={{
            textTransform: 'none'
          }}
          onClick={onDeleteCoach}
          endIcon={
            <DeleteIcon fill="white" stroke="white" style={{ width: '15px', height: '15px' }} />
          }
        >
          {t('coachesPage.create.delete_coach')}
        </Button>
      </Box>
      <Card>
        <Box display="flex" flexDirection="row" justifyContent="space-between" p={2}>
          <Typography variant="h6">{t('coachesPage.edit_title')}</Typography>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: 'none',
              borderColor: theme.palette.sportyMateGray.light
            }}
            endIcon={
              <EditIcon
                fill={theme.palette.primary.main}
                stroke={theme.palette.primary.main}
                viewBox="0 0 25 24"
              />
            }
            onClick={() => onEditCoach()}
          >
            {t('adminEmployeePage.edit.edit_button_text')}
          </Button>
        </Box>

        <CardContent>
          <MaterialReactTable
            columns={coachesColumns}
            data={adminCoachesEditData}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
            defaultColumn={{
              minSize: 20,
              maxSize: 200,
              size: 100
            }}
            muiTablePaperProps={{
              sx: {
                border: 'none',
                boxShadow: 'none'
              }
            }}
            muiTableHeadProps={{
              sx: { display: 'none' }
            }}
          />
        </CardContent>

        <Drawer
          open={openEdit}
          onClose={() => setOpenEdit(!openEdit)}
          anchor="right"
          PaperProps={{
            sx: {
              width: '50%',
              padding: '30'
            }
          }}
        >
          <Box component="form" onSubmit={handleSubmit}>
            <CardWrapper
              removeShadow
              title={t('coachesPage.create.info_title')}
              subtitle={t('coachesPage.create.info_subtitle')}
              children={
                <AddCoachesBasicInfo control={control} t={t} isEditHours={isAddMoreHours} />
              }
            />
            <CardWrapper
              removeShadow
              title={t('fieldsAndPrice')}
              subtitle={t('coachesPage.create.fields_price_subtitle')}
              children={
                <AddCoacgesFieldsAndPrice control={control} t={t} courtSport={courtSport} />
              }
            />
            <Box display="flex" flexDirection="row" p={2} justifyContent="flex-end" gap={2}>
              <Button variant="text" color="primary" onClick={handleCancel}>
                {t('quit')}
              </Button>

              <Button type="submit" variant="contained" disabled={!isDirty && !isValid}>
                {t('save')}
              </Button>
            </Box>
          </Box>
        </Drawer>
      </Card>
    </Box>
  );
};

export default EditCoachesPage;
