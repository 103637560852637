//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//error
import { ErrorRes, ErrorResponse } from '@interfaces/apiResponse';
import {
  AutocompleteRequest,
  AutocompleteResponse,
  LocationPosition,
  LocationResponse
} from '@interfaces/locationsGeoCode/locationsGeoCode';
import {
  getLocation,
  getLocationsList,
  getReverseGeocode
} from '@services/locationsGeoCode/locationsGeoCode';

export const useGetLocationsList = (
  onSuccess: (data: AutocompleteResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: AutocompleteResponse }, // response
  ErrorRes,
  AutocompleteRequest, // request
  (data: AutocompleteResponse) => void // on success
> =>
  useMutation({
    mutationFn: getLocationsList,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useGetLocation = (
  onSuccess: (data: LocationResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: LocationResponse }, // response
  ErrorRes,
  string, // request
  (data: LocationResponse) => void // on success
> =>
  useMutation({
    mutationFn: getLocation,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
export const useGetReverseGeocode = (
  onSuccess: (data: AutocompleteResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: AutocompleteResponse }, // response
  ErrorRes,
  LocationPosition, // request
  (data: AutocompleteResponse) => void // on success
> =>
  useMutation({
    mutationFn: getReverseGeocode,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
