//interfaces
import { OwnerResponse } from '@src/domain/interfaces/user/owner/owner';
//axios instance
import axiosInstance from '@services/config';

export const owners = (
  page: number,
  per_page: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): Promise<{ data: OwnerResponse }> => {
  return axiosInstance.get('users/sport-center-owners', {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });
};
