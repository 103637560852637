// store
import { atom } from 'recoil';
import { PopupVariantType, PopupIconsType } from '@interfaces/popup';

type PopupAtomType = {
  open: boolean;
  title: string;
  content: string;
  variant: keyof PopupVariantType;
  onClick?: () => void;
  onClose?: () => void;
  onCancel?: () => void;
  tooltipTitle?: string | string[];
  stayOpen?: boolean;
  buttonText?: string;
  back?: string;
};

const popupAtom = atom<PopupAtomType>({
  key: 'popupAtomState',
  default: {
    open: false,
    title: '',
    content: '',
    variant: 'success'
  }
});

export { popupAtom };
