import { useCallback, useState } from 'react';

// components
import {
  Button,
  ButtonProps,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  styled
} from '@mui/material';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { DesktopDatePicker, LocalizationProvider, DateView } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/sr-cyrl';
import { useTranslation } from 'react-i18next';
import theme from '@src/theme';
import { ArrowDownIcon } from '@components/icons/icons';

const StyledButton = styled((props: ButtonProps) => <Button {...props} variant="outlined" />)({
  borderRadius: '10px',
  borderColor: '#C9C9C9',
  color: theme.palette.text.secondary
});

interface IPickerViewProps {
  format: string;
  views: DateView[];
}

export type FinanceCardNavigationProps = {
  value?: string;
  viewType: DateView;
  onChange: (date: string) => void;
  maxDate?: string;
  minDate?: string;
  withChangeView?: boolean;
  setViewType?: (view: DateView) => void;
};

const FinanceCardNavigation = ({
  value,
  viewType,
  onChange,
  maxDate,
  minDate,
  withChangeView = false,
  setViewType
}: FinanceCardNavigationProps) => {
  const { t } = useTranslation();
  const lng = localStorage.getItem('lng');
  dayjs.locale(lng === 'sr' ? 'sr' : '');
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Dayjs>(dayjs(value));
  const [labelValue, setLabelValue] = useState<Dayjs>(dayjs(value));
  const valueThatChanges = !open ? labelValue : date;
  const isItToday = date.isSame(dayjs(), 'day');
  const isItYesterday = date.isSame(dayjs().subtract(1, 'day'), 'day');
  const dateFormat = 'DD. MMM YYYY';
  const [labelRef, setLabelRef] = useState<HTMLButtonElement | null>(null);
  const [view, setView] = useState<DateView>(viewType);
  const [openSelect, setOpenSelect] = useState(false);
  const [buttonSelectRef, setButtonSelectRef] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (open) {
      return handleClose();
    }
    setLabelRef(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (openSelect) {
      return handleCloseSelect();
    }
    setButtonSelectRef(event.currentTarget);
    setOpenSelect(true);
  };
  const handleCloseSelect = () => {
    setOpenSelect(false);
  };
  const id = open ? 'openDatePickerButton' : undefined;
  const idSelect = openSelect ? 'openSelectButton' : undefined;
  const getViewsFormat = useCallback((): IPickerViewProps => {
    switch (withChangeView ? view : viewType) {
      case 'month':
        return {
          format: 'MMMM YYYY',
          views: ['year', 'month']
        };
      case 'year':
        return {
          format: 'YYYY',
          views: ['year']
        };
      default:
        return {
          format: dateFormat,
          views: ['year', 'month', 'day']
        };
    }
  }, [viewType, view, withChangeView]);

  const handleOnChange = (value: dayjs.Dayjs | null) => {
    if (!value) return;
    setLabelValue(value);
    onChange(value.format());
    setDate(value);
    setOpen(false);
  };

  const renderSelectViewType = () => {
    return (
      <FormControl>
        <Select
          id={idSelect}
          open={openSelect}
          onClose={handleCloseSelect}
          value={view}
          onChange={e => {
            if (setViewType) {
              setViewType(e.target.value as DateView);
              setView(e.target.value as DateView);
            } else {
              setView(e.target.value as DateView);
            }
          }}
          sx={{ display: 'none' }}
          MenuProps={{
            anchorEl: buttonSelectRef,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left'
            }
          }}
        >
          <MenuItem value="day">{t('day')}</MenuItem>
          <MenuItem value="month">{t('month')}</MenuItem>
          <MenuItem value="year">{t('year')}</MenuItem>
        </Select>
      </FormControl>
    );
  };
  const labelText =
    (isItToday || isItYesterday) && viewType === 'day'
      ? `${t(isItToday ? 'today' : 'yesterday')}`
      : date.format(viewType === 'month' ? 'MMMM YYYY' : viewType === 'year' ? 'YYYY' : dateFormat);

  const handleChangeDate = (changeType: 'add' | 'subtract') => {
    const tempDate: Dayjs = valueThatChanges[changeType](1, viewType);

    handleOnChange(tempDate);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" spacing={2} position="relative">
        <StyledButton onClick={() => handleChangeDate('subtract')}>
          <NavigateBefore sx={{ fill: '#C9C9C9' }} />
        </StyledButton>{' '}
        {withChangeView ? (
          <>
            <Stack
              direction="row"
              sx={{ border: '1px solid #C9C9C9', borderRadius: '10px', px: 2 }}
            >
              <Button variant="text" color="inherit" onClick={handleClick}>
                {labelText}
              </Button>
              <IconButton onClick={handleOpenSelect} aria-describedby={idSelect} size="small">
                <ArrowDownIcon />
              </IconButton>
              {renderSelectViewType()}
            </Stack>
          </>
        ) : (
          <StyledButton aria-describedby={id} onClick={handleClick}>
            {labelText}{' '}
          </StyledButton>
        )}
        <StyledButton disabled={isItToday} onClick={() => handleChangeDate('add')}>
          <NavigateNext sx={{ fill: '#C9C9C9' }} />
        </StyledButton>{' '}
        <DesktopDatePicker
          value={date}
          open={open}
          onAccept={handleOnChange}
          onClose={handleClose}
          view={withChangeView ? view : viewType}
          shouldDisableMonth={() => {
            if (!withChangeView) {
              viewType === 'year';
            }
            return false;
          }}
          closeOnSelect
          disableFuture
          sx={{ display: 'none' }}
          slots={{
            toolbar: undefined
          }}
          slotProps={{
            popper: {
              id: id,
              anchorEl: labelRef,
              placement: 'bottom-start'
            }
          }}
          yearsPerRow={3}
          {...{ views: getViewsFormat().views }}
          {...{ format: getViewsFormat().format }}
          {...(minDate && { minDate: dayjs(minDate) })}
          {...(maxDate && { maxDate: dayjs(maxDate) })}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default FinanceCardNavigation;
