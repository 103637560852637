//hooks
import { useNavigate } from 'react-router-dom';
import { useCreateCportCenterStep3 } from '@hooks/createSportCenterStep3/useCreateSportCenterStep3';
import { useNonWorkingDays } from '@hooks/useNonWorkingDays/useNonWorkingDays';
import { useTranslation } from 'react-i18next';
//components
import { Controller } from 'react-hook-form';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomTimePicker from '@components/CustomTimePicker/CustomTimePicker';
import Loading from '@components/Loading/Loading';
//datetime
import dayjs from 'dayjs';
//helpers
import { getAvailableTimesForTimePicker } from '@helpers/utility';

const CreateNonWorkingDays = () => {
  const { courts } = useCreateCportCenterStep3();

  const {
    control,
    handleSubmit,
    isValid,
    isLoadingSingle,
    success,
    sportCenterId,
    lng,
    timePickerTimes
  } = useNonWorkingDays();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const arrayOfAvailableTimes: string[] = getAvailableTimesForTimePicker(
    timePickerTimes.minTime,
    timePickerTimes.maxTime
  );

  if (isLoadingSingle) {
    return <Loading />;
  }
  return (
    <Box p={2}>
      {success && <Alert sx={{ position: 'fixed', width: '75%', zIndex: 1 }}>{success}</Alert>}
      <Card>
        <CardHeader sx={{ px: 5 }} title={t('non_working_days.infoTitle')} />
        <CardContent>
          <Grid container spacing={2} px={5}>
            <Grid item xs={12}>
              <Controller
                name="name_translates.sr"
                defaultValue=""
                control={control}
                rules={{ required: !lng || lng === 'sr' ? true : false }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    label={
                      !lng || lng === 'sr'
                        ? t('non_working_days.nameSerbian') + ' *'
                        : t('non_working_days.nameSerbianOptional')
                    }
                    sx={{ width: '100%' }}
                    error={invalid}
                    helperText={error && error.message}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="name_translates.en"
                defaultValue=""
                control={control}
                rules={{ required: lng === 'en' ? true : false }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    label={
                      lng === 'en'
                        ? t('non_working_days.nameEnglish') + ' *'
                        : t('non_working_days.nameEnglishOptional')
                    }
                    sx={{ width: '100%' }}
                    error={invalid}
                    helperText={error && error.message}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="court_ids"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <FormControl fullWidth>
                    <InputLabel shrink={true} error={invalid}>
                      {t('non_working_days.appendRuleTo')}
                    </InputLabel>
                    <Select
                      multiple
                      {...field}
                      input={<OutlinedInput notched label={t('non_working_days.appendRuleTo')} />}
                      error={invalid}
                    >
                      {courts.map(court => (
                        <MenuItem key={court.id} value={court.id.toString()}>
                          {court.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="date_start"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <>
                    <DatePicker
                      label={t('date_from') + ' *'}
                      format="DD.MM.YYYY"
                      sx={{ width: '100%' }}
                      {...field}
                      value={dayjs(field.value)}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true }
                        }
                      }}
                    />
                    {error && <FormHelperText color="error">{error.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="date_end"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <>
                    <DatePicker
                      label={t('date_to') + ' *'}
                      format="DD. MMM YYYY"
                      sx={{ width: '100%' }}
                      {...field}
                      value={dayjs(field.value)}
                      slotProps={{
                        textField: {
                          InputLabelProps: { shrink: true },
                          color: invalid ? 'error' : 'primary'
                        }
                      }}
                    />
                    {error && <FormHelperText color="error">{error.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="time_start"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <>
                    <CustomTimePicker
                      field={field}
                      id="nonWorkingDays.time_start"
                      labelId="nonWorkingDays.time_start-label"
                      label={t('non_working_days.time_start')}
                      invalid={invalid}
                      availableTimes={arrayOfAvailableTimes}
                      helperText={error && error.message}
                      className="fullWidth"
                    />
                  </>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="time_end"
                defaultValue=""
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <>
                    <CustomTimePicker
                      field={field}
                      id="nonWorkingDays.time_end"
                      labelId="nonWorkingDays.time_end-label"
                      label={t('non_working_days.time_end')}
                      invalid={invalid}
                      availableTimes={arrayOfAvailableTimes}
                      helperText={error && error.message}
                      className="fullWidth"
                    />
                  </>
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Box mt={2} display={'flex'} justifyContent={'flex-end'}>
        <Button onClick={() => navigate(`/settings/${sportCenterId}/non-working-days`)}>
          {' '}
          {t('cancel')}
        </Button>
        <Button variant="contained" disabled={!isValid} onClick={handleSubmit}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateNonWorkingDays;
