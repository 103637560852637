//react
import React from 'react';
//components
import { Box, Typography } from '@mui/material';
//icon
import { HomePageIcon } from '@components/icons/homePageIcon';
//hook
import { useTranslation } from 'react-i18next';

const HomePage: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Box p={3}>
      <Typography variant="h3" fontWeight={'bold'} textAlign={'center'}>
        {t('welcomeBackMessage')}
      </Typography>

      <Box mt={3}>
        <HomePageIcon />
      </Box>
    </Box>
  );
};
export default HomePage;
