//icons
import { RoundedMinusIcon, RoundedPlusIcon } from '@components/icons/icons';
//components
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import UploadImage from '@containers/ImageUpload/ImageUpload';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from '@mui/material';
//react use form
import { Controller } from 'react-hook-form';
//hooks
import { useTranslation } from 'react-i18next';
import { useCreateSportHook } from '@hooks/useCreateSportHook/useCreateSportHook';
import { useNavigate } from 'react-router-dom';

import theme from '@src/theme';

import { SportCustomFieldsTypeEnum } from '@enum/sportEnum';

const CreateSportPage = () => {
  const {
    handleSubmit,
    onSubmit,
    fields,
    append,
    setValue,
    control,
    watch,
    errors,
    inputTypes,
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    title,
    subtitle,
    handleDeleteCustomField,
    handelDeleteBenefit,
    clearErrors,
    lng,
    handleDeleteOption,
    handleAddOption,
    handleAddBenefit
  } = useCreateSportHook();

  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <Box p={3}>
      <CardWrapper title={title} subtitle={subtitle} isForm={true}>
        <Grid container spacing={2}>
          <Grid container justifyContent={'flex-end'} pl={9}>
            <Grid item xs={12}>
              <Controller
                rules={{ required: !lng || lng === 'sr' ? true : false }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={
                      !lng || lng === 'sr'
                        ? t('sportPage.sportName') + ' ' + t('sportPage.in_serbian') + ' *'
                        : t('sportPage.sportName') +
                          ' ' +
                          t('sportPage.in_serbian') +
                          ' * ' +
                          t('sportPage.optional')
                    }
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
                name={`name_translates.sr`}
                control={control}
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <Controller
                rules={{ required: lng === 'en' ? true : false }}
                render={({ field, fieldState: { invalid, error } }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label={
                      lng === 'en'
                        ? t('sportPage.sportName') + ' ' + t('sportPage.in_english') + ' *'
                        : t('sportPage.sportName') +
                          ' ' +
                          t('sportPage.in_english') +
                          ' * ' +
                          t('sportPage.optional')
                    }
                    error={invalid}
                    helperText={error?.message}
                  />
                )}
                name={`name_translates.en`}
                control={control}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            {fields.map((item, index) => (
              <Box display={'flex'} mb={2} gap={2} key={index}>
                <IconButton
                  onClick={() => {
                    handleDeleteCustomField(index, item.id);
                  }}
                >
                  <RoundedMinusIcon />
                </IconButton>

                <Box display={'flex'} gap={2} flexDirection={'column'} width={'100%'}>
                  <Box
                    display={'flex'}
                    gap={2}
                    width={'100%'}
                    flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                  >
                    <Controller
                      render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                          {...field}
                          label={
                            !lng || lng === 'sr'
                              ? t('characteristic') + ' ' + t('in_serbian') + ' *'
                              : t('characteristic') + ' ' + t('in_serbian') + ' * ' + t('optional')
                          }
                          fullWidth
                          error={
                            (!lng || lng === 'sr') &&
                            (!!errors.custom_fields?.[index]?.label ||
                              !!errors.custom_fields?.[index]?.label_translates?.sr)
                          }
                          helperText={
                            (!lng || lng === 'sr') && errors.custom_fields?.[index]?.label?.message
                          }
                        />
                      )}
                      name={`custom_fields.${index}.label_translates.sr`}
                      defaultValue=""
                      control={control}
                      rules={{ required: !lng || lng === 'sr' ? true : false }}
                    />

                    <Controller
                      render={({ field, fieldState: { invalid, error } }) => (
                        <TextField
                          {...field}
                          label={
                            lng === 'en'
                              ? t('characteristic') + ' ' + t('in_english') + ' *'
                              : t('characteristic') + ' ' + t('in_english') + ' * ' + t('optional')
                          }
                          fullWidth
                          error={
                            lng === 'en' &&
                            (!!errors.custom_fields?.[index]?.label ||
                              !!errors.custom_fields?.[index]?.label_translates?.en)
                          }
                          helperText={lng === 'en' && errors.custom_fields?.[index]?.label?.message}
                        />
                      )}
                      name={`custom_fields.${index}.label_translates.en`}
                      control={control}
                      rules={{ required: lng === 'en' ? true : false }}
                      defaultValue=""
                    />
                  </Box>

                  <Box>
                    <Controller
                      name={`custom_fields.${index}.type`}
                      control={control}
                      rules={{ required: true }}
                      render={({ field, fieldState: { invalid, error } }) => (
                        <FormControl sx={{ width: '100%' }}>
                          <InputLabel error={invalid}>{t('sportPage.fieldType')}</InputLabel>
                          <Select
                            {...field}
                            label={t('sportPage.fieldType')}
                            variant="outlined"
                            sx={{ width: '100%' }}
                            error={invalid}
                            onChange={e => {
                              field.onChange(e);

                              if (e.target.value === SportCustomFieldsTypeEnum.select) {
                                handleAddOption(index);
                              } else {
                                setValue(`custom_fields.${index}.options`, []);
                                setValue(`custom_fields.${index}.options_translates.sr`, []);
                                setValue(`custom_fields.${index}.options_translates.en`, []);
                              }
                            }}
                          >
                            {inputTypes.map(type => (
                              <MenuItem key={type} value={type}>
                                {t(`${type}`)}
                              </MenuItem>
                            ))}
                          </Select>
                          {error && <FormHelperText error>{error.message}</FormHelperText>}
                        </FormControl>
                      )}
                    />
                    {watch(`custom_fields.${index}.type`) === SportCustomFieldsTypeEnum.select && (
                      <>
                        {watch(`custom_fields.${index}.options`)?.map((op, i) => (
                          <FormControl
                            fullWidth
                            key={i}
                            sx={{
                              display: 'flex',
                              flexWrap: 'no-wrap',
                              gap: 2,
                              flexDirection: 'row'
                            }}
                          >
                            <Box display={'flex'} gap={2} mt={2} width={'100%'}>
                              <IconButton
                                onClick={() => {
                                  handleDeleteOption(index, i);
                                }}
                                disabled={i === 0}
                              >
                                <RoundedMinusIcon
                                  fill={i === 0 ? theme.palette.grey[400] : undefined}
                                />
                              </IconButton>
                              <Box
                                display={'flex'}
                                width={'100%'}
                                gap={2}
                                flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                              >
                                <TextField
                                  sx={{ width: '100%' }}
                                  label={
                                    !lng || lng === 'sr'
                                      ? t('option') + ' ' + t('in_serbian') + ' *'
                                      : t('option') + ' ' + t('in_serbian') + ' * ' + t('optional')
                                  }
                                  value={watch(`custom_fields.${index}.options_translates.sr.${i}`)}
                                  onChange={ev => {
                                    if (!!errors.custom_fields?.[index]?.options?.[i])
                                      clearErrors();
                                    setValue(
                                      `custom_fields.${index}.options_translates.sr.${i}`,
                                      ev.target.value
                                    );
                                  }}
                                  error={
                                    (!lng || lng === 'sr') &&
                                    !!errors.custom_fields?.[index]?.options?.[i]
                                  }
                                  helperText={
                                    (!lng || lng === 'sr') &&
                                    errors.custom_fields?.[index]?.options?.[i]?.message
                                  }
                                />
                                <TextField
                                  sx={{ width: '100%' }}
                                  // label={'opcija na eng'}
                                  label={
                                    lng === 'en'
                                      ? t('option') + ' ' + t('in_english') + ' *'
                                      : t('option') + ' ' + t('in_english') + ' * ' + t('optional')
                                  }
                                  value={watch(`custom_fields.${index}.options_translates.en.${i}`)}
                                  onChange={ev => {
                                    if (!!errors.custom_fields?.[index]?.options?.[i])
                                      clearErrors();

                                    setValue(
                                      `custom_fields.${index}.options_translates.en.${i}`,
                                      ev.target.value
                                    );
                                  }}
                                  error={
                                    lng === 'en' && !!errors.custom_fields?.[index]?.options?.[i]
                                  }
                                  helperText={
                                    lng === 'en' &&
                                    errors.custom_fields?.[index]?.options?.[i]?.message
                                  }
                                />
                              </Box>
                            </Box>
                          </FormControl>
                        ))}
                        {(watch(`custom_fields.${index}.options`)?.length ?? 0) > 0 && (
                          <Tooltip title={t('add_option')}>
                            <IconButton
                              onClick={() => {
                                handleAddOption(index);
                              }}
                            >
                              <RoundedPlusIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </Box>
                </Box>
              </Box>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Tooltip title={t('add_field')}>
              <IconButton onClick={() => append({ type: '', label: '', options: [] })}>
                <RoundedPlusIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            {watch('benefits')?.options?.map((el, i) => (
              <FormControl
                fullWidth
                sx={{ display: 'flex', flexWrap: 'no-wrap', gap: 2, flexDirection: 'row' }}
                key={i}
              >
                <IconButton
                  onClick={() => {
                    handelDeleteBenefit(i, el);
                  }}
                >
                  <RoundedMinusIcon />
                </IconButton>

                <Box
                  width={'100%'}
                  display={'flex'}
                  gap={2}
                  mb={2}
                  flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
                >
                  <TextField
                    sx={{ width: { xs: '100%', sm: '50%' } }}
                    label={
                      !lng || lng === 'sr'
                        ? t('benefits') + ' ' + t('in_serbian') + ' *'
                        : t('benefits') + ' ' + t('in_serbian') + ' * ' + t('optional')
                    }
                    value={watch(`benefits.options_translates.sr.${i}`)}
                    onChange={ev => {
                      if (!!errors.benefits?.options_translates?.sr?.[i]) clearErrors();
                      if (!!errors.custom_fields?.[fields.length]?.options?.[i]) clearErrors();

                      setValue(`benefits.options_translates.sr.${i}`, ev.target.value);
                    }}
                    error={lng === 'sr' && !!errors.custom_fields?.[fields.length]?.options?.[i]}
                    helperText={
                      lng === 'sr' && errors.custom_fields?.[fields.length]?.options?.[i]?.message
                    }
                  />
                  <TextField
                    sx={{ width: { xs: '100%', sm: '50%' } }}
                    label={
                      lng === 'en'
                        ? t('benefits') + ' ' + t('in_english') + ' *'
                        : t('benefits') + ' ' + t('in_english') + ' * ' + t('optional')
                    }
                    value={watch(`benefits.options_translates.en.${i}`)}
                    onChange={ev => {
                      if (!!errors.benefits?.options_translates?.en?.[i]) clearErrors();
                      if (!!errors.custom_fields?.[fields.length]?.options?.[i]) clearErrors();
                      setValue(`benefits.options_translates.en.${i}`, ev.target.value);
                    }}
                    error={lng === 'en' && !!errors.custom_fields?.[fields.length]?.options?.[i]}
                    helperText={
                      lng === 'en' && errors.custom_fields?.[fields.length]?.options?.[i]?.message
                    }
                  />
                </Box>
              </FormControl>
            ))}
          </Grid>

          <Grid item xs={12}>
            <Tooltip title={t('add_beneft')}>
              <IconButton
                onClick={() => {
                  handleAddBenefit();
                }}
              >
                <RoundedPlusIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <UploadImage
          title={t('sportPage.sportIcon')}
          images={images}
          onDropCallback={onDropCallback}
          handleDelete={handleDelete}
          uploadPercent={uploadPercent}
        />
      </CardWrapper>

      <Box display={'flex'} justifyContent={'flex-end'} mt={2} gap={2}>
        <Button onClick={() => navigate(-1)}>{t('cancel')}</Button>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateSportPage;
