//react
import { useState, FC } from 'react';
//recoil
import { useSetRecoilState } from 'recoil';
//router
import { Link, useNavigate } from 'react-router-dom';
//react-i18next, translation
import { useTranslation } from 'react-i18next';
//atoms
import { userAtom } from '@atoms/userAtom';
//interfaces
import { LoginErrorResponse, LoginResponse } from '@interfaces/user/login';
import { User, UserErrorResponse } from '@interfaces/user/user';
//hooks
import { useSetLoginUser } from '@hooks/useLoginUser/useSetLoginUser';
//mutations
import { useLogin } from '@api/mutations/user/login';
import { useUser } from '@api/mutations/user/user';
//mui
import Button from '@mui/material/Button';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  FormHelperText,
  Box
} from '@mui/material';
//icons
import { VisibleOffIcon, VisibleOnIcon } from '@components/icons/icons';
//form hooks
import { SubmitHandler, useForm } from 'react-hook-form';

type FormData = {
  email: string;
  password: string;
};

const LoginPage: FC = (): JSX.Element => {
  const { setUserCMS } = useSetLoginUser();

  const setUser = useSetRecoilState(userAtom);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors }
  } = useForm({
    defaultValues: {
      email: '',
      password: ''
    }
  });

  const onUserSuccess = (data: User) => {
    setUser(data);
    setUserCMS(data);
  };

  const onUserError = (err: UserErrorResponse): void => {
    if (err.status === 442) navigate('/email-not-verified');
  };

  const { mutate: me } = useUser(onUserSuccess, onUserError);

  const onLoginSuccess = (data: LoginResponse): void => {
    me('');
  };

  const onLoginError = (err: LoginErrorResponse): void => {
    let other: boolean = true;
    if ('email' in err.response.data.errors) {
      setError('email', { type: 'custom', message: err.response.data.errors.email[0] });
      other = false;
    }
    if ('password' in err.response.data.errors) {
      setError('password', { type: 'custom', message: err.response.data.errors.password[0] });
      other = false;
    }
    if (other) setError('root', { type: 'custom', message: err.response.data.message });
  };

  const { mutate: submitLogin } = useLogin(onLoginSuccess, onLoginError);

  const submitForm: SubmitHandler<FormData> = async (data, e) => {
    e?.preventDefault();

    let isValid = await trigger(['email', 'password']);

    if (isValid) {
      submitLogin(data);
    }
  };
  return (
    <Box width={{ xs: '80%', sm: '70%' }} mt={{ xs: 4, sm: 0 }}>
      <Typography
        variant="h4"
        fontSize={{ xs: '36px', sm: '48px' }}
        color={'#121212'}
        mb={{ xs: -4, sm: 0 }}
        ml={-0.5}
        sx={{
          textAlign: 'left',
          letterSpacing: '-3.5px',
          fontWeight: 500
        }}
      >
        {t('loginPage.welcome_back')}
      </Typography>
      <Typography
        display={{ xs: 'none', sm: 'flex' }}
        fontSize={'14px'}
        color={'#3D3D3D'}
        sx={{
          textAlign: { xs: 'center', sm: 'left', md: 'left' }
        }}
      >
        {t('loginPage.sub_title')}
      </Typography>

      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '42px',
          maxWidth: '430px'
        }}
        onSubmit={handleSubmit(submitForm)}
      >
        {errors.root && <FormHelperText error>{errors.root.message}</FormHelperText>}
        <Typography mb={1}>{t('email')}</Typography>
        <FormControl variant="outlined" sx={{ maxWidth: '430px' }}>
          <InputLabel htmlFor="outlined-email" error={!!errors.email}>
            {t('email')}
          </InputLabel>
          <OutlinedInput label="Email" {...register('email')} sx={{ background: '#F5F7FA' }} />
          {!!errors.email && (
            <FormHelperText error id="error-email">
              {errors.email.message}
            </FormHelperText>
          )}
        </FormControl>
        <Typography mb={-2.5} mt={3}>
          {t('loginPage.password')}
        </Typography>
        <FormControl variant="outlined" sx={{ maxWidth: '430px', marginTop: '27px' }}>
          <InputLabel htmlFor="outlined-password" error={!!errors.password}>
            {t('loginPage.enter_password')}
          </InputLabel>
          <OutlinedInput
            sx={{ background: '#F5F7FA' }}
            label={t('loginPage.enter_password')}
            {...register('password')}
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(show => !show)}
                  edge="end"
                >
                  {showPassword ? <VisibleOnIcon /> : <VisibleOffIcon />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!!errors.password && (
            <FormHelperText error id="error-password">
              {errors.password.message}
            </FormHelperText>
          )}
        </FormControl>

        <Link
          to={'/recover-password'}
          style={{ textDecoration: 'none', marginTop: '12px', marginBottom: '23px' }}
        >
          <Typography
            variant="body2"
            color="#3D3D3D"
            sx={{
              textAlign: 'right'
            }}
          >
            {t('loginPage.forgotPassword')}
          </Typography>
        </Link>

        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ height: '56px', fontWeight: 700, fontSize: '16px', maxWidth: '430px' }}
        >
          {t('loginPage.buttonLogin')}
        </Button>
      </form>
    </Box>
  );
};

export default LoginPage;
