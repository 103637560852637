// components

import { ResponsiveContainer, PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
// styles
import '@styles/components/charts.scss';
// helpers
import { randomColorsForGraphs as COLORS } from '@helpers/utility';

type PieLabelProps = {
  cx: number;
  cy: number;
  startAngle: number;
  endAngle: number;
  paddingAngle: number;
  innerRadius: number;
  outerRadius: number;
  cornerRadius: number;
  midAngle: number;
  percent: number;
  nameKey: string;
};
export type PieDataType = {
  name: string;
  value: number;
};
type PieChartProps = {
  data: PieDataType[];
};
const PieChartSM = ({ data }: PieChartProps): JSX.Element => {
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (props: PieLabelProps) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, nameKey } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        id={nameKey}
        key={nameKey}
        fontFamily="Inter"
        fontSize={12}
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip itemStyle={{ fontFamily: 'Inter' }} />
        <Legend iconType="circle" />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartSM;
