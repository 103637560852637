// hooks
import { Controller } from 'react-hook-form';
// components
import { Typography, Box, Grid, FormHelperText, Avatar, Button } from '@mui/material';
// icons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// types
import { Control } from 'react-hook-form';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
import { useTranslation } from 'react-i18next';

type LogoProps = {
  control: Control<CreateSportCenterType | any>;
  handleOnLogoChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleLogoClick: (event: React.MouseEvent<HTMLLabelElement>) => void;
  name?: string;
  title?: string;
};
const Logo = ({ control, handleLogoClick, handleOnLogoChange, name, title }: LogoProps) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} py={2}>
      <Grid item xs={12}>
        <Typography variant="subtitle2" color="GrayText" sx={{ width: '100%', pb: '7px' }}>
          {title ? title : t('sportCenters.stepOne.logoTitle')}
        </Typography>
      </Grid>
      <Controller
        name={name ? name : 'basic_info.logo'}
        control={control}
        defaultValue={undefined}
        render={({ field: { value }, fieldState: { error } }) => (
          <Grid item xs={12} sx={{ display: 'flex' }}>
            {!value ? (
              <Avatar alt="Avatar" />
            ) : (
              <Avatar
                src={value ? value?.src : ''}
                alt={'logo'}
                sx={{ width: '80px', height: '80px' }}
              />
            )}
            <Box>
              {!value ? (
                <Button variant="text" component="label" startIcon={<AddIcon />}>
                  {t('add')}
                  <input
                    aria-valuemax={3000}
                    onChange={e => handleOnLogoChange(e)}
                    hidden
                    accept="image/*"
                    type="file"
                  />
                </Button>
              ) : (
                <Button
                  variant="text"
                  component="label"
                  startIcon={<RemoveIcon />}
                  onClick={e => handleLogoClick(e)}
                >
                  {t('delete')}
                </Button>
              )}

              {error && <FormHelperText error> {error.message}</FormHelperText>}
            </Box>
          </Grid>
        )}
      />
    </Grid>
  );
};

export default Logo;
