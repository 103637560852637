//  interfaces
import { ControllerRenderProps } from 'react-hook-form';
// components
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SxProps,
  SelectChangeEvent
} from '@mui/material';
import { TimeIcon } from '@components/icons/icons';
// styles
import '@styles/components/customTimePicker.scss';

type CustomTimePickerProps = {
  label: string;
  field?: ControllerRenderProps<any, any>;
  id?: string;
  handleChange?: (e?: SelectChangeEvent<any>) => void;
  availableTimes: string[];
  invalid?: boolean;
  labelId?: string;
  helperText?: string;
  className?: string;
  fullWidth?: boolean;
  required?: boolean;
  sx?: SxProps;
  variant?: 'filled' | 'outlined' | 'standard';
  disabled?: boolean;
};

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  label,
  field,
  id,
  availableTimes,
  invalid,
  labelId,
  helperText,
  handleChange,
  className,
  fullWidth,
  required,
  sx,
  variant = 'outlined',
  disabled = false
}) => (
  <FormControl sx={sx} className={`row justify-between wrap ${className}`}>
    <InputLabel id={labelId} error={invalid}>
      {label}
    </InputLabel>
    <Select
      {...field}
      labelId={labelId}
      id={id}
      label={label}
      onChange={handleChange ? handleChange : field?.onChange}
      variant={variant}
      fullWidth={fullWidth}
      error={invalid}
      IconComponent={() => (
        <TimeIcon
          className="custom-time-picker-time-icon"
          fill={disabled ? 'rgba(0, 0, 0, 0.38)' : ''}
        />
      )}
      required={required}
      disabled={disabled}
    >
      <MenuItem value=""></MenuItem>
      {availableTimes.map(time => (
        <MenuItem key={time} value={time}>
          {time}
        </MenuItem>
      ))}
    </Select>
    {helperText && <FormHelperText error>{helperText}</FormHelperText>}
  </FormControl>
);

export default CustomTimePicker;
