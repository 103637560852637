import * as React from 'react';
//router
import { useLocation, useNavigate } from 'react-router-dom';
//recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
//atoms
import { userAtom } from '@atoms/userAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
//mutations
import { useLogout } from '@api/mutations/user/login';
import { useUser } from '@api/mutations/user/user';
import { useDeleteImpersonate } from '@api/mutations/impersonation/impersonation';
//hooks
import { useSetLoginUser } from '@hooks/useLoginUser/useSetLoginUser';
//interfaces
import { LogoutErrorResponse } from '@interfaces/user/login';
import { SelectChangeEvent } from '@mui/material';
import { User, UserErrorResponse } from '@interfaces/user/user';
//enum
import { RoleEnum } from '@enum/roleEnum';
import { ErrorResponse } from '@interfaces/apiResponse';

export function useProfileMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const [user, setUser] = useRecoilState(userAtom);
  const setCurrentCMS = useSetRecoilState(currentCMSAtom);
  const setPopup = useSetRecoilState(popupAtom);

  const { setUserCMS } = useSetLoginUser();

  const navigate = useNavigate();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutSuccess = () => {
    setUser(null);
    navigate('/login');
    window.history.replaceState(null, '', '/');
  };
  const onLogoutError = (err: LogoutErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: submitLogout } = useLogout(onLogoutSuccess, onLogoutError);

  const handleChange = (ev: SelectChangeEvent) => {
    localStorage.setItem('sportCenter', ev.target.value);

    if (ev.target.value === '-1') {
      if (user?.is_admin) {
        setCurrentCMS(
          current =>
            current && {
              ...current,
              currentSportCenter: ev.target.value,
              role: RoleEnum.SUPERADMIN
            }
        );
      } else {
        setCurrentCMS(
          current =>
            current && {
              ...current,
              currentSportCenter: ev.target.value,
              role: RoleEnum.SUPERADMIN_EMPLOYEE
            }
        );
      }
    } else if (
      user?.owned_sport_centers?.find(sc => sc.id.toString() === ev.target.value.toString())
    ) {
      setCurrentCMS(
        current =>
          current && { ...current, currentSportCenter: ev.target.value, role: RoleEnum.ADMIN }
      );
    } else if (
      user?.coach_in_sport_centers?.find(sc => sc.id.toString() === ev.target.value.toString())
    ) {
      setCurrentCMS(
        current =>
          current && { ...current, currentSportCenter: ev.target.value, role: RoleEnum.COACH }
      );
    } else if (
      user?.sport_centers_with_role?.find(sc => sc.id.toString() === ev.target.value.toString())
    ) {
      setCurrentCMS(
        current =>
          current && {
            ...current,
            currentSportCenter: ev.target.value,
            role: RoleEnum.ADMIN_EMPLOYEE
          }
      );
    }

    window.history.replaceState(null, '', '/');
    navigate('/');
  };

  const onUserSuccess = (data: User) => {
    setUserCMS(data);

    navigate('/');
  };

  const onUserError = (err: UserErrorResponse): void => {
    if (err.status === 442) navigate('/email-not-verified');
  };

  const { mutate: me } = useUser(onUserSuccess, onUserError);

  const onImpersonateSuccess = () => {
    me('');
    window.history.replaceState(null, '', '/');
    navigate('/');
  };

  const onImpersonateError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteImpersonate } = useDeleteImpersonate(
    onImpersonateSuccess,
    onImpersonateError
  );

  return {
    handleChange,
    open,
    handleClick,
    anchorEl,
    handleClose,
    submitLogout,
    navigate,
    deleteImpersonate
  };
}
