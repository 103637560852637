//axios
import axios from 'axios';
//helpers
import { hashWithSha256 } from '@src/helpers/utility';
//packages
import dayjs from 'dayjs';
//plugins
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const BASE_URL = import.meta.env.VITE_BASE_URL;
let agent = navigator.userAgent;
let is_sent = false;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  withXSRFToken: true,
  headers: {
    Accept: 'application/json'
  }
});

axiosInstance.interceptors.request.use(
  async config => {
    let date = dayjs().utc().format('YYYY-MM-DD HH');
    let signatureFormula = `${agent}|cms|${date}`;
    let signatureResult = await hashWithSha256(signatureFormula);

    config.headers.set('X-Device', agent);
    config.headers.set('X-Frontend-Signature', `${signatureResult}`);
    config.headers.set('X-Locale', localStorage.getItem('lng'));

    if (config.method !== 'get') {
      await axiosInstance.get('/sanctum/csrf-cookie');
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  async response => {
    if (is_sent) {
      is_sent = false;
    }
    return response;
  },
  async error => {
    if (error?.response?.status === 441) {
      if (!is_sent) {
        is_sent = true;
        return await axiosInstance(error.config);
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
