//interfaces
import { WholeUsersResponse } from '@interfaces/user/user';
//services
import { appUsers } from '@services/user/appUser/appUser';
//react-query
import { UseQueryResult, useQuery } from '@tanstack/react-query';

export const useAppUsers = (
  pageIndex: number,
  pageSize: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string,
  onError: (err: Error) => void
): UseQueryResult<WholeUsersResponse, Error> =>
  useQuery(
    ['appUsers', pageIndex, pageSize, search, gender, order_by, order_direction],
    () => appUsers(pageIndex, pageSize, search, gender, order_by, order_direction),
    {
      onError: err => onError(err)
    }
  );
