// hooks
import { useCreateSportCenterStep2 } from '@src/ui/hooks/createSportCenterStep2/useCreateSportCenterStep2';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
// components
import { Box, Button, CircularProgress } from '@mui/material';
import UploadImage from '@src/ui/containers/ImageUpload/ImageUpload';
//atom
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermission } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const StepTwo = () => {
  const {
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    isLoading,
    onHandleBack,
    onHandleNext
  } = useCreateSportCenterStep2();

  const { t } = useTranslation();

  const currentCMS = useRecoilValue(currentCMSAtom);

  return (
    <Box component={'form'} sx={{ padding: '1% 10%' }}>
      <UploadImage
        title={t('sportCenters.stepTwoTitle')}
        images={images}
        onDropCallback={onDropCallback}
        handleDelete={handleDelete}
        uploadPercent={uploadPercent}
        isLoading={isLoading}
      />
      {isLoading && <CircularProgress />}
      <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
        {checkPermission(currentCMS, PermissionsEnum.update_sport_centers) && (
          <Button variant="text" onClick={onHandleBack}>
            {t('back')}
          </Button>
        )}

        <Button type="submit" variant="contained" onClick={onHandleNext}>
          {t('next')}
        </Button>
      </Box>
    </Box>
  );
};

export default StepTwo;
