//interfaces
import {
  AllNonWorkingDaysResponseType,
  NonWorkingDaysResponseType,
  NonWorkingDaysType
} from '@interfaces/nonWorkingDays/nonWorkingDays';
//api
import {
  getAllNonWorkingDays,
  getSingleNonWorkingDays
} from '@services/nonWorkingDays/nonWorkingDays';
//react-query
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useSingleNonWorkingDay = (
  sportCenterId: string | undefined,
  nonWorkingDayId: string | undefined,
  onSuccess: (data: NonWorkingDaysType) => void,
  onError: (err: Error) => void
): UseQueryResult<{ data: NonWorkingDaysResponseType }, Error> =>
  useQuery(
    ['single-non-working-day', sportCenterId, nonWorkingDayId],
    () => {
      if (sportCenterId && nonWorkingDayId)
        return getSingleNonWorkingDays(+sportCenterId, +nonWorkingDayId);
      return null;
    },
    {
      onSuccess: data => data && onSuccess(data.data.data),
      onError: err => onError(err)
    }
  );

export const useAllNonWorkingDay = (
  sportCenterId: string | undefined,
  onError: (err: Error) => void
): UseQueryResult<{ data: AllNonWorkingDaysResponseType } | null, Error> =>
  useQuery(
    ['all-non-working-days', sportCenterId],
    () => {
      if (sportCenterId) return getAllNonWorkingDays(+sportCenterId);
      return null;
    },
    {
      onError: err => onError(err)
    }
  );
