import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// translation exports
import { translationSerbian } from './Serbian/translationSerbian';
import { translationEnglish } from './English/translationEnglish';
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: translationEnglish },
    sr: { translation: translationSerbian }
  },
  lng: localStorage.getItem('lng') || 'sr',
  fallbackLng: 'sr',
  interpolation: { escapeValue: false }
});

export default i18n;
