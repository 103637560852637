//layout
import LoginLayout from '@layout/LoginLayout/LoginLayout';
//pages
import LoginPage from '@pages/Login/Login';
import ResetPassword from '@pages/ResetPassword/ResetPassword';
import EmailNotVerifiedPage from '@pages/EmailNotVerified/EmailNotVerifiedPage';
import HomePage from '@pages/Home/Home';
//components
import RequireAuth from '@components/Auth/RequireAuth';
//containers
import RecoverPassword from '@containers/RecoverPassword/RecoverPassword';

export const globalRoutes = {
  element: <RequireAuth allowedPermissions={[]} />,
  children: [
    {
      path: '/',
      element: <HomePage />
    },
    {
      element: <LoginLayout />,
      children: [
        {
          path: 'login',
          element: <LoginPage />
        },
        {
          path: 'recover-password',
          element: <RecoverPassword />
        },
        {
          path: 'reset-password',
          element: <ResetPassword />
        },
        {
          path: 'set-password',
          element: <ResetPassword />
        },
       
      ]
    }
  ]
};
