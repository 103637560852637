//hooks
import { useSettings } from '@hooks/useSettings/useSettings';
//components
import { Grid } from '@mui/material';

const SettingsPage = () => {
  const { getSettings } = useSettings();

  return (
    <Grid container spacing={2} p={2}>
      {getSettings().map((el, i) => (
        <Grid item xs={12} sm={6} key={i}>
          {el}
        </Grid>
      ))}
    </Grid>
  );
};

export default SettingsPage;
