// store
import { atom } from 'recoil';
// interfaces
import { User } from '@interfaces/user/user';

const userAtom = atom<User | null>({
  key: 'userState',
  default: null
});

export { userAtom };
