//react
import { Dispatch, SetStateAction, MutableRefObject } from 'react';
//hooks
import { useTranslation } from 'react-i18next';
//icons
import { DeleteRowIcon, AboutViewIcon } from '@components/icons/icons';
//mui
import MaterialReactTable, {
  MRT_TableInstance,
  type MRT_ColumnDef,
  type MRT_PaginationState
} from 'material-react-table';
import { Box, IconButton, Tooltip } from '@mui/material';
// types
import { TableVariantType } from '@interfaces/table/table';
interface TableProps<T extends object> {
  tableVariant: TableVariantType;
  rows: T[];
  columns: MRT_ColumnDef<{}>[];
  pagination?: MRT_PaginationState;
  setPagination?: Dispatch<SetStateAction<MRT_PaginationState>>;
  rowCount?: number | undefined;
  tableInstanceRef?: MutableRefObject<MRT_TableInstance | null>;
  withActions?: boolean;
  withCheckbox?: boolean;
  handleDeleteRow?: (row: number, name: string) => void;
  handleViewMoreRow?: (route: string) => void;
}
interface TableRowsProps {
  id: number;
  name: string;
  owner_id: number;
}

interface EmployeeTableRowsProps {
  user_info: {
    id: number;
    name: string;
  };
}

export const TableLayout = <T extends object>({
  rows,
  columns,
  pagination,
  setPagination,
  rowCount,
  tableInstanceRef,
  withActions = false,
  withCheckbox = false,
  handleDeleteRow,
  handleViewMoreRow,
  tableVariant
}: TableProps<T>) => {
  const { t } = useTranslation();
  return (
    <MaterialReactTable
      localization={{
        rowsPerPage: t('table.rowsPerPage').toString(),
        goToFirstPage: t('table.goToFirstPage').toString(),
        goToLastPage: t('table.goToLastPage').toString(),
        goToNextPage: t('table.goToNextPage').toString(),
        goToPreviousPage: t('table.goToPreviousPage').toString()
      }}
      manualFiltering
      enableColumnActions={false}
      enableColumnFilters={false}
      enableSorting={false}
      enableTopToolbar={false}
      enableRowSelection={withCheckbox}
      enableStickyHeader
      enableColumnOrdering
      enablePinning
      columns={columns}
      data={rows}
      onPaginationChange={setPagination}
      state={{
        pagination
      }}
      rowCount={rowCount}
      manualPagination
      tableInstanceRef={tableInstanceRef}
      enableRowActions={withActions}
      positionActionsColumn="last"
      displayColumnDefOptions={{
        'mrt-row-actions': {
          header: ''
        }
      }}
      renderRowActions={({ row }) => (
        <Box sx={{ display: 'flex', gap: '0.5rem' }}>
          {handleViewMoreRow && (
            <Tooltip arrow placement="top" title={t('look')}>
              <IconButton
                onClick={() => {
                  const data = row.original as TableRowsProps;
                  handleViewMoreRow && handleViewMoreRow(`${data.owner_id}`);
                }}
              >
                <AboutViewIcon />
              </IconButton>
            </Tooltip>
          )}
          {handleDeleteRow && (
            <Tooltip arrow placement="top" title={t('delete')}>
              <IconButton
                color="error"
                onClick={() => {
                  if (tableVariant !== 'employees') {
                    const data = row.original as TableRowsProps;
                    handleDeleteRow && handleDeleteRow(data.id, data.name);
                  } else {
                    const employeeData = row.original as EmployeeTableRowsProps;
                    handleDeleteRow &&
                      handleDeleteRow(employeeData.user_info.id, employeeData.user_info.name);
                  }
                }}
              >
                <DeleteRowIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      )}
      muiTableHeadCellProps={{
        sx: {
          background: '#f3f4f6'
        }
      }}
      muiTablePaperProps={{
        elevation: 0,
        sx: {
          borderRadius: '0'
        }
      }}
    />
  );
};
