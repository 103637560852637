//react-router
import { useLocation, Navigate, Outlet, useParams } from 'react-router-dom';
//recoil
import { useRecoilValue } from 'recoil';
//atoms
import { userAtom } from '@atoms/userAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//enum
import { RoleEnum } from '@enum/roleEnum';
import { PermissionsEnum } from '@enum/permissionsEnum';

type RequireAuthProps = {
  allowedPermissions: PermissionsEnum[];
};

const RequireAuth = ({ allowedPermissions }: RequireAuthProps): JSX.Element => {
  const user = useRecoilValue(userAtom);
  const currentCMS = useRecoilValue(currentCMSAtom);
  const location = useLocation();
  const { pathname } = location;

  const { sportCenterId } = useParams();

  if (pathname === '/email-not-verified') {
    return <Outlet />;
  }
  if (!user) {
    //user doesn't exists
    if (pathname === '/login') {
      return <Outlet />;
    }
    if (pathname === '/recover-password') {
      return <Outlet />;
    }
    if (pathname === '/set-password') {
      return <Outlet />;
    }
    if (pathname === '/reset-password') {
      return <Outlet />;
    }
    return <Navigate to="/login" state={{ from: location }} />;
  } else {
    //user exists and try go on login page
    if (
      pathname === '/login' ||
      pathname === '/recover-password' ||
      pathname === '/reset-password' ||
      pathname === '/set-password' ||
      pathname === '/email-not-verified'
    ) {
      return <Navigate to="/" state={{ from: location }} />;
    }

    if (pathname === '/') {
      return <Outlet />;
    }

    if (user.is_admin) {
      return <Outlet />;
    }

    //if user is admin
    if (currentCMS?.role === RoleEnum.ADMIN && user.owned_sport_centers) {
      if (!sportCenterId) {
        //if admin doesn't have any sport center

        return <Navigate to={'error-page'} />;
      }
      for (let i = 0; i < user.owned_sport_centers.length; i++) {
        const s = user.owned_sport_centers[i];
        if (s.id == sportCenterId) {
          return <Outlet />;
        }
      }

      return <Navigate to={'error-page'} />;
    }

    //if user is coach
    if (currentCMS?.role === RoleEnum.COACH && user.coach_in_sport_centers) {
      if (!sportCenterId) {
        //if coach doesn't have any sport center
        return <Navigate to={'error-page'} />;
      }
      for (let i = 0; i < user.coach_in_sport_centers.length; i++) {
        const s = user.coach_in_sport_centers[i];
        if (s.id.toString() === sportCenterId.toString()) {
          return <Outlet />;
        }
      }

      return <Navigate to={'error-page'} />;
    }

    if (allowedPermissions.length === 0) {
      return <Outlet />;
    }

    if (
      currentCMS?.permissions.find(permission =>
        allowedPermissions.includes(permission as PermissionsEnum)
      )
    ) {
      return <Outlet />;
    }

    return <Navigate to="/error-page" state={{ from: location }} />;
  }
};

export default RequireAuth;
