import { ReactNode } from 'react';
import { Avatar, Card, CardActionArea, CardActions, CardContent, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { primaryColor } from '@src/theme';

type StatisticCardPropsType = {
  icon?: ReactNode;
  title: string;
  subtitle: string;
  header?: string;
};

const StatisticCard = ({ icon, title, subtitle, header }: StatisticCardPropsType) => {
  return (
    <Card>
      <CardContent>
        {header && (
          <Typography variant="subtitle2" component="div">
            {header}
          </Typography>
        )}
        <Box display={'flex'} sx={{ gap: 4 }}>
          {icon && (
            <Avatar variant="rounded" sx={{ backgroundColor: primaryColor }}>
              {icon}
            </Avatar>
          )}
          <Typography component="div" variant="h5">
            {title}
          </Typography>
        </Box>
        <Typography variant="subtitle2" color="grayText" component="div" pt={2}>
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default StatisticCard;
