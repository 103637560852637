import { SportCustomFields, SportCustomFieldsResponse } from '@src/domain/interfaces/sport/sport';
import { getCustomFields } from '@src/infrastructure/services/sportCustomFields/sportCustomFields';
import { useQuery, UseQueryResult } from '@tanstack/react-query';

export const useSportCustomFields = (
  onSuccess: (data: SportCustomFields[]) => void,
  onError: (err: Error) => void,
  id: string | undefined
): UseQueryResult<{ data: SportCustomFieldsResponse }, Error> =>
  useQuery({
    queryKey: ['get-sport-custom-fields', id],
    queryFn: ({ queryKey }) => {
      if (queryKey[1]) return getCustomFields(queryKey[1]);
      return null;
    },
    onSuccess: data => onSuccess(data?.data.data),
    onError: err => onError(err)
  });
