//interface
import { SuccessResponse } from '@interfaces/apiResponse';
import {
  SportCenterResponse,
  CreateSportCenterType,
  AllSportsCentersResponse,
  SportCenter
} from '@interfaces/SportCenters/SportCenter';
//axios instance
import axiosInstance from '@services/config';

export const sportCenter = (
  page: number,
  per_page: number,
  search: string,
  payment_methods: string[],
  benefits: number[],
  fee_percentage_min: number | null | string,
  fee_percentage_max: number | null | string,
  order_by: string,
  order_direction: string
): Promise<{ data: AllSportsCentersResponse }> =>
  axiosInstance.get('/sport-centers/', {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      payment_methods: payment_methods,
      benefits: benefits,
      fee_percentage_min: fee_percentage_min,
      fee_percentage_max: fee_percentage_max,
      order_by: order_by,
      order_direction: order_direction
    }
  });

export const singleSportCenter = (id: Number): Promise<{ data: SportCenterResponse }> =>
  axiosInstance.get(`/sport-centers/${id}`);

export const createSportCenter = (
  data: CreateSportCenterType
): Promise<{ data: SportCenterResponse }> => axiosInstance.post('/sport-centers', data);

export const updateSportCenter = (
  data: CreateSportCenterType
): Promise<{ data: SportCenterResponse }> => axiosInstance.put(`/sport-centers/${data.id}`, data);

export const deleteSportCenter = (id: number): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/${id}`);

export const deleteSportCenters = (data: number[]): Promise<{ data: SuccessResponse }> =>
  axiosInstance.delete(`/sport-centers/bulk/delete`, {
    params: {
      sport_centers: data
    }
  });
