import {
  AdminEmployeesKeyType,
  CreateAdminEmployeeType,
  EmployeePositionType,
  EmployeeSectorType
} from '@interfaces/employees/employees';

export const positions: EmployeePositionType[] = [
  {
    id: 1,
    name: 'pozicija 1',
    employee_sector: {
      id: 1,
      name: 'sektor 1'
    }
  },
  {
    id: 2,
    name: 'pozicija 2',
    employee_sector: {
      id: 1,
      name: 'sektor 1'
    }
  }
];

export const sectors: EmployeeSectorType[] = [
  {
    id: 1,
    name: 'sektor 1'
  },
  {
    id: 2,
    name: 'sektor 2'
  }
];

export const createAdminEmployee: CreateAdminEmployeeType = {
  user_info: {
    full_name: '',
    email: '',
    phone: ''
  },
  role_id: ''
};
export const adminEmplyeeKey: AdminEmployeesKeyType[] = [
  { name: 'name' },
  { name: 'email' },
  { name: 'phone' },
  { name: 'category' }
];

export const employeeKeys: { name: string }[] = [
  { name: 'user_info.first_name' },
  { name: 'user_info.last_name' },
  { name: 'user_info.gender' },
  { name: 'cooperation_info.address' },
  { name: 'cooperation_info.professional_qualification' },
  { name: 'user_info.email' },
  { name: 'user_info.phone' },
  { name: 'cooperation_info.salary' },
  { name: 'sector' },
  { name: 'cooperation_info.employee_position_id' },
  { name: 'user_info.role_id' },
  { name: 'documents' }
];
