export const formHelper = {
  getBody: function (fields: Object) {
    let formData = new FormData();

    for (const [key, value] of Object.entries(fields)) {
      let fieldValue = value;

      if (typeof fieldValue === 'object') {
        if (fieldValue instanceof File || fieldValue instanceof Blob) {
          formData.append(key, fieldValue);
        } else {
          this.objectToFormData({ object: fieldValue, formData, key });
        }
      } else {
        formData.append(key, fieldValue ?? '');
      }
    }

    return formData;
  },

  objectToFormData: function ({
    object,
    formData,
    key
  }: {
    object: any;
    formData: FormData;
    key: string;
  }) {
    let fieldKey;
    for (let property in object) {
      if (object.hasOwnProperty(property)) {
        if (key) {
          fieldKey = key + '[' + property + ']';
        } else {
          fieldKey = property;
        }

        if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
          this.objectToFormData({ object: object[property], formData: formData, key: fieldKey });
        } else {
          // if it's a string or a File object
          formData.append(fieldKey, object[property]);
        }
      }
    }

    return formData;
  }
};
