import { Dispatch, SetStateAction } from 'react';
//components
import Toolbar from '@mui/material/Toolbar';
import { AppBar, IconButton } from '@mui/material';
import { Box } from '@mui/system';
//icon
import MenuIcon from '@mui/icons-material/Menu';
//containers
import ProfileMenu from '@containers/ProfileMenu/ProfileMenu';

type HeaderProps = {
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const Header = ({ setOpen }: HeaderProps): JSX.Element => {
  return (
    <AppBar
      className="Navbar"
      color="inherit"
      sx={{
        margin: 0,
        boxShadow: 'none'
      }}
    >
      <Toolbar
        sx={{
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <IconButton onClick={() => setOpen(true)} id="MenuIcon" data-testid="MenuIcon">
          <MenuIcon />
        </IconButton>
        <Box sx={{ right: 0, position: 'absolute' }}>
          <ProfileMenu />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
