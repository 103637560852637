//service
import {
  createSportCenterRole,
  deleteSportCenterRole,
  updateSportCenterRole
} from '@services/roles/roles';
//error
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
import {
  RoleErrorResponseType,
  RoleErrorType,
  RoleRequestType,
  RoleType,
  SingleRoleResponseType
} from '@interfaces/roles/roles';
//service
import { createRole, deleteRole, updateRole } from '@services/roles/roles';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useUpdateRole = (
  onSuccess: (data: RoleType) => void,
  onError: (err: RoleErrorType) => void,
  sportCenterId?: number
): UseMutationResult<
  SingleRoleResponseType, // response
  RoleErrorResponseType,
  RoleType, // request
  (data: RoleType) => void // on success
> =>
  useMutation({
    mutationFn: data => {
      if (sportCenterId) return updateSportCenterRole({ ...data, sportCenterId });
      return updateRole(data);
    },
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useCreateRole = (
  onSuccess: (data: RoleType) => void,
  onError: (err: RoleErrorType) => void,
  sportCenterId?: number
): UseMutationResult<
  SingleRoleResponseType, // response
  RoleErrorResponseType,
  RoleRequestType, // request
  (data: RoleType) => void // on success
> =>
  useMutation({
    mutationFn: data => {
      if (sportCenterId) return createSportCenterRole({ ...data, sportCenterId });
      return createRole(data);
    },
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteRole = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void,
  sportCenterId?: number
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  number, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: roleId => {
      if (sportCenterId) return deleteSportCenterRole({ roleId, sportCenterId });
      return deleteRole(roleId);
    },
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
//error

// export const useUpdateSportCenterRole = (
//   onSuccess: (data: RoleType) => void,
//   onError: (err: RoleErrorType) => void
// ): UseMutationResult<
//   SingleRoleResponseType, // response
//   RoleErrorResponseType,
//   RoleType & { sportCenterId: number }, // request
//   (data: RoleType) => void // on success
// > =>
//   useMutation({
//     mutationFn: updateSportCenterRole,
//     onSuccess: data => onSuccess(data.data.data),
//     onError: err => onError(err.response.data)
//   });

// export const useCreateSportCenterRole = (
//   onSuccess: (data: RoleType) => void,
//   onError: (err: RoleErrorType) => void
// ): UseMutationResult<
//   SingleRoleResponseType, // response
//   RoleErrorResponseType,
//   SportCenterRoleRequestType, // request
//   (data: RoleType) => void // on success
// > =>
//   useMutation({
//     mutationFn: createSportCenterRole,
//     onSuccess: data => onSuccess(data.data.data),
//     onError: err => onError(err.response.data)
//   });

// export const useDeleteSportCenterRole = (
//   onSuccess: (data: SuccessResponse) => void,
//   onError: (err: ErrorResponse) => void
// ): UseMutationResult<
//   { data: SuccessResponse }, // response
//   ErrorRes,
//   SingleSportCenterRoleRequestType, // request
//   (data: SuccessResponse) => void // on success
// > =>
//   useMutation({
//     mutationFn: deleteSportCenterRole,
//     onSuccess: data => onSuccess(data.data),
//     onError: err => onError(err.response.data)
//   });
