export enum PermissionsEnum {
  create_sports = 'create_sports',
  read_sports = 'read_sports',
  update_sports = 'update_sports',
  delete_sports = 'delete_sports',

  // read_dashboard = 'read_dashboard', //TODO: remove after MVP

  create_sport_centers = 'create_sport_centers',
  read_sport_centers = 'read_sport_centers',
  update_sport_centers = 'update_sport_centers',
  delete_sport_centers = 'delete_sport_centers',

  read_users = 'read_users',
  delete_users = 'delete_users',

  // create_appUsers = 'create_appUsers',
  // read_appUsers = 'read_appUsers',
  // update_appUsers = 'update_appUsers',
  // delete_appUsers = 'delete_appUsers',
  // create_ownerUsers = 'create_ownerUsers',
  // read_ownerUsers = 'read_ownerUsers',
  // update_ownerUsers = 'update_ownerUsers',
  // delete_ownerUsers = 'delete_ownerUsers',

  // create_businessUsers = 'create_businessUsers',
  // read_businessUsers = 'read_businessUsers',
  // update_businessUsers = 'update_businessUsers',
  // delete_businessUsers = 'delete_businessUsers',

  create_employees = 'create_employees',
  read_employees = 'read_employees',
  update_employees = 'update_employees',
  delete_employees = 'delete_employees',

  // create_externalCooperations = 'create_externalCooperations',
  // read_externalCooperations = 'read_externalCooperations',
  // update_externalCooperations = 'update_externalCooperations',
  // delete_externalCooperations = 'delete_externalCooperations',
  // create_freelanceCooperations = 'create_freelanceCooperations',
  // read_freelanceCooperations = 'read_freelanceCooperations',
  // update_freelanceCooperations = 'update_freelanceCooperations',
  // delete_freelanceCooperations = 'delete_freelanceCooperations',
  // create_sponsorshipCooperations = 'create_sponsorshipCooperations',
  // read_sponsorshipCooperations = 'read_sponsorshipCooperations',
  // update_sponsorshipCooperations = 'update_sponsorshipCooperations',
  // delete_sponsorshipCooperations = 'delete_sponsorshipCooperations',
  // read_overviewAnalytics = 'read_overviewAnalytics',
  // read_sportCenterAnalytics = 'read_sportCenterAnalytics',
  // read_userAnalytics = 'read_userAnalytics',
  // read_overviewFinance = 'read_overviewFinance',
  // read_incomeFinance = 'read_incomeFinance',
  // read_expenseFinance = 'read_expenseFinance',
  // read_reportFinance = 'read_reportFinance',
  // create_invoiceFinance = 'create_invoiceFinance',
  // read_invoiceFinance = 'read_invoiceFinance',
  // update_invoiceFinance = 'update_invoiceFinance',
  // delete_invoiceFinance = 'delete_invoiceFinance',
  // create_notifications = 'create_notifications',
  // read_notifications = 'read_notifications',
  // update_notifications = 'update_notifications',
  // delete_notifications = 'delete_notifications',

  create_roles = 'create_roles',
  read_roles = 'read_roles',
  update_roles = 'update_roles',
  delete_roles = 'delete_roles',

  // create_benefit = 'create_benefit',
  // read_benefit = 'read_benefit',
  // update_benefit = 'update_benefit',
  // delete_benefit = 'delete_benefit',

  //ADMIN
  read_appointments = 'read_appointments',
  create_appointments = 'create_appointments',
  update_appointments = 'update_appointments',
  delete_appointments = 'delete_appointments',

  create_courts = 'create_courts',
  read_courts = 'read_courts',
  update_courts = 'update_courts',
  delete_courts = 'delete_courts',

  create_recurring_appointments = 'create_recurring_appointments',
  read_recurring_appointments = 'read_recurring_appointments',
  update_recurring_appointments = 'update_recurring_appointments',
  delete_recurring_appointments = 'delete_recurring_appointments',

  create_users = 'create_users',
  update_users = 'update_users',

  create_coaches = 'create_coaches',
  read_coaches = 'read_coaches',
  update_coaches = 'update_coaches',
  delete_coaches = 'delete_coaches',

  create_price_rules = 'create_price_rules',
  read_price_rules = 'read_price_rules',
  update_price_rules = 'update_price_rules',
  delete_price_rules = 'delete_price_rules',

  create_non_working_days = 'create_non_working_days',
  read_non_working_days = 'read_non_working_days',
  update_non_working_days = 'update_non_working_days',
  delete_non_working_days = 'delete_non_working_days'
}
