import { ErrorRes, ErrorResponse, SuccessResponse } from '@src/domain/interfaces/apiResponse';
import {
  CourtError,
  CourtErrorResponse,
  CourtResponse,
  CourtType,
  CreateCourtRequest,
  SingleCourtRequestType
} from '@src/domain/interfaces/court/court';
import { AttachSportRequest, DettachSportRequest } from '@src/domain/interfaces/sport/sport';
import {
  attachSport,
  createCourt,
  deleteCourt,
  detachSport,
  singleCourt,
  updateCourt
} from '@src/infrastructure/services/court/court';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useCreateCourt = (
  onSuccess: (data: CourtType) => void,
  onError: (err: CourtError) => void
): UseMutationResult<
  { data: CourtResponse }, // response
  CourtErrorResponse,
  CreateCourtRequest, // request
  (data: CourtType) => void // on success
> =>
  useMutation({
    mutationFn: createCourt,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateCourt = (
  onSuccess: (data: CourtType) => void,
  onError: (err: CourtError) => void
): UseMutationResult<
  { data: CourtResponse }, // response
  CourtErrorResponse,
  CreateCourtRequest & { id: number }, // request
  (data: CourtType) => void // on success
> =>
  useMutation({
    mutationFn: updateCourt,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteCourt = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  SingleCourtRequestType, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteCourt,
    onSuccess: data => onSuccess({ message: 'Successfully deleted court' }),
    onError: err => onError(err.response.data)
  });

export const useAttachSport = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  AttachSportRequest, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: attachSport,
    onSuccess: data => onSuccess({ message: 'Successfully attached sport with court' }),
    onError: err => onError(err.response.data)
  });

export const useDetachSport = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  DettachSportRequest, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: detachSport,
    onSuccess: data => onSuccess({ message: 'Successfully deleted court' }),
    onError: err => onError(err.response.data)
  });

export const useCourtById = (
  onSuccess: (data: CourtType) => void,
  onError: (err: ErrorResponse) => void
  // courtId: number
): UseMutationResult<
  { data: CourtResponse }, // response
  ErrorRes,
  SingleCourtRequestType, // request
  (data: CourtType) => void // on success
> =>
  useMutation({
    // mutationKey: ['get-single-court-by-id'],
    mutationFn: singleCourt,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });
