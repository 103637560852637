//react
import { useState, useRef, useEffect, MouseEvent, ChangeEvent } from 'react';
//react-router
import { useNavigate, useParams } from 'react-router-dom';
//recoil
import { useResetRecoilState, useSetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';
//hooks
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
//helpers
import { sortTables } from '@helpers/utility';
//mui
import { TableProps, useMediaQuery } from '@mui/material';
import { MRT_PaginationState, MRT_TableInstance } from 'material-react-table';
import { TableVariantType } from '@interfaces/table/table';
//interfaces
import { FilterProps } from '@interfaces/table/table';
import { Employee } from '@interfaces/employees/employees';
//theme
import theme from '@src/theme';

const useTablesLogic = (
  deleteItem?: (data: number) => void,
  deleteItems?: (data: number[]) => void,
  popupTitleKey?: 'superAdminUsers' | 'employees' | 'sportCenters' | 'users',
  deleteItemWithObjectID?: (data: { sportCenterID: number; userID: number }) => void,
  deleteItemsWithObjectId?: (data: { sportCenterID: number; memberships: number[] }) => void
) => {
  const [deleteIds, setDeleteIds] = useState<number[]>([]);
  const [deletedNames, setdeletedNames] = useState('');
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10
  });
  const tableInstanceRef = useRef<MRT_TableInstance | null>(null);
  const [sortBy, setSortBy] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('');
  const [checkSort, setCheckSort] = useState<number | null>(null);
  const [filterValue, setFilterValue] = useState<string>('');
  const [filterValues, setFilterValues] = useState<FilterProps>({
    search: '',
    payment_methods: [],
    benefits: [],
    fee_percentage_min: '',
    fee_percentage_max: '',
    gender: []
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorMenuItem, setAnchorMenuItem] = useState<null | HTMLElement>(null);
  const [anchorElActionButton, setAnchorElActionButton] = useState<null | HTMLElement>(null);
  const [anchorSortEl, setAnchorSortEl] = useState<null | HTMLElement>(null);

  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const [successfullyDeletedPopup, setSuccessfullyDeletedPopup] = useState(false);
  const navigate = useNavigate();
  const [openMobileMenu, setOpenMobileMenu] = useState<boolean>(false);
  const [openMobileUserDropdown, setOpenMobileUserDropdown] = useState<number | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleToggleMobileDetails = (index: number) => {
    setOpenMobileUserDropdown(openMobileUserDropdown === index ? null : index);
  };
  const params = useParams();
  const sportCenterID: number | undefined = params.sportCenterId
    ? parseInt(params.sportCenterId)
    : undefined;

  const {
    handleSubmit: handleSubmitFilter,
    control: controlFilter,
    getValues: getFilterValues,
    reset: resetFilter
  } = useForm<FilterProps>({
    defaultValues: filterValues
  });
  const { t } = useTranslation();
  const handleSearch = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterValues({
      search: event.target.value,
      payment_methods: [],
      benefits: [],
      fee_percentage_min: '',
      fee_percentage_max: '',
      gender: []
    });
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement | HTMLLIElement | HTMLDivElement>) => {
    const { currentTarget } = event;
    if (isMobile && currentTarget.ariaLabel === 'dotsMenu') {
      setAnchorElActionButton(event.currentTarget);
      currentTarget.ariaValueNow && setDeleteIds([+currentTarget.ariaValueNow]);
      currentTarget.ariaValueText && setdeletedNames(currentTarget.ariaValueText);
    }
    switch (currentTarget.textContent) {
      case t('action'):
        getSelectedRowsInfo();
        setAnchorElActionButton(event.currentTarget);
        break;
      case t('filter'):
        setAnchorEl(event.currentTarget);
        break;
      case t('paymentMethod'):
      case t('fee_percentage'):
      case t('benefits'):
      case t('gender'):
        setAnchorMenuItem(event.currentTarget);
        break;
      default:
        break;
    }
    if (event.target && currentTarget?.id && currentTarget.id === 'sort-button') {
      setAnchorSortEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    if (anchorElActionButton !== null) {
      setAnchorElActionButton(null);
    } else if (anchorEl && anchorMenuItem) {
      setAnchorMenuItem(null);
    } else {
      setAnchorSortEl(null);
      setAnchorEl(null);
    }
  };

  const onSubmitFilter: SubmitHandler<FilterProps> = async (data, e) => {
    setFilterValues(prev => {
      if (data.gender.length > 1) {
        return { ...prev, gender: [] };
      }
      return data;
    });

    if (
      data.benefits.length ||
      data.fee_percentage_max ||
      data.fee_percentage_min ||
      data.payment_methods ||
      data.gender.length
    ) {
      if (data.fee_percentage_max || data.fee_percentage_min) {
        setFilterValue(
          `${t('from')} ${data.fee_percentage_min ? data.fee_percentage_min : 0}  % ${t('to')} ${
            data.fee_percentage_max ? data.fee_percentage_max : 0
          } %`
        );
      } else {
        setFilterValue(
          data.payment_methods.length > 1
            ? t(data.payment_methods[0]) + ', ' + t(data.payment_methods[1])
            : data.payment_methods.length === 1
            ? t(data.payment_methods[0]).toString()
            : data.gender.length > 1
            ? t(data.gender[0]) + ', ' + t(data.gender[1])
            : t(data.gender.toString()).toString()
        );
      }
    }
    handleClose();
    setAnchorEl(null);
  };
  const handleClearFilter = () => {
    resetFilter();
    setFilterValue('');
    setFilterValues({
      search: '',
      payment_methods: [],
      benefits: [],
      fee_percentage_min: '',
      fee_percentage_max: '',
      gender: []
    });
    handleClose();
  };
  const handleSortTables = (index: number, tableVariant: TableVariantType) => {
    sortTables(index, tableVariant, setSortBy, setSortDirection, setCheckSort);
    isMobile && handleClose();
  };

  const handleClearSort = () => {
    setSortBy('');
    setSortDirection('');
    handleClose();
    setCheckSort(null);
  };

  const getSelectedRowsInfo = () => {
    if (tableInstanceRef.current?.getSelectedRowModel().rows) {
      const ids: number[] = [];

      tableInstanceRef.current.getSelectedRowModel().rows.map(row => {
        if (popupTitleKey === 'employees' && row.original) {
          const employeeData = row.original as Employee;

          return ids.push(+employeeData.user_info.id);
        } else {
          const otherTableData = row.original as TableProps;
          if (otherTableData?.id) return ids.push(+otherTableData?.id);
        }
        return;
      });

      const names: string = tableInstanceRef.current
        .getSelectedRowModel()
        .rows.map(row => {
          if (popupTitleKey === 'employees') {
            return row._valuesCache['user_info.name'];
          }
          return row._valuesCache.name;
        })
        .join(', ');

      setDeleteIds(ids);

      setdeletedNames(names);
    }
  };

  const deleteEventHandler = () => {
    setPopup({
      open: true,
      title:
        t(
          popupTitleKey +
            `${deleteIds.length > 1 ? '.deletePopupTitleMultiple' : '.deletePopupTitle'}`
        ) +
        '  ' +
        (deletedNames.length > 40 ? deletedNames.slice(0, 40) + '...' : deletedNames),
      tooltipTitle: deletedNames.length > 40 ? deletedNames : undefined,
      content: t(
        popupTitleKey +
          `${deleteIds.length > 1 ? '.deleteMessageMultiple' : '.deleteMessageSingle'}`
      ),
      variant: 'delete',
      onClick: onDelete,
      onClose: onClose
    });
  };

  const sendEmailEventHandler = () => {
    let arrayOfSelectedRowsData = [];
    if (tableInstanceRef.current?.getSelectedRowModel().rows != undefined) {
      for (let i = 0; i < tableInstanceRef.current?.getSelectedRowModel().rows.length; i++) {
        arrayOfSelectedRowsData.push(
          tableInstanceRef.current?.getSelectedRowModel().rows[i].original
        );
      }
    }
    let arrayOfRealUserEmail = [];
    for (let i = 0; i < arrayOfSelectedRowsData.length; i++) {
      arrayOfRealUserEmail.push(
        tableInstanceRef.current?.getSelectedRowModel().rows[i]._valuesCache.email
      );
    }
    handleClose();
  };

  const handleDeleteRow = (id: number, name: string): void => {
    setDeleteIds([id]);
    setdeletedNames(name);

    setPopup({
      open: true,
      title: t(popupTitleKey + '.deletePopupTitle') + '  ' + name,
      content: t(popupTitleKey + '.deleteMessageSingle'),
      variant: 'delete',
      onClick: () => {
        deleteItem && !deleteItemWithObjectID
          ? deleteItem(id)
          : sportCenterID && deleteItemWithObjectID
          ? deleteItemWithObjectID({ sportCenterID: sportCenterID, userID: id })
          : null;
        tableInstanceRef.current?.resetRowSelection(true);
        resetPopup();
      },
      onClose: onClose
    });
  };

  const onDelete = () => {
    deleteIds.length > 1 && deleteItemsWithObjectId && sportCenterID
      ? deleteItemsWithObjectId({ sportCenterID: sportCenterID, memberships: deleteIds })
      : deleteItemWithObjectID &&
        sportCenterID &&
        deleteItemWithObjectID({ sportCenterID: sportCenterID, userID: deleteIds[0] });
    console.log('pppp', { sportCenterID: sportCenterID, memberships: deleteIds });
    deleteIds.length > 1 && deleteItems
      ? deleteItems(deleteIds)
      : deleteItem && deleteItem(deleteIds[0]);
    tableInstanceRef.current?.resetRowSelection(true);
    isMobile && setAnchorElActionButton(null);
    resetPopup();
  };

  const onClose = () => {
    setDeleteIds([]);
    setdeletedNames('');
    isMobile && setAnchorElActionButton(null);
  };

  const handleViewMoreRow = (route: string): void => {
    //TODO: ovo ne koristimo ovde treba da stoji da se menja rola
    navigate(route);
  };

  useEffect(() => {
    if (successfullyDeletedPopup) {
      const handle = setTimeout(() => {
        setSuccessfullyDeletedPopup(false);
        setDeleteIds([]);
        setdeletedNames('');

        clearTimeout(handle);
      }, 2000);
    }
  });

  return {
    pagination,
    setPagination,
    tableInstanceRef,
    handleDeleteRow,
    handleViewMoreRow,
    deleteEventHandler,
    sendEmailEventHandler,
    deletedNames,
    onDelete,
    handleSearch,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    handleSortTables,
    checkSort,
    setCheckSort,
    handleClearSort,
    successfullyDeletedPopup,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter: handleSubmitFilter(onSubmitFilter),
    filterValues,
    controlFilter,
    getFilterValues,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    anchorElActionButton,
    handleClick,
    handleClose,
    deleteIds,
    setDeleteIds,
    openMobileMenu,
    setOpenMobileMenu,
    openMobileUserDropdown,
    handleToggleMobileDetails
  };
};

export default useTablesLogic;
