// hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useTablesLogic from '@hooks/tables/useTablesLogic';
import useCalendarsLogic from '@hooks/calendars/useCalendarsLogic';
import { useRecoilValue, useSetRecoilState } from 'recoil';
// services
import { useRecurringAppointments } from '@api/queries/appointments/appointments';
// dayjs
import dayjs from 'dayjs';
// interfaces
import { RecurringAppointment } from '@interfaces/appointments/appointments';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
// components
import { Card, Grid, Button } from '@mui/material';
import { NavLink } from 'react-router-dom';
import Loading from '@components/Loading/Loading';
// layouts
import { TableLayout } from '@layout/TableLayout/TableLayout';
// containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
import AddApointment from '@containers/Appointment/AddAppointment/AddAppointment';
// helpers
import { checkPermission, getDataFromRecurringAppointment } from '@helpers/utility';
// icons
import AddIcon from '@mui/icons-material/Add';
//atom
import { currentCMSAtom } from '@atoms/currentCMS';
import { popupAtom } from '@atoms/popupAtom';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const PermanentAppointmentPage = () => {
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);
  const { pagination, setPagination, tableInstanceRef, handleSearch, filterValues } =
    useTablesLogic();

  const currentCMS = useRecoilValue(currentCMSAtom);

  const {
    sportCenterID,
    openAddAppointment,
    setOpenAddAppointment,
    addPermanentAppointment,
    selectedTimeDate,
    setSelectedTimeDate,
    appointmentID,
    setAppointmentID,
    calendarWorkingHours
  } = useCalendarsLogic();

  const columnsRecurringAppointments = useMemo<MRT_ColumnDef<RecurringAppointment | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('calendar.permanentAppointment.name'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as RecurringAppointment;
          return (
            <NavLink to={`edit-regular/${rowType.id}`} style={{ color: 'black' }}>
              {cell.getValue<string>()}{' '}
            </NavLink>
          );
        }
      },
      {
        accessorKey: 'court_sport',
        header: t('field') + '/' + t('sport'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as RecurringAppointment;
          return (
            <span>
              {rowType.court_sport.court.name} / {rowType.court_sport.sport.name}
            </span>
          );
        }
      },
      {
        accessorKey: 'time_start',
        header: t('calendar.permanentAppointment.appointment_duration'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as RecurringAppointment;
          const day = getDataFromRecurringAppointment(rowType, t).arrayOfDays.join();
          const timeEnd = getDataFromRecurringAppointment(rowType, t).timeEnd;
          return (
            <span>
              {day} {rowType.time_start} - {timeEnd}
            </span>
          );
        }
      },
      {
        accessorKey: 'date_to',
        header: t('calendar.permanentAppointment.active_to'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell }) => <span>{dayjs(cell.getValue<string>()).format('DD.MM.YYYY')}</span>
      }
    ],
    [t]
  );
  const {
    data: recurringAppointmntsData,
    refetch: refatchRecurringAppointments,
    isLoading: isLoadingRecurringAppointments
  } = useRecurringAppointments(
    onRecurringAppoitmentsError,
    sportCenterID,
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search
  );

  function onRecurringAppoitmentsError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const recurringAppointmentsWithCourtSport = recurringAppointmntsData?.data.data.filter(
    recurringAppointment => recurringAppointment.court_sport !== null
  );

  return (
    <>
      {checkPermission(currentCMS, PermissionsEnum.create_recurring_appointments) && (
        <Grid container justifyContent={'flex-end'} alignItems="end" p={3} pt={0}>
          <Grid item mr={5}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => setOpenAddAppointment(true)}
            >
              {t('calendar.permanentAppointment.add_new_appointment')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Card sx={{ mx: 3, px: 3 }}>
        <TableHeaderActions
          tableVariant="coaches"
          handleSearch={handleSearch}
          hideActionBtn
          disableSort
          disableFilter
        />
        <Grid item xs={12}>
          {recurringAppointmentsWithCourtSport && !isLoadingRecurringAppointments ? (
            <TableLayout
              tableVariant="coaches"
              rows={recurringAppointmentsWithCourtSport}
              columns={columnsRecurringAppointments}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={recurringAppointmntsData?.data?.meta.total}
              tableInstanceRef={tableInstanceRef}
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>

      <AddApointment
        sportCenterID={sportCenterID}
        anchor="right"
        open={openAddAppointment}
        onClose={() => {
          setOpenAddAppointment(false);
          setSelectedTimeDate({
            dateAppointment: null,
            startAppointment: '',
            durationAppointment: ''
          });
          refatchRecurringAppointments();
          setAppointmentID(undefined);
        }}
        addAppointmentType={'permanentAppointment'}
        addRegularAppointment={() => {}}
        addPermanentAppointment={addPermanentAppointment}
        dateAppointment={selectedTimeDate.dateAppointment}
        startAppointment={selectedTimeDate.startAppointment}
        appointmentID={appointmentID}
        noButtons
        calendarWorkingHours={calendarWorkingHours}
      />
    </>
  );
};

export default PermanentAppointmentPage;
