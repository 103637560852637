// hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useRecoilValue, useSetRecoilState } from 'recoil';
// interfaces
import { CoachType } from '@interfaces/user/coaches/coaches';
import { ErrorResponse } from '@interfaces/apiResponse';
// queries
import { useAllAdminCoachesQuery } from '@api/queries/coaches/coaches';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
// components
import { Card, Grid, Button, Tooltip } from '@mui/material';
import Loading from '@components/Loading/Loading';
import { NavLink } from 'react-router-dom';
// layouts
import { TableLayout } from '@layout/TableLayout/TableLayout';
// containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
// icons
import AddIcon from '@mui/icons-material/Add';
// atoms
import { popupAtom } from '@atoms/popupAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermission } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const CoachesPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId: number = Number(params?.sportCenterId);

  const setPopup = useSetRecoilState(popupAtom);
  const currentCMS = useRecoilValue(currentCMSAtom);

  const { t } = useTranslation();

  const { pagination, setPagination, tableInstanceRef, handleSearch, filterValues } =
    useTablesLogic();

  const columnsCoaches = useMemo<MRT_ColumnDef<CoachType | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name_and_surname'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as CoachType;
          return (
            <NavLink to={`edit-coach/${rowType.id}`} style={{ color: 'black' }}>
              {cell.getValue<string>()}{' '}
            </NavLink>
          );
        }
      },
      {
        accessorKey: 'court_sports',
        header: t('field') + '/' + t('sport'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as CoachType;
          let tempArr: string[] = [];
          rowType.coach_info.court_sports &&
            rowType.coach_info?.court_sports.map(coSpo => {
              tempArr.push(`${coSpo.court.name} - ${coSpo.sport.name}`);
            });
          return (
            <Tooltip title={tempArr.join('; ')} placement="top">
              <span>
                {tempArr.join('; ').length > 20
                  ? tempArr.join('; ').slice(0, 20) + '...'
                  : tempArr.join('; ')}
              </span>
            </Tooltip>
          );
        }
      },
      {
        accessorKey: 'coach_info.date_start',
        header: t('coachesPage.appointment_start_date'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as CoachType;

          return <span>{rowType.coach_info.date_start.toString()}</span>;
        }
      },
      {
        accessorKey: 'coach_info.date_end',
        header: t('coachesPage.appointment_end_date'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as CoachType;

          return <span>{rowType.coach_info.date_end.toString()}</span>;
        }
      },
      {
        accessorKey: 'coach_info.assigned_hours',
        header: t('coachesPage.appointments.allHours'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'coach_info.remaining_hours',
        header: t('coachesPage.appointments.hoursLeft'),
        maxSize: 4,
        minSize: 4,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );
  const onError = (err: ErrorResponse) =>
    setPopup({
      open: true,
      title: '',
      content: err.message,
      variant: 'error',
      onClose: () => refetch()
    });

  const {
    data: coachesData,
    isLoading,
    refetch
  } = useAllAdminCoachesQuery(
    sportCenterId,
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    onError
  );

  return (
    <>
      {checkPermission(currentCMS, PermissionsEnum.create_coaches) && (
        <Grid container justifyContent="flex-end" alignItems="center" m={2} mt={0}>
          <Grid item mr={5}>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate('create-coach')}
            >
              {t('coachesPage.buttonText')}
            </Button>
          </Grid>
        </Grid>
      )}
      <Card sx={{ mx: 3, px: 3 }}>
        <TableHeaderActions
          tableVariant="coaches"
          handleSearch={handleSearch}
          hideActionBtn
          disableSort
          disableFilter
        />

        <Grid item xs={12}>
          {coachesData?.data.data && !isLoading ? (
            <TableLayout
              tableVariant="coaches"
              rows={coachesData?.data.data}
              columns={columnsCoaches}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={coachesData?.data.meta?.total}
              tableInstanceRef={tableInstanceRef}
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </>
  );
};

export default CoachesPage;
