//hooks
import { useTranslation } from 'react-i18next';
import { useCreateEmployeeHook } from '@hooks/useCreateEmployee/useCreateEmployee';
//components
import { Grid } from '@mui/material';
import ProfileInfoCard from '@containers/ProfileInfoCard/ProfileInfoCard';
// import StatisticCard from '@components/StatisticCard/StatisticCard';
import CustomAccordion from '@components/CustomAccordion/CustomAccordion';
import EmployeeProfileData from '@components/Forms/Employees/EmployeeProfileData/EmployeeProfileData';
import Loading from '@components/Loading/Loading';

const SingleEmployee = () => {
  const { singleEmployee, isLoadingEmployee } = useCreateEmployeeHook();

  const { t } = useTranslation();

  if (isLoadingEmployee) {
    return <Loading />;
  }
  if (!singleEmployee) {
    return <div>Employee does not exists!</div>;
  }
  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={12}>
        <ProfileInfoCard
          name={singleEmployee.user_info.first_name + ' ' + singleEmployee.user_info.last_name}
          image=""
          otherInfo={[
            // TODO: return after MVP
            // { title: 'Status ugovora', value: 'Aktivan' },
            { title: t('email'), value: singleEmployee.user_info.email },
            { title: t('phone_number'), value: singleEmployee.user_info.phone },
            {
              title: t('sector'),
              value: singleEmployee.cooperation_info.employee_position.employee_sector.name
            }
          ]}
          handleDeactivate={() => {}}
          handleDelete={() => {}}
          isUser={true}
        />
      </Grid>
      {/* TODO: return this after MVP */}
      {/* <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <StatisticCard
              header="Iznos plate"
              title={singleEmployee.cooperation_info.salary + ' RSD'}
              subtitle="Poslednje povecanje April 2023"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <StatisticCard
              header="Nedovrsenih zadataka"
              title="12"
              subtitle="Poslednji obavljen zadatak 16.04.2023."
            />
          </Grid>
        </Grid>
      </Grid> */}
      <Grid item xs={12}>
        <CustomAccordion summary={t('employeeProfile.details')} defaultExpanded>
          <EmployeeProfileData />
        </CustomAccordion>
        {/* TODO: return this after MVP */}

        {/* <CustomAccordion summary="Zadaci">zadaci</CustomAccordion>
        <CustomAccordion summary="Beleske">beleske</CustomAccordion> */}
      </Grid>
    </Grid>
  );
};

export default SingleEmployee;
