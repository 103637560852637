//react
import { useEffect, useState, ChangeEvent, MouseEvent } from 'react';
//axios
import { AxiosRequestConfig, AxiosProgressEvent } from 'axios';
// router
import { useNavigate, useParams } from 'react-router-dom';
// hooks
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//mutations
import {
  useCreateSportCenter,
  useSingleSportCenter,
  useUpdateSportCenter
} from '@api/mutations/sportCenter/sportCenter';
import { useUploadSCImage } from '@api/mutations/images/images';
import { useDeleteSCImage } from '@api/mutations/images/images';
import {
  useGetLocationsList,
  useGetLocation,
  useGetReverseGeocode
} from '@api/mutations/locationsGeoCode/locationsGeoCode';
// queries
import { useQueryBenefits } from '@api/queries/sportCenter/benefits';
//interfaces
import { SuccessResponse, ErrorResponse } from '@interfaces/apiResponse';
import {
  CreateSportCenterType,
  SportCenterError,
  SportCenterErrorKeys,
  SportCenterResponse
} from '@interfaces/SportCenters/SportCenter';
import {
  AutocompleteResponse,
  AutocompleteType,
  LocationResponse
} from '@interfaces/locationsGeoCode/locationsGeoCode';
import { UploadImageResponse } from '@interfaces/Images/images';
import { BenefitsType, BenefitsError, BenefitsResponse } from '@interfaces/SportCenters/Benefits';
//atoms
import { stepOneDataAtom } from '@atoms/stepOneData';
// helpers
import {
  allDayObjectValuesResponse,
  createBenefitsArray,
  onUploadProgress
} from '@src/helpers/utility';
import { popupAtom } from '@atoms/popupAtom';

export function useCreateSportCenterStep1(shouldStay?: boolean) {
  const [stepOneData, setStepOneData] = useRecoilState(stepOneDataAtom);
  const resetStepOneData = useResetRecoilState(stepOneDataAtom);
  const setPopup = useSetRecoilState(popupAtom);
  const [logoFormData, setLogoFormData] = useState<FormData>(new FormData());
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [benefitsRes, setBenefitsRes] = useState<BenefitsType>([]);
  const [isBenefitsChecked, setIsBenefitsChecked] = useState<number[]>(stepOneData.benefits);
  const [isPaymentChecked, setIsPaymentChecked] = useState<string[]>(stepOneData.payment_methods);
  const [isItPDV, setIsItPDV] = useState<boolean>(stepOneData.legal_data.in_vat_system);
  const [locations, setLocations] = useState<AutocompleteType[]>([]);
  const [locationsLegalData, setLocationsLegalData] = useState<AutocompleteType[]>([]);
  const [centerMapBasicData, setCenterMapBasicData] = useState<google.maps.LatLngAltitudeLiteral>({
    altitude: 0,
    lat: 0,
    lng: 0
  });
  const [centerMapLegalData, setCenterMapLegalData] = useState<google.maps.LatLngAltitudeLiteral>({
    altitude: 0,
    lat: 0,
    lng: 0
  });
  const lng = localStorage.getItem('lng');

  const { t } = useTranslation();
  const reqMessage = t('errorMessage');
  const steps = [
    t('sportCenters.steps.about_object'),
    t('sportCenters.steps.photos'),
    t('sportCenters.steps.fields'),
    t('pricelist')
  ];

  const navigate = useNavigate();
  const params = useParams();
  const { handleSubmit, control, setValue, setError, trigger, reset, getValues } =
    useForm<CreateSportCenterType>({
      defaultValues: stepOneData,
      mode: 'all'
    });
  const validateWokweek: boolean =
    parseInt(getValues('working_hours.workweek_from')) >=
    parseInt(getValues('working_hours.workweek_to'));

  const validateSaturday: boolean =
    getValues('working_hours.saturday_from') !== '00:00' &&
    getValues('working_hours.saturday_to') !== '00:00'
      ? parseInt(getValues('working_hours.saturday_from')) >=
        parseInt(getValues('working_hours.saturday_to'))
      : false;

  const validateSunday: boolean =
    getValues('working_hours.sunday_from') !== '00:00' &&
    getValues('working_hours.sunday_to') !== '00:00'
      ? parseInt(getValues('working_hours.sunday_from')) >=
        parseInt(getValues('working_hours.sunday_to'))
      : false;
  const watchWorkingHours = useWatch({
    control: control,
    name: 'working_hours'
  });
  const [wholeWorkingDay, setwholeWorkingDay] = useState<boolean>(false);
  const [wholeSaturdayDay, setwholeSaturdayDay] = useState<{ on: boolean; off: boolean }>({
    on: false,
    off: false
  });
  const [wholeSundayDay, setwholeSundayDay] = useState<{ on: boolean; off: boolean }>({
    on: false,
    off: false
  });
  const handleChangeWholeWorkingDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeWorkingDay(event.target.checked);
  };
  const handleChangeWholeSaturdayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSaturdayDay({ on: event.target.checked, off: false });
  };
  const handleChangeWholeSundayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSundayDay({ on: event.target.checked, off: false });
  };
  const handleChangeOffWholeSaturdayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSaturdayDay({ on: false, off: event.target.checked });
  };
  const handleChangeOffWholeSundayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSundayDay({ on: false, off: event.target.checked });
  };

  const handleCancel = () => {
    navigate('/objects');
    resetStepOneData();
  };
  const handleAutocompleteChange = (
    event: React.SyntheticEvent,
    value: string | AutocompleteType | null,
    reason: string
  ) => {
    if (value && typeof value !== 'string' && value?.id) {
      getLocation(value?.id);
    }
  };

  const handleSearchChange = (event: React.SyntheticEvent, value: string, reason: string) => {
    if (value.length > 2) {
      getLocationsList({ query: value });
    } else if (value.length === 0) {
      setValue('basic_info.latitude', '');
      setValue('basic_info.longitude', '');
      setValue('basic_info.city', '');
      setValue('basic_info.municipality', '');
      setValue('basic_info.address', '');
      setCenterMapBasicData({
        altitude: 0,
        lat: 0,
        lng: 0
      });
    }
  };

  const handleAutocompleteChangeLegalData = (
    event: React.SyntheticEvent,
    value: string | AutocompleteType | null,
    reason: string
  ) => {
    if (value && typeof value !== 'string' && value?.id) {
      getLocationLegalData(value?.id);
    }
  };
  const handleSearchChangeLegalData = (
    event: React.SyntheticEvent,
    value: string,
    reason: string
  ) => {
    if (value.length > 2) {
      getLocationsListLegalData({ query: value });
    } else if (value === '') {
      setValue('legal_data.city', '');
      setValue('legal_data.municipality', '');
      setValue('legal_data.address', '');
      setCenterMapLegalData({
        altitude: 0,
        lat: 0,
        lng: 0
      });
    }
  };
  const handleOnLogoChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault();
    const logoFormData = new FormData();
    if (event.target?.files) {
      if (params.sportCenterId || stepOneData.id) {
        logoFormData.append('image', event.target?.files[0]);
        logoFormData.append('is_featured', '1');
        setLogoFormData(logoFormData);
        uploadLogo({
          id: !params.sportCenterId ? stepOneData.id : params.sportCenterId,
          data: logoFormData,
          uploadPercentOptions: uploadPercentOptions
        });
      } else {
        logoFormData.append('image', event.target?.files[0]);
        logoFormData.append('is_featured', '1');
        setLogoFormData(logoFormData);
        setValue('basic_info.logo', { id: 1, src: URL.createObjectURL(event.target?.files[0]) });
      }
    }
  };

  const handleLogoClick = (event: MouseEvent<HTMLLabelElement>) => {
    event.preventDefault();

    if (params.sportCenterId && stepOneData.basic_info.logo) {
      deleteLogo({ objectId: `${params.sportCenterId}`, imageID: stepOneData.basic_info.logo.id });
    } else {
      if (stepOneData.id && stepOneData.basic_info.logo) {
        deleteLogo({ objectId: `${stepOneData.id}`, imageID: stepOneData.basic_info.logo.id });
      } else {
        setLogoFormData(new FormData());
        setStepOneData(stepOneData => ({
          ...stepOneData,
          basic_info: { ...stepOneData.basic_info, logo: undefined }
        }));
        setValue('basic_info.logo', undefined);
        reset(stepOneData);
      }
    }
  };
  const uploadPercentOptions: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      onUploadProgress(progressEvent, setUploadPercent)
  };

  const handleOnMarkerChange = (newPosition: google.maps.LatLngLiteral) => {
    getReverseGeocode({ latitude: newPosition.lat, longitude: newPosition.lng });
  };

  const onLocationsListSuccess = (data: AutocompleteResponse) => {
    setLocations(data.data);
  };
  const onLocationsListError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const onNewLocationSuccess = (data: AutocompleteResponse) => {
    setPopup({
      open: true,
      title: `${t('sportCenters.stepOne.basic_data.map_popup_title')} 
      ${t('sportCenters.stepOne.basic_data.map_popup_subtitle')}`,
      content: `
     ${t('address')}: ${data.data[0].street} ${
        data.data[0].houseNumber !== null ? data.data[0].houseNumber : ''
      },
      ${t('city')}: ${data.data[0].city},
      ${t('municipality')}: ${data.data[0].municipality}
      `,
      variant: 'info',
      onClose: () => {
        getLocation(data.data[0].id);
        setPopup({ open: false, title: '', content: '', variant: 'info' });
      },
      onClick: () => {
        if (data.data[0].position) {
          setValue('basic_info.latitude', data.data[0].position.latitude.toString());
          setValue('basic_info.longitude', data.data[0].position.longitude.toString());
          setPopup({ open: false, title: '', content: '', variant: 'info' });
        }
      },
      onCancel: () => {
        const oldLocation = locations.find((location: AutocompleteType) => {
          location.position?.latitude === +getValues('basic_info.latitude');
          return location;
        });
        if (oldLocation) {
          getLocation(oldLocation?.id);

          setPopup({
            open: false,
            title: '',
            content: '',
            variant: 'info',
            back: '',
            buttonText: ''
          });
          return;
        }
        setPopup({
          open: false,
          title: '',
          content: '',
          variant: 'info',
          back: '',
          buttonText: ''
        });
      },
      back: t('sportCenters.stepOne.basic_data.map_button_confirm').toString(),
      buttonText: t('sportCenters.stepOne.basic_data.map_button_cancel').toString()
    });
  };

  const onLocationSuccess = (data: LocationResponse) => {
    if (data.data.position) {
      setValue('basic_info.latitude', data?.data?.position.latitude.toString());
      setValue('basic_info.longitude', data.data.position.longitude.toString());
    }
    setValue('basic_info.city', data.data.city);
    setValue('basic_info.municipality', data.data.municipality);
    setValue(
      'basic_info.address',
      `${data.data.street} ${data.data.houseNumber !== null ? data.data.houseNumber : ''}`
    );
    if (data.data.position) {
      setCenterMapBasicData({
        altitude: 0,
        lat: data.data.position.latitude,
        lng: data.data.position.longitude
      });
    }
  };
  const onLocationError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  const onLocationsListLegalDataSuccess = (data: AutocompleteResponse) => {
    setLocationsLegalData(data.data);
  };
  const onLocationsListLegalDataError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const onLocationLegalDataSuccess = (data: LocationResponse) => {
    setValue('legal_data.city', data.data.city);
    setValue('legal_data.municipality', data.data.municipality);
    setValue(
      'legal_data.address',
      `${data.data.street} ${data.data.houseNumber !== null ? data.data.houseNumber : ''}`
    );
    if (data.data.position) {
      setCenterMapLegalData({
        altitude: 0,
        lat: data.data.position.latitude,
        lng: data.data.position.longitude
      });
    }
  };
  const onLocationLegalDataError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  // benefits
  const onBenefitsSuccess = (data: BenefitsResponse) => {
    setBenefitsRes(data.data);
  };
  const onBenefitsError = (err: BenefitsError) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  // logo
  const onUploadLogoSuccess = (data: UploadImageResponse) => {
    setStepOneData(stepOneData => ({
      ...stepOneData,
      basic_info: { ...stepOneData.basic_info, logo: data.data }
    }));
    uploadPercent === 0 &&
      !params.sportCenterId &&
      navigate(`/objects/create-object/${stepOneData.id}/images`, { replace: true });
  };
  const onUploadLogoError = (err: SportCenterError) => {
    if (err.errors) {
      for (const key in err.errors) {
        if (key === 'image') {
          setError(
            'basic_info.logo',
            {
              type: 'custom',
              message: err.errors[key][0]
            },
            {
              shouldFocus: true
            }
          );
        } else {
          setError(
            key as SportCenterErrorKeys,
            {
              type: 'custom',
              message: err.errors[key][0]
            },
            {
              shouldFocus: true
            }
          );
        }
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };
  const onDeleteLogoSuccess = (data: SuccessResponse) => {
    if (stepOneData.id) {
      getSingleSportCenter(stepOneData.id);
    }
  };
  const onDeleteLogoError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };
  // sport center
  const onCreateSportCenterSuccess = (data: SportCenterResponse) => {
    setStepOneData(stepOneData => ({ ...stepOneData, id: data.data.id }));
    const isLogo = getValues('basic_info.logo');
    if (data.data.id) {
      if (isLogo) {
        return uploadLogo({
          id: data.data.id,
          data: logoFormData,
          uploadPercentOptions: uploadPercentOptions
        });
      }

      history.replaceState({}, '', `/objects/create-object`);
      history.pushState({}, '', `/objects/create-object/${data.data.id}`);

      return navigate(`/objects/create-object/${data.data.id}/images`, { replace: true });
    }
  };
  const onCreateSportCenterError = (err: SportCenterError) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as SportCenterErrorKeys,
          {
            type: 'custom',
            message: err.errors[key][0]
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };

  const onUpdateSportCenterSuccess = (data: SportCenterResponse) => {
    if (shouldStay) {
      return;
    }
    navigate(
      `/objects/create-object/${!params.sportCenterId ? data.data.id : params.sportCenterId}/images`
    );
  };
  const onUpdateSportCenterError = (err: SportCenterError) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as SportCenterErrorKeys,
          {
            type: 'custom',
            message: err.errors[key][0]
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setError('id', {
        type: 'custom',
        message: err.message
      });
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };

  const onSingleSportCenterSuccess = (data: SportCenterResponse) => {
    const benefitsArr: number[] = createBenefitsArray(data.data.benefits);
    setIsBenefitsChecked(benefitsArr);
    setIsItPDV(data.data.legal_data.in_vat_system);
    setIsPaymentChecked(data.data.payment_methods);
    setCenterMapBasicData({
      altitude: 0,
      lat: Number(data.data.latitude),
      lng: Number(data.data.longitude)
    });
    data.data.working_hours = allDayObjectValuesResponse(data);
    setStepOneData(prevState => ({
      ...prevState,
      id: data.data.id,
      basic_info: {
        name: data.data.name,
        email: data.data.email,
        phone: data.data.phone,
        address: data.data.address,
        city: data.data.city,
        municipality: data.data.municipality,
        longitude: data.data.longitude,
        latitude: data.data.latitude,
        description: data.data.description,
        description_translates: data.data.description_translates,
        logo: data.data.logo,
        gallery_images: data.data.gallery_images
      },
      legal_data: {
        ...prevState.legal_data,
        name: data.data.legal_data.name,
        tax_identification_number: data.data.legal_data.tax_identification_number,
        identification_number: data.data.legal_data.identification_number,
        bank_account_number: data.data.legal_data.bank_account_number,
        bank_name: data.data.legal_data.bank_name,
        address: data.data.legal_data.address,
        city: data.data.legal_data.city,
        municipality: data.data.legal_data.municipality,
        legal_representative_1: data.data.legal_data.legal_representative_1,
        legal_representative_2:
          data.data.legal_data.legal_representative_2 === null
            ? ''
            : data.data.legal_data.legal_representative_2,
        in_vat_system: data.data.legal_data.in_vat_system,
        e_invoicing_email:
          data.data.legal_data.e_invoicing_email === null
            ? ''
            : data.data.legal_data.e_invoicing_email,
        contract_start_date: data.data.legal_data.contract_start_date,
        contract_expiry_date: data.data.legal_data.contract_expiry_date
      },
      payment_methods: data.data.payment_methods,
      benefits: benefitsArr,
      working_hours: data.data.working_hours,
      fee_percentage: data.data.fee_percentage,
      max_days_upfront: data.data.advanced?.max_days_upfront,
      max_upfront_reservations: data.data.advanced?.max_upfront_reservations,
      list_sport_center_in_app: data.data.advanced?.list_sport_center_in_app,
      cancellation_policy_in_hours: data.data.advanced?.cancellation_policy_in_hours,
      allow_half_hour_appointment: data.data.advanced?.allow_half_hour_appointment
    }));
    if (data.data.working_hours.workweek_to === '24:00') {
      setwholeWorkingDay(true);
    }
    if (data.data.working_hours.saturday_to === '24:00') {
      setwholeSaturdayDay({ on: true, off: false });
    }
    if (data.data.working_hours.saturday_to === '00:00') {
      setwholeSaturdayDay({ on: false, off: true });
    }
    if (data.data.working_hours.sunday_to === '24:00') {
      setwholeSundayDay({ on: true, off: false });
    }
    if (data.data.working_hours.sunday_to === '00:00') {
      setwholeSundayDay({ on: false, off: true });
    }
  };

  const onSingleSportCenterError = (err: SportCenterError) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: getLocationsList, isLoading: isLoadingLocationsList } = useGetLocationsList(
    onLocationsListSuccess,
    onLocationsListError
  );
  const { mutate: getLocation, isLoading: isLoadingLocation } = useGetLocation(
    onLocationSuccess,
    onLocationError
  );
  const { mutate: getReverseGeocode } = useGetReverseGeocode(onNewLocationSuccess, onLocationError);
  const { mutate: getLocationsListLegalData, isLoading: isLoadingLocationsListLegalData } =
    useGetLocationsList(onLocationsListLegalDataSuccess, onLocationsListLegalDataError);
  const { mutate: getLocationLegalData, isLoading: isLoadingLocationLegalData } = useGetLocation(
    onLocationLegalDataSuccess,
    onLocationLegalDataError
  );
  useQueryBenefits(onBenefitsSuccess, onBenefitsError);
  const { mutate: deleteLogo } = useDeleteSCImage(onDeleteLogoSuccess, onDeleteLogoError);
  const { mutate: uploadLogo, isLoading: isUploadLogoLoading } = useUploadSCImage(
    onUploadLogoSuccess,
    onUploadLogoError
  );
  const { mutate: createSportCeneter, isLoading: isCreateSportCenterLoading } =
    useCreateSportCenter(onCreateSportCenterSuccess, onCreateSportCenterError);
  const { mutate: updateSportCenter, isLoading: isUpdateSingleSportCenterLoading } =
    useUpdateSportCenter(onUpdateSportCenterSuccess, onUpdateSportCenterError);
  const { mutate: getSingleSportCenter, isLoading: isSingleSportCenterLoading } =
    useSingleSportCenter(onSingleSportCenterSuccess, onSingleSportCenterError);

  const onSubmit: SubmitHandler<CreateSportCenterType> = async (data, e) => {
    e?.preventDefault();
    if (data.working_hours.workweek_to === '24:00') {
      data.working_hours.workweek_to = '23:59';
    }
    if (data.working_hours.saturday_to === '24:00') {
      data.working_hours.saturday_to = '23:59';
    }
    if (
      data.working_hours.saturday_from === '00:00' &&
      data.working_hours.saturday_to === '00:00'
    ) {
      data.working_hours.saturday_from = '';
      data.working_hours.saturday_to = '';
    }
    if (data.working_hours.sunday_to === '24:00') {
      data.working_hours.sunday_to = '23:59';
    }
    if (data.working_hours.sunday_from === '00:00' && data.working_hours.sunday_to === '00:00') {
      data.working_hours.sunday_from = '';
      data.working_hours.sunday_to = '';
    }

    data.basic_info.description =
      lng === 'en'
        ? data.basic_info.description_translates.en
        : data.basic_info.description_translates.sr;
    let isValid = await trigger([
      'basic_info.name',
      'basic_info.email',
      'basic_info.phone',
      'basic_info.address',
      'basic_info.city',
      'basic_info.municipality',
      'basic_info.latitude',
      'basic_info.longitude',
      'basic_info.description_translates.sr',
      'basic_info.description_translates.en',
      'legal_data.name',
      'legal_data.e_invoicing_email',
      'legal_data.tax_identification_number',
      'legal_data.identification_number',
      'legal_data.address',
      'legal_data.city',
      'legal_data.legal_representative_1',
      'payment_methods',
      'benefits',
      'working_hours'
    ]);
    if (isValid) {
      if (!data.id) {
        createSportCeneter(data);
        setStepOneData(data);
      } else {
        updateSportCenter(data);
      }
    }
  };

  useEffect(() => {
    if (params.sportCenterId) {
      getSingleSportCenter(parseInt(params.sportCenterId));
    }
  }, [params.sportCenterId]);
  useEffect(() => {
    if (!isSingleSportCenterLoading) {
      reset(stepOneData);
    }
  }, [stepOneData, isSingleSportCenterLoading]);
  useEffect(() => {
    //work-day
    if (wholeWorkingDay) {
      setValue('working_hours.workweek_from', '00:00');
      setValue('working_hours.workweek_to', '24:00');
    }
    //saturday
    if (wholeSaturdayDay.on) {
      setValue('working_hours.saturday_from', '00:00');
      setValue('working_hours.saturday_to', '24:00');
    }
    if (wholeSaturdayDay.off) {
      setValue('working_hours.saturday_from', '00:00');
      setValue('working_hours.saturday_to', '00:00');
    }
    //sunday
    if (wholeSundayDay.on) {
      setValue('working_hours.sunday_from', '00:00');
      setValue('working_hours.sunday_to', '24:00');
    }
    if (wholeSundayDay.off) {
      setValue('working_hours.sunday_from', '00:00');
      setValue('working_hours.sunday_to', '00:00');
    }
  }, [wholeWorkingDay, wholeSaturdayDay, wholeSundayDay]);

  return {
    handleSubmit,
    onSubmit,
    reqMessage,
    control,
    isItPDV,
    setIsItPDV,
    isPaymentChecked,
    setIsPaymentChecked,
    benefitsRes,
    isBenefitsChecked,
    setIsBenefitsChecked,
    handleCancel,
    handleOnLogoChange,
    handleLogoClick,
    isCreateSportCenterLoading,
    isUpdateSingleSportCenterLoading,
    isSingleSportCenterLoading,
    isUploadLogoLoading,
    steps,
    handleSearchChange,
    handleAutocompleteChange,
    locations,
    isLoadingLocationsList,
    isLoadingLocation,
    handleAutocompleteChangeLegalData,
    handleSearchChangeLegalData,
    locationsLegalData,
    isLoadingLocationsListLegalData,
    isLoadingLocationLegalData,
    centerMapLegalData,
    centerMapBasicData,
    params,
    setPopup,
    getValues,
    validateSaturday,
    validateSunday,
    validateWokweek,
    handleOnMarkerChange,
    wholeWorkingDay,
    handleChangeWholeWorkingDay,
    wholeSaturdayDay,
    handleChangeWholeSaturdayDay,
    handleChangeOffWholeSaturdayDay,
    wholeSundayDay,
    handleChangeWholeSundayDay,
    handleChangeOffWholeSundayDay
  };
}
