// store
import { atom } from 'recoil';

const sportFormAtom = atom<{
  fromSportForm?: boolean;
  sportCenterID?: number;
  sportID: string;
} | null>({
  key: 'sportFormAtom',
  default: null
});

export { sportFormAtom };
