//react
import { Navigate, useLocation } from 'react-router-dom';
//components
import { Box, Typography } from '@mui/material';
import ResetPasswordForm from '@components/Forms/ResetPasswordForm/ResetPasswordForm';
//form hooks
import { SubmitHandler, useForm } from 'react-hook-form';
//type
import { ResetPasswordError, ResetPasswordFormRequestType } from '@interfaces/user/login';
//mutations
import { useResetPassword } from '@api/mutations/user/login';
//recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
//translate
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const setPopup = useSetRecoilState(popupAtom);

  const { search } = useLocation();

  const { t } = useTranslation();

  const token = new URLSearchParams(search).get('token');
  const email = new URLSearchParams(search).get('email');

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors }
  } = useForm<ResetPasswordFormRequestType>({
    defaultValues: {
      password: '',
      password_confirmation: ''
    }
  });

  const { mutate: resetPassword, isLoading } = useResetPassword(() => {
    setPopup({
      open: true,
      title: t('setPassword.successTitle'),
      content: '',
      variant: 'success',
      stayOpen: true
    });
  }, onError);

  const submitForm: SubmitHandler<ResetPasswordFormRequestType> = async (data, e) => {
    e?.preventDefault();
    if (token) data.token = token;
    if (email) data.email = email;
    // console.log('data', data, { token });
    resetPassword(data);
  };

  function onError(err: ResetPasswordError) {
    if (err.errors) {
      let field_error = false;
      for (const key in err.errors) {
        field_error = true;
        setError(
          key as keyof ResetPasswordFormRequestType,
          {
            type: 'custom',
            message: err.errors[key as keyof ResetPasswordFormRequestType]?.join(' ')
          },
          {
            shouldFocus: true
          }
        );
      }
      if (!field_error) {
        setError('root', {
          message: err.message
        });
      }
    } else {
      setError('root', {
        message: 'doslo je do greske'
      });
    }
  }

  if (!token || !email) {
    return <Navigate to="/error-page" state={{ from: location }} />;
  }

  return (
    <Box width={{ xs: '80%', sm: '70%' }} mt={{ xs: 4, sm: 0 }}>
      {errors.root && (
        <Typography variant="body2" color="error">
          {errors.root.message}
        </Typography>
      )}
      {errors.token && (
        <Typography variant="body2" color="error">
          {errors.token.message}
        </Typography>
      )}
      <ResetPasswordForm
        onSubmit={handleSubmit(submitForm)}
        control={control}
        isLoading={isLoading}
      />
    </Box>
  );
};

export default ResetPassword;
