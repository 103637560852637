//components
import EmploymentDetails from '@components/Forms/Employees/EmploymentDetails/EmploymentDetails';
import PersonalData from '@components/Forms/Employees/PersonalData/PersonalData';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
//hooks
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCreateEmployeeHook } from '@hooks/useCreateEmployee/useCreateEmployee';
//icons
import ControlPointIcon from '@mui/icons-material/ControlPoint';

const CreateEmployee = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    control,
    documents,
    onDropCallback,
    sectors,
    positions,
    setValue,
    handleFileDelete,
    isLoading,
    handleSubmit,
    onSubmit,
    uploadPercent,
    roles,
    onCreateRoleClick
  } = useCreateEmployeeHook();

  return (
    <Box px={5} py={2}>
      <PersonalData control={control} />

      <EmploymentDetails
        control={control}
        files={documents}
        onDropCallback={onDropCallback}
        uploadPercent={uploadPercent}
        sectors={sectors}
        positions={positions}
        setValue={setValue}
        handleFileDelete={handleFileDelete}
        isLoading={isLoading}
      />

      <Accordion expanded={true} sx={{ px: 6 }}>
        <AccordionSummary>
          <Box>
            <Typography variant="h6">{t('permissions')}</Typography>
            <Typography variant="subtitle2" color="GrayText">
              {t('employee.permissionsSubtitle')}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ pb: 5 }}>
          <Controller
            control={control}
            name="user_info.role_id"
            rules={{ required: true }}
            defaultValue={''}
            render={({ field }) => (
              <FormControl fullWidth>
                <InputLabel>{t('permissions') + ' *'}</InputLabel>
                <Select label={t('permissions') + ' *'} {...field}>
                  <MenuItem>
                    <Box display={'flex'} gap={2}>
                      <ControlPointIcon sx={{ color: 'gray' }} />
                      <NavLink
                        to={'/settings/access/create-role'}
                        state={{ from: 'employee' }}
                        onClick={() => onCreateRoleClick()}
                      >
                        {t('employee.createNewPermission')}
                      </NavLink>
                    </Box>
                  </MenuItem>
                  {roles.map(role => (
                    <MenuItem key={role.id} value={role.id}>
                      {role.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </AccordionDetails>
      </Accordion>

      <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
        <Button variant="text" onClick={() => navigate(-1)}>
          {t('back')}
        </Button>
        <Button type="submit" variant="contained" onClick={handleSubmit(onSubmit)}>
          {t('next')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateEmployee;
