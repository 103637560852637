// hooks
import { useNavigate } from 'react-router-dom';
import { useCreateSportCenterStep1 } from '@src/ui/hooks/createSportCenterStep1/useCreateSportCenterStep1';
import { useCreateSportCenterStep2 } from '@hooks/createSportCenterStep2/useCreateSportCenterStep2';
import { useSetRecoilState } from 'recoil';

// components
import UploadImage from '@containers/ImageUpload/ImageUpload';
import { Box, Button, Typography } from '@mui/material';
import BasicData from '@src/ui/components/Forms/SportCenters/BasicData/BasicData';
import Logo from '@src/ui/components/Forms/SportCenters/Logo/Logo';
import LegalData from '@src/ui/components/Forms/SportCenters/LegalData/LegalData';
import OtherData from '@src/ui/components/Forms/SportCenters/OtherData/OtherData';
import Payment from '@src/ui/components/Forms/SportCenters/Payment/Payment';
import ObjectAdvancedSettings from '@components/Forms/Admin/ObjectAdvancedSettings/ObjectAdvancedSettings';
import MapsView from '@components/MapsView/MapsView';
//types
import { Control } from 'react-hook-form';
import { CreateSportCenterType } from '@interfaces/SportCenters/SportCenter';
import { BusinessUserTypeRequest } from '@interfaces/user/user';
import { SuccessResponse, ErrorResponse } from '@interfaces/apiResponse';
import { LogoutErrorResponse } from '@interfaces/user/login';
// mutatitons
import { useDeleteSportCenter } from '@api/mutations/sportCenter/sportCenter';
import { useLogout } from '@api/mutations/user/login';
// atoms
import { userAtom } from '@atoms/userAtom';

//translate
import { useTranslation } from 'react-i18next';
//icons
import { DeleteIcon } from '@components/icons/icons';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
import Loading from '@components/Loading/Loading';

const ObjectProfile = () => {
  const setUser = useSetRecoilState(userAtom);
  const navigate = useNavigate();
  const {
    handleSubmit,
    onSubmit,
    reqMessage,
    control,
    isItPDV,
    setIsItPDV,
    isPaymentChecked,
    setIsPaymentChecked,
    benefitsRes,
    isBenefitsChecked,
    setIsBenefitsChecked,
    handleOnLogoChange,
    handleLogoClick,
    isUpdateSingleSportCenterLoading,
    handleAutocompleteChange,
    handleSearchChange,
    isLoadingLocation,
    isLoadingLocationsList,
    locations,
    handleAutocompleteChangeLegalData,
    handleSearchChangeLegalData,
    locationsLegalData,
    isLoadingLocationsListLegalData,
    isLoadingLocationLegalData,
    centerMapBasicData,
    centerMapLegalData,
    params,
    setPopup,
    getValues,
    validateSaturday,
    validateSunday,
    validateWokweek,
    wholeWorkingDay,
    handleChangeWholeWorkingDay,
    wholeSaturdayDay,
    handleChangeWholeSaturdayDay,
    handleChangeOffWholeSaturdayDay,
    wholeSundayDay,
    handleChangeWholeSundayDay,
    handleChangeOffWholeSundayDay
  } = useCreateSportCenterStep1(true);

  const { images, onDropCallback, handleDelete, uploadPercent, isLoading } =
    useCreateSportCenterStep2();

  const { t } = useTranslation();
  const sportCenterName: string = getValues('basic_info.name');

  const onLogoutSuccess = () => {
    setUser(null);
    navigate('/login');
    window.history.replaceState(null, '', '/');
  };
  const onLogoutError = (err: LogoutErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: submitLogout } = useLogout(onLogoutSuccess, onLogoutError);

  const onDeleteSuccesss = (data: SuccessResponse) => {
    setPopup({
      open: false,
      title: '',
      variant: 'delete',
      content: ''
    });
    submitLogout('');
  };

  const onDeleteError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteObject } = useDeleteSportCenter(onDeleteSuccesss, onDeleteError);

  if (isUpdateSingleSportCenterLoading) {
    return <Loading />;
  }
  return (
    <Box p={2}>
      <Box display={'flex'} justifyContent={'flex-end'} mt={-5}>
        <Button
          color="error"
          variant="contained"
          size="small"
          endIcon={<DeleteIcon stroke="white" fontSize="small" />}
          onClick={() => {
            setPopup({
              open: true,
              variant: 'delete',
              title: t('sportCenters.deletePopupTitle').toString() + ' ' + sportCenterName,
              content: t('sportCenters.deleteMessageSingle').toString(),
              onClick: () => deleteObject(params?.sportCenterId ? +params.sportCenterId : 0)
            });
          }}
        >
          {t('deleteProfile')}
        </Button>
      </Box>
      <ObjectAdvancedSettings control={control} />

      <CardWrapper title="" isForm={true}>
        <BasicData
          control={control}
          reqMessage={reqMessage}
          handleAutocompleteChange={handleAutocompleteChange}
          handleSearchChange={handleSearchChange}
          isLoadingLocation={isLoadingLocation}
          isLoadingLocationsList={isLoadingLocationsList}
          locations={locations}
        />
        {!isLoadingLocation && centerMapBasicData.lat > 0 && (
          <MapsView id="cms-map-basic-data" center={centerMapBasicData} />
        )}
        <Logo
          control={control}
          handleLogoClick={handleLogoClick}
          handleOnLogoChange={handleOnLogoChange}
        />
      </CardWrapper>

      <CardWrapper
        title={
          <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
            {t('sportCenters.stepTwoTitle')}
          </Typography>
        }
        subtitle={
          <Typography variant="subtitle2" color="GrayText" sx={{ pb: 2 }}>
            {t('sportCenters.stepTwoSubtitle')}
          </Typography>
        }
        isForm={true}
      >
        <UploadImage
          title={''}
          images={images}
          onDropCallback={onDropCallback}
          handleDelete={handleDelete}
          uploadPercent={uploadPercent}
          isLoading={isLoading}
        />
      </CardWrapper>

      <LegalData
        control={control as Control<CreateSportCenterType | BusinessUserTypeRequest>}
        isItPDV={isItPDV}
        setIsItPDV={setIsItPDV}
        reqMessage={reqMessage}
        handleAutocompleteChange={handleAutocompleteChangeLegalData}
        handleSearchChange={handleSearchChangeLegalData}
        isLoadingLocation={isLoadingLocationLegalData}
        isLoadingLocationsList={isLoadingLocationsListLegalData}
        locations={locationsLegalData}
        centerMapLegalData={centerMapLegalData}
      />

      <Payment
        control={control}
        isPaymentChecked={isPaymentChecked}
        setIsPaymentChecked={setIsPaymentChecked}
        reqMessage={reqMessage}
      />

      <OtherData
        control={control}
        benefitsRes={benefitsRes}
        isBenefitsChecked={isBenefitsChecked}
        setIsBenefitsChecked={setIsBenefitsChecked}
        reqMessage={reqMessage}
        getValues={getValues}
        wholeWorkingDay={wholeWorkingDay}
        handleChangeWholeWorkingDay={handleChangeWholeWorkingDay}
        wholeSaturdayDay={wholeSaturdayDay}
        handleChangeWholeSaturdayDay={handleChangeWholeSaturdayDay}
        handleChangeOffWholeSaturdayDay={handleChangeOffWholeSaturdayDay}
        wholeSundayDay={wholeSundayDay}
        handleChangeWholeSundayDay={handleChangeWholeSundayDay}
        handleChangeOffWholeSundayDay={handleChangeOffWholeSundayDay}
      />

      <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate(`/settings/${params.sportCenterId}`)}
        >
          {t('quit')}
        </Button>

        <Button
          type="submit"
          disabled={validateSaturday || validateSunday || validateWokweek}
          variant="contained"
          onClick={handleSubmit(onSubmit)}
        >
          {t('finish')}
        </Button>
      </Box>
    </Box>
  );
};

export default ObjectProfile;
