// hooks
import { useTranslation } from 'react-i18next';
import { Controller, Control } from 'react-hook-form';
// dayjs
import dayjs from 'dayjs';
// components
import {
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  FormHelperText,
  Switch,
  Box
} from '@mui/material';
import SearchGeoCode from '@components/SearchGeoCode/SearchGeoCode';
import MapsView from '@components/MapsView/MapsView';
import CardWrapper from '@containers/CardWrapper/CardWrapper';
// types
import { AutocompleteType } from '@interfaces/locationsGeoCode/locationsGeoCode';
import { CreateSportCenterType, LegalDataType } from '@interfaces/SportCenters/SportCenter';
import { BusinessUserTypeRequest } from '@interfaces/user/user';
//mock
import { banks } from '@src/__mock__/createSportsCenter/steps';

type LegalDataProps = {
  control: Control<CreateSportCenterType | BusinessUserTypeRequest>;
  isItPDV: boolean;
  setIsItPDV: (pdv: boolean) => void;
  reqMessage: string;
  handleAutocompleteChange: (
    event: React.SyntheticEvent,
    option: string | AutocompleteType | null,
    reason: string
  ) => void;
  handleSearchChange: (event: React.SyntheticEvent, value: string, reason: string) => void;
  locations: AutocompleteType[];
  isLoadingLocationsList: boolean;
  isLoadingLocation: boolean;
  centerMapLegalData: google.maps.LatLngAltitudeLiteral;
};

const LegalData = ({
  control,
  isItPDV,
  reqMessage,
  setIsItPDV,
  handleAutocompleteChange,
  handleSearchChange,
  locations: locations,
  isLoadingLocationsList,
  isLoadingLocation,
  centerMapLegalData
}: LegalDataProps) => {
  const { t } = useTranslation();
  return (
    <CardWrapper
      isForm={true}
      title={
        <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
          {t('sportCenters.stepOne.legalData.title')}
        </Typography>
      }
      subtitle={
        <Typography variant="subtitle2" color="GrayText" sx={{ pb: 1 }}>
          {t('sportCenters.stepOne.legalData.subtitle') + ' *'}
        </Typography>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            name="legal_data.name"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('sportCenters.stepOne.legalData.name')}
                variant="outlined"
                sx={{ width: '100%' }}
                error={invalid}
                helperText={error && error.message}
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="legal_data.tax_identification_number"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('sportCenters.stepOne.legalData.tax_identification_number')}
                variant="outlined"
                sx={{ width: '100%' }}
                type="number"
                onChange={e => field.onChange(e.target.value)}
                error={invalid}
                helperText={error && error.message}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="legal_data.identification_number"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('sportCenters.stepOne.legalData.identification_number') + ' *'}
                variant="outlined"
                sx={{ width: '100%' }}
                type="number"
                onChange={e => field.onChange(e.target.value)}
                error={invalid}
                helperText={error && error.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="legal_data.bank_account_number"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('sportCenters.stepOne.legalData.bank_account_number')}
                variant="outlined"
                sx={{ width: '100%' }}
                error={invalid}
                helperText={error && error.message}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="legal_data.bank_name"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <FormControl
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                <InputLabel id="bank-name-selectlabel" error={invalid}>
                  {t('bank_name') + ' *'}
                </InputLabel>
                <Select
                  {...field}
                  labelId="bank-name-selectlabel"
                  id="bank-name-select"
                  label={t('bank_name') + ' *'}
                  variant="outlined"
                  sx={{ width: '100%' }}
                  error={invalid}
                >
                  <MenuItem>
                    <em>NONE</em>
                  </MenuItem>
                  {banks.map(bank => (
                    <MenuItem key={bank} value={bank}>
                      {bank}
                    </MenuItem>
                  ))}
                </Select>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </FormControl>
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="legal_data.address"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <SearchGeoCode
                field={field}
                isLoadingLocationsList={isLoadingLocationsList}
                handleAutocompleteChange={handleAutocompleteChange}
                handleSearchChange={handleSearchChange}
                locations={locations}
                label={t('sportCenters.stepOne.legalData.address').toString() + ' *'}
                optionLabel={field.value}
                id="autocomplte_legal_data"
                error={invalid}
                helperText={error?.message}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            name="legal_data.city"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('city')}
                variant="outlined"
                sx={{ width: '100%' }}
                error={invalid}
                helperText={error && error.message}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="legal_data.municipality"
            defaultValue=""
            control={control}
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('municipality')}
                variant="outlined"
                sx={{ width: '100%' }}
                error={invalid}
                helperText={error && error.message}
                required
              />
            )}
          />
        </Grid>
        {!isLoadingLocation && centerMapLegalData.lat > 0 && (
          <Grid item xs={12} my={1}>
            <MapsView id="cms-map-legal-data" center={centerMapLegalData} />
          </Grid>
        )}
        <Grid item xs={12}>
          <Controller
            name="legal_data.legal_representative_1"
            control={control}
            defaultValue=""
            rules={{ required: reqMessage }}
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('sportCenters.stepOne.legalData.legal_representative_1')}
                variant="outlined"
                sx={{ width: '100%' }}
                error={invalid}
                helperText={error && error.message}
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="legal_data.legal_representative_2"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('sportCenters.stepOne.legalData.legal_representative_2')}
                variant="outlined"
                value={field.value === null ? '' : field.value}
                sx={{ width: '100%' }}
                error={invalid}
                helperText={error && error.message}
              />
            )}
          />
        </Grid>

        {/* {/* PDV i email za SEF */}

        <Grid item xs={12} sm={6}>
          <InputLabel>{t('sportCenters.stepOne.legalData.in_vat_system') + ' *'}</InputLabel>
          <Controller
            name="legal_data.in_vat_system"
            control={control}
            defaultValue={false}
            render={({ field, fieldState: { invalid, error } }) => (
              <Box sx={{ width: '100% ', display: 'flex', alignItems: 'center' }}>
                <InputLabel error={invalid}>{t('no')}</InputLabel>
                <Switch
                  {...field}
                  onChange={e => {
                    setIsItPDV(e.target.checked);
                    field.onChange(e.target.checked);
                  }}
                  checked={field.value}
                />
                <InputLabel error={invalid}>{t('yes')}</InputLabel>
                {error && <FormHelperText error>{error.message}</FormHelperText>}
              </Box>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="legal_data.e_invoicing_email"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                {...field}
                label={t('sportCenters.stepOne.legalData.e_invoicing_email')}
                variant="outlined"
                sx={{ width: '100%' }}
                disabled={!isItPDV}
                error={invalid && isItPDV}
                helperText={error && isItPDV && error.message}
                required
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Controller
            name="legal_data.contract_start_date"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                type="date"
                {...field}
                onChange={e =>
                  field.onChange(dayjs(e.target.value).format('YYYY-MM-DD').toString())
                }
                label={t('sportCenters.stepOne.legalData.contract_start_date')}
                fullWidth
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="legal_data.contract_expiry_date"
            control={control}
            defaultValue=""
            render={({ field, fieldState: { invalid, error } }) => (
              <TextField
                type="date"
                fullWidth
                {...field}
                onChange={e =>
                  field.onChange(dayjs(e.target.value).format('YYYY-MM-DD').toString())
                }
                label={t('sportCenters.stepOne.legalData.contract_expiry_date')}
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </CardWrapper>
  );
};

export default LegalData;
