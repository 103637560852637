//interfaces
import { UserResponse } from '@interfaces/user/user';
//axios instance
import axiosInstance from '@services/config';

export const appUsers = (
  page: number,
  per_page: number,
  search: string,
  gender: string,
  order_by: string,
  order_direction: string
): Promise<{ data: UserResponse }> => {
  return axiosInstance.get('users/app-users', {
    params: {
      page: page,
      per_page: per_page,
      search: search,
      gender: gender,
      order_by: order_by,
      order_direction: order_direction
    }
  });
};
