//hooks
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
import { deleteImpersonate, postImpersonate } from '@services/impersonation/impersonation';
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const usePostImpersonate = (
  onSuccess: () => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  string, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: postImpersonate,
    onSuccess: data => onSuccess(),
    onError: err => onError(err.response.data)
  });

export const useDeleteImpersonate = (
  onSuccess: () => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  undefined, // request
  () => void // on success
> =>
  useMutation({
    mutationFn: deleteImpersonate,
    onSuccess: data => onSuccess(),
    onError: err => onError(err.response.data)
  });
