//hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
//components
import { Box, IconButton, Tooltip } from '@mui/material';
import DataCard from '@components/DataCard/DataCard';
// interfaces
import { PriceRuleTableFields } from '@interfaces/priceRules/priceRules';
import { MRT_ColumnDef } from 'material-react-table';
// icons
import { DeleteRowIcon, EditIcon } from '@components/icons/icons';
// recoil
import { useSetRecoilState, useResetRecoilState } from 'recoil';
// atoms
import { popupAtom } from '@atoms/popupAtom';

type PriceRulesDataProps = {
  priceRulesFields: PriceRuleTableFields[];
  onEditRule: (data: PriceRuleTableFields) => void;
  onDeletePriceRule: (data: PriceRuleTableFields) => void;
  settings?: boolean;
};

const PriceRulesData = ({
  priceRulesFields,
  onEditRule,
  onDeletePriceRule,
  settings = false
}: PriceRulesDataProps): JSX.Element => {
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const lng = localStorage.getItem('lng');
  const fieldColumns = useMemo<MRT_ColumnDef<PriceRuleTableFields>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        Cell: ({ row }) =>
          lng === 'en' ? row.original.name_translates.en : row.original.name_translates.sr
      },
      {
        accessorKey: 'workingHours',
        header: ''
      },
      {
        accessorKey: 'days',
        header: '',
        Cell: ({ row }) => (
          <Tooltip title={row.original.days} placement="bottom">
            <span>
              {row.original.days?.length > 20
                ? row.original.days.slice(0, 20) + '...'
                : row.original.days}
            </span>
          </Tooltip>
        )
      },
      {
        accessorKey: 'fieldsNames',
        header: '',
        Cell: ({ row }) => (
          <Tooltip title={row.original.fieldsNames} placement="bottom">
            <span>
              {row.original.fieldsNames?.length > 20
                ? row.original.fieldsNames.slice(0, 20) + '...'
                : row.original.fieldsNames}
            </span>
          </Tooltip>
        )
      },
      {
        accessorKey: 'id',
        header: '',
        Cell: ({ row }) => (
          <Box width={'100%'} display="flex" justifyContent={'space-evenly'}>
            <IconButton onClick={() => onEditRule(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setPopup({
                  open: true,
                  variant: 'delete',
                  title:
                    t('stepFour.price_rule_delete_message').toString() + row.original.name + '?',
                  content: '',
                  onClick: () => {
                    onDeletePriceRule(row.original);
                    resetPopup();
                  }
                });
              }}
            >
              <DeleteRowIcon />
            </IconButton>
          </Box>
        )
      }
    ],
    []
    //end
  );

  const settingsColumns = useMemo<MRT_ColumnDef<PriceRuleTableFields>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('stepFour.newPriceRule.price_rule.name')
      },
      {
        accessorKey: 'days',
        header: t('days'),
        Cell: ({ row }) => (
          <Tooltip title={row.original.days} placement="bottom">
            <span>
              {row.original.days?.length > 20
                ? row.original.days.slice(0, 20) + '...'
                : row.original.days}
            </span>
          </Tooltip>
        )
      },
      {
        accessorKey: 'fieldsNames',
        header: t('fields'),
        Cell: ({ row }) => (
          <Tooltip title={row.original.fieldsNames} placement="bottom">
            <span>
              {row.original.fieldsNames?.length > 20
                ? row.original.fieldsNames.slice(0, 20) + '...'
                : row.original.fieldsNames}
            </span>
          </Tooltip>
        )
      },
      {
        accessorKey: 'priceTime',
        header: t('stepFour.newPriceRule.price'),
        Cell: ({ row }) => (
          <Tooltip title={row.original.priceTime} placement="bottom">
            <span>
              {row.original.priceTime?.length > 20
                ? row.original.priceTime.slice(0, 20) + '...'
                : row.original.priceTime}
            </span>
          </Tooltip>
        )
      },
      {
        accessorKey: 'id',
        header: '',
        Cell: ({ row }) => (
          <Box width={'100%'} display="flex" justifyContent={'space-evenly'}>
            <IconButton onClick={() => onEditRule(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setPopup({
                  open: true,
                  variant: 'delete',
                  title:
                    t('stepFour.price_rule_delete_message').toString() + row.original.name + '?',
                  content: '',
                  onClick: () => {
                    onDeletePriceRule(row.original);
                    resetPopup();
                  }
                });
              }}
            >
              <DeleteRowIcon fill="red" />
            </IconButton>
          </Box>
        )
      }
    ],
    []
    //end
  );
  return (
    <DataCard
      columns={settings ? settingsColumns : fieldColumns}
      data={priceRulesFields}
      title={
        settings
          ? t('stepFour.price_rule_settings_table_title')
          : t('stepFour.price_rule_table_title').toString()
      }
      subtitle={settings ? '' : t('stepFour.price_rule_table_subtitle').toString()}
      showHeader={settings}
    />
  );
};

export default PriceRulesData;
