import { useEffect, useRef } from 'react';

type MapProps = {
  center: google.maps.LatLngLiteral;
  zoom: number;
  id?: string;
  handleOnMarkerChange?: (newPosition: google.maps.LatLngLiteral) => void;
  draggable?: boolean;
};
const Map = ({ center, zoom, id, handleOnMarkerChange, draggable }: MapProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const markerPosition = {
    lat: center.lat,
    lng: center.lng
  };
  useEffect(() => {
    if (ref.current) {
      const map = new window.google.maps.Map(ref.current, { center, zoom });

      const marker = new google.maps.Marker({
        position: markerPosition,
        map,
        draggable: draggable
      });
      marker.addListener('dragend', () => {
        const newPosition = marker.getPosition();
        if (handleOnMarkerChange && newPosition?.lat() && newPosition?.lng())
          return handleOnMarkerChange?.({ lat: newPosition.lat(), lng: newPosition.lng() });
      });
    }
  }, [ref]);

  return <div id={id} ref={ref} style={{ position: 'relative', height: '200px', width: '100%' }} />;
};

export default Map;
