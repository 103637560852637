//react
import { ReactNode, useMemo } from 'react';
//components
import { Link } from 'react-router-dom';
import { Box, MenuItem } from '@mui/material';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import EditableRow from '@components/EditableRow/EditableRow';
import FileUpload from '@containers/FileUpload/FileUpload';
//hooks
import { useTranslation } from 'react-i18next';
import { useCreateEmployeeHook } from '@hooks/useCreateEmployee/useCreateEmployee';
//types
import { Employee } from '@interfaces/employees/employees';
//mock
import { employeeKeys } from '@src/__mock__/employees/employees';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';
//helpers
import { getInputType } from '@helpers/utility';

const EmployeeProfileData = () => {
  const { t } = useTranslation();

  const {
    positions,
    sectors,
    control,
    onSubmit,
    handleSubmit,
    enable,
    setEnable,
    setValue,
    roles,
    singleEmployee,
    handleFileDelete,
    onDropCallback,
    uploadPercent,
    isDirty,
    handleCancel
  } = useCreateEmployeeHook();

  const getSelectOptions = (key: string): ReactNode[] | undefined => {
    if (key === 'user_info.gender') {
      return [
        <MenuItem key={'male'} value={'male'}>
          {t('male')}
        </MenuItem>,
        <MenuItem key={'female'} value={'female'}>
          {t('female')}
        </MenuItem>
      ];
    }
    if (key === 'sector') {
      return sectors.map(s => (
        <MenuItem
          key={s.id}
          value={s.id}
          onClick={() => {
            setValue('cooperation_info.employee_position_id', '');
          }}
        >
          {s.name}
        </MenuItem>
      ));
    }
    if (key === 'cooperation_info.employee_position_id') {
      return positions.map(p => (
        <MenuItem key={p.id} value={p.id}>
          {p.name}
        </MenuItem>
      ));
    }
    if (key === 'user_info.role_id') {
      return roles.map(p => (
        <MenuItem key={p.id} value={p.id}>
          {p.name}
        </MenuItem>
      ));
    }
  };

  const columns = useMemo<MRT_ColumnDef<{ name: string }>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        Cell: ({ row }) => (
          <EditableRow
            title={t(`employeeProfile.${row.original.name}`, '').toString()}
            control={control}
            controlKey={[`${row.original.name}` as keyof Employee]}
            enable={
              row.original.name === 'sector' ||
              row.original.name === 'cooperation_info.employee_position_id'
                ? enable === 'cooperation_info.employee_position_id' || enable === 'sector'
                : enable === row.original.name
              // handleEnable(row.original.name, enable)
            }
            onClick={() => {
              if (isDirty) {
                setEnable(row.original.name);
                handleSubmit(onSubmit)();
              } else {
                setEnable(enable => (enable === row.original.name ? '' : row.original.name));
              }
            }}
            type={getInputType(row.original.name)}
            getSelectOptions={getSelectOptions(row.original.name)}
            handleCancel={handleCancel}
            hideDeleteBtn={row.original.name === 'documents'}
            permission={PermissionsEnum.update_employees}
          >
            {row.original.name === 'documents' && (
              <Box display={'flex'} flexDirection={'column'}>
                {singleEmployee?.cooperation_info.documents?.map((doc, i) => (
                  <Link to={doc.src} key={doc.id}>
                    {doc.title}
                  </Link>
                ))}
              </Box>
            )}
          </EditableRow>
        )
      }
    ],
    [enable, sectors, positions, isDirty]
  );

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={employeeKeys}
        enableColumnActions={false}
        enableColumnFilters={false}
        enablePagination={false}
        enableSorting={false}
        enableBottomToolbar={false}
        enableTopToolbar={false}
        muiTableBodyRowProps={{ hover: false }}
        defaultColumn={{
          minSize: 20,
          maxSize: 200,
          size: 100
        }}
        muiTablePaperProps={{
          sx: {
            border: 'none',
            boxShadow: 'none'
          }
        }}
        muiTableHeadProps={{
          sx: { display: 'none' }
        }}
      />
      {enable === 'documents' && (
        <FileUpload
          title=""
          onDropCallback={onDropCallback}
          uploadPercent={uploadPercent}
          files={
            singleEmployee?.cooperation_info.documents
              ? singleEmployee.cooperation_info.documents
              : []
          }
          handleFileDelete={handleFileDelete}
        />
      )}
    </Box>
  );
};

export default EmployeeProfileData;
