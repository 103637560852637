//error
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//interface
import {
  SportCenterError,
  SportCenterResponse,
  SportCenterErrorResponse,
  CreateSportCenterType
} from '@interfaces/SportCenters/SportCenter';
//service
import {
  createSportCenter,
  deleteSportCenter,
  updateSportCenter,
  singleSportCenter,
  deleteSportCenters
} from '@services/sportCenter/sportCenter';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useSingleSportCenter = (
  onSuccess: (data: SportCenterResponse) => void,
  onError: (err: SportCenterError) => void
): UseMutationResult<
  { data: SportCenterResponse }, // response
  SportCenterErrorResponse,
  Number, // request
  (data: CreateSportCenterType) => void // on success
> =>
  useMutation({
    mutationFn: singleSportCenter,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useUpdateSportCenter = (
  onSuccess: (data: SportCenterResponse) => void,
  onError: (err: SportCenterError) => void
): UseMutationResult<
  { data: SportCenterResponse }, // response
  SportCenterErrorResponse,
  CreateSportCenterType, // request
  (data: CreateSportCenterType) => void // on success
> =>
  useMutation({
    mutationFn: updateSportCenter,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useCreateSportCenter = (
  onSuccess: (data: SportCenterResponse) => void,
  onError: (err: SportCenterError) => void
): UseMutationResult<
  { data: SportCenterResponse }, // response
  SportCenterErrorResponse,
  CreateSportCenterType, // request
  (data: CreateSportCenterType) => void // on success
> =>
  useMutation({
    mutationFn: createSportCenter,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteSportCenter = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  number, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteSportCenter,
    onSuccess: data => onSuccess({ message: 'Successfully deleted sport center' }),
    onError: err => onError(err.response.data)
  });

export const useDeleteSportCenters = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  number[], // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteSportCenters,
    onSuccess: data => onSuccess({ message: 'Successfully deleted sport centers' }),
    onError: err => onError(err.response.data)
  });
