//react
import { useEffect, useRef, useState } from 'react';
//react-router
import { useParams } from 'react-router-dom';
//atoms
import { popupAtom } from '@atoms/popupAtom';
import { appointmentFormAtom } from '@atoms/appointmentFormAtom';
import { calendarFullScreenAtom } from '@atoms/calendarFullScreen';
import { userAtom } from '@atoms/userAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//hooks
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useSingleSportCenter } from '@api/queries/sportCenter/sportCenter';
import { useCourtsByObjectId } from '@api/queries/court/court';
import { useForm, useWatch } from 'react-hook-form';
import useWindowSize from '@hooks/windowSize/useWindowSize';
import { usePriceRules } from '@api/queries/priceRules/priceRules';
//fullcalendar
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, DatesSetArg } from '@fullcalendar/core';
//heplers
import { fillWorkingHoursArrayForSportCenter, findMinMaxTime } from '@helpers/utility';
//interfaces
import { SportCenterResponse } from '@interfaces/SportCenters/SportCenter';
import { CourtType } from '@interfaces/court/court';
import {
  CalendarAppointmentsFormType,
  CalendarSelectedTimeDateType,
  CalendarWorkingHoursType
} from '@interfaces/appointments/appointments';
import {
  AllPriceRulesResponse,
  CourtSportErrorResponse,
  PriceRuleTypeResponse
} from '@interfaces/priceRules/priceRules';
//dayjs
import dayjs, { Dayjs } from 'dayjs';
//enums
import { RoleEnum } from '@enum/roleEnum';

const useCalendarsLogic = () => {
  const params = useParams();
  const sportCenterID: number | undefined = params.sportCenterId
    ? parseInt(params.sportCenterId)
    : undefined;
  const calendarRef = useRef<FullCalendar>(null);
  const [openAddAppointment, setOpenAddAppointment] = useState<boolean>(false);
  const [addAppointmentType, setAddAppointmentType] = useState<string>('regularAppointment');
  const [selectedTimeDate, setSelectedTimeDate] = useState<CalendarSelectedTimeDateType>({
    startAppointment: '',
    dateAppointment: null,
    durationAppointment: ''
  });
  const [calendarWorkingHours, setCalendarWorkingHours] = useState<CalendarWorkingHoursType>({
    workingHours: {
      workweek_from: '',
      workweek_to: '',
      saturday_from: '',
      saturday_to: '',
      sunday_from: '',
      sunday_to: ''
    },
    calendarHourMin: '',
    calendarHourMax: ''
  });
  const [appointmentID, setAppointmentID] = useState<number | undefined>();
  const setPopup = useSetRecoilState(popupAtom);
  const appointmentForm = useRecoilValue(appointmentFormAtom);
  const calendarFullScreen = useRecoilValue(calendarFullScreenAtom);
  const [calendarTitle, setCalendarTitle] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [sportCenterCourts, setSportCenterCourts] = useState<
    { id: string | number; title: string }[]
  >([]);
  const [visibleSportCenterCourts, setVisibleSportCenterCourts] = useState<
    { id: string | number; title: string }[]
  >([]);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [openCalendarMobileMenu, setOpenCalendarMobileMenu] = useState<boolean>(false);
  const [selectedCourt, setSelectedCourt] = useState<number | string>('');
  const currentCMS = useRecoilValue(currentCMSAtom);
  const loggedUser = useRecoilValue(userAtom);
  const [allowedCourtsForCoach, setAllowedCourtsForCoach] = useState<number[]>([]);
  const [courtsPriceRules, setCourtsPriceRules] = useState<{ id: number; minutes: number[] }[]>([]);
  const { control: controlCalendarAppointments, setValue: setCalendarAppointmentsValue } =
    useForm<CalendarAppointmentsFormType>({
      defaultValues: {
        fromDate: '',
        toDate: '',
        courtValue: '',
        sportValue: ''
      },
      mode: 'onBlur'
    });
  const selectedCourtCalendar = useWatch({
    control: controlCalendarAppointments,
    name: 'courtValue'
  });
  const selectedSportCalendar = useWatch({
    control: controlCalendarAppointments,
    name: 'sportValue'
  });
  const fromDateCalendar = useWatch({
    control: controlCalendarAppointments,
    name: 'fromDate'
  });
  const toDateCalendar = useWatch({
    control: controlCalendarAppointments,
    name: 'toDate'
  });
  const { width } = useWindowSize();

  const onSportCenterSuccess = (data: SportCenterResponse | undefined) => {
    let array = fillWorkingHoursArrayForSportCenter(data?.data?.working_hours);
    findMinMaxTime(array);
    setCalendarWorkingHours({
      workingHours: data?.data?.working_hours,
      calendarHourMin: array[array.length - 1],
      calendarHourMax: array[0]
    });
  };

  const onSportCenterError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const {} = useSingleSportCenter(
    sportCenterID?.toString(),
    onSportCenterSuccess,
    onSportCenterError
  );

  const onCourtsSuccess = (data: CourtType[]) => {
    let filteredCourts = [];
    for (let i = 0; i < data.length; i++) {
      filteredCourts.push({ id: data[i].id, title: data[i].name });
    }
    setSportCenterCourts(filteredCourts);
    let lastElement = calendarFullScreen ? Math.floor(width / 280) + 1 : Math.floor(width / 280);
    setVisibleSportCenterCourts(filteredCourts.slice(0, lastElement));
    if (currentCMS?.role === RoleEnum.COACH) {
      let coachFilteredSportCenter = loggedUser?.coach_in_sport_centers.filter(
        el => el.id === sportCenterID
      );
      let map1 = new Map();
      let commonElements: { id: string | number; title: string }[];
      if (coachFilteredSportCenter)
        map1 = new Map(
          coachFilteredSportCenter[0].coach_info.court_sports?.map(item => [item.court_id, item])
        );
      commonElements = filteredCourts.filter(item => map1.has(item.id));
      let commonElementsIds = [];
      for (let i = 0; i < commonElements.length; i++) {
        commonElementsIds.push(+commonElements[i].id);
      }
      setAllowedCourtsForCoach(commonElementsIds);
    }
  };

  const onCourtsError = (err: Error) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { isLoading: isCourtLoading } = useCourtsByObjectId(
    onCourtsError,
    sportCenterID,
    onCourtsSuccess
  );

  const prevCourts = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  const nextCourts = () => {
    if (currentSlide < sportCenterCourts.length - visibleSportCenterCourts.length) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const datesChanged = (arg: DatesSetArg) => {
    let start = dayjs(arg.start).format('YYYY-MM-DD');
    let end = dayjs(arg.end).format('YYYY-MM-DD');
    let title = dayjs(arg.start).format('ddd, DD MMM');
    setCalendarAppointmentsValue('fromDate', start);
    setCalendarAppointmentsValue('toDate', end);
    setCalendarTitle(title);
  };

  const handleChangeDay = (changeType: 'next' | 'prev' | 'nextWeek' | 'prevWeek' | 'today') => {
    setSelectedDate(null);
    const calendarApi = calendarRef?.current?.getApi();
    if (changeType === 'next') {
      calendarApi?.incrementDate({ days: 1 });
    } else if (changeType === 'prev') {
      calendarApi?.incrementDate({ days: -1 });
    } else if (changeType === 'nextWeek') {
      calendarApi?.incrementDate({ days: 7 });
    } else if (changeType === 'prevWeek') {
      calendarApi?.incrementDate({ days: -7 });
    } else {
      calendarApi?.today();
    }
  };

  const selectRows = (selectionInfo: DateSelectArg) => {
    let tempValue: number | string = '';
    sportCenterCourts.map(coSpo => {
      if (selectionInfo.resource && +selectionInfo.resource.id === coSpo.id) {
        return (tempValue = coSpo.id);
      }
    });
    setSelectedCourt(tempValue);
    let startHour = dayjs(selectionInfo.startStr).format('HH');
    let startMinutes = dayjs(selectionInfo.startStr).format('mm');
    let minutesDifference = dayjs(selectionInfo.endStr).diff(
      dayjs(selectionInfo.startStr),
      'minutes'
    );
    setSelectedTimeDate({
      startAppointment: `${startHour}:${startMinutes}`,
      dateAppointment: dayjs(selectionInfo.startStr),
      durationAppointment: minutesDifference
    });
    onAddAppointment();
  };

  const onAddAppointment = () => {
    setOpenAddAppointment(true);
  };

  const addRegularAppointment = () => {
    setAddAppointmentType('regularAppointment');
  };

  const addPermanentAppointment = () => {
    setAddAppointmentType('permanentAppointment');
  };

  const onPriceRulesSuccess = (data: AllPriceRulesResponse) => {
    let priceRuleForSelectedDay: PriceRuleTypeResponse[] = [];
    data.data.map(priceRule => {
      if (dayjs(fromDateCalendar).day() === 0) {
        priceRule.price_rule.sun === true && priceRuleForSelectedDay.push(priceRule);
      } else if (dayjs(fromDateCalendar).day() === 6) {
        priceRule.price_rule.sat === true && priceRuleForSelectedDay.push(priceRule);
      } else {
        priceRule.price_rule.mon === true && priceRuleForSelectedDay.push(priceRule);
      }
    });
    let availableDurationForSelectedCourt: { courtID: number; minutes: string | number }[] = [];
    priceRuleForSelectedDay.map(priceRule => {
      priceRule.time_prices.map(duration => {
        availableDurationForSelectedCourt.push({
          courtID: priceRule.court_sports[0].court_id,
          minutes: duration.minutes
        });
      });
    });
    let arrangedDurationsByCourts: { courtID: number; minutes: number[] }[] = Object.values(
      availableDurationForSelectedCourt.reduce((accumulator: any, current) => {
        const { courtID, minutes } = current;
        if (!accumulator[courtID]) {
          accumulator[courtID] = { courtID, minutes: [minutes] };
        } else {
          accumulator[courtID].minutes.push(minutes);
        }
        return accumulator;
      }, {})
    );
    const uniqueMinutesForCourt = arrangedDurationsByCourts.map(obj => ({
      id: obj.courtID,
      minutes: [...new Set(obj.minutes)]
    }));
    setCourtsPriceRules(uniqueMinutesForCourt);
  };

  const onPriceRulesError = (err: CourtSportErrorResponse) => {
    setPopup({
      open: true,
      title: err.response.data.message,
      content: '',
      variant: 'error'
    });
  };

  const { refetch: refetchPriceRules } = usePriceRules(
    { sportCenterID: sportCenterID },
    onPriceRulesSuccess,
    onPriceRulesError,
    currentCMS?.role === RoleEnum.COACH ? false : true
  );

  useEffect(() => {
    if (appointmentForm && appointmentForm.formType === 'regularAppointment') {
      onAddAppointment();
      addRegularAppointment();
    }
    if (appointmentForm && appointmentForm.formType === 'permanentAppointment') {
      onAddAppointment();
      addPermanentAppointment();
    }
  }, []);

  useEffect(() => {
    let lastElement = calendarFullScreen ? Math.floor(width / 280) + 1 : Math.floor(width / 280);
    setVisibleSportCenterCourts(sportCenterCourts.slice(0, lastElement));
  }, [width, calendarFullScreen]);

  useEffect(() => {
    setVisibleSportCenterCourts(
      sportCenterCourts.slice(currentSlide, currentSlide + visibleSportCenterCourts.length)
    );
  }, [currentSlide]);

  useEffect(() => {
    currentCMS?.role !== RoleEnum.COACH && refetchPriceRules();
  }, [fromDateCalendar]);

  return {
    sportCenterID,
    calendarRef,
    selectRows,
    onAddAppointment,
    openAddAppointment,
    setOpenAddAppointment,
    addAppointmentType,
    setAddAppointmentType,
    addRegularAppointment,
    addPermanentAppointment,
    selectedTimeDate,
    setSelectedTimeDate,
    datesChanged,
    fromDateCalendar,
    toDateCalendar,
    calendarWorkingHours,
    isCourtLoading,
    appointmentID,
    setAppointmentID,
    controlCalendarAppointments,
    selectedCourtCalendar,
    selectedSportCalendar,
    calendarTitle,
    setCalendarTitle,
    handleChangeDay,
    selectedDate,
    setSelectedDate,
    sportCenterCourts,
    prevCourts,
    nextCourts,
    visibleSportCenterCourts,
    openCalendarMobileMenu,
    setOpenCalendarMobileMenu,
    selectedCourt,
    setSelectedCourt,
    allowedCourtsForCoach,
    courtsPriceRules
  };
};

export default useCalendarsLogic;
