//react
import { useMemo, useState, ChangeEvent, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography
} from '@mui/material';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import Loading from '@src/ui/components/Loading/Loading';
import EditableRow from '@components/EditableRow/EditableRow';
//icons
import { ShowLessIcon } from '@src/ui/components/icons/icons';
//types
import {
  CreateSportCenterType,
  LegalDataKeyType,
  OpenHoursKeys,
  SportCenterError,
  SportCenterResponse,
  SportCenterResponseType,
  WorkingHoursType
} from '@src/domain/interfaces/SportCenters/SportCenter';
//services
import { useSingleSportCenter } from '@src/infrastructure/api/queries/sportCenter/sportCenter';
import { useUpdateSportCenter } from '@src/infrastructure/api/mutations/sportCenter/sportCenter';
//hooks
import { useForm, useWatch } from 'react-hook-form';
//mock
import { legalDataKeys, openHoursKeys } from '@src/__mock__/sportCenter/sportCenter';
import { banks } from '@src/__mock__/createSportsCenter/steps';
import { useTranslation } from 'react-i18next';
//recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
//helpers
import dayjs from 'dayjs';
import { allDayObjectValuesRequest, allDayObjectValuesResponse } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const getType: any = {
  name: 'text',
  address: 'text',
  identification_number: 'number',
  bank_account_number: 'text',
  bank_name: 'select',
  legal_representative_1: 'text',
  in_vat_system: 'switchButton',
  e_invoicing_email: 'text',
  contract_start_date: 'date',
  contract_expiry_date: 'date'
};

const SportCenterOverview = (): JSX.Element => {
  const { isLoading, data, refetch } = useSingleSportCenter(
    useParams().objectId,
    onSuccess,
    onError
  );
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [wholeWorkingDay, setwholeWorkingDay] = useState<boolean>(false);
  const [wholeSaturdayDay, setwholeSaturdayDay] = useState<{ on: boolean; off: boolean }>({
    on: false,
    off: false
  });
  const [wholeSundayDay, setwholeSundayDay] = useState<{ on: boolean; off: boolean }>({
    on: false,
    off: false
  });
  const [triggeredCheckbox, setTriggeredCheckbox] = useState<boolean>(false);
  const handleChangeWholeWorkingDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeWorkingDay(event.target.checked);
    setTriggeredCheckbox(event.target.checked);
    setEnable('');
  };
  const handleChangeWholeSaturdayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSaturdayDay({ on: event.target.checked, off: false });
    setTriggeredCheckbox(event.target.checked);
    setEnable('');
  };
  const handleChangeWholeSundayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSundayDay({ on: event.target.checked, off: false });
    setTriggeredCheckbox(event.target.checked);
    setEnable('');
  };
  const handleChangeOffWholeSaturdayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSaturdayDay({ on: false, off: event.target.checked });
    setTriggeredCheckbox(event.target.checked);
    setEnable('');
  };
  const handleChangeOffWholeSundayDay = (event: ChangeEvent<HTMLInputElement>) => {
    setwholeSundayDay({ on: false, off: event.target.checked });
    setTriggeredCheckbox(event.target.checked);
    setEnable('');
  };

  const {
    handleSubmit,
    control,
    setError,
    watch,
    formState: { dirtyFields },
    reset,
    getValues,
    setValue
  } = useForm<SportCenterResponseType>({
    defaultValues: data && { ...data.data, working_hours: allDayObjectValuesResponse(data) },
    mode: 'onBlur'
  });

  const { mutate: submitEdit } = useUpdateSportCenter(onEditSuccess, onEditError);

  const [enable, setEnable] = useState('');

  const watchWorkingHours = useWatch({
    control: control,
    name: 'working_hours'
  });

  const setPopup = useSetRecoilState(popupAtom);

  function onSuccess(res: SportCenterResponse | undefined) {
    setEnable('');
    if (res) {
      res.data.working_hours = allDayObjectValuesResponse(res);
      if (res.data.working_hours.workweek_to === '24:00') {
        setwholeWorkingDay(true);
      }
      if (res.data.working_hours.saturday_to === '24:00') {
        setwholeSaturdayDay({ on: true, off: false });
      }
      if (res.data.working_hours.saturday_to === '00:00') {
        setwholeSaturdayDay({ on: false, off: true });
      }
      if (res.data.working_hours.sunday_to === '24:00') {
        setwholeSundayDay({ on: true, off: false });
      }
      if (res.data.working_hours.sunday_to === '00:00') {
        setwholeSundayDay({ on: false, off: true });
      }
      reset(res.data);
    }
  }
  function onError(error: Error) {
    setPopup({
      open: true,
      title: error.message,
      content: '',
      variant: 'error'
    });
  }

  function onSubmit(all_data: SportCenterResponseType) {
    const pom: CreateSportCenterType = {
      id: all_data.id,
      basic_info: {
        name: all_data.name,
        email: all_data.email,
        phone: all_data.phone,
        address: all_data.address,
        city: all_data.city,
        municipality: all_data.municipality,
        longitude: all_data.longitude,
        latitude: all_data.latitude,
        description: all_data.description,
        description_translates: all_data.description_translates,
        logo: all_data.logo,
        gallery_images: all_data.gallery_images
      },
      legal_data: all_data.legal_data,
      payment_methods: all_data.payment_methods,
      benefits: all_data.benefits.map(el => el.id),
      working_hours: allDayObjectValuesRequest(all_data),
      fee_percentage: all_data.fee_percentage,
      package: all_data.package
    };

    pom.legal_data.contract_expiry_date = dayjs(pom.legal_data.contract_expiry_date).format(
      'YYYY-MM-DD'
    );
    pom.legal_data.contract_start_date = dayjs(pom.legal_data.contract_start_date).format(
      'YYYY-MM-DD'
    );

    submitEdit(pom);
  }
  function onEditSuccess(res: SportCenterResponse) {
    refetch();
  }
  function onEditError(err: SportCenterError) {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as keyof SportCenterResponseType,
          {
            type: 'custom',
            message: err.errors[key as keyof CreateSportCenterType]?.join(' ')
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  }

  function onCancel() {
    if (dirtyFields && Object.keys(dirtyFields).length !== 0) {
      reset();
    }
    setEnable('');
  }

  const legalDataColumns = useMemo<MRT_ColumnDef<LegalDataKeyType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: '',
        Cell: ({ row }) => (
          <EditableRow
            title={t(
              row.original.name !== 'bank_name'
                ? 'sportCenters.stepOne.legalData.' + row.original.name
                : row.original.name
            ).toString()}
            control={control}
            controlKey={[`legal_data.${row.original.name}`]}
            // handleSubmit={handleSubmit(onSubmit)}
            disabled={
              row.original.name === 'e_invoicing_email' ? !watch('legal_data.in_vat_system') : false
            }
            enable={row.original.name ? enable === row.original.name : false}
            onClick={() => {
              setEnable(enable => (enable === row.original.name ? '' : row.original.name));
              if (dirtyFields && Object.keys(dirtyFields).length !== 0) {
                handleSubmit(onSubmit)();
              }
            }}
            type={getType[row.original.name]}
            select={banks}
            handleCancel={onCancel}
            permission={PermissionsEnum.update_sport_centers}
          />
        )
      }
    ],
    [enable]
    //end
  );

  const workHoursColumns = useMemo<MRT_ColumnDef<OpenHoursKeys>[]>(
    () => [
      {
        accessorKey: 'day_type',
        header: '',
        Cell: ({ row }) => {
          const from = parseInt(
            getValues(`working_hours.${row.original.from as keyof Omit<WorkingHoursType, 'id'>}`)
          );
          const to = parseInt(
            getValues(`working_hours.${row.original.to as keyof Omit<WorkingHoursType, 'id'>}`)
          );
          return (
            <Box>
              <EditableRow
                isWholeDay={
                  (row.original.from === 'workweek_from' && wholeWorkingDay) ||
                  (row.original.from === 'saturday_from' &&
                    (wholeSaturdayDay.on || wholeSaturdayDay.off)) ||
                  (row.original.from === 'sunday_from' && (wholeSundayDay.on || wholeSundayDay.off))
                    ? true
                    : false
                }
                title={t(`sportCenters.openHours.${row.original.day_type}`).toString()}
                control={control}
                controlKey={[
                  `working_hours.${row.original.from}`,
                  `working_hours.${row.original.to}`
                ]}
                type="time"
                enable={enable === row.original.day_type}
                disableSubmit={from !== 0 && to !== 0 ? from >= to : false}
                helperText={
                  from !== 0 && to !== 0 && from >= to
                    ? t('stepFour.newPriceRule.price_rule.time_start_error_message').toString()
                    : undefined
                }
                onClick={() => {
                  if (!enable) {
                    setEnable(enable =>
                      enable === row.original.day_type ? '' : row.original.day_type
                    );
                  }
                  if (dirtyFields && Object.keys(dirtyFields).length !== 0) {
                    setEnable('');
                    return handleSubmit(onSubmit)();
                  }
                }}
                handleCancel={onCancel}
                permission={PermissionsEnum.update_sport_centers}
              />
              <Box display={'flex'} flexDirection={'row'}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                  {row.original.from === 'workweek_from' && (
                    <Checkbox
                      disabled={enable === row.original.day_type ? false : true}
                      checked={wholeWorkingDay}
                      value={wholeWorkingDay}
                      onChange={handleChangeWholeWorkingDay}
                    />
                  )}

                  {row.original.from === 'saturday_from' && (
                    <Checkbox
                      disabled={enable === row.original.day_type ? false : true}
                      checked={wholeSaturdayDay.on}
                      value={wholeSaturdayDay.on}
                      onChange={handleChangeWholeSaturdayDay}
                    />
                  )}

                  {row.original.from === 'sunday_from' && (
                    <Checkbox
                      disabled={enable === row.original.day_type ? false : true}
                      checked={wholeSundayDay.on}
                      value={wholeSundayDay.on}
                      onChange={handleChangeWholeSundayDay}
                    />
                  )}

                  <Typography variant="subtitle2" color="grayText">
                    {t('sportCenters.stepOne.otherData.work_all_day')}
                  </Typography>
                </Box>
                {row.original.from !== 'workweek_from' ? (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                    {row.original.from === 'saturday_from' && (
                      <Checkbox
                        disabled={enable === row.original.day_type ? false : true}
                        checked={wholeSaturdayDay.off}
                        value={wholeSaturdayDay.off}
                        onChange={handleChangeOffWholeSaturdayDay}
                      />
                    )}

                    {row.original.from === 'sunday_from' && (
                      <Checkbox
                        disabled={enable === row.original.day_type ? false : true}
                        checked={wholeSundayDay.off}
                        value={wholeSundayDay.off}
                        onChange={handleChangeOffWholeSundayDay}
                      />
                    )}

                    <Typography variant="subtitle2" color="grayText">
                      {t('sportCenters.stepOne.otherData.off_day')}
                    </Typography>
                  </Box>
                ) : null}
              </Box>
            </Box>
          );
        }
      }
    ],
    [enable]
    //end
  );

  if (isLoading) {
    return <Loading />;
  }
  if (!data) {
    navigate(-1);
    return <div>error</div>;
  }

  useEffect(() => {
    //work-day
    if (wholeWorkingDay) {
      setValue('working_hours.workweek_from', '00:00');
      setValue('working_hours.workweek_to', '24:00');
    }
    //saturday
    if (wholeSaturdayDay.on) {
      setValue('working_hours.saturday_from', '00:00');
      setValue('working_hours.saturday_to', '24:00');
    }
    if (wholeSaturdayDay.off) {
      setValue('working_hours.saturday_from', '00:00');
      setValue('working_hours.saturday_to', '00:00');
    }
    //sunday
    if (wholeSundayDay.on) {
      setValue('working_hours.sunday_from', '00:00');
      setValue('working_hours.sunday_to', '24:00');
    }
    if (wholeSundayDay.off) {
      setValue('working_hours.sunday_from', '00:00');
      setValue('working_hours.sunday_to', '00:00');
    }
    if (
      triggeredCheckbox &&
      (wholeWorkingDay ||
        wholeSaturdayDay.on ||
        wholeSaturdayDay.off ||
        wholeSundayDay.on ||
        wholeSundayDay.off)
    ) {
      handleSubmit(onSubmit)();
      setTriggeredCheckbox(false);
    }
  }, [wholeWorkingDay, wholeSaturdayDay, wholeSundayDay]);

  return (
    <Box p={2}>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ShowLessIcon />}>
          <Typography variant="h6">{t(`sportCenters.stepOne.legalData.title`)}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MaterialReactTable
            columns={legalDataColumns}
            data={legalDataKeys}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
            defaultColumn={{
              minSize: 20,
              maxSize: 200,
              size: 100
            }}
            muiTablePaperProps={{
              sx: {
                border: 'none',
                boxShadow: 'none'
              }
            }}
            muiTableHeadProps={{
              sx: { display: 'none' }
            }}
          />
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ShowLessIcon />}>
          <Typography variant="h6">{t('sidebar.aboutObject')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MaterialReactTable
            columns={workHoursColumns}
            data={openHoursKeys}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
            defaultColumn={{
              minSize: 20,
              maxSize: 200,
              size: 100
            }}
            muiTablePaperProps={{
              sx: {
                border: 'none',
                boxShadow: 'none'
              }
            }}
            muiTableHeadProps={{
              sx: { display: 'none' }
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SportCenterOverview;
