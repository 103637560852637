//components
import DataCard from '@components/DataCard/DataCard';
import { Alert, Box, Button, IconButton } from '@mui/material';
//icons
import { DeleteIcon, EditIcon } from '@components/icons/icons';
import AddIcon from '@mui/icons-material/Add';
//interfaces
import { MRT_ColumnDef } from 'material-react-table';
import { RoleType } from '@interfaces/roles/roles';
//hooks
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSuperadminRoleHook } from '@hooks/useCreateRoleHook/useCreateRoleHook';
//recoil
import { useRecoilValue, useSetRecoilState, useResetRecoilState } from 'recoil';
// atoms
import { popupAtom } from '@atoms/popupAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermission } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const RoleSettingsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const { roles, deleteRole, success, lng } = useSuperadminRoleHook();

  const currentCMS = useRecoilValue(currentCMSAtom);

  const columns = useMemo<MRT_ColumnDef<RoleType>[]>(
    () => [
      {
        header: t('rolePage.roleName'),
        accessorKey: 'name',
        Cell: ({ row }) =>
          (!lng || lng === 'sr') && row.original.name_translates.sr
            ? row.original.name_translates.sr
            : lng === 'en' && row.original.name_translates.en
            ? row.original.name_translates.en
            : row.original.name
      },
      {
        header: '',
        accessorKey: 'id',
        Cell: ({ row }) => (
          <Box display={'flex'} justifyContent={'flex-end'}>
            {checkPermission(currentCMS, PermissionsEnum.update_roles) && (
              <IconButton onClick={() => navigate('edit-role/' + row.original.id)}>
                <EditIcon />
              </IconButton>
            )}
            {checkPermission(currentCMS, PermissionsEnum.delete_roles) && (
              <IconButton
                onClick={() =>
                  setPopup({
                    open: true,
                    variant: 'delete',
                    title:
                      lng === 'en'
                        ? t('rolePage.deleteMessage').toString() +
                          row.original.name_translates.en +
                          '?'
                        : t('rolePage.deleteMessage').toString() +
                          row.original.name_translates.sr +
                          '?',
                    content: '',
                    onClick: () => {
                      deleteRole(row.original.id);
                      resetPopup();
                    }
                  })
                }
              >
                <DeleteIcon stroke="red" />
              </IconButton>
            )}
          </Box>
        )
      }
    ],
    []
  );

  return (
    <Box p={{ xs: 1, sm: 3 }}>
      {success && <Alert sx={{ position: 'fixed', width: '75%', zIndex: 1 }}>{success}</Alert>}
      {checkPermission(currentCMS, PermissionsEnum.create_roles) && (
        <Box mt={{ sm: -7 }} mb={2} display={'flex'} justifyContent={'flex-end'}>
          <Button variant="contained" onClick={() => navigate('create-role')}>
            <AddIcon /> {t('add')}
          </Button>
        </Box>
      )}
      <DataCard title={t('access')} data={roles} columns={columns} showHeader={true} />
    </Box>
  );
};

export default RoleSettingsPage;
