//react
import { MutableRefObject } from 'react';
//fullcalendar
import {
  AllowFunc,
  CssDimValue,
  DateSelectArg,
  DatesSetArg,
  EventClickArg,
  EventContentArg,
  FormatterInput
} from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
//fullcalendar-plugins
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateFormatter } from '@fullcalendar/core/internal';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
//locales
import srLocale from '@fullcalendar/core/locales/sr';
import enLocale from '@fullcalendar/core/locales/en-au';
//dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/sr';
//custom-plugins
import dayjsPlugin from '@src/plugins/FullcalendarDayjsPlugin/index';
interface CalendarProps<T extends object> {
  events: T[] | undefined;
  calendarRef: MutableRefObject<FullCalendar | null>;
  initialView: string;
  nowIndicator: boolean;
  weekends: boolean;
  titleFormat: FormatterInput;
  dayMaxEvents: boolean | number;
  eventTimeFormat: FormatterInput | DateFormatter;
  dayHeaderFormat: FormatterInput | DateFormatter;
  allDaySlot: boolean;
  slotMinTime: string | undefined;
  slotMaxTime: string | undefined;
  selectable: boolean;
  selectRows?: (data: DateSelectArg) => void;
  className?: string;
  datesChanged: ((arg: DatesSetArg) => void) | undefined;
  eventContent?: (data: EventContentArg) => JSX.Element;
  eventClick: (eventClickInfo: EventClickArg) => void;
  slotEventOverlap?: boolean;
  eventMaxStack?: number;
  moreLinkClick?: 'popover' | 'week' | 'day';
  dayPopoverFormat?: FormatterInput;
  resources?: any;
  resourceLabelContent?: any;
  selectAllow?: AllowFunc;
  contentHeight?: CssDimValue;
}

export const CalendarLayout = <T extends object>({
  events,
  calendarRef,
  initialView,
  nowIndicator,
  weekends,
  titleFormat,
  dayMaxEvents,
  eventTimeFormat,
  dayHeaderFormat,
  allDaySlot,
  slotMinTime,
  slotMaxTime,
  selectable,
  selectRows,
  className,
  datesChanged,
  eventContent,
  eventClick,
  slotEventOverlap,
  eventMaxStack,
  moreLinkClick,
  dayPopoverFormat,
  resources,
  resourceLabelContent,
  selectAllow,
  contentHeight
}: CalendarProps<T>) => {
  const lng = localStorage.getItem('lng');
  return (
    <FullCalendar
      // schedulerLicenseKey={'CC-Attribution-NonCommercial-NoDerivatives'}
      resourceLabelContent={resourceLabelContent}
      slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
      resources={resources}
      locales={[enLocale, srLocale]}
      locale={!lng || lng === 'sr' ? srLocale && dayjs.locale('sr') : ''}
      slotEventOverlap={slotEventOverlap}
      eventClick={eventClick}
      eventContent={eventContent}
      datesSet={datesChanged}
      events={events}
      ref={calendarRef}
      plugins={[
        dayGridPlugin,
        timeGridPlugin,
        interactionPlugin,
        dayjsPlugin,
        resourceTimeGridPlugin
      ]}
      initialView={initialView}
      headerToolbar={false}
      nowIndicator={nowIndicator}
      weekends={weekends}
      titleFormat={titleFormat}
      dayMaxEvents={dayMaxEvents}
      eventTimeFormat={eventTimeFormat}
      dayHeaderFormat={dayHeaderFormat}
      allDaySlot={allDaySlot}
      slotMinTime={slotMinTime}
      slotMaxTime={slotMaxTime}
      selectable={selectable}
      select={selectRows}
      eventMaxStack={eventMaxStack}
      moreLinkClick={moreLinkClick}
      dayPopoverFormat={dayPopoverFormat}
      contentHeight={contentHeight}
      selectAllow={selectAllow}
    />
  );
};
