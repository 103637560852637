//react
import { FC } from 'react';
//hooks
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import useAppointments from '@hooks/appointments/useAppointments';
//dayjs
import dayjs, { Dayjs } from 'dayjs';
//components
import AddRegularAppointment from '@components/AddAppointment/AddRegularAppointment';
import AddPermanentAppointment from '@components/AddAppointment/AddPermanentAppointment';
//icons
import { DeleteIcon, EditIcon } from '@components/icons/icons';
//mui
import { Box, Button, CircularProgress, Drawer, Grid, Typography } from '@mui/material';
//interfaces
import { CourtSportData } from '@interfaces/priceRules/priceRules';
import { CalendarWorkingHoursType } from '@interfaces/appointments/appointments';
//enums
import { AppointmentReservedFromEnum, AppointmentType } from '@enum/appointmentStatusEnum';
import { PermissionsEnum } from '@enum/permissionsEnum';
//atoms
import { popupAtom } from '@atoms/popupAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermission } from '@helpers/utility';

interface AddApointmentProps {
  anchor: 'bottom' | 'left' | 'right' | 'top';
  open: boolean;
  onClose: () => void;
  addAppointmentType: string;
  addRegularAppointment?: () => void;
  addPermanentAppointment?: () => void;
  dateAppointment?: Dayjs | null;
  startAppointment?: string;
  durationAppointment?: string | number;
  appointmentID?: number;
  reccuringAppointmentId?: number;
  courtItems?: CourtSportData[];
  sportCenterID: number | undefined;
  noButtons?: boolean;
  sportItems?: CourtSportData[];
  calendarWorkingHours: CalendarWorkingHoursType;
  selectedCourt?: string | number;
}

const AddApointment: FC<AddApointmentProps> = ({
  anchor,
  open,
  onClose,
  addAppointmentType,
  addRegularAppointment,
  addPermanentAppointment,
  dateAppointment,
  startAppointment,
  durationAppointment,
  appointmentID,
  reccuringAppointmentId,
  noButtons = false,
  courtItems,
  sportCenterID,
  sportItems,
  calendarWorkingHours,
  selectedCourt
}): JSX.Element => {
  const { t } = useTranslation();
  const setPopup = useSetRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);

  const currentCMS = useRecoilValue(currentCMSAtom);

  const {
    controlRegularAppointment,
    handleRegularAppointmentSubmit,
    onRegularAppointmentSubmit,
    courtSport,
    refUsersMenu,
    openUsersMenu,
    setOpenUsersMenu,
    userInput,
    setUserInput,
    handleSearch,
    debounceValue,
    durationTime,
    getRegularAppointmentValues,
    selectedAppointmentByID,
    timePickerTimes,
    confirmAppointment,
    declineAppointment,
    cancelAppointment,
    controlRecurringAppointment,
    handleRecurringAppointmentSubmit,
    onRecurringAppointmentSubmit,
    getRecurringAppointmentValues,
    setRecurringAppointmentValue,
    isRecurringAppointmentSubmitted,
    workingDays,
    setWorkingDays,
    editAppointment,
    setEditAppointment,
    deletePermanentAppointment,
    durationTimeRecurring,
    timePickerTimesRecurring
  } = useAppointments(
    open,
    onClose,
    calendarWorkingHours,
    selectedCourt,
    appointmentID,
    dateAppointment,
    startAppointment,
    reccuringAppointmentId,
    durationAppointment
  );

  // this is when user is logged in as coach role, and coach can reserve appointment only on assigned court/sports,
  // so when coach filters appointments in calendar with court or sport filter,
  // only his-her assigned court/sports with that setted court or sport filter will appear as options in appointment form court/sport input
  let filteredCourtItems: CourtSportData[] = [];
  let filteredSportItems: CourtSportData[] = [];
  courtItems?.map(item => {
    if (courtSport.find(e => e.id === item.id)) {
      filteredCourtItems.push(item);
    }
  });
  sportItems?.map(item => {
    if (courtSport.find(e => e.id === item.id)) {
      filteredSportItems.push(item);
    }
  });

  const renderEdit = () => {
    if (
      appointmentID &&
      selectedAppointmentByID?.data.data.reserved_from === AppointmentReservedFromEnum.cms &&
      dayjs().isBefore(dayjs(`${selectedAppointmentByID.data.data.datetime_start}`))
    ) {
      if (addAppointmentType === 'regularAppointment') {
        return checkPermission(currentCMS, PermissionsEnum.update_appointments);
      }
      return checkPermission(currentCMS, PermissionsEnum.update_recurring_appointments);
    }
    return false;
  };
  const renderDelete = () => {
    if (
      appointmentID &&
      dayjs().isBefore(dayjs(`${selectedAppointmentByID?.data.data.datetime_start}`)) &&
      (selectedAppointmentByID?.data.data.reserved_from === AppointmentReservedFromEnum.cms ||
        selectedAppointmentByID?.data.data.reserved_from === AppointmentReservedFromEnum.client)
    ) {
      if (addAppointmentType === 'regularAppointment') {
        return checkPermission(currentCMS, PermissionsEnum.delete_appointments);
      }
      return checkPermission(currentCMS, PermissionsEnum.delete_recurring_appointments);
    }
    return false;
  };

  if (appointmentID && !selectedAppointmentByID && addAppointmentType === AppointmentType.regular) {
    return (
      <Grid container justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      PaperProps={{
        sx: {
          width: { sm: '100%', md: '50%' }
        }
      }}
    >
      <Grid container p={3}>
        <Grid
          item
          xs={12}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          mb={noButtons ? 2 : 0}
        >
          {!appointmentID && (
            <Typography variant="h5" fontWeight={'bold'}>
              {t('calendar.add_appointment')}
            </Typography>
          )}
          {appointmentID && (
            <Typography variant="h5" fontWeight={'bold'}>
              {t('calendar.info_appointment')}
            </Typography>
          )}
          <Box>
            {renderEdit() && (
              <Button
                variant="contained"
                color="primary"
                endIcon={<EditIcon fill={'white'} stroke={'white'} viewBox="0 0 25 24" />}
                onClick={() => setEditAppointment(true)}
              >
                <Typography display={{ xs: 'none', sm: 'flex', md: 'none', lg: 'flex' }}>
                  {t('calendar.edit_appointment')}
                </Typography>
              </Button>
            )}

            {renderDelete() && (
              <Button
                variant="contained"
                color="error"
                endIcon={<DeleteIcon fill={'white'} stroke={'white'} viewBox="0 0 25 24" />}
                sx={{ ml: 1 }}
                onClick={() => {
                  setPopup({
                    open: true,
                    title:
                      addAppointmentType === AppointmentType.regular
                        ? `${t('calendar.cancel_appointment_popup_title')}`
                        : `${t('calendar.delete_appointment_popup_title')}`,
                    content:
                      addAppointmentType === AppointmentType.regular
                        ? `${t('calendar.cancel_appointment_popup_content')}`
                        : `${t('calendar.delete_appointment_popup_content')}`,
                    variant: 'delete',
                    buttonText:
                      addAppointmentType === AppointmentType.regular
                        ? `${t('cancel')}`
                        : `${t('delete')}`,
                    onClick: () => {
                      selectedAppointmentByID && addAppointmentType === AppointmentType.regular
                        ? cancelAppointment(selectedAppointmentByID?.data.data.id)
                        : deletePermanentAppointment(
                            Number(selectedAppointmentByID?.data.data.recurring_appointment_id)
                          );
                      resetPopup();
                    },
                    back: addAppointmentType === AppointmentType.regular ? `${t('back')}` : ''
                  });
                }}
              >
                <Typography display={{ xs: 'none', sm: 'flex', md: 'none', lg: 'flex' }}>
                  {addAppointmentType === AppointmentType.regular
                    ? t('calendar.cancelAppointment')
                    : t('calendar.delete_appointment')}
                </Typography>
              </Button>
            )}
          </Box>
        </Grid>
        {!noButtons && (
          <Grid item display={'flex'} flexDirection={'row'} mb={4} mt={2}>
            <Button
              variant={'tab'}
              color={addAppointmentType == 'regularAppointment' ? 'primary' : 'secondary'}
              onClick={addRegularAppointment}
            >
              {t('calendar.regularAppointment.buttonText')}
            </Button>
            {selectedAppointmentByID?.data.data.recurring_appointment_id !== null ? (
              <Button
                variant={'tab'}
                onClick={addPermanentAppointment}
                color={addAppointmentType == 'permanentAppointment' ? 'primary' : 'secondary'}
              >
                {t('calendar.permanentAppointment.buttonText')}
              </Button>
            ) : null}
          </Grid>
        )}
        {addAppointmentType == 'regularAppointment' && (
          <AddRegularAppointment
            onClose={onClose}
            appointmentID={appointmentID}
            courtItems={filteredCourtItems}
            editAppointment={editAppointment}
            sportCenterID={sportCenterID}
            controlRegularAppointment={controlRegularAppointment}
            handleRegularAppointmentSubmit={handleRegularAppointmentSubmit}
            onRegularAppointmentSubmit={onRegularAppointmentSubmit}
            courtSport={courtSport}
            refUsersMenu={refUsersMenu}
            openUsersMenu={openUsersMenu}
            setOpenUsersMenu={setOpenUsersMenu}
            userInput={userInput}
            setUserInput={setUserInput}
            handleSearch={handleSearch}
            debounceValue={debounceValue}
            durationTime={durationTime}
            getRegularAppointmentValues={getRegularAppointmentValues}
            selectedAppointmentByID={selectedAppointmentByID}
            timePickerTimes={timePickerTimes}
            confirmAppointment={confirmAppointment}
            declineAppointment={declineAppointment}
            sportItems={filteredSportItems}
          />
        )}
        {addAppointmentType == 'permanentAppointment' && (
          <AddPermanentAppointment
            onClose={onClose}
            courtItems={filteredCourtItems}
            editAppointment={editAppointment}
            sportCenterID={sportCenterID}
            controlRecurringAppointment={controlRecurringAppointment}
            handleRecurringAppointmentSubmit={handleRecurringAppointmentSubmit}
            onRecurringAppointmentSubmit={onRecurringAppointmentSubmit}
            getRecurringAppointmentValues={getRecurringAppointmentValues}
            setRecurringAppointmentValue={setRecurringAppointmentValue}
            isRecurringAppointmentSubmitted={isRecurringAppointmentSubmitted}
            courtSport={courtSport}
            refUsersMenu={refUsersMenu}
            openUsersMenu={openUsersMenu}
            setOpenUsersMenu={setOpenUsersMenu}
            userInput={userInput}
            setUserInput={setUserInput}
            handleSearch={handleSearch}
            debounceValue={debounceValue}
            timePickerTimesRecurring={timePickerTimesRecurring}
            workingDays={workingDays}
            setWorkingDays={setWorkingDays}
            durationTime={durationTimeRecurring}
            reccuringAppointmentId={reccuringAppointmentId}
            sportItems={filteredSportItems}
            appointmentID={appointmentID}
          />
        )}
      </Grid>
    </Drawer>
  );
};

export default AddApointment;
