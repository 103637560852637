//mui
import { Box, Drawer, ListItem, ListItemText, useMediaQuery } from '@mui/material';
import List from '@mui/material/List';
//theme
import theme from '@src/theme';
//icons
import { Logo } from '@src/ui/components/icons/icons';
//hooks
import { useSidemenu } from '@hooks/useSidemenu/useSidemenu';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
//containers
import MobileMenu from '@containers/MobileMenu/MobileMenu';

type SidebarProps = {
  open: boolean;
  onClose: () => void;
};

const Sidebar = ({ open, onClose }: SidebarProps): JSX.Element => {
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const location = useLocation();
  const { getSidemenu } = useSidemenu();

  const close = () => {
    if (!isMdUp) {
      onClose();
    }
  };

  useEffect(() => {
    close();
  }, [location.pathname]);

  return (
    <Box className="Sidebar">
      {isSmUp ? (
        <Drawer
          variant={isMdUp ? 'permanent' : 'temporary'}
          sx={{
            '& .MuiDrawer-paper': {
              width: 270,
              overflowX: 'hidden'
            }
          }}
          open={open}
          onClose={close}
        >
          <List>
            <ListItem sx={{ marginLeft: '15px' }}>
              <Logo />
              <ListItemText
                sx={{ marginLeft: '10px' }}
                primary={'SportyMate'}
                primaryTypographyProps={{
                  style: {
                    color: '#4F2A5B',
                    fontWeight: 600,
                    fontSize: '14px'
                  }
                }}
              />
            </ListItem>

            {getSidemenu()}
          </List>
        </Drawer>
      ) : (
        <MobileMenu open={open} onClose={onClose} anchor="left" />
      )}
    </Box>
  );
};
export default Sidebar;
