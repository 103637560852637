//hooks
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
//api
import { usePermissions } from '@api/queries/employees/employees';
import { useCreateRole, useDeleteRole, useUpdateRole } from '@api/mutations/roles/roles';
import { useAllRoles, useSingleRole } from '@api/queries/roles/roles';
//interfaces
import { RoleErrorType, RoleType } from '@interfaces/roles/roles';
//helpers
import { handleChecked } from '@helpers/utility';
//recoil
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//enum
import { RoleEnum } from '@enum/roleEnum';

export function useSuperadminRoleHook() {
  const currentCMS = useRecoilValue(currentCMSAtom);

  const [values, setValues] = useState<RoleType | undefined>();
  const [success, setSuccess] = useState('');
  const params = useParams();
  const roleId = params.roleId ? +params.roleId : undefined;
  const sportCenterId = params.sportCenterId ? +params.sportCenterId : undefined;
  const setPopup = useSetRecoilState(popupAtom);
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const lng = localStorage.getItem('lng');
  const {
    control,
    reset,
    getValues,
    setValue,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<RoleType>({
    defaultValues: {
      name: '',
      permissions: [],
      name_translates: {
        en: '',
        sr: ''
      }
    },
    values
  });

  const checkItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = getValues('permissions');
    if (!value) {
      setValue(`permissions`, [event.target.value]);
      return;
    }

    handleChecked(event, value, arr => setValue(`permissions`, arr));
    if (errors.permissions) clearErrors();
  };

  const handleSuccessCreate = (data: RoleType) => {
    if (!roleId) {
      setSuccess(t('rolePage.successCreateMessage').toString());
      reset();
      if (location.state?.from === 'employee') {
        navigate(-1);
      }
    } else {
      setSuccess(t('rolePage.successEditMessage').toString());
    }
    setTimeout(() => {
      setSuccess('');
    }, 2000);
  };
  const handleErrorCreate = (err: RoleErrorType) => {
    if (err.errors) {
      if (err.errors.name) {
        setError('name', {
          type: 'custom',
          message: err.errors.name.join(',')
        });
      }
      if (err.errors.permissions) {
        setError('permissions', {
          type: 'custom',
          message: err.errors.permissions.join(',')
        });
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };

  const onSubmit = (data: RoleType) => {
    data.name = lng === 'en' ? data.name_translates.en : data.name_translates.sr;
    if (roleId) {
      updateRole(data);
    } else {
      createRole(data);
    }
  };

  const { data: permissions } = usePermissions(
    !!(
      currentCMS?.role === RoleEnum.SUPERADMIN || currentCMS?.role === RoleEnum.SUPERADMIN_EMPLOYEE
    ),
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  );

  const { mutate: createRole } = useCreateRole(
    handleSuccessCreate,
    handleErrorCreate,
    sportCenterId
  );
  const { mutate: updateRole } = useUpdateRole(
    handleSuccessCreate,
    handleErrorCreate,
    sportCenterId
  );

  useSingleRole(
    roleId ? +roleId : undefined,
    data => {
      setValues(data);
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    },
    sportCenterId
  );

  const { data: roles, refetch } = useAllRoles(err => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }, sportCenterId);
  const { mutate: deleteRole } = useDeleteRole(
    () => {
      setSuccess(t('rolePage.successDelete').toString());
      refetch();
      setTimeout(() => {
        setSuccess('');
      }, 2000);
    },
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    },
    sportCenterId
  );

  return {
    checkItem,
    control,
    reset,
    onSubmit: handleSubmit(onSubmit),
    success: success,
    permissions: permissions ? permissions.data : [],
    default_permissions: watch('permissions'),
    roles: roles ? roles.data.data : [],
    deleteRole,
    errors,
    lng
  };
}
