//error
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//interface
import {
  SportCustomFields,
  SportCustomFieldsResponse,
  SportError,
  SportErrorResponse
} from '@interfaces/sport/sport';
//service
import {
  createCustomFields,
  deleteCustomFields,
  updateCustomFields
} from '@services/sportCustomFields/sportCustomFields';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useUpdateSportCustomField = (
  onSuccess: (data: SportCustomFieldsResponse) => void,
  onError: (err: SportError) => void
): UseMutationResult<
  { data: SportCustomFieldsResponse }, // response
  SportErrorResponse,
  SportCustomFields & { sportId: number }, // request
  (data: SportCustomFieldsResponse) => void // on success
> =>
  useMutation({
    mutationFn: updateCustomFields,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useCreateSportCustomField = (
  onSuccess: (data: SportCustomFieldsResponse) => void,
  onError: (err: SportError) => void
): UseMutationResult<
  { data: SportCustomFieldsResponse }, // response
  SportErrorResponse,
  SportCustomFields & { sportId: number }, // request
  (data: SportCustomFieldsResponse) => void // on success
> =>
  useMutation({
    mutationFn: createCustomFields,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteCustomField = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  ErrorRes,
  { sportId: number; customFieldId: number }, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteCustomFields,
    onSuccess: data => onSuccess({ message: 'Successfully deleted custom fields' }),
    onError: err => onError(err.response.data)
  });
