//error
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//interface
import {
  NonWorkingDaysError,
  NonWorkingDaysErrorResponse,
  NonWorkingDaysRequestType,
  NonWorkingDaysResponseType,
  NonWorkingDaysType
} from '@interfaces/nonWorkingDays/nonWorkingDays';

//service
import {
  createNonWorkingDays,
  deleteNonWorkingDay,
  update
} from '@services/nonWorkingDays/nonWorkingDays';
//react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';

export const useUpdateNonWorkingDays = (
  onSuccess: (data: NonWorkingDaysType) => void,
  onError: (err: NonWorkingDaysError) => void
): UseMutationResult<
  { data: NonWorkingDaysResponseType }, // response
  NonWorkingDaysErrorResponse,
  NonWorkingDaysRequestType & { sportCenterId: number; id: number }, // request
  (data: NonWorkingDaysType) => void // on success
> =>
  useMutation({
    mutationFn: data => update(data.id, data.sportCenterId, data),
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useCreateNonWorkingDays = (
  onSuccess: (data: NonWorkingDaysType) => void,
  onError: (err: NonWorkingDaysError) => void
): UseMutationResult<
  { data: NonWorkingDaysResponseType }, // response
  NonWorkingDaysErrorResponse,
  NonWorkingDaysRequestType & { sportCenterId: number }, // request
  (data: NonWorkingDaysType) => void // on success
> =>
  useMutation({
    mutationFn: data => createNonWorkingDays(data.sportCenterId, data),
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteNonWorkingDays = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: Error) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  Error,
  { sportCenterId: number; nonWorkingDaysId: number }, // request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: req => deleteNonWorkingDay(req.sportCenterId, req.nonWorkingDaysId),
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });
