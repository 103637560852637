//hooks
import { useMutation, UseMutationResult } from '@tanstack/react-query';
//interfaces
import {
  ChangePasswordErrorResponseType,
  ChangePasswordErrorType,
  ChangePasswordRequestType,
  LoginErrorResponse,
  LoginRequest,
  LoginResponse,
  LogoutErrorResponse,
  LogoutResponse,
  RecoverPasswordErrorResponse,
  RecoverPasswordRequest,
  RecoverPasswordResponse,
  ResetPasswordError,
  ResetPasswordErrorResponse,
  ResetPasswordFormRequestType,
  UnverifiedEmailErrorResponseType,
  UnverifiedEmailErrorType
} from '@interfaces/user/login';
//services
import {
  changePassword,
  login,
  logout,
  recoverPassword,
  resetPassword,
  sendVerificationEmail,
  updateUnverifiedEmail
} from '@services/user/user';
import { User, UserResponse } from '@interfaces/user/user';
import { SuccessResponse } from '@interfaces/apiResponse';

export const useLogin = (
  onSuccess: (data: LoginResponse) => void,
  onError: (err: LoginErrorResponse) => void
): UseMutationResult<{ data: LoginResponse }, LoginErrorResponse, LoginRequest> =>
  useMutation({
    mutationFn: login,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useLogout = (
  onSuccess: (data: LogoutResponse) => void,
  onError: (err: LogoutErrorResponse) => void
): UseMutationResult<{ data: LogoutResponse }, LogoutErrorResponse> =>
  useMutation({
    mutationFn: logout,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useRecoverPassword = (
  onSuccess: (data: RecoverPasswordResponse) => void,
  onError: (err: RecoverPasswordErrorResponse) => void
): UseMutationResult<
  { data: RecoverPasswordResponse },
  RecoverPasswordErrorResponse,
  RecoverPasswordRequest
> =>
  useMutation({
    mutationFn: recoverPassword,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useResetPassword = (
  onSuccess: () => void,
  onError: (err: ResetPasswordError) => void
): UseMutationResult<
  { data: RecoverPasswordResponse },
  ResetPasswordErrorResponse,
  ResetPasswordFormRequestType
> =>
  useMutation({
    mutationFn: resetPassword,
    onSuccess: data => onSuccess(),
    onError: err => onError(err.response.data)
  });

export const useChangePassword = (
  onSuccess: (u: User) => void,
  onError: (err: ChangePasswordErrorType) => void
): UseMutationResult<
  { data: UserResponse },
  ChangePasswordErrorResponseType,
  ChangePasswordRequestType
> =>
  useMutation({
    mutationFn: changePassword,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useSendVerificationEmail = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: Error) => void
): UseMutationResult<{ data: SuccessResponse }, Error> =>
  useMutation({
    mutationFn: sendVerificationEmail,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useUpdateUnverifiedEmail = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: UnverifiedEmailErrorType) => void
): UseMutationResult<
  { data: SuccessResponse },
  UnverifiedEmailErrorResponseType,
  { email: string }
> =>
  useMutation({
    mutationFn: updateUnverifiedEmail,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
