//components
import { Grid, Typography } from '@mui/material';
//icon
import { HomePageIcon } from '@components/icons/homePageIcon';
//hook
import { useTranslation } from 'react-i18next';

type CommingSoonPageType = {
  imageURL: string;
};

const ComingSoonPage = ({ imageURL }: CommingSoonPageType) => {
  const { t } = useTranslation();
  return (
    <Grid container justifyContent="center" alignItems="top" p={3}>
      <Grid item>
        <Typography variant="h3" fontWeight={'bold'} textAlign={'center'}>
          {t('coming_soon')}
        </Typography>
      </Grid>
      <Grid
        item
        height="100vh"
        width="100%"
        sx={{
          backgroundImage: `url(${imageURL})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      ></Grid>
    </Grid>
  );
};

export default ComingSoonPage;
