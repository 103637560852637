// hooks
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

// mutations
import { useCreateAdminUser } from '@api/mutations/user/user';

// interfaces
import {
  AdminUserErrorResponseType,
  AdminUserResponseType,
  CreateAdminUserDataType
} from '@interfaces/user/user';
import { SubmitHandler } from 'react-hook-form';

// atoms
import { createdObjectUserAtom } from '@atoms/createdObjectUserAtom';
import { appointmentFormAtom } from '@atoms/appointmentFormAtom';

const useAdminUsers = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId = params.sportCenterId ? params.sportCenterId : '';
  const setCreatedObjectUser = useSetRecoilState(createdObjectUserAtom);
  const appointmentForm = useRecoilValue(appointmentFormAtom);
  const {
    handleSubmit,
    control,
    trigger,
    setError,
    formState: { isDirty, isValid }
  } = useForm<CreateAdminUserDataType>({
    mode: 'onBlur'
  });
  const handleCancel = () => {
    if (appointmentForm) {
      navigate(`/calendar/${sportCenterId}`, { replace: true });
    } else {
      navigate(`/users/${sportCenterId}`, { replace: true });
    }
  };
  const onCreateSuccess = (data: AdminUserResponseType) => {
    if (appointmentForm) {
      navigate(`/calendar/${sportCenterId}`, { replace: true });
      setCreatedObjectUser({ id: data.data.user_id, name: data.data.name });
    } else {
      navigate(`/users/${sportCenterId}`, { replace: true });
    }
  };
  const onCreateError = (err: any) => {
    for (const key in err.response.data.errors) {
      setError(
        key as keyof CreateAdminUserDataType,
        {
          type: 'custom',
          message: err.response.data.errors[key][0]
        },
        {
          shouldFocus: true
        }
      );
    }
  };

  const { isLoading, mutate: createSportCenterUser } = useCreateAdminUser(
    onCreateSuccess,
    onCreateError
  );

  const onSubmitUser: SubmitHandler<CreateAdminUserDataType> = async (data, e) => {
    await trigger([
      'user_info.date_of_birth',
      'user_info.first_name',
      'user_info.phone',
      'user_info.last_name',
      'user_info.email',
      'user_info.gender'
    ]);

    createSportCenterUser({
      sportCenterId: +sportCenterId,
      data: data
    });
  };
  return {
    handleSubmit: handleSubmit(onSubmitUser),
    control,
    handleCancel,
    t,
    isDirty,
    isValid
  };
};

export default useAdminUsers;
