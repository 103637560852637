//react
import { NavLink, useNavigate } from 'react-router-dom';
import { useMemo, FC } from 'react';
//hooks
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useEmployees } from '@api/queries/cooperation/employee/employee';
import { useDeleteUser, useDeleteUsers } from '@api/mutations/user/user';
import { useTranslation } from 'react-i18next';
//containers
import TableHeaderActions from '@containers/TableHeaderAction/TableHeaderActions';
//components
import Loading from '@components/Loading/Loading';
//interfaces
import { Employee } from '@interfaces/employees/employees';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import { Alert, Card, Grid } from '@mui/material';
//recoil
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
//atom
import { popupAtom } from '@atoms/popupAtom';
import { createEmployeeAtom } from '@atoms/createEmployeeAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermissionFunc } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const EmployeesPage: FC = (): JSX.Element | null => {
  const setPopup = useSetRecoilState(popupAtom);
  const resetCreateEmployeeAtom = useResetRecoilState(createEmployeeAtom);

  const currentCMS = useRecoilValue(currentCMSAtom);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onDeleteSuccesss = (data: SuccessResponse) => {
    refetch();
    setSuccessfullyDeletedPopup(true);
  };

  const onDeleteError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteEmployee } = useDeleteUser(onDeleteSuccesss, onDeleteError);

  const { mutate: deleteEmployees } = useDeleteUsers(onDeleteSuccesss, onDeleteError);

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    sendEmailEventHandler,
    deleteEventHandler,
    deletedNames,
    deleteIds,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    successfullyDeletedPopup,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    anchorElActionButton,
    handleClick,
    handleClose
  } = useTablesLogic(deleteEmployee, deleteEmployees, 'employees');

  function onEmployeesError(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  const {
    data,
    refetch,
    isLoading: isLoadingEmployees
  } = useEmployees(
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.gender.toString(),
    sortBy,
    sortDirection,
    onEmployeesError
  );

  const columnsEmployees = useMemo<MRT_ColumnDef<Employee>[]>(
    () => [
      {
        accessorKey: 'user_info.name',
        header: t('name_and_surname'),
        minSize: 10,
        maxSize: 100,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => (
          <NavLink to={`${row.original.user_info.id}`} end style={{ color: 'black' }}>
            {row.original.user_info.name}
          </NavLink>
        )
      },
      {
        accessorKey: 'user_info.phone',
        header: t('phone'),
        minSize: 10,
        maxSize: 100,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'user_info.email',
        header: t('email'),
        minSize: 10,
        maxSize: 100,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'cooperation_info.employee_position.employee_sector.name',
        header: t('sector'),
        minSize: 10,
        maxSize: 100,
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'cooperation_info.employee_position.name',
        header: t('position'),
        minSize: 10,
        maxSize: 100,
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    []
  );

  return (
    <Grid container>
      {successfullyDeletedPopup && deleteIds.length > 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('employees.employees')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('employees.successMultiple')}
        </Alert>
      ) : successfullyDeletedPopup && deleteIds.length === 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('employees.employee')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('employees.successSingle')}
        </Alert>
      ) : null}
      <Card sx={{ mx: 3 }} className="fullWidth">
        <TableHeaderActions
          disableActions={!deletedNames}
          tableVariant="employees"
          handleAddItemBtn={checkPermissionFunc(
            currentCMS,
            PermissionsEnum.create_employees,
            () => {
              navigate('create');
              resetCreateEmployeeAtom();
            }
          )}
          // sendEmailEventHandler={sendEmailEventHandler}// TODO: return after MVP
          deleteEventHandler={checkPermissionFunc(
            currentCMS,
            PermissionsEnum.delete_employees,
            deleteEventHandler
          )}
          handleSearch={handleSearch}
          //sort
          handleSortTables={handleSortTables}
          checkSort={checkSort}
          handleClearSort={handleClearSort}
          //filter
          handleClearFilter={handleClearFilter}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorFilterEl={anchorEl}
          anchorElActionButton={anchorElActionButton}
          anchorMenuItem={anchorMenuItem}
          anchorSortEl={anchorSortEl}
          controlFilter={controlFilter}
          handleSubmitFilter={handleSubmitFilter}
          filterValue={filterValue}
        />
        <Grid item lg={12} ml={'20px'} mr={'20px'}>
          {data && !isLoadingEmployees ? (
            <TableLayout
              tableVariant="employees"
              rows={data?.data?.data}
              columns={columnsEmployees as MRT_ColumnDef<{}>[]}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={data?.data?.meta?.total}
              tableInstanceRef={tableInstanceRef}
              withCheckbox
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default EmployeesPage;
