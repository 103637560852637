//service
import {
  CreateEmployeeErrorType,
  CreateEmployeeErrorTypeResponse,
  Employee,
  EmployeeCreateRequest,
  EmployeeResponseType
} from '@interfaces/employees/employees';
import {
  createEmployee,
  deleteEmployeeDocuments,
  updateEmployee,
  uploadEmployeeDocuments
} from '@services/employees/employees';
//type
import {
  DeleteImageRequest,
  ImageType,
  UploadImageRequest,
  UploadImageResponse
} from '@interfaces/Images/images';
//hooks
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { SuccessResponse } from '@interfaces/apiResponse';

export const useCreateEmployee = (
  onSuccess: (data: Employee) => void,
  onError: (err: CreateEmployeeErrorType) => void
): UseMutationResult<
  EmployeeResponseType, // response
  CreateEmployeeErrorTypeResponse,
  EmployeeCreateRequest, // request
  (data: Employee) => void // on success
> =>
  useMutation({
    mutationFn: createEmployee,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useUploadEmployeeDocument = (
  onSuccess: (data: ImageType) => void,
  onError: (err: Error) => void
): UseMutationResult<
  UploadImageResponse, // response
  Error,
  UploadImageRequest, // request
  (data: ImageType) => void // on success
> =>
  useMutation({
    mutationFn: uploadEmployeeDocuments,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err)
  });

export const useUpdateEmployee = (
  onSuccess: (data: Employee) => void,
  onError: (err: CreateEmployeeErrorType) => void
): UseMutationResult<
  EmployeeResponseType, // response
  CreateEmployeeErrorTypeResponse,
  EmployeeCreateRequest & { employeeId: number }, // request
  (data: Employee) => void // on success
> =>
  useMutation({
    mutationFn: updateEmployee,
    onSuccess: data => onSuccess(data.data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteEmployeeDocument = (
  onSuccess: (data: string) => void,
  onError: (err: Error) => void
): UseMutationResult<
  SuccessResponse, // response
  Error,
  DeleteImageRequest, // request
  (data: string) => void // on success
> =>
  useMutation({
    mutationFn: deleteEmployeeDocuments,
    onSuccess: data => onSuccess(data.message),
    onError: err => onError(err)
  });
