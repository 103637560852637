// hooks
import useAdminEmployees from '@hooks/adminEmployees/useAdminEmployeesHook';

// components
import { Box, Button } from '@mui/material';
import CreateAdminEmployeeForm from '@components/Forms/AdminEmployees/CreateAdminEmployeeForm';
import Loading from '@components/Loading/Loading';

// containers
import CardWrapper from '@containers/CardWrapper/CardWrapper';

const CreateAdminEmployeePage = () => {
  const {
    handleCancel,
    handleSubmit,
    control,
    t,
    isDirty,
    isValid,
    sportCenterId,
    sportCenterRolesData,
    isLoadingCreateAdminEmployee,
    isLoadingRoles
  } = useAdminEmployees();

  if (isLoadingCreateAdminEmployee && isLoadingRoles) return <Loading />;

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <CardWrapper
        isForm
        title={t('adminEmployeePage.create.info_title')}
        subtitle={t('adminEmployeePage.create.info_subtitle')}
        children={
          !isLoadingRoles ? (
            <CreateAdminEmployeeForm
              control={control}
              t={t}
              sportCenterId={sportCenterId}
              sportCenterRoles={sportCenterRolesData}
            />
          ) : (
            <Loading />
          )
        }
      />
      <Box display="flex" flexDirection="row" p={2} justifyContent="flex-end" gap={2}>
        <Button variant="text" color="primary" onClick={handleCancel}>
          {t('quit')}
        </Button>

        <Button type="submit" variant="contained" disabled={!isDirty && !isValid}>
          {t('save')}
        </Button>
      </Box>
    </Box>
  );
};

export default CreateAdminEmployeePage;
