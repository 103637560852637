import { ReactNode } from 'react';
//components
import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomTimePicker from '@components/CustomTimePicker/CustomTimePicker';
//icons
import { EditIcon } from '../icons/icons';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
//hooks
import { useTranslation } from 'react-i18next';
import { Control, Controller, Path } from 'react-hook-form';
//helpers
import dayjs from 'dayjs';
import { checkPermission, getAvailableTimesForTimePicker } from '@src/helpers/utility';
//recoil
import { useRecoilValue } from 'recoil';
import { currentCMSAtom } from '@atoms/currentCMS';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const availableTimes: string[] = getAvailableTimesForTimePicker(0, 24);

type EditableRowProps<T extends object> = {
  title: string | undefined;
  control: Control<T>;
  controlKey: Path<T>[];
  enable: boolean;
  onClick: () => void;
  type?: 'text' | 'select' | 'number' | 'custom' | 'time' | 'switchButton' | 'date';
  disabled?: boolean;
  select?: string[];
  getSelectOptions?: ReactNode[];
  children?: ReactNode;
  handleCancel: () => void;
  hideDeleteBtn?: boolean;
  permission: PermissionsEnum;
  disableSubmit?: boolean;
  helperText?: string;
  isWholeDay?: boolean;
};

function EditableRow<T extends object>({
  title,
  control,
  controlKey,
  type,
  disabled,
  enable,
  onClick,
  select,
  handleCancel,
  getSelectOptions,
  children,
  hideDeleteBtn = false,
  permission,
  disableSubmit = false,
  helperText,
  isWholeDay
}: EditableRowProps<T>) {
  const { t } = useTranslation();
  const currentCMS = useRecoilValue(currentCMSAtom);

  function handleClick() {
    if (disabled) {
      return;
    }

    onClick();
  }

  return (
    <Grid container>
      <Grid item sm={5}>
        <Typography>{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={7} display={'flex'} alignItems="center">
        {type === 'custom'
          ? children
          : controlKey.map((c, i) => (
              <Controller
                key={i}
                name={c}
                control={control}
                render={({ field, fieldState: { invalid, error } }) => {
                  if (type === 'switchButton') {
                    return (
                      <Box sx={{ width: '100% ', display: 'flex', alignItems: 'center' }}>
                        <InputLabel error={invalid}>{t('no')}</InputLabel>
                        <Switch
                          {...field}
                          onChange={e => {
                            field.onChange(e.target.checked);
                          }}
                          checked={field.value ? field.value : false}
                          disabled={!enable}
                        />
                        <InputLabel error={invalid}>{t('yes')}</InputLabel>
                        {(error || helperText) && (
                          <FormHelperText error>
                            {helperText ? helperText : error?.message}
                          </FormHelperText>
                        )}
                      </Box>
                    );
                  }

                  if (type === 'select') {
                    return (
                      <FormControl fullWidth>
                        <Select
                          {...field}
                          variant="standard"
                          fullWidth
                          error={invalid}
                          size={'small'}
                          disabled={!enable}
                          value={field.value ? field.value : ''}
                        >
                          {getSelectOptions
                            ? getSelectOptions
                            : select?.map(option => (
                                <MenuItem key={option} value={option}>
                                  {option}
                                </MenuItem>
                              ))}
                        </Select>
                        {(error || helperText) && (
                          <FormHelperText error>
                            {helperText ? helperText : error?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }
                  if (type === 'date') {
                    return (
                      <FormControl fullWidth>
                        <DatePicker
                          format="DD.MM.YYYY"
                          sx={{ width: '100%' }}
                          {...field}
                          value={dayjs(field.value)}
                          slotProps={{
                            textField: {
                              color: invalid ? 'error' : 'primary',
                              variant: 'standard'
                            }
                          }}
                          disabled={!enable}
                        />
                        {(error || helperText) && (
                          <FormHelperText color="error">
                            {helperText ? helperText : error?.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }
                  if (type === 'time') {
                    return (
                      <CustomTimePicker
                        field={field}
                        availableTimes={availableTimes}
                        label={''}
                        invalid={invalid}
                        helperText={helperText ? helperText : error && error.message}
                        className="timePickerStepOne"
                        variant="standard"
                        disabled={!enable || isWholeDay}
                        sx={{ '& .MuiInputBase-root': { m: 0 } }}
                        required
                      />
                    );
                  }

                  return (
                    <TextField
                      fullWidth
                      {...field}
                      error={invalid}
                      helperText={helperText ? helperText : error && error.message}
                      disabled={!enable}
                      size="small"
                      variant="standard"
                      type={type}
                      value={field.value ? field.value : ''}
                    />
                  );
                }}
              />
            ))}
        <Grid item sm={2} display={'flex'} alignItems="center" justifyContent={'flex-end'}>
          {enable ? (
            <Box>
              {!hideDeleteBtn && (
                <IconButton size="small" onClick={() => handleCancel()}>
                  <ClearRoundedIcon color="error" />
                </IconButton>
              )}
              <IconButton
                disabled={disableSubmit}
                size="small"
                onClick={disableSubmit ? () => (enable = true) : handleClick}
              >
                <CheckRoundedIcon color="primary" />
              </IconButton>
            </Box>
          ) : (
            checkPermission(currentCMS, permission) && (
              <IconButton size="small" onClick={handleClick}>
                <EditIcon />
              </IconButton>
            )
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default EditableRow;
