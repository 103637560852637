//hooks
import { useDropzone } from 'react-dropzone';
// components
import { Typography, IconButton, Button, Grid, LinearProgress, Badge, Box } from '@mui/material';
//interfaces
import { ImageType } from '@src/domain/interfaces/Images/images';
// icons
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import { RemoveIcon } from '@components/icons/icons';
import { useTranslation } from 'react-i18next';

type UploadImageProps = {
  title: string;
  images: ImageType[];
  handleDelete: (id: number) => void;
  onDropCallback: (image: File) => void;
  uploadPercent: number;
  isLoading?: boolean;
};
// TODO: Change the way images delete when have get requests when delete refatch images
const UploadImage = ({
  title,
  images,
  onDropCallback,
  handleDelete,
  uploadPercent,
  isLoading
}: UploadImageProps) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: acceptImages =>
      acceptImages.map(image => {
        onDropCallback(image);
      })
  });

  const { t } = useTranslation();

  const renderImage =
    images.length > 0 &&
    images.map((image, index) => (
      <Grid item xs={12} sm={6} md={5} lg={3} key={image.id}>
        <Badge
          color="error"
          sx={{
            cursor: 'pointer',
            '.MuiBadge-badge': { padding: '12px 4px', borderRadius: '50%' }
          }}
          onClick={() => handleDelete(image.id)}
          badgeContent={<ClearIcon id={`${image.id}`} fontSize="small" />}
        >
          <img src={image.src} width={150} />
          {isLoading ? (
            <LinearProgress
              color="success"
              variant="buffer"
              value={uploadPercent}
              valueBuffer={uploadPercent}
            />
          ) : null}
        </Badge>
      </Grid>
    ));

  return (
    <Box pb={2}>
      <Typography variant="subtitle2" color="GrayText" sx={{ width: '100%', pb: '7px' }}>
        {title}
      </Typography>
      <Box
        {...getRootProps({ className: 'dropzone' })}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: '152px',
          border: '1px dashed rgba(0, 0, 0, 0.2)',
          borderRadius: '4px',
          '&:hover': {
            borderColor: 'blue',
            background: 'rgba(0, 0, 0, 0.04)'
          }
        }}
      >
        <input {...getInputProps()} />
        <IconButton size="medium" color="primary">
          <UploadFileOutlinedIcon />
        </IconButton>
        <Typography variant="subtitle2">
          <Button variant="text" component="a" sx={{ textTransform: 'none' }}>
            {t('imageUpload.click')}
            <input {...getInputProps()} />
          </Button>
          {t('imageUpload.dragAndDrop')}
        </Typography>
        <Typography variant="subtitle2" color="GrayText">
          SVG, PNG, JPG or GIF (max. 3MB)
        </Typography>
      </Box>
      <Grid container spacing={2} py={2} sx={{ gap: 3 }} wrap="wrap">
        {renderImage}
      </Grid>
    </Box>
  );
};

export default UploadImage;
