// hooks
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
// interfaces
import { MRT_ColumnDef } from 'material-react-table';
import { CoachType } from '@interfaces/user/coaches/coaches';
// components
import { Card, Typography, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

// containers
import DataCard from '@components/DataCard/DataCard';
import { useGetSuperAdminCoachQuery } from '@api/queries/coaches/coaches';
import Loading from '@components/Loading/Loading';
import { ErrorResponse } from '@interfaces/apiResponse';
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';

const SuperUserOverview = () => {
  const { t } = useTranslation();
  const params = useParams();
  const superAdminCoachId: number = Number(params.superUserId);
  const setPopup = useSetRecoilState(popupAtom);
  const onError = (err: ErrorResponse) => {
    setPopup({
      open: true,
      variant: 'error',
      title: '',
      content: err.message
    });
  };

  const { data: superAdminCoachData, isLoading } = useGetSuperAdminCoachQuery(
    superAdminCoachId,
    onError
  );

  const columnsSuperAdminCoaches = useMemo<MRT_ColumnDef<CoachType | {}>[]>(
    () => [
      {
        accessorKey: 'sport_center',
        header: t('object'),
        maxSize: 200,
        minSize: 200,
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as CoachType;
          const SCName = rowType.sport_center && rowType.sport_center.name;
          return <span>{SCName}</span>;
        }
      },
      {
        accessorKey: 'coach_info.court_sports',
        header: t('sport'),

        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as CoachType;
          const sportName =
            rowType.coach_info.court_sports && rowType.coach_info.court_sports[0].sport.name;
          return <span>{sportName}</span>;
        }
      },
      {
        accessorKey: 'coach_info.date_start',
        header: t('creation_date'),
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as CoachType;

          return <span>{rowType.coach_info.date_start.toString()}</span>;
        }
      },
      {
        accessorKey: 'coach_info.assigned_hours',
        header: t('coachesPage.appointments.allAppointments'),
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'coach_info.date_end',
        header: t('expiry_date'),
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as CoachType;

          return <span>{rowType.coach_info.date_end.toString()}</span>;
        }
      },
      {
        accessorKey: 'coach_info.remaining_hours',
        header: t('coachesPage.appointments.appointmentsLeft'),
        enableEditing: false,
        enableColumnOrdering: false
      }
    ],
    [t]
  );

  if (!superAdminCoachData && isLoading) {
    return <Loading />;
  }
  return (
    <Box p={3}>
      <Card sx={{ mb: 5, p: 2 }}>
        <Typography variant="h4"> {superAdminCoachData?.data.data.name}</Typography>
        <Typography variant="subtitle2">
          {t('email')}: {superAdminCoachData?.data.data.email}
        </Typography>
        <Typography variant="subtitle2">
          {' '}
          {t('phone')}: {superAdminCoachData?.data.data.phone}
        </Typography>
      </Card>
      {superAdminCoachData && superAdminCoachData.data.data.coach_memberships && (
        <DataCard
          columns={columnsSuperAdminCoaches}
          data={superAdminCoachData?.data.data.coach_memberships}
          title={t('object')}
          subtitle={t('coachesPage.superAdminCoach_table_subtitle').toString()}
          showHeader
        />
      )}
    </Box>
  );
};

export default SuperUserOverview;
