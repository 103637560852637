// store
import { atom } from 'recoil';
// interfaces
import { CreateSportCenterType } from '../interfaces/SportCenters/SportCenter';
//mock
import { emptyObject } from '@src/__mock__/createSportsCenter/steps';

const stepOneDataAtom = atom<CreateSportCenterType>({
  key: 'stepOneDataState',
  default: emptyObject
});

export { stepOneDataAtom };
