// react
import { ReactElement, useCallback } from 'react';

// types
import { DateView } from '@mui/x-date-pickers';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

// dayjs
import dayjs from 'dayjs';

// hooks
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

// components
import Loading from '@components/Loading/Loading';
import { Card, Typography, useMediaQuery, Stack, Box } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Label,
  Tooltip,
  TooltipProps,
  Legend,
  LegendProps
} from 'recharts';

export interface BarData {
  timestampStartOfPeriod: string;
  cashPayment: string | number;
  cardPayment: string | number;
}

interface IChartBarProps {
  data?: BarData[];
  unit: 'k' | 'RSD';
  xAxisDate: DateView;
  noDataDateValue?: string;
  isLoading?: boolean;
  handleOnDateChange?: CallableFunction;
}

const BarChartSM = ({ data = [], unit, xAxisDate, isLoading }: IChartBarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isWeb = useMediaQuery(theme.breakpoints.up('lg'));

  const chartStyles = {
    fontSize: 11,
    lineHeight: 11,
    stroke: '',
    fontFamily: 'Inter',
    fontWeight: 500,
    tickLine: false,
    tick: { stroke: 'transparent' },
    axisLine: { stroke: 'transparent' }
  };

  const handleFormatXAxis = useCallback(
    (value: string, index: number) => {
      if (xAxisDate === 'month') {
        return dayjs(value).format('MMM');
      }
      if (!isWeb && !isTablet) {
        const visibleIndices = [2, 9, 16, 23];
        return visibleIndices.includes(index)
          ? dayjs(value).format(isMobile ? 'DD MMM' : 'DD')
          : '';
      }
      return dayjs(value).format(isMobile ? 'DD' : 'DD');
    },
    [xAxisDate, isWeb, isTablet, isMobile]
  );

  const intervalValue = () => {
    let interval = undefined;

    if (xAxisDate === 'month') {
      interval = 0;
    } else if (isWeb || isTablet) {
      interval = isTablet ? 1 : 0;
    }

    return interval;
  };
  const CustomTooltip = (props: TooltipProps<ValueType, NameType>): ReactElement => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      return (
        <Card sx={{ p: 2 }}>
          <Typography variant="h6">
            {dayjs(label).format(xAxisDate === 'month' ? 'MMMM YYYY' : 'DD MMM YYYY')}
          </Typography>
          {payload.map((item, index) => (
            <Typography key={index} variant="body1">
              {t('finances_overview.' + String(item.name))}: {item.value?.toLocaleString()} {unit}
            </Typography>
          ))}
        </Card>
      );
    }

    return <></>;
  };
  const CustomLegend = (props: LegendProps): ReactElement => {
    const { payload } = props;
    if (payload && payload.length === 0) return <></>;
    return (
      <Stack mt={3} pl={8} direction="row" spacing={2}>
        {payload?.map((entry, index) => (
          <Stack key={`item-${index}`} direction="row" alignItems="center" spacing={1}>
            <Box
              component="div"
              width={10}
              height={10}
              borderRadius={2}
              sx={{ backgroundColor: entry.color }}
            />
            <Typography variant="body1">{t('finances_overview.' + String(entry.value))}</Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  if (isLoading) return <Loading />;
  return (
    <ResponsiveContainer width="100%" height={270}>
      <BarChart
        data={data}
        margin={{
          top: 50,
          right: 0,
          left: 0,
          bottom: 10
        }}
        style={{ pointerEvents: 'none' }}
      >
        <CartesianGrid strokeWidth={1} strokeDasharray="3 3" vertical={false} />
        <XAxis
          dataKey="timestampStartOfPeriod"
          {...chartStyles}
          interval={intervalValue()}
          tickFormatter={handleFormatXAxis}
        >
          {xAxisDate === 'day' && (isWeb || isTablet) && (
            <Label
              style={{ fontSize: isMobile ? 11 : 14, fontWeight: 700 }}
              dy={13}
              position="insideBottomLeft"
            ></Label>
          )}
        </XAxis>
        <YAxis {...chartStyles} tickFormatter={value => value + unit}>
          <Label style={{ fontSize: isMobile ? 11 : 14 }} dy={-140} dx={10}></Label>
        </YAxis>
        <Tooltip content={<CustomTooltip />} animationDuration={300} />
        <Bar
          dataKey="cashPayment"
          stackId="a"
          fill={theme.palette.primary.main}
          stroke={theme.palette.primary.main}
          strokeWidth={1}
          cursor="pointer"
          pointerEvents="initial"
        />
        <Bar
          dataKey="cardPayment"
          stackId="a"
          fill={theme.palette.sportyMateGreen.main}
          stroke={theme.palette.sportyMateGreen.main}
          strokeWidth={1}
          cursor="pointer"
          pointerEvents="initial"
        />
        <Legend content={<CustomLegend />} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartSM;
