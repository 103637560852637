// interfaces
import { AdminCoachesKeyType, CreateCoachRequestType } from '@interfaces/user/coaches/coaches';
import dayjs from 'dayjs';

export const adminCoachesKeys: AdminCoachesKeyType[] = [
  { name: 'name_and_surname' },
  { name: 'email' },
  { name: 'phone_number' },
  { name: 'coachesPage.create.appointment_date_from' },
  { name: 'coachesPage.create.appointment_date_to' },
  { name: 'coachesPage.create.number_of_hours' },
  { name: 'cancel_policy' },
  { name: 'fieldsAndSports' },
  { name: 'price' }
];

export const cancel_policy: string[] = ['12', '24', '48', '72'];

export const createAdminCoach: CreateCoachRequestType = {
  user_info: {
    full_name: '',
    email: '',
    phone: ''
  },
  coach_info: {
    date_start: dayjs(),
    date_end: dayjs(),
    price_per_hour: '',
    cancellation_policy: '',
    assigned_hours: ''
  },
  court_sport_ids: []
};
