import { Dispatch, SetStateAction } from 'react';
// components
import {
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Box,
  Accordion,
  AccordionDetails,
  FormHelperText,
  Tooltip,
  Button
} from '@mui/material';
import UploadImage from '@src/ui/containers/ImageUpload/ImageUpload';
import { AdvancedCourtSettings } from '@components/Forms/AdvancedCourtSettings/AdvancedCourtSettings';
//icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
//react form
import { Control, Controller } from 'react-hook-form';
//interfaces
import { CreateCourtRequest, RoofStatusType, SurfaceTypeType } from '@interfaces/court/court';
import { ImageType } from '@interfaces/Images/images';
//hooks
import { useTranslation } from 'react-i18next';

type NewFieldProps = {
  title: string;
  subtitle?: string;
  control: Control<CreateCourtRequest>;
  handleClick: Function;
  images: ImageType[];
  handleDelete: (id: number) => void;
  onDropCallback: (image: File) => void;
  uploadPercent: number;
  roofStatus: RoofStatusType[];
  surfaceType: SurfaceTypeType[];
  open: boolean;
  onClick: Dispatch<SetStateAction<boolean>>;
  showAdvanced?: boolean;
  edit?: boolean;
  onCancel: () => void;
};

const NewCourt = ({
  control,
  handleClick,
  images,
  handleDelete,
  onDropCallback,
  uploadPercent,
  roofStatus,
  surfaceType,
  open,
  onClick,
  title,
  subtitle,
  showAdvanced = false,
  edit = false,
  onCancel
}: NewFieldProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      {!open && (
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            onClick={() => onClick(!open)}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t('sportCenters.stepThree.newField.add_new_field_button')}
          </Button>
        </Grid>
      )}

      {(open || edit) && (
        <Accordion expanded={true}>
          <AccordionDetails sx={{ px: { md: 15, xs: 2 } }}>
            <Box>
              <Typography variant="h6" mt={2} sx={{ width: '100%' }}>
                {title}
              </Typography>
              <Typography variant="subtitle2" color="GrayText">
                {subtitle}
              </Typography>
            </Box>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: t('errorMessage').toString() }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <TextField
                      sx={{ width: '100%' }}
                      label={t('sportCenters.stepThree.newField.fieldName')}
                      variant="outlined"
                      {...field}
                      error={invalid}
                      helperText={error?.message}
                      id="fieldName"
                      onBlur={() => field.onBlur()}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="surface_type"
                  control={control}
                  rules={{ required: t('errorMessage').toString() }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel error={invalid}>
                        {t('sportCenters.stepThree.newField.surfaceType')}
                      </InputLabel>
                      <Select
                        {...field}
                        label={t('sportCenters.stepThree.newField.surfaceType')}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        error={invalid}
                        id="surface_type"
                      >
                        {surfaceType.map(surface => (
                          <MenuItem key={surface.value} value={surface.value}>
                            {surface.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="roof_status"
                  control={control}
                  rules={{ required: t('errorMessage').toString() }}
                  render={({ field, fieldState: { invalid, error } }) => (
                    <FormControl sx={{ width: '100%' }}>
                      <InputLabel error={invalid}>
                        {t('sportCenters.stepThree.newField.roofStatus')}
                      </InputLabel>
                      <Select
                        {...field}
                        label={t('sportCenters.stepThree.newField.roofStatus')}
                        variant="outlined"
                        sx={{ width: '100%' }}
                        error={invalid}
                        id="roof_status"
                      >
                        {roofStatus.map(roof => (
                          <MenuItem key={roof.value} value={roof.value}>
                            {roof.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && <FormHelperText error>{error.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <UploadImage
                  title={t('sportCenters.stepThree.newField.fieldPhotos')}
                  images={images}
                  onDropCallback={onDropCallback}
                  handleDelete={handleDelete}
                  uploadPercent={uploadPercent}
                />
              </Grid>
              {showAdvanced && <AdvancedCourtSettings control={control} />}
            </Grid>

            {edit ? (
              <Box display={'flex'} justifyContent="flex-end" my={2}>
                <Button color="error" size="small" onClick={() => onCancel()}>
                  {t('cancel')}
                </Button>
                <Button
                  color="success"
                  size="small"
                  variant="contained"
                  onClick={() => handleClick()}
                >
                  {t('confirm')}
                </Button>
              </Box>
            ) : (
              <Box display={'flex'} justifyContent="flex-end" my={2}>
                <Button sx={{ color: 'gray', mr: 1 }} size="small" onClick={() => onCancel()}>
                  {t('cancel')}
                </Button>
                <Tooltip
                  arrow
                  title={t('sportCenters.stepThree.newField.tooltip')}
                  placement="right"
                >
                  <Button variant="contained" onClick={() => handleClick()}>
                    {t('add')}
                  </Button>
                </Tooltip>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default NewCourt;
