// hooks
import { MouseEventHandler, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useResetRecoilState } from 'recoil';

// interfaces
import { SubmitHandler } from 'react-hook-form';
import {
  AdminEmployeeType,
  AdminEmployeesKeyType,
  CreateAdminEmployeeErrorResponseType,
  CreateAdminEmployeeType,
  createAdminEmployeeErrorKeys
} from '@interfaces/employees/employees';
import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';

// atoms
import { popupAtom } from '@atoms/popupAtom';

// mutations
import {
  useCreateAdminEmployee,
  useUpdateAdminEmployee,
  useDeleteAdminEmployee
} from '@api/mutations/cooperation/employee/employee';

// queries
import { useAllRoles } from '@api/queries/roles/roles';
import { useSingleSportCenterEmployee } from '@api/queries/cooperation/employee/employee';

// mock
import { createAdminEmployee, adminEmplyeeKey } from '@src/__mock__/employees/employees';

const useAdminEmployees = () => {
  const [employeeData, setEmployeeData] = useState<CreateAdminEmployeeType>(createAdminEmployee);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const sportCenterId = params.sportCenterId ? params.sportCenterId : '';
  const employeeId = params?.employeeID ? params?.employeeID : '';
  const [popup, setPopup] = useRecoilState(popupAtom);
  const resetPopup = useResetRecoilState(popupAtom);
  const [successAlert, setSuccessAlert] = useState('');
  const [employeeEditData, setEmployeeEditData] =
    useState<AdminEmployeesKeyType[]>(adminEmplyeeKey);

  const [openEdit, setOpenEdit] = useState<boolean>(false);

  const onClose = () => {
    setOpenEdit(false);
  };
  const {
    handleSubmit,
    control,
    getValues,
    setError,
    formState: { isDirty, isValid },
    reset
  } = useForm<CreateAdminEmployeeType>({
    defaultValues: employeeData,
    mode: 'onBlur'
  });

  // Roles
  const onRolesError = (err: Error) => {
    setPopup({
      open: true,
      title: '',
      content: err.message,
      variant: 'error'
    });
  };

  const { data: sportCenterRolesData, isLoading: isLoadingRoles } = useAllRoles(
    onRolesError,
    +sportCenterId
  );

  // Create employee
  const onCreateAdminEmployeeSuccess = (data: AdminEmployeeType) => {
    setPopup({
      open: true,
      title: '',
      content: `${t('adminEmployeePage.create.success_message')}`,
      variant: 'success'
    });
    navigate(`/employees/${sportCenterId}`);
    setTimeout(() => resetPopup(), 2000);
  };

  const onCreateAdminEmployeeError = (err: CreateAdminEmployeeErrorResponseType) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as createAdminEmployeeErrorKeys,
          {
            type: 'custom',
            message: err.errors[key].join(', ')
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  };

  const { mutate: createAdminEmploye, isLoading: isLoadingCreateAdminEmployee } =
    useCreateAdminEmployee(onCreateAdminEmployeeSuccess, onCreateAdminEmployeeError);

  // update admin employee
  const onUpdateAdminEmployeeSuccess = (data: AdminEmployeeType) => {
    refatchSingleEmployee();
    setOpenEdit(false);
    setPopup({
      open: true,
      title: '',
      content: `${t('adminEmployeePage.edit.success_message')}`,
      variant: 'success'
    });
    setTimeout(() => resetPopup(), 2000);
  };

  const { mutate: updateAdminEmploye, isLoading: isLoadingUpdateAdminEmployee } =
    useUpdateAdminEmployee(onUpdateAdminEmployeeSuccess, onCreateAdminEmployeeError);

  // single employee
  const onSingleEmployeeSuccess = (data: AdminEmployeeType) => {
    setEmployeeEditData([
      {
        name: 'name',
        value: data.name
      },
      {
        name: 'email',
        value: data.email
      },
      {
        name: 'phone',
        value: data.phone
      },
      {
        name: 'access',
        value: data.role.name
      }
    ]);
    reset({
      user_info: {
        full_name: data.name,
        email: data.email,
        phone: data.phone
      },
      role_id: data.role.id
    });
  };

  const onSingleEmployeeError = (err: CreateAdminEmployeeErrorResponseType) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { refetch: refatchSingleEmployee, isLoading: isLoadingSingleEmployee } =
    useSingleSportCenterEmployee(
      {
        sportCenterId: +sportCenterId,
        employeeMembershipId: +employeeId
      },
      onSingleEmployeeSuccess,
      onSingleEmployeeError
    );

  // delete employee
  const onDeleteSingleEmployeeSuccess = (data: SuccessResponse) => {
    setPopup({
      open: true,
      title: '',
      content: `${t('adminEmployeePage.delete.success_message')}`,
      variant: 'success'
    });
    navigate(`/employees/${sportCenterId}`);
    setTimeout(() => resetPopup(), 2000);
  };
  const onDeleteSingleEmployeeError = (
    err: CreateAdminEmployeeErrorResponseType | ErrorResponse
  ) => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  };

  const { mutate: deleteEmployee, isLoading: isLoadingDeleteSingleEmployee } =
    useDeleteAdminEmployee(onDeleteSingleEmployeeSuccess, onDeleteSingleEmployeeError);

  // handle Functions

  const handleCancel = () => {
    if (employeeId) {
      onClose();
    } else {
      navigate(`/employees/${sportCenterId}`);
    }
  };

  const onEditEmployee = (row?: AdminEmployeeType): void => {
    if (employeeId) {
      setOpenEdit(true);
    } else if (row) {
      navigate(`edit-employee/${row.id}`);
    }
  };

  const onDeleteEmployee: MouseEventHandler<HTMLButtonElement> = e => {
    setPopup({
      open: true,
      title:
        t('adminEmployeePage.delete.alert_message').toString() +
        ' ' +
        getValues('user_info.full_name') +
        '?',
      content: '',
      variant: 'info',
      onClick: () =>
        deleteEmployee({ sportCenterId: +sportCenterId, employeeMembershipId: +employeeId })
    });
  };

  // submiting admin employees form

  const onSubmitEmployee: SubmitHandler<CreateAdminEmployeeType> = async (data, e) => {
    if (employeeId && sportCenterId) {
      // handle edit
      updateAdminEmploye({
        sportCenterId: +sportCenterId,
        data: data,
        employeeMembershipId: +employeeId
      });
    } else {
      createAdminEmploye({
        sportCenterId: +sportCenterId,
        data: data
      });
    }
  };
  return {
    handleSubmit: handleSubmit(onSubmitEmployee),
    control,
    handleCancel,
    t,
    isDirty,
    isValid,
    sportCenterId,
    employeeId,
    onEditEmployee,
    employeeEditData,
    openEdit,
    sportCenterRolesData: sportCenterRolesData ? sportCenterRolesData.data.data : [],
    onClose,
    isLoadingCreateAdminEmployee,
    isLoadingRoles,
    isLoadingSingleEmployee,
    isLoadingUpdateAdminEmployee,
    isLoadingDeleteSingleEmployee,
    successAlert,
    onDeleteEmployee,
    popup
  };
};

export default useAdminEmployees;
