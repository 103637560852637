// react-query
import { useMutation, UseMutationResult } from '@tanstack/react-query';
// interfaces
import {
  CoachErrorResponseType,
  CoachErrorType,
  CoachResponseType,
  CreateCoachRequestParamsType,
  SingleCoachRequestType,
  CreateCoachRequestType
} from '@interfaces/user/coaches/coaches';
import { ErrorRes, ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
// services
import { createCoach, deleteCoach, getSingleCoach, updateCoach } from '@services/coaches/coaches';

export const useCreateCoach = (
  onSuccess: (data: CoachResponseType) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: CoachResponseType }, // response
  ErrorRes,
  CreateCoachRequestParamsType, //request
  (data: CoachResponseType) => void // on success
> =>
  useMutation({
    mutationFn: createCoach,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useSingleCoach = (
  onSuccess: (data: CoachResponseType) => void,
  onError: (err: CoachErrorType) => void
): UseMutationResult<
  { data: CoachResponseType }, // response
  CoachErrorResponseType,
  SingleCoachRequestType, //request
  (data: CoachResponseType) => void // on success
> =>
  useMutation({
    mutationFn: getSingleCoach,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
export const useUpdateSingleCoach = (
  onSuccess: (data: CoachResponseType) => void,
  onError: (err: ErrorResponse) => void
): UseMutationResult<
  { data: CoachResponseType }, // response
  ErrorRes,
  SingleCoachRequestType & { data: CreateCoachRequestType }, //request
  (data: CoachResponseType) => void // on success
> =>
  useMutation({
    mutationFn: updateCoach,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });

export const useDeleteCoach = (
  onSuccess: (data: SuccessResponse) => void,
  onError: (err: CoachErrorType) => void
): UseMutationResult<
  { data: SuccessResponse }, // response
  CoachErrorResponseType,
  SingleCoachRequestType, //request
  (data: SuccessResponse) => void // on success
> =>
  useMutation({
    mutationFn: deleteCoach,
    onSuccess: data => onSuccess(data.data),
    onError: err => onError(err.response.data)
  });
