import { Dispatch, ReactNode, SetStateAction } from 'react';
//components
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
//icons
import { ArrowDownIcon, ArrowUpIcon } from '@components/icons/icons';
import { TabEnum } from '@src/domain/enum/tabEnum';

type TabButtonProps = {
  text: string;
  down: boolean;
  icon: ReactNode;
  onClick: Dispatch<SetStateAction<TabEnum>>;
  index: TabEnum;
  dataTestId?: string;
  children?: ReactNode;
  coming_soon?: string;
};

export const TabButton = ({
  text,
  down,
  icon,
  onClick,
  index,
  children,
  dataTestId,
  coming_soon
}: TabButtonProps) => {
  return (
    <>
      <ListItem sx={{ opacity: down ? 1 : 0.7 }} dense>
        <ListItemButton onClick={() => onClick(down ? TabEnum.None : index)}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} secondary={coming_soon && coming_soon} />
          <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
            {down ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </ListItemIcon>
        </ListItemButton>
      </ListItem>
      {down && <Box data-testid={dataTestId}>{children}</Box>}
    </>
  );
};
