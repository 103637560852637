//react
import { useMemo, FC } from 'react';
//react-router
import { useNavigate, NavLink } from 'react-router-dom';
//hooks
import useTablesLogic from '@hooks/tables/useTablesLogic';
import { useTranslation } from 'react-i18next';
// queries
import { useAllCoachesQuery } from '@api/queries/coaches/coaches';
//interfaces
// TODO: return this after BE finishes
// import { ErrorResponse, SuccessResponse } from '@interfaces/apiResponse';
import { CoachType } from '@interfaces/user/coaches/coaches';
//containers
import TableHeaderActions from '@src/ui/containers/TableHeaderAction/TableHeaderActions';
//components
import Loading from '@components/Loading/Loading';
//layout
import { TableLayout } from '@layout/TableLayout/TableLayout';
//material-react-table
import { type MRT_ColumnDef } from 'material-react-table';
//mui
import { Alert, Card, Grid, Tooltip } from '@mui/material';
//recoil
import { useRecoilValue } from 'recoil';
//atom
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermissionFunc } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';

const SuperUsersPage: FC = (): JSX.Element | null => {
  const currentCMS = useRecoilValue(currentCMSAtom);
  const { t } = useTranslation();
  // TODO: add delete coaches when BE finishes

  // const onDeleteSuccesss = (data: SuccessResponse) => {
  //   refetch();
  //   console.log(data.message);
  //   setSuccessfullyDeletedPopup(true);
  // };

  // const onDeleteError = (err: ErrorResponse) => {
  //   console.log(err.message);
  // };
  // const { mutate: deleteUser } = useDeleteUser(onDeleteSuccesss, onDeleteError);

  // const { mutate: deleteUsers } = useDeleteCoach(onDeleteSuccesss, onDeleteError);

  const {
    pagination,
    setPagination,
    tableInstanceRef,
    sendEmailEventHandler,
    deleteEventHandler,
    deletedNames,
    deleteIds,
    handleSearch,
    sortBy,
    sortDirection,
    handleSortTables,
    checkSort,
    handleClearSort,
    successfullyDeletedPopup,
    setSuccessfullyDeletedPopup,
    handleSubmitFilter,
    filterValues,
    controlFilter,
    filterValue,
    handleClearFilter,
    anchorSortEl,
    anchorEl,
    anchorMenuItem,
    anchorElActionButton,
    handleClick,
    handleClose
  } = useTablesLogic(undefined, undefined, 'superAdminUsers');

  const { data: allCoachesData, isLoading: isLoadingAllCoaches } = useAllCoachesQuery(
    pagination.pageIndex + 1,
    pagination.pageSize,
    filterValues.search,
    filterValues.gender,
    sortBy,
    sortDirection
  );

  const columnsCoaches = useMemo<MRT_ColumnDef<CoachType | {}>[]>(
    () => [
      {
        accessorKey: 'name',
        header: t('name_and_surname'),
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ cell, row }) => {
          const rowType = row.original as CoachType;
          return (
            <NavLink to={`superuser/${rowType.id}`} style={{ color: 'black' }}>
              {cell.getValue<string>()}{' '}
            </NavLink>
          );
        }
      },
      {
        accessorKey: 'phone',
        header: t('phone'),
        enableEditing: false,
        enableColumnOrdering: false
      },
      {
        accessorKey: 'email',
        header: t('email'),
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as CoachType;
          return <span>{rowType.email}</span>;
        }
      },
      {
        accessorKey: 'sport_centers',
        header: t('object'),
        enableEditing: false,
        enableColumnOrdering: false,
        Cell: ({ row }) => {
          const rowType = row.original as CoachType;
          let tempArrOfSCNames: string[] = [];
          rowType.sport_centers &&
            rowType.sport_centers.map(spo => {
              tempArrOfSCNames.push(spo.name);
            });
          return (
            <Tooltip title={tempArrOfSCNames.join('; ')} placement="top">
              <span>
                {tempArrOfSCNames.join('; ').length > 30
                  ? tempArrOfSCNames.join('; ').slice(0, 20) + '...'
                  : tempArrOfSCNames.join('; ')}
              </span>
            </Tooltip>
          );
        }
      }
    ],
    [t]
  );
  return (
    <Grid container>
      {successfullyDeletedPopup && deleteIds.length > 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('users')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('superAdminUsers.successMultiple')}
        </Alert>
      ) : successfullyDeletedPopup && deleteIds.length === 1 ? (
        <Alert
          severity="success"
          sx={{ position: 'sticky', zIndex: 200, width: '95%', m: 'auto', mt: 2 }}
        >
          {t('user')}{' '}
          <span>{deletedNames.length > 30 ? deletedNames.slice(0, 30) + '...' : deletedNames}</span>{' '}
          {t('superAdminUsers.successSingle')}
        </Alert>
      ) : null}
      <Card sx={{ mx: 3 }} className="fullWidth">
        <TableHeaderActions
          disableActions={!deletedNames}
          tableVariant="superUsers"
          // sendEmailEventHandler={sendEmailEventHandler} //TODO: return after MVP
          deleteEventHandler={checkPermissionFunc(
            currentCMS,
            PermissionsEnum.delete_users,
            deleteEventHandler
          )}
          handleSearch={handleSearch}
          //sort
          handleSortTables={handleSortTables}
          checkSort={checkSort}
          handleClearSort={handleClearSort}
          //filter
          handleClearFilter={handleClearFilter}
          handleClick={handleClick}
          handleClose={handleClose}
          anchorFilterEl={anchorEl}
          anchorElActionButton={anchorElActionButton}
          anchorMenuItem={anchorMenuItem}
          anchorSortEl={anchorSortEl}
          controlFilter={controlFilter}
          handleSubmitFilter={handleSubmitFilter}
          filterValue={filterValue}
        />

        <Grid item lg={12} mx={'20px'}>
          {allCoachesData && !isLoadingAllCoaches ? (
            <TableLayout
              tableVariant="superUsers"
              rows={allCoachesData.data.data}
              columns={columnsCoaches}
              pagination={pagination}
              setPagination={setPagination}
              rowCount={allCoachesData?.data?.meta?.total}
              tableInstanceRef={tableInstanceRef}
              withCheckbox
            />
          ) : (
            <Loading />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default SuperUsersPage;
