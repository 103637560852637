//types
import {
  NonWorkingDaysError,
  NonWorkingDaysRequestType,
  NonWorkingDaysType
} from '@interfaces/nonWorkingDays/nonWorkingDays';
//hooks
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSingleSportCenter } from '@api/queries/sportCenter/sportCenter';
//api
import { useDeleteNonWorkingDays } from '@api/mutations/nonWorkingDays/nonWorkingDays';
import { useAllNonWorkingDay } from '@api/queries/nonWorkingDays/nonWorkingDays';
import { useSingleNonWorkingDay } from '@api/queries/nonWorkingDays/nonWorkingDays';
import {
  useCreateNonWorkingDays,
  useUpdateNonWorkingDays
} from '@api/mutations/nonWorkingDays/nonWorkingDays';
//react form
import { useForm, useWatch } from 'react-hook-form';
//helpers
import dayjs from 'dayjs';
import { getStartTimeForAppointmentBasedOnTheGivenDate } from '@helpers/utility';
//recoil
import { useSetRecoilState } from 'recoil';
//atoms
import { popupAtom } from '@atoms/popupAtom';

export function useNonWorkingDays() {
  const { ruleId, sportCenterId } = useParams();

  const [values, setValues] = useState<NonWorkingDaysRequestType | undefined>(undefined);

  const [success, setSuccess] = useState('');

  const setPopup = useSetRecoilState(popupAtom);

  const { t } = useTranslation();

  const lng = localStorage.getItem('lng');

  const [timePickerTimes, setTimePickerTimes] = useState<{ minTime: number; maxTime: number }>({
    minTime: 0,
    maxTime: 0
  });

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setError,
    reset,
    setValue
  } = useForm<NonWorkingDaysRequestType>({
    defaultValues: {
      court_ids: [],
      date_start: dayjs().toString(),
      date_end: dayjs().toString(),
      time_start: '',
      time_end: '',
      name_translates: {
        en: '',
        sr: ''
      }
    },
    values: values
  });

  const selectedDateStart = useWatch({
    control: control,
    name: 'date_start'
  });

  const handleError = (err: Error) => {
    setPopup({
      open: true,
      variant: 'error',
      title: err.message,
      content: ''
    });
  };

  const handleCreateError = (err: NonWorkingDaysError) => {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as keyof NonWorkingDaysRequestType,
          {
            type: 'custom',
            message: err.errors[key as keyof NonWorkingDaysRequestType]?.join()
          },
          {
            shouldFocus: true
          }
        );
      }
    } else
      setPopup({
        open: true,
        variant: 'error',
        title: err.message,
        content: ''
      });
  };

  const onDeleteSuccess = () => {
    setSuccess(t('non_working_days.successDelete').toString());
    refetch();
    setTimeout(() => {
      setSuccess('');
    }, 2000);
  };

  const onCreateSuccess = () => {
    reset();

    if (ruleId) {
      refetchSingle();
      setSuccess(t('non_working_days.successEdit').toString());
    } else {
      setSuccess(t('non_working_days.successCreate').toString());
    }

    setTimeout(() => setSuccess(''), 1000);
  };

  const { isLoading: isLoadingSingle, refetch: refetchSingle } = useSingleNonWorkingDay(
    sportCenterId,
    ruleId,
    (data: NonWorkingDaysType) => {
      const curr: NonWorkingDaysRequestType = {
        name: data.name,
        date_start: data.date_start,
        date_end: data.date_end,
        time_start: data.time_start,
        time_end: data.time_end,
        court_ids: data.courts.map(c => c.id.toString()),
        name_translates: data.name_translates
      };
      setValues(curr);
    },
    handleError
  );

  const { data, refetch } = useAllNonWorkingDay(sportCenterId, handleError);

  const { mutate: deleteNonWorkingDay, isLoading: isLoadingDelete } = useDeleteNonWorkingDays(
    onDeleteSuccess,
    handleError
  );
  const { mutate: createNonWorkingDays } = useCreateNonWorkingDays(
    onCreateSuccess,
    handleCreateError
  );
  const { mutate: updateNonWorkingDays } = useUpdateNonWorkingDays(
    onCreateSuccess,
    handleCreateError
  );

  const onSubmit = (data: NonWorkingDaysRequestType) => {
    if (values) {
      if (sportCenterId && ruleId)
        updateNonWorkingDays({
          ...data,
          name: lng === 'en' ? data.name_translates.en : data.name_translates.sr,
          date_start: dayjs(data.date_start).format('YYYY-MM-DD'),
          date_end: dayjs(data.date_end).format('YYYY-MM-DD'),
          sportCenterId: +sportCenterId,
          id: +ruleId
        });
    } else {
      if (sportCenterId)
        createNonWorkingDays({
          ...data,
          name: lng === 'en' ? data.name_translates.en : data.name_translates.sr,
          date_start: dayjs(data.date_start).format('YYYY-MM-DD'),
          date_end: dayjs(data.date_end).format('YYYY-MM-DD'),
          sportCenterId: +sportCenterId
        });
    }
  };

  const { data: sportCenterData } = useSingleSportCenter(sportCenterId);
  //created this variable only that I can use helper function to get available start and end times for non-working day
  let workingHoursObject = {
    workingHours: sportCenterData?.data.working_hours,
    calendarHourMin: '',
    calendarHourMax: ''
  };

  useEffect(() => {
    setTimePickerTimes(
      getStartTimeForAppointmentBasedOnTheGivenDate(dayjs(selectedDateStart), workingHoursObject)
    );
  }, [selectedDateStart]);

  useEffect(() => {
    const minTimeString =
      timePickerTimes.minTime < 10
        ? `0${timePickerTimes.minTime}:00`
        : `${timePickerTimes.minTime}:00`;
    const maxTimeString =
      timePickerTimes.maxTime < 10
        ? `0${timePickerTimes.maxTime}:00`
        : `${timePickerTimes.maxTime}:00`;
    timePickerTimes.minTime && setValue('time_start', minTimeString);
    timePickerTimes.maxTime && setValue('time_end', maxTimeString);
  }, [timePickerTimes]);

  return {
    deleteNonWorkingDay,
    nonWorkingDays: data ? data.data.data : [],
    handleSubmit: handleSubmit(onSubmit),
    isLoadingSingle,
    control,
    isValid,
    setValue,
    isLoadingDelete,
    success,
    sportCenterId,
    lng,
    timePickerTimes
  };
}
