//react
import React from 'react';
//router
import { Link, useNavigate } from 'react-router-dom';
//interfaces
import { RecoverPasswordErrorResponse, RecoverPasswordResponse } from '@interfaces/user/login';
//mutations
import { useRecoverPassword } from '@api/mutations/user/login';
//mui
import Button from '@mui/material/Button';
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
  FormHelperText,
  Box
} from '@mui/material';
//form hooks
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
//recoil
import { useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
//translate
import { useTranslation } from 'react-i18next';

const schema = yup
  .object({
    email: yup.string().email().required('Nevalidna email adresa')
  })
  .required();

type FormData = yup.InferType<typeof schema>;

const RecoverPassword: React.FC = (): JSX.Element => {
  const navigate = useNavigate();

  const { t: translation } = useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    trigger,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: ''
    }
  });

  const setPopup = useSetRecoilState(popupAtom);

  const onSuccess = (data: RecoverPasswordResponse): void => {
    setPopup({
      open: true,
      title: translation('resetPassword.successTitle'),
      content: translation('resetPassword.successContent'),
      variant: 'success',
      onClose: () => {
        navigate('/login');
      }
    });
  };

  const onError = (err: RecoverPasswordErrorResponse): void => {
    if ('email' in err.response.data.errors) {
      setError('email', { type: 'custom', message: err.response.data.errors.email[0] });
    } else {
      setPopup({
        open: true,
        title: translation('error.title'),
        content: '',
        variant: 'error'
      });
    }
  };

  const { mutate: submitRecoverPassword } = useRecoverPassword(onSuccess, onError);

  const submitForm: SubmitHandler<FormData> = async (data, e) => {
    e?.preventDefault();

    let isValid = await trigger(['email']);

    if (isValid) {
      submitRecoverPassword(data);
    }
  };

  return (
    <Box width={{ xs: '80%', sm: '70%' }} mt={{ xs: 4, sm: 0 }}>
      <Typography
        variant="h4"
        fontSize={{ xs: '36px', sm: '48px' }}
        mb={{ xs: -4, sm: 0 }}
        sx={{
          textAlign: 'left',
          fontWeight: 500,
          letterSpacing: '-3.5px'
        }}
      >
        {translation('resetPassword.title')}
      </Typography>
      <Box
        component={'form'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '42px',
          maxWidth: '430px'
        }}
        onSubmit={handleSubmit(submitForm)}
      >
        <Typography mb={1}>{translation('email')}</Typography>
        <FormControl variant="outlined" sx={{ maxWidth: '430px' }}>
          <InputLabel htmlFor="outlined-email" error={!!errors.email}>
            Email
          </InputLabel>
          <OutlinedInput
            label="Email"
            {...register('email')}
            error={!!errors.email}
            sx={{ background: '#F5F7FA' }}
          />
          <FormHelperText error>{errors.email?.message}</FormHelperText>
        </FormControl>

        <Link to={'/login'} style={{ textDecoration: 'none' }}>
          <Typography
            variant="body2"
            color="#3D3D3D"
            sx={{
              textAlign: 'right'
            }}
          >
            {translation('resetPassword.backToLogin')}
          </Typography>
        </Link>

        <Button
          type="submit"
          variant="contained"
          sx={{ my: 3, height: '56px', fontWeight: 700, fontSize: '16px', maxWidth: '430px' }}
        >
          {translation('resetPassword.button')}
        </Button>

        <Typography variant="caption" color="text.secondary">
          {translation('resetPassword.helperInfo')}
        </Typography>
      </Box>
    </Box>
  );
};

export default RecoverPassword;
