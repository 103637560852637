import { useMemo } from 'react';
// components
import { Alert, Box, Button, IconButton } from '@mui/material';
import NewCourt from '@components/Forms/SportCenters/NewCourt/NewCourt';
import NewSport from '@components/Forms/SportCenters/NewSport/NewSport';
import DataCard from '@components/DataCard/DataCard';
//interface
import { CourtSportType, CourtType } from '@interfaces/court/court';
import { MRT_ColumnDef } from 'material-react-table';
//icons
import { DeleteIcon, EditIcon } from '@components/icons/icons';
//hooks
import { useCreateCportCenterStep3 } from '@src/ui/hooks/createSportCenterStep3/useCreateSportCenterStep3';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';
//atom
import { currentCMSAtom } from '@atoms/currentCMS';
//helpers
import { checkPermission } from '@helpers/utility';
//enum
import { PermissionsEnum } from '@enum/permissionsEnum';
import { RoleEnum } from '@enum/roleEnum';
// atoms
import { popupAtom } from '@atoms/popupAtom';

type StepThreePropsType = {
  hideButtons?: boolean;
};
const StepThree = ({ hideButtons }: StepThreePropsType) => {
  const setPopup = useSetRecoilState(popupAtom);

  const {
    deleteCourt,
    courts,
    controlField,
    handleSubmitField,
    successField,
    sports,
    images,
    onDropCallback,
    handleDelete,
    uploadPercent,
    onEditCourt,
    controlSport,
    handleSubmitSport,
    detachSport,
    roofStatus,
    surfaceType,
    handleNext,
    handleBack,
    setValueSport,
    customFields,
    onEditSport,
    openCourt,
    setOpenCourt,
    openSport,
    setOpenSport,
    editSport,
    handleCancelEditSport,
    editCourt,
    handleCancelEditCourt,
    sportCenterId,
    courtSport
  } = useCreateCportCenterStep3();

  const currentCMS = useRecoilValue(currentCMSAtom);
  const { t, i18n } = useTranslation();

  const fieldColumns = useMemo<MRT_ColumnDef<CourtType>[]>(
    () => [
      {
        accessorKey: 'name',
        header: ''
      },
      {
        accessorKey: 'surface_type.label',
        header: ''
      },
      {
        accessorKey: 'roof_status.label',
        header: ''
      },
      {
        accessorKey: 'id',
        header: '',
        Cell: ({ row }) => (
          <Box width={'100%'} display="flex" justifyContent={'space-evenly'}>
            <IconButton onClick={() => onEditCourt(row.original.id)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                setPopup({
                  open: true,
                  variant: 'delete',
                  title:
                    t('sportCenters.stepThree.newField.deleteMessage').toString() +
                    row.original.name +
                    '?',
                  content: '',
                  onClick: () =>
                    deleteCourt({ sportCenterId: Number(sportCenterId), courtId: row.original.id })
                })
              }
            >
              <DeleteIcon fill="white" stroke="#707070" />
            </IconButton>
          </Box>
        )
      }
    ],
    []
    //end
  );

  const sportColumns = useMemo<MRT_ColumnDef<CourtSportType>[]>(
    () => [
      {
        accessorKey: 'sportName',
        header: ''
      },
      {
        accessorKey: 'courtName',
        header: ''
      },
      {
        accessorKey: 'roof_status',
        header: ''
      },
      {
        accessorKey: 'courtId',
        header: '',
        Cell: ({ row }) => (
          <Box width={'100%'} display="flex" justifyContent={'space-evenly'}>
            <IconButton onClick={() => onEditSport(row.original)}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={() =>
                setPopup({
                  open: true,
                  variant: 'delete',
                  title:
                    t('sportCenters.stepThree.newSport.deleteMessage').toString() +
                    row.original.sportName +
                    '?',
                  content: '',
                  onClick: () =>
                    sportCenterId &&
                    detachSport({
                      sportId: row.original.sportId,
                      courtId: row.original.courtId,
                      sportCenterId: sportCenterId.toString()
                    })
                })
              }
            >
              <DeleteIcon fill="white" stroke="#707070" />
            </IconButton>
          </Box>
        )
      }
    ],
    []
    //end
  );

  return (
    <Box sx={{ padding: '2% 5%' }} display="flex" flexDirection={'column'} gap={3}>
      {successField && (
        <Alert severity="success" sx={{ position: 'fixed', zIndex: 200, width: '70%' }}>
          {successField}
        </Alert>
      )}
      {courts.length !== 0 && (
        <DataCard
          columns={fieldColumns}
          data={courts}
          title={t('sportCenters.stepThree.createdFields.title')}
          subtitle={t('sportCenters.stepThree.createdFields.subtitle').toString()}
        />
      )}

      <NewCourt
        title={
          editCourt
            ? t('sportCenters.stepThree.editField.title')
            : t('sportCenters.stepThree.newField.title')
        }
        subtitle={
          editCourt
            ? t('sportCenters.stepThree.editField.subtitle').toString()
            : t('sportCenters.stepThree.newField.subtitle').toString()
        }
        control={controlField}
        handleClick={handleSubmitField}
        images={images}
        onDropCallback={onDropCallback}
        handleDelete={handleDelete}
        uploadPercent={uploadPercent}
        surfaceType={surfaceType}
        roofStatus={roofStatus}
        open={openCourt}
        onClick={setOpenCourt}
        showAdvanced={currentCMS?.role === RoleEnum.ADMIN}
        edit={editCourt}
        onCancel={handleCancelEditCourt}
      />

      {courtSport?.length !== 0 && (
        <DataCard
          columns={sportColumns}
          data={courtSport ? courtSport : []}
          title={t('sportCenters.stepThree.createdSports.title')}
          subtitle={t('sportCenters.stepThree.createdSports.subtitle').toString()}
        />
      )}

      <NewSport
        title={
          editSport
            ? t('sportCenters.stepThree.editSport.title').toString()
            : t('sportCenters.stepThree.newSport.title').toString()
        }
        subtitle={
          editSport
            ? t('sportCenters.stepThree.editSport.subtitle').toString()
            : t('sportCenters.stepThree.newSport.subtitle').toString()
        }
        control={controlSport}
        fields={courts}
        sports={sports}
        setValueSport={setValueSport}
        customFields={customFields}
        editSport={editSport}
        disabled={courts.length === 0}
        open={openSport}
        handleClick={() => handleSubmitSport()}
        onClick={setOpenSport}
        onCancel={handleCancelEditSport}
        sportCenterId={sportCenterId}
      />

      {!hideButtons && (
        <Box display="flex" flexDirection="row" pt={2} justifyContent="flex-end" gap={2}>
          {checkPermission(currentCMS, PermissionsEnum.update_sport_centers) && (
            <Button variant="text" onClick={handleBack}>
              {t('back')}
            </Button>
          )}

          <Button
            variant="contained"
            onClick={handleNext}
            disabled={courts.length === 0 || sports.length === 0}
          >
            {t('next')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default StepThree;
