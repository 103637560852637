//react
import { FC } from 'react';
//recoil
import { useRecoilValue } from 'recoil';
//atoms
import { userAtom } from '@atoms/userAtom';
import { currentCMSAtom } from '@atoms/currentCMS';
//components
import CustomSelect from '@components/CustomSelect/CustomSelect';
//hooks
import { useProfileMenu } from '@hooks/useProfileMenu/useProfileMenu';
import { useSidemenu } from '@hooks/useSidemenu/useSidemenu';
import { useTranslation } from 'react-i18next';
//icons
import { ContactIcon, LogOutIcon, Logo } from '@components/icons/icons';
import CloseIcon from '@mui/icons-material/Close';
//mui
import { Box, Button, Drawer, Grid, IconButton, Link, MenuItem, Typography } from '@mui/material';
// colors
import { greenSelectBgColor, greenSelectColor } from '@src/theme';
interface MobileMenuProps {
  anchor: 'bottom' | 'left' | 'right' | 'top';
  open: boolean;
  onClose: () => void;
}

const MobileMenu: FC<MobileMenuProps> = ({ anchor, open, onClose }): JSX.Element => {
  const user = useRecoilValue(userAtom);
  const currentCMS = useRecoilValue(currentCMSAtom);
  const { t: translation } = useTranslation();
  const { handleChange, submitLogout } = useProfileMenu();
  const { getSidemenu } = useSidemenu();
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      PaperProps={{
        sx: {
          width: '100%',
          display: { xs: 'flex', sm: 'none' }
        }
      }}
      variant="temporary"
    >
      <Grid
        container
        alignItems={'center'}
        height={80}
        justifyContent={'space-between'}
        borderBottom={'1px solid #D9D9D9'}
      >
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} ml={1}>
          <Logo />
          <Typography ml={0.5} color={'#4F2A5B'} fontWeight={600} fontSize={'12px'}>
            SportyMate
          </Typography>
        </Box>
        <Box display={'flex'} flexDirection={'row'} mr={1}>
          {!!(
            user?.owned_sport_centers?.length ||
            user?.sport_centers_with_role.length ||
            user?.coach_in_sport_centers.length
          ) && (
            <CustomSelect
              value={currentCMS ? currentCMS.currentSportCenter : ''}
              onChange={handleChange}
              color={greenSelectColor}
              bgColor={greenSelectBgColor}
              fontSize="14px"
            >
              {!user.impersonating && (user.is_admin || user.role) && (
                <MenuItem value={'-1'} key={''}>
                  superadmin
                </MenuItem>
              )}

              {user?.owned_sport_centers?.map(sc => (
                <MenuItem value={sc.id} key={sc.id} sx={{ fontSize: '14px' }}>
                  {sc.name.length > 11 ? `${sc.name.substring(0, 11)}..` : sc.name}
                </MenuItem>
              ))}
              {user?.sport_centers_with_role?.map(sc => (
                <MenuItem value={sc.id} key={sc.id} sx={{ fontSize: '14px' }}>
                  {sc.name.length > 11 ? `${sc.name.substring(0, 11)}..` : sc.name}
                </MenuItem>
              ))}
              {user?.coach_in_sport_centers?.map(sc => (
                <MenuItem value={sc.id} key={sc.id} sx={{ fontSize: '14px' }}>
                  {sc.name.length > 11 ? `${sc.name.substring(0, 11)}..` : sc.name}
                </MenuItem>
              ))}
            </CustomSelect>
          )}
          <IconButton
            id="CloseIconCalendarMobile"
            data-testid="CloseIconCalendarMobile"
            sx={{
              borderRadius: '6px',
              border: '1px solid #CACCCF',
              color: 'black',
              ml: 1
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Grid>
      <Box mt={1} height={'60%'} sx={{ overflowY: 'auto' }}>
        {getSidemenu()}
      </Box>
      <Grid container display={'flex'} justifyContent={'center'} position={'fixed'} bottom={40}>
        <Grid item xs={11}>
          <Button sx={{ width: '100%', padding: '6px, 16px, 6px, 16px', boxShadow: 3 }}>
            <ContactIcon />
            <Typography color={'black'} ml={1.5}>
              {translation('contact')}
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={11} mt={1.5}>
          <Button
            sx={{ width: '100%', padding: '6px, 16px, 6px, 16px', boxShadow: 3 }}
            onClick={submitLogout}
          >
            <LogOutIcon />
            <Typography color={'black'} ml={1.5}>
              {translation('log_out')}
            </Typography>
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          mt={1.5}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'center'}
        >
          <Link
            href="https://sportymate.rs/politika-privatnosti"
            fontFamily={'inter'}
            fontSize={'14px'}
            sx={{ textDecoration: 'underline' }}
          >
            {translation('privacy_policy')}
          </Link>
          <Typography ml={1} mr={1} mt={-0.3} fontSize={'14px'}>
            &
          </Typography>
          <Link
            href="https://sportymate.rs/uslovi-koriscenja"
            fontFamily={'inter'}
            fontSize={'14px'}
            sx={{ textDecoration: 'underline' }}
          >
            {translation('terms_of_use')}
          </Link>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default MobileMenu;
