//hooks
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
//types
import {
  CreateEmployeeErrorType,
  Employee,
  EmployeeCreateRequest,
  EmployeePositionType
} from '@interfaces/employees/employees';
import { ImageType } from '@interfaces/Images/images';
//api
import {
  useAllPositions,
  useAllSectors,
  useSingleEmployee
} from '@api/queries/employees/employees';
import {
  useCreateEmployee,
  useDeleteEmployeeDocument,
  useUpdateEmployee,
  useUploadEmployeeDocument
} from '@api/mutations/employees/employees';
import { useAllRoles } from '@api/queries/roles/roles';
import { AxiosRequestConfig, AxiosProgressEvent } from 'axios';
//helpers
import { onUploadProgress } from '@src/helpers/utility';
//recoil
import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupAtom } from '@atoms/popupAtom';
import { createEmployeeAtom } from '@atoms/createEmployeeAtom';

let doc_num = 0;
let employee_id = '0';

export function useCreateEmployeeHook() {
  const [formAtom, setCreateEmployee] = useRecoilState(createEmployeeAtom);
  const setPopup = useSetRecoilState(popupAtom);

  const navigate = useNavigate();
  const params = useParams();
  const employeeId = params.id ? params.id : undefined;

  const [documents, setDocuments] = useState<File[]>([]);
  const [enable, setEnable] = useState('');
  const [uploadPercent, setUploadPercent] = useState<number>(0);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    getValues,
    formState: { isDirty, dirtyFields },
    reset
  } = useForm<EmployeeCreateRequest>({
    values: formAtom ? formAtom : undefined
  });

  const sector = watch('sector');

  const { data: sectors } = useAllSectors(err => {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  });
  const { data: positions, refetch: refetchPositions } = useAllPositions(
    sector,
    err => {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    },
    onPositionSuccess
  );
  const {
    data: singleEmployee,
    refetch,
    isLoading: isLoadingEmployee
  } = useSingleEmployee(employeeId, onErrorSingleEmployee, onSuccessSingleEmployee);

  const { mutate: createEmployee } = useCreateEmployee(handleSuccessCreate, handleErrorCreate);
  const { mutate: editEmployee } = useUpdateEmployee(handleSuccessCreate, handleErrorCreate);

  const { mutate: uploadDocument, isLoading } = useUploadEmployeeDocument(
    onSuccessUploadDocument,
    onErrorUploadDocument
  );

  const { mutate: deleteDocument } = useDeleteEmployeeDocument(
    onSuccessDeleteDocument,
    onErrorUploadDocument
  );
  const { data: roles } = useAllRoles((err: Error) => {});

  const uploadPercentOptions: AxiosRequestConfig = {
    onUploadProgress: (progressEvent: AxiosProgressEvent) =>
      onUploadProgress(progressEvent, setUploadPercent)
  };

  function handleSuccessCreate(data: Employee) {
    if (employeeId) {
      setEnable('');
      refetch();
    } else {
      employee_id = data.user_info.id;
      documents.forEach(doc => {
        const formData = new FormData();
        formData.append('document', doc);
        uploadDocument({
          id: data.user_info.id,
          data: formData,
          uploadPercentOptions: uploadPercentOptions
        });
      });
      if (documents.length === 0) {
        navigate('/cooperation/employees/' + data.user_info.id);
      }
    }
  }

  function handleErrorCreate(err: CreateEmployeeErrorType) {
    if (err.errors) {
      for (const key in err.errors) {
        setError(
          key as keyof EmployeeCreateRequest,
          {
            type: 'custom',
            message: err.errors[key as keyof EmployeeCreateRequest]?.join(', ')
          },
          {
            shouldFocus: true
          }
        );
      }
    } else {
      setPopup({
        open: true,
        title: err.message,
        content: '',
        variant: 'error'
      });
    }
  }

  function onSuccessUploadDocument(data: ImageType) {
    if (employeeId) {
      refetch();
    } else {
      doc_num++;

      if (doc_num === documents.length) {
        doc_num = 0;
        navigate('/cooperation/employees/' + employee_id);
        employee_id = '0';
        setCreateEmployee(null);
      }
    }
  }

  function onErrorUploadDocument(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }

  function onSuccessSingleEmployee(data: Employee) {
    setCreateEmployee({
      user_info: { ...data.user_info, role_id: data.user_info.role?.id.toString() },
      cooperation_info: {
        ...data.cooperation_info,
        employee_position_id: ''
      },
      sector: data.cooperation_info.employee_position.employee_sector.id?.toString()
    });
    refetchPositions();
  }
  function onErrorSingleEmployee(err: Error) {
    setPopup({
      open: true,
      title: err.message,
      content: '',
      variant: 'error'
    });
  }
  function onSuccessDeleteDocument() {
    refetch();
  }

  function onPositionSuccess(data: EmployeePositionType[]) {
    if (singleEmployee) {
      if (
        +sector === singleEmployee.data.data.cooperation_info.employee_position.employee_sector.id
      ) {
        setValue(
          'cooperation_info.employee_position_id',
          singleEmployee.data.data.cooperation_info.employee_position.id.toString()
        );
      } else {
        setValue('cooperation_info.employee_position_id', '');
      }
    }
  }

  const onSubmit = (data: EmployeeCreateRequest) => {
    if (employeeId) {
      if (data.cooperation_info.employee_position_id === '') {
        setError('cooperation_info.employee_position_id', {});
      } else {
        editEmployee({ ...data, employeeId: +employeeId });
      }
    } else {
      data.user_info.phone = '+3816' + data.user_info.phone;
      createEmployee(data);
    }
  };

  const handleFileDelete = (file: File | ImageType) => {
    if (employeeId) {
      if ('id' in file) {
        deleteDocument({ objectId: employeeId, imageID: file.id });
      }
    } else {
      const tmp = [...documents];

      tmp.splice(
        tmp.findIndex(f => f == file),
        1
      );

      setDocuments(tmp);
    }
  };

  const onDropCallback = (file: File) => {
    if (employeeId) {
      const formData = new FormData();
      formData.append('document', file);
      uploadDocument({
        id: employeeId,
        data: formData,
        uploadPercentOptions: uploadPercentOptions
      });
    } else setDocuments([...documents, file]);
  };

  const onCreateRoleClick = () => {
    setCreateEmployee(getValues());
  };

  function handleCancel() {
    setEnable('');
    reset();
    if (singleEmployee)
      setValue(
        'cooperation_info.employee_position_id',
        singleEmployee.data.data.cooperation_info.employee_position.id.toString()
      );
  }

  return {
    control,
    documents,
    onDropCallback,
    sectors: sectors ? sectors.data.data : [],
    positions: positions ? positions.data.data : [],
    setValue,
    handleFileDelete,
    isLoading,
    handleSubmit,
    onSubmit,
    uploadPercent,
    roles: roles ? roles.data.data : [],
    onCreateRoleClick,
    enable,
    setEnable,
    dirtyFields,
    isDirty,
    singleEmployee: singleEmployee?.data.data,
    isLoadingEmployee,
    handleCancel
  };
}
